import React, { useState, useEffect, useCallback, useContext, forwardRef, useRef, useImperativeHandle } from 'react';
import { server_var } from "../config/servervar";
import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import auth, { hooks } from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import { View, Text } from "react-native"
import Icon from '@mdi/react';
import { mdiAccount, mdiBasket, mdiCloseCircle, mdiPhone, mdiCamera, mdiImage, mdiSend, mdiNote, mdiChevronDown, mdiCloseCircleOutline, mdiCheckboxMarkedCircleOutline, mdiChevronUp, mdiPaperclip, mdiBellOutline, mdiChatOutline, mdiChat, mdiClock, mdiStore, mdiPill, mdiCalendar, mdiMap, mdiHistory, mdiChartBar, mdiHeadset } from '@mdi/js';
import { mdiShare, mdiVideo, mdiMinusCircle, mdiPlusCircle, mdiPlus, mdiAccountCircle, mdiChevronRightCircle } from '@mdi/js';
import { mdiCheckCircle, mdiCheckCircleOutline, mdiCheckboxBlankCircleOutline, mdiFile, mdiBook } from '@mdi/js';
import { mdiChatQuestion, mdiClose, mdiNoteOutline, mdiCircleMedium, mdiBookOpenVariant, mdiFileImagePlus } from '@mdi/js';
import { mdiPrinter, mdiBarcodeScan, mdiFileOutline, mdiBikeFast, mdiPlusCircleOutline, mdiVideoPlus } from '@mdi/js';
import { mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline, mdiAlertCircleOutline, mdiExitToApp, mdiNotePlus, mdiTrashCanOutline, mdiCalendarClock } from '@mdi/js';
import { mdiFrequentlyAskedQuestions, mdiChevronLeft, mdiChevronRight, mdiHome, mdiMagnify, mdiEmail, mdiFacebook, mdiWeb, mdiClockAlertOutline } from '@mdi/js';
import { mdiChevronUpCircle, mdiChevronDownCircle, mdiTrayArrowUp } from '@mdi/js';
import axios from 'axios';
import { mdiNoteEditOutline, mdiFilePdfBox, mdiNoteTextOutline } from '@mdi/js';
import { DatePicker, TimePicker } from 'antd';
import { useNavigation } from "@pankod/refine-core";
import moment from 'moment';
// import { WebView } from 'react-native-web-webview';
import { Image } from 'react-native-web';
import { Image as AntDImage } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { unstable_createElement } from 'react-native-web';

export function getSocketClient() {
  // const socket = io(server_var.server_api, {
  //   transports: ['websocket'],
  //   forceNew: true,
  // });
  const socket = io(server_var.server_api);
  const client = feathers();
  client.configure(socketio(socket));
  client.configure(auth({ storage: window.localStorage }));
  return { client, socket };
}

export async function getDeviceId() {
  try {
    // const value = await AsyncStorage.getItem('@deviceId')
    const value = window.localStorage.getItem('@deviceId');
    if (value !== null) {
      return value
    } else {
      const tmp1 = uuidv4();
      storeDeviceId(tmp1);
      return tmp1
    }
  } catch (e) {
    const tmp1 = uuidv4();
    storeDeviceId(tmp1);
    return tmp1
  }
}

async function storeDeviceId(val) {
  try {
    await window.localStorage.setItem('@deviceId', val)
  } catch (e) { }
}

export function numberWithCommas(x) {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return x.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function CustomIcon({ name, size, color }) {
  const mapping = [
    { name: 'camera', icon: mdiCamera },
    { name: 'image', icon: mdiImage },
    { name: 'send', icon: mdiSend },
    { name: 'basket', icon: mdiBasket },
    { name: 'account', icon: mdiAccount },
    { name: 'account-circle', icon: mdiAccountCircle },
    { name: 'close-circle', icon: mdiCloseCircle },
    { name: 'phone', icon: mdiPhone },
    { name: 'note', icon: mdiNote },
    { name: 'share', icon: mdiShare },
    { name: 'video', icon: mdiVideo },
    { name: 'minus-circle', icon: mdiMinusCircle },
    { name: 'plus-circle', icon: mdiPlusCircle },
    { name: 'plus', icon: mdiPlus },
    { name: 'check-circle', icon: mdiCheckCircle },
    { name: 'check-circle-outline', icon: mdiCheckCircleOutline },
    { name: 'checkbox-blank-circle-outline', icon: mdiCheckboxBlankCircleOutline },
    { name: 'file', icon: mdiFile },
    { name: 'book', icon: mdiBook },
    { name: 'chat-question', icon: mdiChatQuestion },
    { name: 'close', icon: mdiClose },
    { name: 'note-outline', icon: mdiNoteOutline },
    { name: 'circle-medium', icon: mdiCircleMedium },
    { name: 'printer', icon: mdiPrinter },
    { name: 'barcode-scan', icon: mdiBarcodeScan },
    { name: 'file-outline', icon: mdiFileOutline },
    { name: 'bike-fast', icon: mdiBikeFast },
    { name: 'checkbox-marked-outline', icon: mdiCheckboxMarkedOutline },
    { name: 'checkbox-blank-outline', icon: mdiCheckboxBlankOutline },
    { name: 'alert-circle-outline', icon: mdiAlertCircleOutline },
    { name: 'exit-to-app', icon: mdiExitToApp },
    { name: 'note-edit-outline', icon: mdiNoteEditOutline },
    { name: 'file-pdf-box', icon: mdiFilePdfBox },
    { name: 'chevron-down', icon: mdiChevronDown },
    { name: 'chevron-up', icon: mdiChevronUp },
    { name: 'chevron-right', icon: mdiChevronRight },
    { name: 'check-circle-outline', icon: mdiCheckCircleOutline },
    { name: 'checkbox-blank-circle-outline', icon: mdiCheckboxBlankCircleOutline },
    { name: 'close-circle-outline', icon: mdiCloseCircleOutline },
    { name: 'checkbox-marked-circle-outline', icon: mdiCheckboxMarkedCircleOutline },
    { name: 'checkbox-blank-circle-outline', icon: mdiCheckboxBlankCircleOutline },
    { name: 'paperclip', icon: mdiPaperclip },
    { name: 'account-details', icon: mdiAccount },
    { name: 'bell-outline', icon: mdiBellOutline },
    { name: 'chat', icon: mdiChat },
    { name: 'clock', icon: mdiClock },
    { name: 'store', icon: mdiStore },
    { name: 'pill', icon: mdiPill },
    { name: 'calendar', icon: mdiCalendar },
    { name: 'map', icon: mdiMap },
    { name: 'history', icon: mdiHistory },
    { name: 'chart-bar', icon: mdiChartBar },
    { name: 'headset', icon: mdiHeadset },
    { name: 'frequently-asked-questions', icon: mdiFrequentlyAskedQuestions },
    { name: 'chevron-left', icon: mdiChevronLeft },
    { name: 'home', icon: mdiHome },
    { name: 'magnify', icon: mdiMagnify },
    { name: 'email', icon: mdiEmail },
    { name: 'facebook', icon: mdiFacebook },
    { name: 'web', icon: mdiWeb },
    { name: 'book-open-variant', icon: mdiBookOpenVariant },
    { name: 'plus-circle-outline', icon: mdiPlusCircleOutline },
    { name: 'chevron-right-circle', icon: mdiChevronRightCircle },
    { name: 'file-image-plus', icon: mdiFileImagePlus },
    { name: 'video-plus', icon: mdiVideoPlus },
    { name: 'note-plus', icon: mdiNotePlus },
    { name: 'trash-can-outline', icon: mdiTrashCanOutline },
    { name: 'clock-alert-outline', icon: mdiClockAlertOutline },
    { name: 'calendar-clock', icon: mdiCalendarClock },
    { name: 'chevron-up-circle', icon: mdiChevronUpCircle },
    { name: 'chevron-down-circle', icon: mdiChevronDownCircle },
    { name: 'note-plus', icon: mdiNotePlus },
    { name: 'note-text-outline', icon: mdiNoteTextOutline },
    { name: 'tray-arrow-up', icon: mdiTrayArrowUp },
  ]

  const iconPath = mapping.find(item => item.name == name)?.icon
  if (iconPath) {
    return (
      <Icon path={mapping.find(item => item.name == name).icon}
        size={size / 24}
        color={color} />
    )
  } else {
    return (
      <View>
        <Text>icon {name}</Text>
      </View>
    )
  }
}

export function getDistance(lat1, lon1, lat2, lon2) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist;
  }
}

export async function uploadPhoto(mode, multiple, photoType, targetFiles) {
  let retImage = [];
  const jwtToken = await getJwtToken();
  const FormData = require("form-data");
  let uploadTo = mode;
  if (mode == 'banner') {
    uploadTo = 'feed';
  }
  if (mode == 'avatar') {
    uploadTo = 'profile';
  }
  for (const item of targetFiles) {
    const form = new FormData();
    const headers = form.getHeaders ? form.getHeaders() : { 'Content-Type': 'multipart/form-data' };
    form.append("tmpFile", (item));
    let originalFileName = item.name;
    const config = { headers, params: { uploadTo, jwtToken, originalFileName } };
    const uploadResult = await axios.post(`${server_var.server_api}/uploadFile`, form, config);
    console.log('\n\n\nuploadResult', uploadResult);
    let fileName;
    if (uploadResult?.body) {
      fileName = JSON.parse(uploadResult?.body)?.filename;
    } else if (uploadResult?.data) {
      fileName = uploadResult?.data?.filename;
    }
    console.log('fileName:' + fileName);
    retImage.push({ filename: fileName });
  }
  return retImage;
};

// uploadFile(mode,e.target.files);
export async function uploadFile(mode, targetFiles) {
  const FormData = require("form-data");
  for (const file of targetFiles) {
    const form = new FormData();
    const headers = form.getHeaders ? form.getHeaders() : { 'Content-Type': 'multipart/form-data' };
    form.append("tmpFile", (file));
    const jwtToken = await getJwtToken();
    const config = { headers, params: { uploadTo: mode, jwtToken, originalFileName: file.name } };
    const uploadResult = await axios.post(`${server_var.server_api}/uploadFile`, form, config);
    console.log('\n\n\nuploadResult', uploadResult);
    let fileName;
    if (uploadResult?.body) {
      fileName = JSON.parse(uploadResult?.body)?.filename;
    } else if (uploadResult?.data) {
      fileName = uploadResult?.data?.filename;
    }
    console.log('fileName:' + fileName);
    return { originalFileName: file.name, newFileName: fileName };
  }
  return null;
}

export function CustomAudio(props) {
  return null;
}

export function CustomVideo(props) {
  const Video = (props) => {
    const attrs = {
      src: props.src ?? props.source,
      style: props.style,
      controls: "controls",
      controlsList: "nodownload",
      autoplay: "autoplay",
      type: "video/mp4",
      //poster: props.poster,
    }
    return unstable_createElement("video", attrs);
  }
  return <Video {...props} />
}

export function useCustomNavigation() {
  return useNavigation()
}

export async function removeJwtFromAsyncStore() {
  return;
}

export async function getJwtToken() {
  return window.localStorage.getItem('feathers-jwt');
}

export async function isJailbreak() {
  return Promise.resolve(false);
}

export async function isWrongCert() {
  return Promise.resolve(false);
}

// export const ImageWithAuth = ({ url, style }) => {
//   const [fetchedImage, setFetchedImage] = useState()
//   const img = React.createRef()


//   useEffect(() => {
//     axios
//       .get(`${url}`, {
//         responseType: 'arraybuffer',
//         headers: { Accept: 'image/jpeg', headertemp: server_var.deliProdMode == 'uat' ? server_var.headertempUat : server_var.headertempProd },
//       })
//       .then((res) => {
//         const blob = new Blob([res.data], { type: 'image/jpeg' })
//         const objectURL = URL.createObjectURL(blob)
//         setFetchedImage(objectURL)
//       })
//   }, [url])

//   useEffect(() => {
//     if (img.current && fetchedImage) {
//       img.current.src = fetchedImage
//     }
//   }, [fetchedImage])

//   return <img src={''} alt={'Loading...'} ref={img} style={style} />
// }

export const ImageWithAuth = ({ url, style }) => {
  // return <img src={''} alt={'Loading...'} ref={img} style={style} />
  return (<Image
    source={{ uri: url, headers: { headertemp: server_var.deliProdMode == 'uat' ? server_var.headertempUat : server_var.headertempProd } }}
    style={style} resizeMode="contain"
  />)
}

function getDate(str) {
  const result = (str) ? moment(str, "DD/MM/YYYY") : null
  console.log(result)
  return result;
}
function getTime(str) {
  const result = (str) ? moment(str, "HH:mm") : null
  console.log(result)
  return result;
}
export function CustomWebDateTimePicker({ value, pickerType, onPickerChange }) {
  console.log('value', value)

  switch (pickerType) {
    case 'date':
      const resultDate = getDate(value);
      return <DatePicker onChange={onPickerChange} value={resultDate} format='DD/MM/YYYY' getPopupContainer={(triggerNode) => {
        return triggerNode.parentNode;
      }} />
      break;
    case 'time':
      const resultTime = getTime(value);
      return <TimePicker onChange={onPickerChange} value={resultTime} format='HH:mm' getPopupContainer={(triggerNode) => {
        return triggerNode.parentNode;
      }} />
      break;
    default:
      return <></>
      break;
  }
}


export function CustomMobileDateTimePicker({ data, pickerType, onPickerChange }) {
  return <></>;
}

export function useCustomIsFocused() {
  return false;
}

export function useCustomSafeAreaInsets() {
  return { bottom: 10 }
}

export const CustomTabActions = null;

export const CustomCommonActions = null;

export function useCustomFocusEffect() {
  return console.log('fake focus effect');
}

export function useCustomFocusEffectCallBack(p1, p2) {
  return useEffect(p1, p2)
}

export const CustomClipboard = null;

export const CustomHeaderBackButton = () => <View />;


export function getFlowInfo() {
  return;
}

export function getNextFlow() {
  return;
}

export async function updateTc() {
  return
}

export function CustomDatePicker5({ value, onChange, dialogWidth }) {
  let validValue;
  if (value == null) {
    validValue = null;
  } else if (typeof value == 'string') {
    validValue = moment(value, 'DD/MM/YYYY')
  } else {
    validValue = moment(value);
  }

  return (
    <DatePicker
      value={validValue}
      onChange={(date) => onChange(date)}
      format={'DD/MM/YYYY'}
    />
  )
}

export function CustomTimePicker5({ value, onChange, dialogWidth }) {
  return (
    <TimePicker
      onChange={(time) => onChange(time)}
      format={'HH:mm'}
    />
  )
}

export function CustomWebView(props) {
  // return <WebView {...props} />
  return <View {...props} />
}

export async function genActivityLog(globalState, activityName, params) {
  return;
}

export function CustomAntDImage({ style, source }) {
  return <AntDImage width={style.width} height={style.height} src={source.uri} />
}

export function getVersionNumber(version) {
  const a = version.split('.')
  return (a[0] * 10000 + a[1] * 100 + a[2] * 1)
}