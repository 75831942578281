import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, SafeAreaView, ScrollView, Dimensions, Image, ImageBackground, TouchableOpacity, TextInput, FlatList, Platform } from 'react-native';
import { store } from '../sharewebapp/store'
import { fontStyles, themeColor } from '../config/servervar';
import { appLogin } from '../sharewebapp/common1.js';
import { server_var } from '../config/servervar';


export function PasswordEnterPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const { userId, memberStatus, mobileNumber } = route?.params;
  const [errorObj, setErrorObj] = useState({ errorAt: [], errorMsg: '' });

  const FIELD_LIST = [
    { id: 1, title: 'รหัสผ่าน', field: 'password', type: 'textinput', memberStatus: ['verified', 'new'] },
    { id: 2, title: 'ตั้งรหัสผ่านใหม่', field: 'newpassword', type: 'textinput', memberStatus: ['new'] },
    { id: 3, title: 'ยืนยันรหัสผ่านใหม่', field: 'renewpassword', type: 'textinput', memberStatus: ['new'] },
    { id: 4, title: '', type: 'errorMsg', errorMsg: errorObj.errorMsg },
    {
      id: 5, title: 'ต่อไป', type: 'button', onPress: async () => {
        setErrorObj({ errorAt: [], errorMsg: "" });
        console.log('point1 ' + memberStatus);
        if (memberStatus == 'verified') {
          try {
            await appLogin(mobileNumber, data.password, globalState, dispatch);
            navigation.reset({ index: 0, routes: [{ name: 'Main' }] })
          } catch (e) {
            console.log('point2 ' + e);
            setErrorObj({ errorAt: ['newpassword'], errorMsg: "รหัสผ่านไม่ถูกค้อง" });
            return;
          }
        } else if (memberStatus == 'new') {
          const blankField = ['newpassword', 'renewpassword'].filter((item) => data[item] == "");
          if (blankField.length > 0) {
            setErrorObj({ errorAt: blankField, errorMsg: "ไม่สามารถเว้นไว้ได้" });
            return;
          }
          if (data['newpassword'] != data['renewpassword']) {
            setErrorObj({ errorAt: ['newpassword', 'renewpassword'], errorMsg: "รหัสผ่านไม่ตรงกัน" });
            return;
          }
          if (data['password'] == data['newpassword']) {
            setErrorObj({ errorAt: ['newpassword'], errorMsg: "รหัสผ่านใหม่ตรงกับรหัสเดิม" });
            return;
          }
          if (!validatePassword(data['newpassword'])) {
            setErrorObj({
              errorAt: ['newpassword'],
              // errorMsg: 'รหัสผ่านง่ายเกินไป(ต้องยาวกว่า 8 ตัว และมีทั้งตัวเลข ตัวอักษรตัวเล็ก ตัวใหญ่ และสัญลักษณ์)'
              errorMsg: 'รหัสผ่านง่ายเกินไป(ต้องยาวกว่า 12 ตัว มีทั้งตัวเลขและตัวอักษร)'
            })
            return;
          }
          console.log('point4');
          const defHeader = { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json' } };
          const res3 = await fetch(`${server_var.server_api}/changePwWithOldPw`, {
            ...defHeader,
            body: JSON.stringify({ password: data.password, newpassword: data.newpassword, mobileNumber })
          });
          const resJson = await res3.json()
          console.log('hello11:' + JSON.stringify(resJson));
          if (resJson.result) {
            await appLogin(mobileNumber, data.newpassword, globalState, dispatch);
            if (Platform.OS == 'web') {
              alert("รหัสผ่านถูกเปลี่ยนแล้ว");
              navigation.goBack(1);
            } else {
              dispatch({ type: 'showToast', payload: { text: 'รหัสผ่านถูกเปลี่ยนแล้ว' }, });
              navigation.reset({ index: 0, routes: [{ name: 'Main' }] })
            }
          } else {
            setErrorObj({ errorAt: ['password'], errorMsg: (resJson.msg ?? "รหัสผ่านเดิมไม่ถูกต้อง") });
          }
        }
      }
    },
  ];

  const [data, setData] = useState(Object.fromEntries(
    FIELD_LIST.filter(item => item.type == 'textinput').map(item => ([item.field, '']))
  ));

  useEffect(() => {
    if (route?.params?.noBackButton) {
      navigation.setOptions({ headerLeft: (props) => (<View />) });
    }
  }, [])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ alignItems: 'center', paddingHorizontal: 10, flex: 1 }}>
        <Text style={{
          marginTop: 10, marginBottom: 10, color: themeColor.color1, fontWeight: 'bold', fontSize: 20
        }}>
          รหัสผ่าน
        </Text>
        {
          FIELD_LIST.filter(item => (item?.memberStatus?.includes(memberStatus) || !item?.memberStatus))
            .map(item => (
              <ScreenItem key={item.id.toString()} item={item} setData={setData} data={data} errorObj={errorObj} />
            ))
        }
      </View>
    </SafeAreaView >
  )
}


function ScreenItem({ item, setData, data, errorObj }) {
  if (item.type == 'textinput') {
    return (
      <TextInput
        style={[
          { height: 40, backgroundColor: 'white', width: '80%', borderWidth: 1, marginVertical: 10, borderRadius: 5, paddingHorizontal: 5 },
          errorObj?.errorAt?.includes(item.field) ? { borderColor: 'red' } : { borderColor: 'lightgray' }
        ]}
        secureTextEntry={true}
        value={data[item.field]}
        onChangeText={text => setData(prev => ({ ...prev, [item.field]: text }))}
        placeholder={item.title}
      />
    )
  } else if (item.type == 'button') {
    return (
      <TouchableOpacity onPress={() => item.onPress()} style={{ width: '80%', height: 40, borderRadius: 5, justifyContent: 'center', alignItems: 'center', backgroundColor: themeColor.color1, marginTop: 40 }}>
        <Text style={{ color: 'white' }}>{item.title}</Text>
      </TouchableOpacity>
    )
  } else if (item.type == 'errorMsg' && item.errorMsg) {
    return <Text style={{ color: 'red' }}>{item.errorMsg}</Text>
  } else {
    return <View />
  }
}

const validatePassword = (password) => {
  // const mediumPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
  const mediumPassword = new RegExp('(?=.*[a-zA-Z])(?=.*[0-9])(?=.{12,})')
  // https://www.section.io/engineering-education/password-strength-checker-javascript/
  // min length 6, at least 1 number and 1 english or thai character 
  console.log('test result:' + mediumPassword.test(password));
  return mediumPassword.test(password)
}
