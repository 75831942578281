import { useEffect } from "react";

const liveProvider = (globalState, dispatch) => {
  console.log('CONSOLE LOG HELLO NEW CREATE LIVE PROVDER');
  return {
    subscribe: ({ channel, params, types, callback }) => {
      // client
      console.log('HELLO live provider subscribe:' + globalState?.user?.id);
    },
    unsubscribe: (subscription) => {
      console.log('HELLO live provider UNSUBSCRIBE');
    },
  }
};

export default liveProvider;