import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import moment from 'moment';
import { CustomIcon, CustomDatePicker5 } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';
import { PHARMANOTE_STATUS } from '../sharewebapp/common1';
import { Dialog } from '../sharewebapp/pharmanotepart';

export function ManagePharmaNotePart({ navigation }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [dialog, setDialog] = useState({ visible: false });
  const [searchData, setSearchData] = useState({ shopId: null, shopName: null, statusId: null, status: null, dateFrom: null, dateTo: null });

  useEffect(() => {
    EventEmitter.addListener('ManagePharmaNote:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManagePharmaNote:refresh', handleRefresh);
    };
  }, [])

  const handleRefresh = () => {
    loadDataList(0);
  }

  useEffect(() => {
    loadDataList(0);
  }, [searchData?.shopId, searchData?.statusId, searchData?.dateFrom, searchData?.dateTo])

  const onPharmaNoteAndOrderPress = (pharmaNoteId, ordermainId) => {
    navigation.navigate('ViewPharmaNoteOrderAppointment', { pharmaNoteId, ordermainId, showEditPharmaNote: true })
  }

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res;
      let query = { pharmaId: globalState?.user?.id, $skip: pSkip ?? skip, $sort: { id: -1 } }

      let criteriaDateFrom;
      if (searchData?.dateFrom) {
        criteriaDateFrom = moment(searchData?.dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        criteriaDateFrom = moment().format('YYYY-MM-DD');
      }
      let criteriaDateTo;
      if (searchData?.dateTo) {
        criteriaDateTo = moment(searchData?.dateTo, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
      } else {
        criteriaDateTo = moment().add(1, 'days').format('YYYY-MM-DD');
      }
      query = {
        ...query,
        $and: [
          { createdAt: { $gte: criteriaDateFrom } },
          { createdAt: { $lte: criteriaDateTo } }
        ],
      };

      if (searchData?.shopId) {
        query = {
          ...query,
          shopId: searchData?.shopId
        }
      }
      if (searchData?.statusId) {
        query = {
          ...query,
          status: PHARMANOTE_STATUS.find(item => item.id == searchData?.statusId)?.value
        }
      }

      try {
        res = await globalState.client.service('pharmanotes').find({ query });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  const onBackDialog = async (backItem) => {
    if (backItem?.type == 'statuslist') {
      setSearchData(prev => ({ ...prev, statusId: backItem?.id, status: backItem?.title }));
    } else if (backItem?.type == 'shoplist') {
      setSearchData(prev => ({ ...prev, shopId: backItem?.shop?.id, shopName: backItem?.shop?.shopThName }));
    }
    setDialog(prev => ({ ...prev, visible: false }));
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth };
  const dialogContent = [
      {
        type: 'statuslist',
        title: 'สถานะ',
        com: <StatusList {...dialogProp} STATUS_LIST={PHARMANOTE_STATUS} />,
      },
      {
        type: 'shoplist',
        title: 'เลือกสาขา',
        com: <ShopList {...dialogProp} />,
      },
  ]

  return (
    <View style={{ flex: 1 }}>
      <SearchBox dialog={dialog} setDialog={setDialog} searchData={searchData} setSearchData={setSearchData} />
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '80vh' } : null]}
        renderItem={({ item }) => (
          <PharmaNotePharmacistItem item={item} onPharmaNoteAndOrderPress={onPharmaNoteAndOrderPress} />
        )}
      />
      <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
    </View>
  )
}

function SearchBox({ dialog, setDialog, searchData, setSearchData }) {
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  return (
    <View style={{ marginHorizontal: 20, marginVertical: 10 }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>สาขา</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: true, type: 'shoplist' }))}>
            <View style={{
              flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{searchData?.shopName ?? ''}</Text>
              <CustomIcon name="store" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
      </View>

      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ตั้งแต่วันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateFrom}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateFrom: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ถึงวันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateTo}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateTo: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>

      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>สถานะ</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: true, type: 'statuslist' }))}>
            <View style={{
              flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{searchData?.status ?? 'ทั้งหมด'}</Text>
              <CustomIcon name="chevron-down" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

function PharmaNotePharmacistItem({ item, onPharmaNoteAndOrderPress }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, marginHorizontal: 20, }}
      onPress={() => onPharmaNoteAndOrderPress(item.id, item?.oldqueline?.ordermain?.id)}
    >
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ paddingBottom: 10, flex: 1 }}>
          {[
            { id: 1, title: 'วันและเวลาที่ปรึกษา', value: item?.startTime ? moment(item?.startTime).format('DD/MM/YYYY HH:mm') : '', bold: true },
            { id: 2, title: 'การปรึกษา', value: item?.id },
            { id: 3, title: 'ผู้รับบริการ', value: item?.user?.displayName },
            { id: 4, title: 'สถานะ', value: PHARMANOTE_STATUS.find(item2 => item2.value == item?.status)?.title },
          ].map(item2 => (
            <View key={item2.id.toString()} style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <View style={{ flex: 0.5 }}>
                <Text style={[item2.bold ? { fontWeight: 'bold' } : null]}>{item2?.title}</Text>
              </View>
              <View style={{ flex: 0.5 }}>
                <Text style={[item2.bold ? { fontWeight: 'bold' } : null]}>{item2?.value}</Text>
              </View>
            </View>
          ))}
        </View>
        <PharmaNoteIcon item={item} />
      </View>
    </TouchableOpacity >
  )
}

function PharmaNoteIcon({ item }) {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {[
        { id: 1, iconName: 'note-outline', visible: true },
        { id: 2, iconName: 'basket', visible: item?.oldqueline?.ordermain?.id },
      ].map(item2 => {
        if (item2.visible) {
          return (
            <View key={item2.id.toString()}
              style={{
                width: 24, height: 24, borderRadius: 12, backgroundColor: themeColor.color1,
                justifyContent: 'center', alignItems: 'center', marginHorizontal: 5
              }}>
              <CustomIcon name={item2.iconName} size={16} color="white" />
            </View>
          )
        } else {
          return <View key={item2.id.toString()} style={{ width: 24, height: 24, marginHorizontal: 5 }} />
        }
      })}
    </View>
  )
}

function ShopList({ dialog, setDialog, onBackDialog, dialogWidth }) {
  const { globalState, dispatch } = useContext(store);
  const [shopId, setShopId] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    const res = await globalState.client.service('shopusers').find({
      query: { userId: globalState.user.id }
    });
    setData(res.data)
  }
  return (
    <View style={{ paddingHorizontal: 10 }}>
      {data?.map(item => (
        <TouchableOpacity key={item.id.toString()} style={{ marginVertical: 5 }} onPress={() => {
          setShopId(item?.shopId);
          onBackDialog({ ...item, type: 'shoplist' })
        }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <CustomIcon
              name={shopId == item?.shopId ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={22} color="gray"
            />
            <Text style={{ marginLeft: 5 }}>{item?.shop?.shopThName}</Text>
          </View>

        </TouchableOpacity>
      ))}
    </View>
  )
}

function StatusList({ dialog, setDialog, onBackDialog, dialogWidth, STATUS_LIST }) {
  let statusList = [{ id: 0, title: 'ทั้งหมด' }, ...STATUS_LIST];
  return (
    <View style={{ paddingHorizontal: 5, alignItems: 'center', marginTop: 20 }}>
      {statusList?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          onBackDialog({ ...item, type: 'statuslist' })
        }}>
          <View style={{
            width: dialogWidth - 30, backgroundColor: themeColor.color1, height: 30,
            justifyContent: 'center',
            paddingHorizontal: 10,
            marginVertical: 10, borderRadius: 5
          }}>
            <Text style={{ color: 'white' }}>{item?.title}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}
