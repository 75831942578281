import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { useDebounce } from '../sharewebapp/common1';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomIcon } from '../component/custom';

export function ManageGreetingPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [catId, setCatId] = useState('00');
  const [catList, setCatList] = useState();
  const forAdmin = route?.params?.forAdmin;
  const [adminType, setAdminType] = useState();

  const SCREEN_LIST = [
    {
      id: 11, title: 'ประเภทร้านทั่วไป\nมีบริการจัดส่ง',
      iconName: require('../assets/images/Greeting_General.png'),
      onPress: () => navigation.navigate('EditGreeting', { id: 11 }),
    },
    {
      id: 12, title: 'ประเภทร้านทั่วไป\nไม่มีบริการจัดส่ง',
      iconName: require('../assets/images/Greeting_General.png'),
      onPress: () => navigation.navigate('EditGreeting', { id: 12 }),
    },
    {
      id: 13, title: 'ประเภทร้านพิเศษ\nมีบริการจัดส่ง',
      iconName: require('../assets/images/Greeting_Special.png'),
      onPress: () => navigation.navigate('EditGreeting', { id: 13 }),
    },
    {
      id: 14, title: 'ประเภทร้านพิเศษ\nไม่มีบริการจัดส่ง',
      iconName: require('../assets/images/Greeting_Special.png'),
      onPress: () => navigation.navigate('EditGreeting', { id: 14 }),
    },
    {
      id: 15, title: 'ประเภทร้าน 24 Hour\nมีบริการจัดส่ง',
      iconName: require('../assets/images/Greeting_24Hrs.png'),
      onPress: () => navigation.navigate('EditGreeting', { id: 15 }),
    },
    {
      id: 16, title: 'ประเภทร้าน 24 Hour\nไม่มีบริการจัดส่ง',
      iconName: require('../assets/images/Greeting_24Hrs.png'),
      onPress: () => navigation.navigate('EditGreeting', { id: 16 }),
    },
  ]  

  useEffect(() => {
  }, [])

  return (
    <SafeAreaView style={{ flex: 1, marginHorizontal: 20 }}>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', marginVertical: 5 }}>
        {SCREEN_LIST.map(item => (
          <View key={item.id.toString()} style={{ width: '45%', marginHorizontal: 5, marginVertical: 5 }}>
            <TouchableOpacity onPress={() => item.onPress()}>
              <View style={{
                flexDirection: 'column', alignItems: 'center', height: 120, width: '100%',
                borderRadius: 10, backgroundColor: 'white', paddingHorizontal: 10, paddingVertical: 10
              }}>
                <Image source={item.iconName} style={{ width: 50, height: 50 }} />
                <Text style={{ fontSize: 12, textAlign: 'center', marginTop: 10 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          </View>
        ))}
      </View>      
    </SafeAreaView>
  )
}