import axios, { AxiosInstance } from "axios";
import { useContext } from "react";
import {store} from "../sharewebapp/store"
import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import rest from "@feathersjs/rest-client";
import { stringify } from "query-string";

const client = feathers();
// const restClient = rest('http://testapi.local')
const restClient = rest('http://localhost:3030')
client.configure(restClient.axios(axios));
client.configure(auth({ storage: window.localStorage }));

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("feathers-jwt");
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      request.headers = { Authorization: `Bearer ${token}` };
    }
    return request;
  },
);


const dataProvider = (apiUrl, httpClient = axiosInstance) => {
  return ({
    create: async ({ resource, variables, metaData }) => {
      const url = `${apiUrl}/${resource}`;
      const { data } = await httpClient.post(url, variables);
      console.log('hello create', data);
      return { data };
    },
    createMany: async ({ resource, variables, metaData }) => {
      const response = await Promise.all(
        variables.map(async (param) => {
          const { data } = await httpClient.post(`${apiUrl}/${resource}`, param);
          return data;
        }),
      );
      return { data: response };
    },
    getList: async ({ resource, pagination, sort, filters, metaData }) => {
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 10;
      let queryStr = `$skip=${(current - 1) * pageSize}&$limit=${pageSize}`
      let sortStr = '';
      if (sort && sort.length > 0) {
        sortStr = `&$sort[${sort[0]['field']}]=${sort[0]['order'] == 'asc' ? 1 : -1}`
      }
      for (const item of filters) {
        if (item.operator == 'eq') {
          queryStr += `&${item.field}=${item.value}`;
        }
      }
      const { data, headers } = await httpClient.get(`${apiUrl}/${resource}?${queryStr}${sortStr ? sortStr : ''}`);
      return { data: data.data, total: data.total };
    },
    getOne: async ({ resource, id, metaData }) => {
      const { data, headers } = await httpClient.get(`${apiUrl}/${resource}/${id}`);
      return { data }
    },
    getMany: async ({ resource, ids }) => {
      // const { data } = await httpClient.get(`${apiUrl}/${resource}?${stringify({ id: ids })}`);
      // return { data };
    },
    update: async ({ resource, id, variables, metaData }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      const { data } = await httpClient.patch(url, variables);
      return { data };
    },
    updateMany: async ({ resource, ids, variables, metaData }) => {
      const response = await Promise.all(
        ids.map(async (id) => {
          const { data } = await httpClient.patch(`${apiUrl}/${resource}/${id}`, variables);
          return data;
        }),
      );
      return { data: response };
    },
    deleteOne: async ({ resource, id, variables, metaData }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      const { data } = await httpClient.delete(url);
      return { data };
    },
    deleteMany: async ({ resource, ids, variables, metaData }) => {
      const response = await Promise.all(
        ids.map(async (id) => {
          const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
          return data;
        }),
      );
      return { data: response };
    },
    custom: ({ url, method, sort, filters, payload, query, headers, metaData }) => Promise,
    getApiUrl: () => "",
  });
}

// import dataProvider from "@pankod/refine-simple-rest";
export default dataProvider;