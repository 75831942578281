import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { getOpenCloseTimeList } from '../sharewebapp/common1';
import { CustomIcon, useCustomFocusEffect } from '../component/custom';

export function ShopPart({ navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [seedNum, setSeedNum] = useState(0);

  const loadData = async () => {
    const res1 = await globalState.client.service('shopusers').find({
      query: {
        userId: globalState?.user?.id,
        '$shop.shopType$': 'general1'
      }
    });
    const res2 = await globalState.client.service('shops').find({
      query: {
        id: { $in: res1.data.map(item => item.shopId) },
        shopStatus: 'A',
        $limit: 5,
        $select: [
          'id', 'shopThName', 'shopTel', 'shopDesc', 'shopEnName', 'shopIdBranch',
          'shopPhoto1', 'shopThAddress', 'shopOpenCloseTimeJson', 'shopOpenCloseTime'
        ],
        $sort: { id: 1 }
      }
    })
    setData(res2.data);
  }

  useCustomFocusEffect(
    useCallback(() => {
      setSeedNum(Math.floor(Date.now() / 1000));
    }, [])
  )

  useEffect(() => {
    loadData();
  }, [globalState?.user, seedNum])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={[Platform.OS == 'web' ? { height: '95vh' } : null]}>
        <View>
          {data?.map(item => <ShopItem key={item.id.toString()} item={item} navigation={navigation} />)}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

function ShopItem({ item, navigation }) {
  return (
    <View style={{ backgroundColor: 'white', marginHorizontal: 20, marginVertical: 10, paddingHorizontal: 20, paddingVertical: 10, borderRadius: 10 }}>
      <View style={{ flexDirection: 'row', marginBottom: 20 }}>
        <Image
          source={{ uri: item?.shopPhoto1 }}
          style={{ width: 80, height: 80, borderRadius: 40 }}
        />
        <View style={{ marginLeft: 30, justifyContent: 'center', flex: 1 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ color: themeColor.color1 }}>{item?.shopThName}</Text>
          </View>
          <Text style={{ fontSize: 12, marginTop: 5 }}>รหัสสาขา {item?.shopIdBranch}</Text>
        </View>
      </View>
      {[
        { id: 1, title: 'ที่อยู่', type: 'normal', value: item?.shopThAddress, style: { borderBottomWidth: 1, borderColor: 'lightgray', borderTopWidth: 1 } },
        { id: 2, title: 'โทร', type: 'normal', value: item?.shopTel },
        { id: 3, title: 'วันเวลาทำการ', type: 'openclosetime', value: getOpenCloseTimeList(item?.shopOpenCloseTimeJson) },
      ].map(item2 => (
        <ViewItem key={item2.id.toString()} item={item2} shopId={item.id} navigation={navigation} />
      ))}
    </View>
  )
}

function ViewItem({ item, shopId, navigation }) {
  if (item.type == 'normal') {
    return (
      <View style={{
        flexDirection: 'row', marginTop: 10, paddingBottom: 10, borderTopWidth: 1, borderColor: 'lightgray', paddingTop: 10
      }}>
        <View style={{ flex: 0.4 }}>
          <Text style={{ fontWeight: 'bold' }}>{item.title}</Text>
        </View>
        <View style={{ flex: 0.6 }}>
          <Text>{item.value}</Text>
        </View>
      </View>
    )
  } else if (item.type == 'openclosetime') {
    return (
      <View style={{
        flexDirection: 'row', marginTop: 10, paddingBottom: 10, borderTopWidth: 1, borderColor: 'lightgray', paddingTop: 10
      }}>
        <View style={{ flex: 0.4 }}>
          <Text style={{ fontWeight: 'bold' }}>{item.title}</Text>
        </View>
        <View style={{ flex: 0.6 }}>
          {item?.value?.map(item2 => (
            <View key={item2.title} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 0.4 }}>
                <Text style={{ fontSize: 12 }}>{item2.title}</Text>
              </View>
              <View style={{ flex: 0.6, flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ fontSize: 12 }}>{item2.start}</Text>
                <Text>-</Text>
                <Text style={{ fontSize: 12 }}>{item2.end}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ position: 'absolute', top: 10, right: 0 }}>
          <TouchableOpacity onPress={() => navigation.navigate('ShopEdit', { shopId })}>
            <View>
              <CustomIcon name="note-edit-outline" size={22} color={'gray'} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

}
