import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, ScrollView, SafeAreaView, Dimensions, Linking, Platform, FlatList, Modal, ActivityIndicator, Alert } from 'react-native';
import { themeColor, fontStyles, server_var, default_shop_image } from '../config/servervar';
import { store } from './store'
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import { CustomIcon } from '../component/custom';
import moment from 'moment';

export function EditConsultVideoPart({ navigation, route }) {
  const [status, setStatus] = useState({});
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState(null);
  const [staffData, setStaffData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({ visible: false, data: null });
  const TIME_AUTHEN = 12; //12 hors

  useEffect(() => {
    if (route?.params?.id > 0) {
      loadData();
      loadStaffData();
    }
  }, [route?.params?.id])

  const loadData = async () => {
    let query = { id: route?.params?.id };
    let res;
    if (route?.params?.type == 'video') {
      res = await globalState.client.service('videorooms').find({ query });
    } else {
      res = await globalState.client.service('oldquelines').find({ query });
    }
    //console.log(route?.params?.type, route?.params?.id, JSON.stringify(res));
    if (res.total == 0) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูลหมายเลขนี้' }, });
      navigation.goBack();
    }
    setData(res.data[0]);
  }

  const loadStaffData = async () => {
    const resAuthenUsers = await globalState.client.service('authenlogs').find({
      query: { refId: route?.params?.id, mediaType: route?.params?.type, $limit: 20 }
    });
    setStaffData(resAuthenUsers?.data);
  }

  const onRemoveStaff = async (userId) => {
    const removeAction = async () => {
      await globalState.client.service('authenlogs').remove(null, { query: { id: userId } });
      loadStaffData();
    }

    if (Platform.OS == 'web' && window.confirm('คุณต้องการยกเลิกสิทธิ์คนนี้')) {
      removeAction();
    } else {
      Alert.alert('ยกเลิกสิทธิ์', 'คุณต้องการยกเลิกสิทธิ์คนนี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => removeAction() },
        ]
      );
    }
  }

  const onBack = async (item) => {
    setLoading(true);
    const res = await globalState.client.service('authenlogs').create({
      userId: item.id,
      refId: data?.id,
      mediaType: route?.params?.type,
      expiredDate: moment().add(TIME_AUTHEN, 'hours'),
      authenValue: {
        id: (route?.params?.type == 'video') ? data?.id : data?.oldId,
        shopId: (route?.params?.type == 'video') ? data?.oldqueline?.shop?.id : data?.shop?.id,
        shopThName: (route?.params?.type == 'video') ? data?.oldqueline?.shop?.shopThName : data?.shop?.shopThName,
        pharmaName: (route?.params?.type == 'video') ? data?.oldqueline?.pharmaUser?.displayName : data?.pharmaUser?.displayName,
        pharmaId: (route?.params?.type == 'video') ? data?.oldqueline?.pharmaUser?.id : data?.pharmaUser?.id,
        userName: (route?.params?.type == 'video') ? data?.oldqueline?.user?.displayName : data?.user?.displayName,
        userId: (route?.params?.type == 'video') ? data?.oldqueline?.user?.id : data?.user?.id,
        consultDate: (route?.params?.type == 'video') ? data?.startTime : data?.createdAt
      }
    });
    loadStaffData();
    setLoading(false);
  }

  if (data) {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView style={{ marginVertical: 10, marginHorizontal: 20 }}>

          {route?.params?.type == 'video' &&
            [{ id: 1, title: 'หมายเลขวิดีโอ', value: data?.id, textStyle: { color: themeColor.color1 } },
            { id: 2, title: 'สาขาที่ให้บริการ', value: data?.oldqueline?.shop?.shopThName, textStyle: {} },
            { id: 3, title: 'ผู้ให้บริการ', value: data?.oldqueline?.pharmaUser?.displayName ?? '', textStyle: {} },
            { id: 4, title: 'ผู้รับบริการ', value: data?.oldqueline?.user?.displayName ?? '', textStyle: {} },
            { id: 5, title: 'วันและเวลาที่รับบริการ', value: moment(data?.startTime).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
            ].map(item => (
              <View key={item.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 0.4 }}>
                  <Text style={item?.textStyle}>{item?.title}</Text>
                </View>
                <View style={{ flex: 0.1 }}>
                  <Text style={item?.textStyle}>:</Text>
                </View>
                <View style={{ flex: 0.5 }}>
                  <Text style={item?.textStyle}>{item?.value}</Text>
                </View>
              </View>
            ))}
          {route?.params?.type == 'chat' &&
            [{ id: 1, title: 'หมายเลขแชท', value: data?.oldId, textStyle: { color: themeColor.color1 } },
            { id: 2, title: 'สาขาที่ให้บริการ', value: data?.shop?.shopThName, textStyle: {} },
            { id: 3, title: 'ผู้ให้บริการ', value: data?.pharmaUser?.displayName ?? '', textStyle: {} },
            { id: 4, title: 'ผู้รับบริการ', value: data?.user?.displayName ?? '', textStyle: {} },
            { id: 5, title: 'วันและเวลาที่รับบริการ', value: moment(data?.createdAt).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
            ].map(item => (
              <View key={item.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 0.4 }}>
                  <Text style={item?.textStyle}>{item?.title}</Text>
                </View>
                <View style={{ flex: 0.1 }}>
                  <Text style={item?.textStyle}>:</Text>
                </View>
                <View style={{ flex: 0.5 }}>
                  <Text style={item?.textStyle}>{item?.value}</Text>
                </View>
              </View>
            ))}

          <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 5, flex: 1 }}>
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }}
              onPress={() => {
                if (route?.params?.type == 'video') {
                  navigation.navigate('ViewConsultVideo', { id: data?.id });
                } else {
                  const paramChat = {
                    queId: data?.oldId,
                    viewhistory: true,
                    fakeUserId: data?.pharmaUser?.id
                  }
                  navigation.navigate('Chat', paramChat);
                }
              }} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, borderColor: themeColor.color1, borderWidth: 1
              }}>
                <Text style={{ color: themeColor.color1 }}>{route?.params?.type == 'video' ? 'เล่นวิดีโอ' : 'แสดงข้อความแชท'}</Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={{ flex: 1, marginVertical: 10, borderTopWidth: 1, borderColor: 'lightgray' }}>
            <Text style={{ fontSize: 14, marginTop: 10 }}>เพิ่มสิทธิ์การเข้าถึง{route?.params?.type == 'video' ? 'วิดีโอ' : 'แชท'}การปรึกษา</Text>
            <View style={{ flexDirection: 'column', marginVertical: 10, paddingHorizontal: 10 }}>
              {
                staffData && staffData.length > 0 && staffData.map((item2, i) => (
                  <View key={item2.id.toString()} style={{ flexDirection: 'row', marginVertical: 10 }} >
                    <View style={{ flex: 0.3 }}>
                      <Image
                        style={{ width: 50, height: 50, borderRadius: 25 }} source={item2?.user?.avatar ? { uri: item2?.user?.avatar } : { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }}
                      />
                      <TouchableOpacity
                        style={{ position: "absolute", top: -5, left: -5 }}
                        onPress={() => {
                          onRemoveStaff(item2?.id);
                        }}
                      >
                        <CustomIcon name="close-circle" size={20} color={"red"} />
                      </TouchableOpacity>
                    </View>
                    <View style={{ flex: 0.7 }}>
                      <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item2?.user?.displayName}</Text>
                      <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item2?.user?.staffId}</Text>
                      <Text style={{ flex: 1, flexWrap: 'wrap', color: 'gray' }}>สิทธิ์หมดอายุ : {moment(item2?.expiredDate).format('DD/MM/YYYY HH:mm')}</Text>
                    </View>
                  </View>
                ))
              }
              <TouchableOpacity
                style={{
                  flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                  marginVertical: 20, height: 50, width: 50
                }}
                onPress={() => setDialog(prev => ({ ...prev, visible: true }))}
              >
                <View
                  style={{
                    height: 50, width: 50, borderRadius: 25, flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: 'lightgray'
                  }}
                >
                  <CustomIcon name='plus' size={24} color="gray" />
                </View>
                <Text style={{ color: 'gray', textAlign: 'center', fontSize: 12, marginTop: 10 }}>เพิ่ม</Text>
              </TouchableOpacity>
            </View>
          </View>

          {loading &&
            <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
              <ActivityIndicator size='large' />
            </View>
          }
          <StaffDialog dialog={dialog} setDialog={setDialog} onBack={onBack} />

        </ScrollView>
      </SafeAreaView>
    )
  } else {
    return <View />
  }
}

function StaffDialog({ dialog, setDialog, onBack }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;

  useEffect(() => {
    loadDataList(0);
  }, [debouncedSearchTerm])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { staffFlag: 1, $skip: pSkip ?? skip, $sort: { id: 1 }, $limit: 10 };
      if (searchArray.length > 0) {
        const tmpSearch = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { displayName: { $like: `%${tmpSearch}%` } },
            { staffPhoneNumber: { $like: `%${tmpSearch}%` } },
            { staffId: { $like: `%${tmpSearch}%` } },
          ]
        };
      }
      let res;
      try {
        res = await globalState.client.service('users').find({ query });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30,
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <View style={{ width: dialogWidth, height: (dialogWidth * 1.5) - 30 }}>
            <TextInput
              value={searchText}
              onChangeText={text => setSearchText(text)}
              placeholder={'ชื่อหรือรหัสพนักงาน'}
              style={{
                alignSelf: 'center', borderColor: 'lightgray', borderWidth: 1, marginTop: 10, marginBottom: 5, height: 40,
                paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white', marginHorizontal: 20,
                width: (dialogWidth - 30)
              }}
            />
            <FlatList
              keyExtractor={(item) => item.id.toString()}
              data={dataList}
              onEndReached={(xxx) => loadDataList()}
              onEndReachedThreshold={0.5}
              onRefresh={() => loadDataList(0)}
              refreshing={(loading && skip == 0)}
              style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: 100 } : null]}
              renderItem={({ item }) => (<StaffItem item={item} setDialog={setDialog} onBack={onBack} />)}
            />
          </View>

          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

function StaffItem({ item, setDialog, onBack }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, paddingHorizontal: 20, }}
      onPress={() => {
        onBack(item);
        setDialog(prev => ({ ...prev, visible: false }));
      }}>
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, flex: 1 }}>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
          <Image
            style={{ width: 40, height: 40, borderRadius: 20 }}
            source={item?.avatar ? { uri: item?.avatar } : { uri: `${server_var.server_api}/images/unknown-user-pic.jpeg` }}
          />
        </View>
        <View style={{ flex: 0.7, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.displayName}</Text>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.staffId}</Text>
        </View>
      </View>
    </TouchableOpacity >
  )
}

