import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomIcon } from '../component/custom';

export function ManageShopPart({ navigation }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [adminType, setAdminType] = useState();

  useEffect(() => {
    const loadUserRole = async () => {
      const res = await globalState.client.service('users').get(globalState?.user?.id, { query: { $select: ['roles'] } });
      setAdminType(res?.roles);
    }
    if (globalState.user) {
      loadUserRole();
    }
  }, [])

  useEffect(() => {
    loadDataList(0);
  }, [debouncedSearchTerm])

  useEffect(() => {
    EventEmitter.addListener('ManageShop:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageShop:refresh', handleRefresh);
    }
  }, [searchText])

  const handleRefresh = () => {
    loadDataList(0);
  }

  const onEdit = (id) => {
    navigation.navigate('EditShop', { id })
  }

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $skip: (pSkip ?? skip), $limit: 20, $select: ['shopThName', 'id', 'shopIdBranch'], shopStatus: 'A' };
      if (searchArray.length > 0) {
        const tmpName = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { shopIdBranch: { $like: `%${tmpName}%` } },
            { shopThName: { $like: `%${tmpName}%` } }
          ],
        };
      }
      let res;
      try {
        res = await globalState.client.service('shops').find({ query });
      } catch { }

      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        {['admin'].includes(adminType) &&
          <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
            <TouchableOpacity style={{
              height: 40, width: "100%", backgroundColor: themeColor.color1,
              borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
            }} onPress={() => navigation.navigate('EditShop', { id: 0 })}>
              <Text style={{ color: 'white' }}><CustomIcon name="plus-circle-outline" size={16} color='white' /> สร้าง</Text>
            </TouchableOpacity>
          </View>
        }
        <View style={{ flex: ['admin'].includes(adminType) ? 0.7 : 1, paddingBottom: 10, paddingTop: 5, marginTop: 5, marginLeft: ['admin'].includes(adminType) ? 15 : 0 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาร้านค้า'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />
        </View>
      </View>

      <FlatList
        style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '70vh' } : null]}
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}

        renderItem={({ item }) => (
          <ShopItem item={item} onEdit={onEdit} />
        )}
      />
    </SafeAreaView>
  )
}

function ShopItem({ item, onEdit }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, marginHorizontal: 20, }}
      onPress={() => onEdit(item.id)}
    >
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item.shopIdBranch}</Text>
        </View>
        <View style={{ flex: 0.7, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item.shopThName}</Text>
        </View>
      </View>
    </TouchableOpacity >
  )
}
