import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, ScrollView, SafeAreaView, Dimensions, Linking, Platform } from 'react-native';
import { themeColor, fontStyles, server_var, default_shop_image } from '../config/servervar';
import { store } from './store'
import { CustomIcon, CustomVideo, CustomAudio } from '../component/custom';

export function ViewConsultVideoPart({ navigation, route }) {
  const ref = useRef(null);
  const [status, setStatus] = useState({});
  const { globalState } = useContext(store);
  const [data, setData] = useState();
  const videoWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : (Dimensions.get('window').width - 10);
  const [videoHeight, setVideoHeight] = useState(videoWidth * 3 / 4);

  useEffect(() => {
    loadData(route?.params?.id)
  }, [route?.params?.id])

  const loadData = async (id) => {
    const res = await globalState.client.service('videorooms').get(id);
    setData(res);
  }

  useEffect(() => {
    if (status.isPlaying) triggerAudio(ref);
  }, [ref, status.isPlaying]);

  const triggerAudio = async (ref) => {
    if (Platform.OS == 'ios') {
      try {
        // const Audio = require('expo-av').Audio;
        await CustomAudio.setAudioModeAsync({ playsInSilentModeIOS: true });
        ref.current.playAsync();
      } catch { }
    }
  };

  const updateVideoRatioOnDisplay = (videoDetails) => {
    const { width, height } = videoDetails.naturalSize;
    console.log('videoDetails', videoDetails);
    // setVideoHeight(videoWidth / width * height);
  }

  if (route?.params?.id && data?.videoJwt) {
    const uri = `${server_var.server_api}/viewrecvideo___${Platform.OS}___${route?.params?.id}___${data?.videoJwt}`;
    //console.log('uri' + uri);
    return (
      <SafeAreaView style={{ flex: 1, alignItems: 'center' }}>
        <CustomVideo
          ref={ref}
          onPlaybackStatusUpdate={(status) => setStatus(status)}
          style={{ width: videoWidth, height: videoHeight, backgroundColor: 'black', marginVertical: 10 }}
          // source={{ uri: data?.recordingFile }}
          // source={{ uri: 'https://api02.allpharmasee.com/viewrecvideo_ios_485' }}
          source={{ uri }}
          src={uri}
          // src={data?.recordingFile}

          ignoreSilentSwitch={'ignore'}
          useNativeControls
          resizeMode="stretch"
          isLooping={false}
          shouldPlay={true}
          onReadyForDisplay={(vid) => updateVideoRatioOnDisplay(vid)}
          onError={err => console.log('error', err)}
          onLoadStart={() => console.log('on load start')}
          onLoad={() => console.log('on load')}
        />
      </SafeAreaView>
    )
  } else {
    return <View />
  }

}

