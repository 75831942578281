import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { server_var, themeColor } from '../config/servervar';
// import { useNavigation } from '@react-navigation/native';
// import { useRoute } from '@react-navigation/native';
import { store } from '../sharewebapp/store'
import moment from 'moment';
import { useDebounce } from './common1';

const GENDER_LIST = [
    { id: 1, value: 'F', title: 'หญิง' },
    { id: 2, value: 'M', title: 'ชาย' },
    { id: 3, value: 'U', title: 'ไม่ระบุ' },
]

export function ManageCustomerPart({ onPressViewUser }) {
    const { globalState } = useContext(store);
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [searchText, setSearchText] = useState('');
    const debouncedSearchTerm = useDebounce(searchText, 1000);

    useEffect(() => {
        loadData(0)
    }, [debouncedSearchTerm])

    const loadData = async (pSkip) => {
        if (!loading) {
            setLoading(true);
            let res;
            try {
                res = await globalState.client.service('users').find({
                    query: {
                        staffFlag: 0,
                        $skip: pSkip ?? skip,
                        $or: [
                            { displayName: { $like: `%${searchText}%` } },
                            { mobileNumber: { $like: `%${searchText}%` } }
                        ],
                        memberStatus: { $ne: 'unverified' },
                        $sort: { id: -1 }
                    }
                });
            } catch { }

            if (res) {
                if ((pSkip ?? skip) === 0) {
                    setDataList(res.data);
                } else {
                    setDataList(prev => [...prev, ...res.data]);
                }
                setSkip((res.skip + res.limit));
            }

            setLoading(false);
        }
    }

    return (
        <View style={{ flex: 1 }}>
            <TextInput
                value={searchText}
                onChangeText={text => setSearchText(text)}
                placeholder={'ค้นหาลูกค้าจากชื่อ นามสกุล เบอร์โทร'}
                style={{ alignSelf: 'center', marginTop: 20, marginHorizontal: 20, width: "90%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
            />
            <FlatList
                keyExtractor={(item) => item.id.toString()}
                data={dataList}
                style={{ marginTop: 20 }}
                onEndReached={(xxx) => loadData()}
                onEndReachedThreshold={0.5}
                onRefresh={() => loadData(0)}
                refreshing={(loading && skip == 0)}
                renderItem={({ item }) => (
                    <Item item={item} onPressViewUser={onPressViewUser} />
                )}
            />
        </View>
    )
}

function Item({ item, onPressViewUser }) {
    //   const navigation = useNavigation();
    return (
        <TouchableOpacity onPress={() => {
            //   navigation.navigate('ViewUser', { userId: item.id })
            onPressViewUser({ userId: item.id });
        }}>
            <View style={{
                flexDirection: 'row', paddingHorizontal: 20, paddingVertical: 10, borderBottomWidth: 1,
                borderColor: 'lightgray', paddingBottom: 10, justifyContent: 'space-between', alignItems: 'center'
            }}>
                <Image source={{ uri: item?.avatar }} style={{ width: 40, height: 40, borderRadius: 20 }} />
                <View style={{ marginLeft: 10, flex: 1 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ flex: 0.5 }}>
                            <Text>{item.displayName}</Text>
                        </View>
                        <View style={{ flex: 0.5 }}>
                            <Text style={{ textAlign: 'right' }}>{item?.mobileNumber}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                        <View style={{ flex: 0.3 }}>
                            <Text style={{ color: 'gray', fontSize: 12, }}>
                                เพศ {GENDER_LIST.find(item2 => item2.value == item?.gender).title}
                            </Text>
                        </View>
                        <View style={{ flex: 0.3 }}>
                            <Text style={{ color: 'gray', fontSize: 12, }}>
                                อายุ {moment().diff(item?.birthDate, 'years')}
                            </Text>
                        </View>
                        <View style={{ flex: 0.4 }}>
                            <Text style={{ fontSize: 12, color: 'gray', textAlign: 'right' }}>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    )
}