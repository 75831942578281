import React from 'react'
import { useLogin, useLogout } from "@pankod/refine-core";
import { Button, Badge } from "antd";
import axios from "axios";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import io from "socket.io-client";
import auth from "@feathersjs/authentication-client";
import rest from "@feathersjs/rest-client";
import { useContext } from "react";
import { themeColor } from "../config/servervar";
import { Image } from 'react-native-web';
import { mdiBellOutline } from '@mdi/js';
import Icon from '@mdi/react'
import { store } from "../sharewebapp/store"
import { View } from "react-native-web"
// const socket = io("http://testapi.local/");
// const socket = io("http://localhost:3030/");


const app = feathers();
// app.configure(socketio(socket));
app.configure(rest('http://localhost:3030').axios(axios));
app.configure(auth({ storage: window.localStorage }));

export const CustomHeader = () => {
  const { mutate: logout } = useLogout();
  const { globalState, dispatch } = useContext(store);

  const test1Click = async () => {
    const res = await globalState.client.service('tambols').find();
    alert('res' + JSON.stringify(res));
    // var accessToken = window.localStorage.getItem("feathers-jwt");
    // alert('accessToken', accessToken);
    //   app.authenticate({
    //     strategy: "local",
    //     mobileNumber: "01",
    //     password: "Test1234",
    //   }).then(async (payload1) => {
    //     console.log("hello payload1" + JSON.stringify(payload1));

    //   }).catch((e) => {
    //     console.error("Authentication error", e);
    //   });
  }

  const test2Click = () => {
    // dispatch({ type: 'setUser', payload: 'abc' })

  }
  const test3Click = async () => {
    // alert(JSON.stringify(globalState?.user));
    const res = await globalState.client.service('users').get(1);
    alert('hello' + JSON.stringify(res));
  }

  const test4Click = async () => {
    alert('user' + JSON.stringify(globalState?.user));
  }

  return (
    <div style={{
      backgroundColor: "white", height: "64px", display: "flex", justifyContent: "flex-end",
      alignItems: "center", backgroundColor: themeColor.color0
    }}
    >
      {/* {[
        // { id: 1, title: 'Authen', onClick: () => test1Click() },
        // { id: 2, title: 'Set Store', onClick: () => test2Click() },
        // { id: 3, title: 'Read Store', onClick: () => test3Click() },
        // { id: 4, title: 'Read globalstate', onClick: () => test4Click() },
        // { id: 5, title: 'Logout', onClick: () => logout() },
      ].map(item => (
        <Button
          key={item.id.toString()}
          style={{ marginLeft: 20, marginRight: 20 }}
          onClick={() => item.onClick()}>
          {item.title}
        </Button>
      ))} */}
      <View style={{ marginRight: 20 }}>
        <Badge count={5}>
          <Icon path={mdiBellOutline}
            title="Alert"
            size={1.2}
            color="black"
          />
        </Badge>
      </View>
      <Image source={{ uri: globalState?.user?.avatar }} style={{ width: 50, height: 50, borderRadius: 25, margin: 5 }} />
    </div>
  )
}
