import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, SafeAreaView, ScrollView, Dimensions, Linking, Alert, Modal } from "react-native";
import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { server_var, themeColor } from "../config/servervar";
import { store } from "./store";
import { numberWithCommas, getTotalPriceText } from "./common1";
import { SafeAreaProvider, useSafeAreaInsets } from "react-native-safe-area-context";
import moment from "moment";
import { DELIVER_LIST, PAYMENT_LIST } from './common1';
import { CustomIcon, uploadPhoto, useCustomSafeAreaInsets, useCustomFocusEffect } from "../component/custom";
import EventEmitter from "./EventEmitter";
import { getJwtToken, genActivityLog } from '../component/custom';

export function OrderAction({
  totalOrderPrice, ordermainId, onPressPrintOrder,
  onPressConfirmDelivery,
  onPressPaymentOption, onPressVatRequest, goBack, passVerifyBasket, onPressReOtp, onPressPostVoid, onPressOrderVoid
}) {
  const { globalState, dispatch } = useContext(store);
  const [orderMain, setOrderMain] = useState();
  const [com, setCom] = useState();
  const insets = useCustomSafeAreaInsets();
  const [waitFlag, setWaitFlag] = useState(false);
  const [dialog, setDialog] = useState({ visible: false, type: null });
  const fileRef1 = useRef();
  const [receiptInfo, setReceiptInfo] = useState({ receiptNo: null, isVoidable: false, order_status_id: null });

  useEffect(() => {
    EventEmitter.addListener('orderAction:refresh', loadData);
    return () => EventEmitter.removeListener('orderAction:refresh', loadData)
  }, [])

  useEffect(() => {
    loadData()
  }, [ordermainId])

  useCustomFocusEffect(
    useCallback(() => {
      loadData()
    }, [])
  );

  const loadData = async (paramId) => {
    const id = paramId ?? ordermainId;
    console.log('ordermainpart loadData', id);
    const res = await globalState.client.service('ordermains').get(id);
    console.log('ordermains orderVoidStatus : ', JSON.stringify(res?.orderVoidStatus));
    await inquiryReceipt(res?.deliOrderId, res?.userId);
    await getFullForm(res?.deliOrderId, res?.userId);
    setOrderMain(res);
  }

  const inquiryReceipt = async (orderId, userId) => {
    let res;
    let jwtToken;
    try {
      jwtToken = await getJwtToken();
    } catch (e) { }
    try {
      console.log('inquiryReceipt orderId : ', orderId);
      res = await fetch(`${server_var.server_api}/inquiryReceipt`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
        body: JSON.stringify({ userId: userId, orderId })
      });
    } catch (e) {
      console.log('inquiryReceipt post error : ', e);
    }
    if (!res) return;
    const resJson = await res.json();
    console.log(themeColor.FgYellow, 'inquiryReceipt resJson : ' + JSON.stringify(resJson), themeColor.FgReset);

    if (resJson?.result) {
      setReceiptInfo(resJson?.resultData);
      console.log('inquiryReceipt receiptNo : ', resJson?.resultData?.receiptNo);
      console.log('inquiryReceipt isVoidable : ', resJson?.resultData?.isVoidable);
      console.log('inquiryReceipt voidStatusId : ', resJson?.resultData?.voidStatusId);
    } else {
      setReceiptInfo(prev => ({ ...prev, receiptNo: null, isVoidable: false }));
    }
  }

  const getFullForm = async (orderId, userId) => {
    let res;
    let jwtToken;
    try {
      jwtToken = await getJwtToken();
    } catch (e) { }
    try {
      console.log('getFullForm orderId : ', orderId);
      res = await fetch(`${server_var.server_api}/getFullForm`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
        body: JSON.stringify({ userId: userId, orderId })
      });
    } catch (e) {
      console.log('getFullForm post error : ', e);
    }
    if (!res) return;
    const resJson = await res.json();
    console.log(themeColor.FgYellow, 'getFullForm resJson : ' + JSON.stringify(resJson), themeColor.FgReset);

    if (resJson?.result) {
      setReceiptInfo(prev => ({ ...prev, order_status_id: resJson?.resultData?.order_status_id }));
      console.log('getFullForm order_status_id : ', resJson?.resultData?.order_status_id);
      console.log('getFullForm order_status_name : ', resJson?.resultData?.order_status_name);
    } else {
      setReceiptInfo(prev => ({ ...prev, order_status_id: null }));
    }
  }  

  const verifyBasket = async () => {
    let jwtToken;
    try {
      jwtToken = await getJwtToken();
    } catch (e) {
      alert('มีปัญหากับ jwtToken' + JSON.stringify(e));
      return
    }
    let result;
    setWaitFlag(true)
    let retData = { error: true };
    try {
      const defHeader = {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken }
      };
      result = await fetch(`${server_var.server_api}/verifyBasket7Deli`, {
        ...defHeader, body: JSON.stringify({ userId: orderMain?.userId, shopId: orderMain?.shopId })
      });
      retData = await result.json();
      console.log('hello ', retData);
      setWaitFlag(false);
    } catch (error) {
      console.log(error);
      setWaitFlag(false)
    }
    return retData;
  }

  const reOtp = async () => {
    if (!waitFlag) {
      const result = await verifyBasket()
      if (result?.error) {
        alert((result?.resultData?.errorMsgTh) ?? "สินค้าในตะกร้ามีการเปลี่ยนแปลง");
        return
      }
      onPressReOtp({
        totalOrderPrice, ordermainId: orderMain?.id
      });
    }
  }

  const nextAction = async () => {
    genActivityLog(globalState, 'press_want_to_pay');
    if (!waitFlag) {
      const result = await verifyBasket()
      if (result?.error) {
        alert((result?.resultData?.errorMsgTh) ?? "สินค้าในตะกร้ามีการเปลี่ยนแปลง");
        return
      }
      onPressPaymentOption({
        totalOrderPrice, ordermainId: orderMain?.id
      });
    }
  };
  const onPressPrintOrderPart = () => {
    onPressPrintOrder({ ordermainId: orderMain?.id });
  };

  const uploadOrderPhoto1 = async () => {
    if (Platform.OS == 'web') {
      fileRef1.current.click();
    } else {
      uploadOrderPhoto3()
    }

  }
  const uploadOrderPhoto2 = async (e) => {
    uploadOrderPhoto3(e.target.files)
  }

  const uploadOrderPhoto3 = async (targetFiles) => {
    let images;
    // if (Platform.OS != "web") {
    if (true) {
      try {
        images = await uploadPhoto("order", true, 'photolib', targetFiles);
      } catch { }
      console.log("images!!", images);
      if (!images) return;
      if (!Array.isArray(images)) {
        images = [images];
        console.log("hello images", images);
      }
      await globalState.client.service('ordermains').patch(orderMain?.id, {
        orderPhotoList: images.map(item => ({ filename: `${server_var.server_api}/images/order/${item.filename}` })),
      });
      EventEmitter.notify("ViewOrder:refresh");
      EventEmitter.notify("orderAction:refresh", orderMain?.id);
    }
  };

  const buttonWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;

  const MODE_LIST = [
    {
      id: 10,
      condition: orderMain?.userId == globalState?.user?.id && orderMain?.orderStatus == "in_basket" && orderMain?.paymentPartialStatus != "reotp",
      com: {
        id: 1,
        list: [
          {
            id: 10,
            title: "ชำระเงิน",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: waitFlag ? 'gray' : themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            showTotal: true,
            onPress: () => nextAction(),
          },
        ],
      },
    },
    {
      id: 12,
      condition: orderMain?.userId == globalState?.user?.id && orderMain?.paymentPartialStatus == "reotp",
      com: {
        id: 1,
        list: [
          {
            id: 10,
            title: "ยืนยัน OTP",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: waitFlag ? 'gray' : themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            showTotal: true,
            onPress: () => reOtp(),
          },
        ],
      },
    },
    {
      id: 15,
      condition: globalState?.user?.staffFlag && globalState?.user?.roles == 'admin' && orderMain?.orderStatus == "in_basket",
      com: {
        id: 1,
        list: [
          {
            id: 10,
            title: "ยกเลิกคำสั่งซื้อ",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            showTotal: true,
            onPress: () => setDialog({ visible: true, type: 'cancel_order' }),
          },
        ],
      },
    },
    {
      id: 17,
      condition: orderMain?.userId == globalState?.user?.id && receiptInfo?.order_status_id == 5 && receiptInfo?.isVoidable && orderMain?.orderStatus == 'customer_got_deliver' && moment(orderMain?.paymentDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'),
      // condition: orderMain?.userId == globalState?.user?.id,
      com: {
        id: 1,
        list: [
          {
            id: 10,
            //title: `${moment(orderMain?.paymentDate).format('YYYY-MM-DD')} : ${moment().format('YYYY-MM-DD')}`,
            title: "ขอยกเลิกรายการสั่งซื้อ",
            isButton: true,
            buttonEnable: !orderMain?.orderVoidStatus, //&& receiptInfo?.voidStatusId == 0
            buttonProp: { height: 40, width: buttonWidth, borderWidth: 1, borderColor: themeColor.color6, borderRadius: 10 },
            textProp: { color: themeColor.color6 },
            showTotal: false,
            onPress: () => onPressPostVoid({ ordermainId: orderMain?.id, orderId: orderMain?.deliOrderId, receiptNo: receiptInfo?.receiptNo }),
            //onPress: () => onPressPostVoid({ ordermainId: orderMain?.id, orderId: orderMain?.deliOrderId, receiptNo: 1000000117 }),
          },
        ],
      },
    },   
    {
      id: 19,
      condition: globalState?.user?.roles == 'admin' && orderMain?.orderVoidStatus == 'void_request',
      com: {
        id: 2,
        list: [
          {
            id: 10,
            title: "ไม่อนุมัติคำขอยกเลิก",
            isButton: true,
            buttonEnable: true,
            buttonProp: { height: 40, width: buttonWidth / 2, borderWidth: 1, borderColor: themeColor.color6, borderRadius: 10 },
            textProp: { color: themeColor.color6 },
            showTotal: false,
            onPress: () => onPressOrderVoid({ ordermainId: orderMain?.id, orderId: orderMain?.deliOrderId, receiptNo: orderMain?.receiptNo, orderVoidStatus: null }),
          },
          {
            id: 20,
            title: "อนุมัติคำขอยกเลิก",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: themeColor.color6, height: 40, width: buttonWidth / 2 },
            textProp: { color: "white" },
            showTotal: false,
            onPress: () => onPressOrderVoid({ ordermainId: orderMain?.id, orderId: orderMain?.deliOrderId, receiptNo: orderMain?.receiptNo, orderVoidStatus: 'void_process' }),
          },                    
        ],
      },
    },  
    {
      id: 20,
      ////// HERE TO FIX แก้ปัญหาร้านเรวดี
      condition: globalState?.user?.staffFlag && ["customer_paid", 'partner_reject', 'grab_reject'].includes(orderMain?.orderStatus),
      com: {
        id: 2,
        list: [
          {
            id: 10,
            title: "พิมพ์ฉลากยา\n(ผ่านเว็บ)",
            buttonEnable: true,
            isButton: true,
            isCheckButton: true,
            checkFlag: orderMain?.stepJson?.step1,
            iconName: "printer",
            buttonProp: {
              backgroundColor: themeColor.color1, height: 70,
              paddingHorizontal: 10, paddingVertical: 10
            },
            textProp: { fontSize: 10, color: "white", textAlign: "center" },
            touchProp: { marginVertical: 3 },
            onPress: onPressPrintOrderPart,
          },
          {
            id: 30,
            title: "ออกใบกำกับภาษี",
            buttonEnable: orderMain?.vatRequestJson,
            iconName: "file-outline",
            isButton: true,
            isCheckButton: true,
            checkFlag: orderMain?.stepJson?.step3,
            buttonProp: {
              backgroundColor: themeColor.color1, height: 70,
              paddingHorizontal: 10, paddingVertical: 10
            },
            textProp: { fontSize: 10, color: "white", textAlign: "center" },
            touchProp: { marginVertical: 3 },
            onPress: () => {
              onPressVatRequest({ ordermainId: orderMain?.id, readOnly: true });
            },
          },
          {
            id: 40,
            title: "แนบรูปภาพสินค้า",
            buttonEnable: true,
            iconName: "image",
            isButton: true,
            isCheckButton: true,
            checkFlag: orderMain?.orderPhotoList && orderMain?.orderPhotoList?.length > 0,
            buttonProp: {
              backgroundColor: themeColor.color1, height: 70,
              paddingHorizontal: 10, paddingVertical: 10
            },
            textProp: { fontSize: 10, color: "white", textAlign: "center" },
            touchProp: { marginVertical: 3 },
            onPress: () => uploadOrderPhoto1(),
          },
          {
            id: 50,
            title: "ขั้นตอนการจัดส่ง",
            buttonEnable:
              (
                (!waitFlag) && (orderMain?.stepJson?.step1) &&
                (orderMain?.stepJson?.step3 || !orderMain?.vatRequestJson) &&
                (orderMain?.orderPhotoList && orderMain?.orderPhotoList?.length > 0) &&
                //20Dec2023
                (orderMain?.paymentPartialStatus == 'appstaff_deduct_money')
              ),
            iconName: "bike-fast",
            isButton: true,
            isCheckButton: orderMain?.deliverType == '7deli' ? false : true,
            checkFlag: orderMain?.lalamoveOrderId,
            buttonProp: {
              backgroundColor: themeColor.color1, height: 70,
              paddingHorizontal: 70, paddingVertical: 10
            },
            textProp: { fontSize: 10, color: "white", textAlign: "center" },
            touchProp: { marginVertical: 3 },
            onPress: () => {
              console.log('hello', orderMain)
              if (!(orderMain?.lalamoveOrderId)) {
                setDialog({ visible: true, type: orderMain?.deliverType })
              }
            },
          },
          {
            id: 60,
            title: "ยกเลิกคำสั่งซื้อ",
            buttonEnable: globalState?.user?.roles == 'admin',
            iconName: "alert-circle-outline",
            isButton: true,
            isCheckButton: false,
            checkFlag: false,
            hideFlag: true,
            buttonProp: {
              backgroundColor: themeColor.color1, height: 70,
              paddingHorizontal: 10, paddingVertical: 10
            },
            textProp: { fontSize: 10, color: "white", textAlign: "center" },
            touchProp: { marginVertical: 3 },
            onPress: () => setDialog({ visible: true, type: 'cancel_order' }),
          },
        ],
      },
    },
    {
      id: 50,
      condition: globalState?.user?.staffFlag && orderMain?.orderStatus == "speedd_start_deliver",
      com: {
        id: 3,
        list: [
          {
            id: 20,
            title: "Speed D ส่งสินค้าเรียบร้อยแล้ว",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: async () => {
              await globalState.client.service('ordermains').patch(orderMain.id, { orderStatus: "speedd_complete_deliver" });
              dispatch({ type: "showToast", payload: { text: "บันทึกข้อมูลแล้ว" } });
              goBack(1);
            },
          },
          {
            id: 10,
            title: "ยกเลิกคำสั่งซื้อ",
            isButton: true,
            buttonEnable: globalState?.user?.roles == 'admin',
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: () => setDialog({ visible: true, type: 'cancel_order' }),
          },

        ],
      },
    },
    {
      id: 55,
      condition: globalState?.user?.staffFlag && orderMain?.orderStatus == "post_start_deliver",
      com: {
        id: 3,
        list: [
          {
            id: 20,
            title: "ไปรษณีย์ ส่งสินค้าเรียบร้อยแล้ว",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: async () => {
              await globalState.client.service('ordermains').patch(orderMain.id, { orderStatus: "post_complete_deliver" });
              dispatch({ type: "showToast", payload: { text: "บันทึกข้อมูลแล้ว" } });
              goBack(1);
            },
          },
          {
            id: 10,
            title: "ยกเลิกคำสั่งซื้อ",
            isButton: true,
            buttonEnable: globalState?.user?.roles == 'admin',
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: () => setDialog({ visible: true, type: 'cancel_order' }),
          },

        ],
      },
    },
    {
      id: 60,
      condition: globalState?.user?.staffFlag && orderMain?.orderStatus == "self_pickup_start",
      com: {
        id: 3,
        list: [
          {
            id: 20,
            title: "ลูกค้ามารับสินค้าแล้ว",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: async () => {
              await globalState.client.service('ordermains').patch(orderMain.id, { orderStatus: "self_pickup_complete" });
              dispatch({ type: "showToast", payload: { text: "บันทึกข้อมูลแล้ว" } });
              goBack(1);
            },
          },
          {
            id: 10,
            title: "ยกเลิกคำสั่งซื้อ",
            isButton: true,
            buttonEnable: globalState?.user?.roles == 'admin',
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: () => setDialog({ visible: true, type: 'cancel_order' }),
          },
        ],
      },
    },
    {
      id: 65,
      condition:
        globalState?.user?.roles == 'admin' &&
        ["partner_complete_deliver", "7deli_complete_deliver", "self_pickup_complete", 'speedd_complete_deliver', 'post_complete_deliver', 'grab_complete_deliver'].includes(orderMain?.orderStatus),
      com: {
        id: 3,
        list: [
          {
            id: 10,
            title: "ยกเลิกคำสั่งซื้อ",
            isButton: true,
            buttonEnable: globalState?.user?.roles == 'admin',
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: () => setDialog({ visible: true, type: 'cancel_order' }),
          },
        ],
      },
    },
    {
      id: 70,
      condition:
        orderMain?.userId == globalState?.user?.id &&
        ["partner_complete_deliver", "7deli_complete_deliver", "self_pickup_complete", 'speedd_complete_deliver', 'post_complete_deliver', 'grab_complete_deliver'].includes(orderMain?.orderStatus),
      com: {
        id: 3,
        list: [
          {
            id: 20,
            title: "ได้รับสินค้าครบถ้วน",
            isButton: true,
            buttonEnable: true,
            buttonProp: { backgroundColor: themeColor.color1, height: 40, width: buttonWidth },
            textProp: { color: "white" },
            onPress: () => {
              globalState.client.service('ordermains').patch(orderMain.id,
                { orderStatus: "customer_got_deliver" },
                { query: { $strict: 1 } }
              );
              onPressConfirmDelivery({ ordermainId: orderMain?.id });
            },
          },
          {
            id: 30,
            title: "หากพบปัญหาหรือได้รับสินค้าไม่ครบถ้วน\nกรุณาติดต่อไลน์ @allpharmasee",
            isButton: false,
            prop: { marginBottom: 20, marginTop: 5 },
            textProp: { color: themeColor.color1, textAlign: "center", fontSize: 12 },
          },
        ],
      },
    },    
  ];

  useEffect(() => {
    setCom(MODE_LIST.find((item) => item.condition)?.com);
  }, [orderMain, waitFlag]);

  if (!com) {
    return <View />;
  } else {
    return (
      <View
        style={[
          {
            paddingBottom: insets.bottom, paddingTop: 10, backgroundColor: "white",
            borderTopWidth: 1, borderColor: "lightgray",
          },
          com.id == 2 ?
            { flexDirection: "row", flexWrap: "wrap", paddingHorizontal: 10, justifyContent: 'center' } :
            { flexDirection: "column" },
        ]}>
        {com?.list.map((item) => (
          <View key={item.id.toString()}>
            {item.showTotal && (
              <View style={{ paddingHorizontal: 10, flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ color: themeColor.color1, fontWeight: "bold" }}>ยอดรวม</Text>
                <Text style={{ color: themeColor.color1, fontWeight: "bold" }}>฿{getTotalPriceText(orderMain)}</Text>
              </View>
            )}
            {item?.isButton && !(item?.hideFlag) && (
              <TouchableOpacity
                style={[{ marginVertical: 5, alignSelf: "center", marginHorizontal: 5 }, item.touchProp]}
                onPress={() => {
                  if (item.buttonEnable) {
                    item.onPress()
                  }
                }}>
                <View
                  style={[
                    { borderRadius: 10, justifyContent: "center", alignItems: "center" }, item.buttonProp,
                    !item.buttonEnable ? { backgroundColor: "lightgray" } : null,
                  ]}>
                  {item.iconName != null && (
                    <View>
                      <CustomIcon name={item.iconName} color="white" size={20} />
                      <View style={{ height: 10, width: 20 }} />
                    </View>
                  )}
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {item.isCheckButton && (
                      <View style={{ marginRight: 3 }}>
                        <CustomIcon name={item.checkFlag ? "checkbox-marked-outline" : "checkbox-blank-outline"} size={16} color={"white"} />
                      </View>
                    )}
                    <Text style={item.textProp}>{item.title}</Text>
                  </View>

                </View>
              </TouchableOpacity>
            )}
            {!item?.isButton && !(item?.hideFlag) && (
              <View style={[{ justifyContent: "center", alignItems: "center" }, item.prop]}>
                <Text style={item.textProp}>{item.title}</Text>
              </View>
            )}
          </View>
        ))}
        <Dialog
          dialog={dialog} setDialog={setDialog} setWaitFlag={setWaitFlag} ordermainId={orderMain?.id}
          goBack={goBack} waitFlag={waitFlag}
        />
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadOrderPhoto2} multiple accept=".mp4, .jpg, .png" />
            </div>
          )
        }
      </View>
    );
  }
}

function Dialog({ dialog, setDialog, setWaitFlag, ordermainId, goBack, waitFlag }) {
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({ speedDRefNo: null, reasonToCancel: null, reasonToCancelOther: '', postRefNo: null })
  const [error, setError] = useState({ at: '', msg: '' });

  useEffect(() => {
    if (data?.reasonToCancel != 'อื่นๆ') {
      setData(prev => ({ ...prev, reasonToCancelOther: '' }))
    }
  }, [data?.reasonToCancel])

  const CONTENT_LIST = [
    {
      value: 'post', text: 'กรุณากรอกเลขจัดส่งเลขติดตามพัสดุและกดยืนยันการจัดส่ง', orderStatus: 'post_start_deliver',
      imagePath: require('../assets/images/box.png'), choice: 5, com:
        <TextInput
          value={data['postRefNo']} onChangeText={text => setData(prev => ({ ...prev, postRefNo: text }))}
          style={[{
            borderWidth: 1, borderColor: 'lightgray', paddingHorizontal: 5,
            height: 35, paddingHorizontal: 5, backgroundColor: 'white', marginTop: 10, marginHorizontal: 5
          }, (error?.at == 'postRefNo') && { borderColor: 'red' }]}
        />
    },
    {
      value: 'speed_d', text: 'กรุณากรอกเลขจัดส่งเลขติดตามพัสดุและกดยืนยันการจัดส่ง', orderStatus: 'speedd_start_deliver',
      imagePath: require('../assets/images/SpeedD_icon.png'), choice: 3, com:
        <TextInput
          value={data['speedDRefNo']} onChangeText={text => setData(prev => ({ ...prev, speedDRefNo: text }))}
          style={[{
            borderWidth: 1, borderColor: 'lightgray', paddingHorizontal: 5,
            height: 35, paddingHorizontal: 5, backgroundColor: 'white', marginTop: 10, marginHorizontal: 5
          }, (error?.at == 'speedDRefNo') && { borderColor: 'red' }]}
        />
    },
    {
      value: '7deli', text: 'คำสั่งซื้อถูกจัดส่งไปยัง \n7-Delivery เรียบร้อยแล้ว', orderStatus: '7deli_start_deliver',
      imagePath: require('../assets/images/7Deli_icon.png'), choice: 4
    },
    {
      value: 'grab', text: 'ยืนยันการจัดส่งโดย Grab', imagePath: require('../assets/images/grab_delivery.png'), choice: 6,
    },
    {
      value: 'lalamove', text: 'ยืนยันการจัดส่งโดย Lalamove', imagePath: require('../assets/images/Lalamove_icon.png'), choice: 2,
    },
    {
      value: 'self_pickup', text: 'ยืนยันลูกค้ามารับสินค้าที่ร้าน', imagePath: require('../assets/images/SelfPickup_icon.png'), orderStatus: "self_pickup_start",
      choice: 1
    },
    {
      value: 'cancel_order', text: 'ยืนยัน Cancel คำสั่งซื้อนี้', heightRation: 1.3, com: (
        <View style={{ marginBottom: 50, flex: 1, alignItems: 'center' }}>
          <View>
            <Text style={{ fontWeight: 'bold', marginTop: 20, marginBottom: 10 }}>เหตุผลการ Cancel</Text>
            {[
              { id: 1, title: 'คำสั่งซื้อผิด' },
              { id: 2, title: 'เภสัชกรขอยกเลิก' },
              { id: 3, title: 'ลูกค้าขอยกเลิก' },
              { id: 4, title: 'ลูกค้าคืนสินค้า' },
              { id: 5, title: 'ปัญหาระบบ' },
              { id: 6, title: 'อื่นๆ' },
            ].map(item6 => (
              <TouchableOpacity key={item6.id.toString()} style={{ marginVertical: 5 }} onPress={() => setData(prev => ({ ...prev, reasonToCancel: item6.title }))}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <CustomIcon
                    name={data['reasonToCancel'] == item6.title ?
                      'check-circle-outline' : 'checkbox-blank-circle-outline'
                    }
                    size={20}
                    color={'gray'}
                  />
                  <Text style={{ marginLeft: 5 }}>{item6.title}</Text>
                  {item6.id == 6 && (
                    <TextInput
                      value={data?.reasonToCancelOther}
                      onChangeText={text => setData(prev => ({ ...prev, reasonToCancelOther: text }))}
                      editable={data?.reasonToCancel == 'อื่นๆ'}
                      style={{ marginLeft: 5, width: 150, height: 30, borderWidth: 1, borderColor: 'lightgray', paddingHorizontal: 3, borderRadius: 5 }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      )
    }
  ]

  const callDelivery = async () => {
    if (true) {
      setWaitFlag(true);
      let skipGoBack = false;
      const tmpOrderStatus = CONTENT_LIST.find(item5 => item5.value == dialog.type)?.orderStatus;
      if (CONTENT_LIST.filter(item5 => item5.choice == 4).map(item5 => item5.value).includes(dialog.type)) {
        skipGoBack = true;
      } else if (CONTENT_LIST.filter(item5 => item5.choice == 3).map(item5 => item5.value).includes(dialog.type)) {
        setError({ at: '', msg: '' })
        if (!data.speedDRefNo) {
          setError({ at: 'speedDRefNo', msg: 'ไม่สามารถเว้นว่างได้' })
          setWaitFlag(false);
          return;
        }
        await globalState.client.service('ordermains').patch(ordermainId, { ...data, orderStatus: tmpOrderStatus, });
      } else if (CONTENT_LIST.filter(item5 => item5.choice == 5).map(item5 => item5.value).includes(dialog.type)) {
        console.log('abc' + data.postRefNo);
        setError({ at: '', msg: '' })
        if (!data.postRefNo) {
          setError({ at: 'postRefNo', msg: 'ไม่สามารถเว้นว่างได้' })
          setWaitFlag(false);
          return;
        }
        await globalState.client.service('ordermains').patch(ordermainId, { ...data, orderStatus: tmpOrderStatus, });
      } else if (CONTENT_LIST.filter(item5 => item5.choice == 1).map(item5 => item5.value).includes(dialog.type)) {
        await globalState.client.service('ordermains').patch(ordermainId, { ...data, orderStatus: tmpOrderStatus, });
      } else if (CONTENT_LIST.filter(item5 => item5.choice == 2).map(item5 => item5.value).includes(dialog.type)) {
        const jwtToken = await getJwtToken();
        const res = await fetch(`${server_var.server_api}/lalamovePlaceOrder`, {
          method: "POST",
          headers: { Accept: "application/json", "Content-Type": "application/json", 'authorization': jwtToken },
          body: JSON.stringify({ ordermainId }),
        });
        const resJson = await res.json();
      } else if (CONTENT_LIST.filter(item5 => item5.choice == 6).map(item5 => item5.value).includes(dialog.type)) {
        const jwtToken = await getJwtToken();
        const res = await fetch(`${server_var.server_api}/grabPlaceOrder`, {
          method: "POST",
          headers: { Accept: "application/json", "Content-Type": "application/json", 'authorization': jwtToken },
          body: JSON.stringify({ ordermainId }),
        });
        const resJson = await res.json();
      }
      setDialog({ visible: false });
      setWaitFlag(false);
      if (!skipGoBack) {
        dispatch({ type: "showToast", payload: { text: "บันทึกข้อมูลแล้ว" } });
        goBack(1);
      }
    }
  }

  const cancelOrderByAdmin = async () => {
    await globalState.client.service('ordermains').patch(ordermainId, {
      ...data, orderStatus: 'cancel_by_admin',
    });
    setDialog({ visible: false });
    goBack(1);
    EventEmitter.notify("ViewOrder:refresh");
    EventEmitter.notify("orderAction:refresh", ordermainId);
  }

  const CONTENT_ITEM = CONTENT_LIST.find(item => item.value == dialog.type);

  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * (CONTENT_ITEM?.heightRation ?? 1), paddingTop: 10, paddingBottom: 30,
          borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <ScrollView>
            {/* <Text>{JSON.stringify(data)}</Text>
            <Text>{JSON.stringify(error)}</Text> */}
            <View style={{ marginTop: 20, paddingHorizontal: 20 }}>
              {CONTENT_ITEM?.imagePath != null &&
                <Image
                  source={CONTENT_ITEM?.imagePath}
                  style={{ width: dialogWidth * 0.8, height: dialogWidth / 3 }}
                  resizeMode="contain"
                />
              }
              <Text style={{ textAlign: 'center', marginTop: 10 }}>
                {CONTENT_ITEM?.text}
              </Text>
            </View>
            {CONTENT_ITEM?.com}
          </ScrollView>
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            {
              [
                {
                  id: 1, title: 'ยกเลิก', onPress: () => {
                    if (!waitFlag) {
                      setDialog({ visible: false })
                    }
                  },
                  viewStyle: { backgroundColor: 'white' }, textStyle: { color: themeColor.color1 },
                  visible: (dialog.type != '7deli'),
                },
                {
                  id: 2, title: (dialog.type == '7deli' ? 'ปิดหน้าต่าง' : 'ยืนยัน'), onPress: async () => {
                    if (!waitFlag) {
                      if (dialog.type == 'cancel_order') {
                        cancelOrderByAdmin()
                      } else {
                        callDelivery();
                      }
                    }
                  },
                  viewStyle: { backgroundColor: themeColor.color1 }, textStyle: { color: 'white' },
                  visible: true,
                }
              ].map(item => {
                if (item?.visible) {
                  return (<TouchableOpacity key={item.id.toString()} onPress={() => item.onPress()} style={{ marginHorizontal: 10 }}>
                    <View style={[{
                      width: 120, height: 35, borderRadius: 10, borderWidth: 1, borderColor: themeColor.color1,
                      justifyContent: 'center', alignItems: 'center'
                    }, item.viewStyle, waitFlag && { backgroundColor: 'lightgray', borderColor: 'gray' }]}>
                      <Text style={[item.textStyle]}>{item.title}</Text>
                    </View>
                  </TouchableOpacity>
                  )
                } else {
                  return <View key={item.id.toString()} />
                }
              })
            }
          </View>
          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

