import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { themeColor, fontStyles } from '../config/servervar';
import { Refine, Authenticated, AuthProvider } from "@pankod/refine-core";
import { View, Text } from 'react-native-web';
import { store } from '../sharewebapp/store'
import moment from 'moment';
import { Col, Row } from '@pankod/refine-antd';
import { HomePharmacistPart } from '../sharewebapp/homepharmacist';
import { useNavigate } from 'react-router-dom';
import { ManageCustomerPart } from '../sharewebapp/managecustomerpart';
import { ViewProductPart } from '../sharewebapp/viewproductpart';
import { ManageProductPart } from '../sharewebapp/manageproduct';
import { ManageLabelPart } from '../sharewebapp/managelabel';
import { getPanelIndex, PanelHeader, getFlexIndex } from './webcommon';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ManageAppointmentPart } from '../sharewebapp/manageappointment';
import { ManagePharmaNotePart } from '../sharewebapp/managepharmanote';
import { ViewPharmaNoteOrderAppointmentPart } from '../sharewebapp/viewpharmanoteorderappointment';
import { FaqPart } from '../sharewebapp/faq';
import { ViewFaqPart } from '../sharewebapp/viewfaq';
import { CustomerServicePart } from '../sharewebapp/customerservice';
import { UserManualPart } from '../sharewebapp/usermanual';
import { ReportsPart } from '../sharewebapp/reports';
import { EditFaqPart } from '../sharewebapp/editfaq';
import { EditUserPart } from '../sharewebapp/edituser';
import { ManageUserPart } from '../sharewebapp/manageuser';
import { ManageShopPart } from '../sharewebapp/manageshop';
import { EditShopPart } from '../sharewebapp/editshop';
import { ManageBannerPart } from '../sharewebapp/managebanner';
import { ManageRolePart } from '../sharewebapp/managerole';
import { EditRolePart } from '../sharewebapp/editrole';
import { FeedNotiPart } from '../sharewebapp/feednoti';
import { NotiPart } from '../sharewebapp/noti';
import { PharmaNotePart } from '../sharewebapp/pharmanotepart';
import { ViewFeedNotiPart } from '../sharewebapp/viewfeednoti';
import { ViewFeedNotiDetailPart } from '../sharewebapp/viewfeednotidetail';
import { FeedViewPart } from '../sharewebapp/feedviewpart';
import { ViewOrderPart } from '../sharewebapp/vieworderpart';
import { ShopOnlinePart } from '../sharewebapp/shoponline';
import { ManageGreetingPart } from '../sharewebapp/managegreeting';
import { EditGreetingPart } from '../sharewebapp/editgreeting';
import { PasswordEnterPart } from '../sharewebapp/passwordenter';
import { EditContentPage } from '../pages/EditContentPage';
import { ManageContentPage } from './ManageContentPage';
import { ManageCouponPage } from './ManageCouponPage';
import { ManagePromotionPart } from '../sharewebapp/managepromotion';
import { EditPromotionPart } from '../sharewebapp/editpromotion';
import { ManageConsultVideoPart } from '../sharewebapp/manageconsultvideo';
import { EditConsultVideoPart } from '../sharewebapp/editconsultvideo';
import { ConsultVideoListPart } from '../sharewebapp/consultvideolist';
import { ViewConsultVideoPart } from '../sharewebapp/viewconsultvideo';
import { ChatPart } from '../sharewebapp/chatpart';
import { ManagePreOrderPart } from '../sharewebapp/managepreorder';
import { ViewPreOrderPart } from '../sharewebapp/viewpreorder';
import { EditCouponPage } from './EditCouponPage';
import { ManageOrderVoidPart } from '../sharewebapp/manageordervoid';

export function HomeScreen() {
  const { globalState } = useContext(store);
  const [listPanel, setListPanel] = useState([
    { name: 'HomePharmacist', params: null }
  ]);
  const listPanelRef = useRef(listPanel);

  const navigate = useNavigate();

  useEffect(() => {
    listPanelRef.current = listPanel;
  }, [listPanel])

  const navigation = {
    navigate: (navName, navParams) => {
      const panelIndex = listPanelRef.current.findIndex(item => item.name == navName);
      if (panelIndex >= 0) {
        setListPanel(prev => {
          prev.length = panelIndex;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ManageOrder') {
        navigate('../manage_order')
        return;
      }
      if (navName == 'ManageOrderAdmin') {
        navigate('../manage_order', {
          state: {
            forAdmin: true,
          }
        });
        return;
      }
      if (navName == 'ManageProduct') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ManageLabel') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ViewProduct') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageAppointment') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManagePharmaNote') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ViewPharmaNoteOrderAppointment') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'Faq') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ViewFaq') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'CustomerService') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'UserManual') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'Reports') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditFaq') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageUser') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditUser') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageShop') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditShop') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageBanner') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageRole') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditRole') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'FeedNoti') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ViewFeedNoti') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ViewFeedNotiDetail') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'Noti') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'PharmaNote') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'FeedView') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'PasswordEnter') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ViewOrder') {
        navigate('../manage_order')
        // setListPanel(prev => {
        //   return [...prev, { name: navName, params: navParams, index: prev.length }]
        // })
      }
      if (navName == 'ShopOnline') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageGreeting') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditGreeting') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditContent') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageContent') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManagePromotion') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditPromotion') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageConsultVideo') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }      
      if (navName == 'EditConsultVideo') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }      
      if (navName == 'ConsultVideoList') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }       
      if (navName == 'ViewConsultVideo') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }      
      if (navName == 'Chat') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }  
      if (navName == 'ManagePreOrder') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ViewPreOrder') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageCoupon') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'EditCoupon') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }
      if (navName == 'ManageOrderVoid') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
      }      
    },
    setOptions: (optionParams, index) => {
      setListPanel(prev => {
        const tmpListPanel = [...prev];
        if (index >= 0) {
          tmpListPanel[index] = { ...tmpListPanel[index], options: optionParams }
        } else {
          tmpListPanel[tmpListPanel.length - 1] = { ...tmpListPanel[tmpListPanel.length - 1], options: optionParams }
        }
        return tmpListPanel
      })
    },
    goBack: (index) => {
      setListPanel(prev => {
        if (index >= 0) {
          prev.length = index;
        }
        return [...prev];
        // return prev;
      })
    },
    dispatch: () => {
      console.log('dispatch')
    }
  };

  return (
    <Authenticated>
      <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
        <Col span={8} style={{ display: getFlexIndex(0, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[0]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(1, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[1]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(2, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[2]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(3, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[3]} navigation={navigation} />
        </Col>
        <Col span={8} style={{ display: getFlexIndex(4, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
          <Panel panel={listPanel?.[4]} navigation={navigation} />
        </Col>
      </Row>
    </Authenticated>
  )
}

function Panel({ panel, navigation }) {
  const panelProp = { navigation, route: { params: panel?.params, custom: { index: panel?.index } } };
  if (panel?.name == 'HomePharmacist') {
    return (
      <SafeAreaProvider>
        <HomePharmacistPart {...panelProp} />
      </SafeAreaProvider>
    )

  }
  if (panel?.name == 'ManageCustomer') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageCustomerPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageProduct') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageProductPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageLabel') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageLabelPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewProduct') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewProductPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageAppointment') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageAppointmentPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManagePharmaNote') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManagePharmaNotePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewPharmaNoteOrderAppointment') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewPharmaNoteOrderAppointmentPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'Faq') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <FaqPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewFaq') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewFaqPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'CustomerService') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <CustomerServicePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'UserManual') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <UserManualPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'Reports') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ReportsPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditFaq') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditFaqPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageUser') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageUserPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditUser') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditUserPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageShop') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageShopPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditShop') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditShopPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageBanner') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageBannerPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageRole') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageRolePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditRole') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditRolePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'FeedNoti') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <FeedNotiPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewFeedNoti') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewFeedNotiPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewFeedNotiDetail') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewFeedNotiDetailPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'Noti') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <NotiPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'PharmaNote') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <PharmaNotePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'FeedView') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <FeedViewPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewOrder') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewOrderPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ShopOnline') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ShopOnlinePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageGreeting') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageGreetingPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditGreeting') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditGreetingPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'PasswordEnter') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <PasswordEnterPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditContent') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditContentPage {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageContent') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageContentPage {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManagePromotion') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManagePromotionPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditPromotion') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditPromotionPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageConsultVideo') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageConsultVideoPart {...panelProp} />
      </SafeAreaProvider>
    )
  }  
  if (panel?.name == 'EditConsultVideo') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditConsultVideoPart {...panelProp} />
      </SafeAreaProvider>
    )
  }  
  if (panel?.name == 'ConsultVideoList') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ConsultVideoListPart {...panelProp} />
      </SafeAreaProvider>
    )
  }  
  if (panel?.name == 'ViewConsultVideo') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewConsultVideoPart {...panelProp} />
      </SafeAreaProvider>
    )
  }  
  if (panel?.name == 'Chat') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ChatPart {...panelProp} />
      </SafeAreaProvider>
    )
  }  
  if (panel?.name == 'ManagePreOrder') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManagePreOrderPart {...panelProp} />
      </SafeAreaProvider>
    )
  }  
  if (panel?.name == 'ViewPreOrder') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewPreOrderPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageCoupon') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageCouponPage {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'EditCoupon') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <EditCouponPage {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ManageOrderVoid') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ManageOrderVoidPart {...panelProp} />
      </SafeAreaProvider>
    )
  }  
  return <View />
}