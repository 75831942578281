import React, { useEffect, useState, useContext, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Keyboard, Platform, Dimensions, ScrollView, Linking, ActivityIndicator, Image, Pressable } from 'react-native';
import { GiftedChat, Bubble, Send, SystemMessage, Composer } from 'react-native-gifted-chat';
import { useChatContext } from 'react-native-gifted-chat/lib/GiftedChatContext'; //To Be Test
import { InputToolbar } from './CustomInputToolbar';
import { QuickReplies } from '../sharewebapp/CustomQuickReplies';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { uploadPhoto } from '../component/custom';
import { CustomIcon } from '../component/custom';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { uploadFile } from '../component/custom';
import moment from 'moment';
import { CustomVideo, getJwtToken, CustomAntDImage } from '../component/custom';
import { CustomClipboard } from '../component/custom';


export function FrequentQuestion({ setShowMenu, showMenu, sendMessage, setCustomText }) {
  const { globalState, dispatch } = useContext(store)
  const [data, setData] = useState();

  const loadData = async () => {
    const res = await globalState.client.service('freqquestions').find({ query: { $limit: 50, $sort: { id: 1 } } });
    setData(res.data)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <View style={{ height: 230, borderTopWidth: 1, borderTopColor: 'lightgray' }}>
      <ScrollView>
        <View>
          {data?.map(item => (
            <TouchableOpacity
              key={item.id.toString()}
              onPress={() => {
                setCustomText(`${textGenderRepace(item.text, globalState?.user?.gender)}`);
                setShowMenu(0);
              }}
              style={{ marginTop: 10, paddingBottom: 10, marginHorizontal: 10, borderBottomWidth: 1, borderColor: 'lightgray' }}
            >
              <Text style={{ fontSize: 12, color: 'gray' }}>{item.text}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
      <TouchableOpacity style={{ position: 'absolute', top: 0, right: 0 }} onPress={() => setShowMenu(0)}>
        <CustomIcon name="close-circle-outline" size={25} color="gray" />
      </TouchableOpacity>
    </View>
  )
}

export function ReplyBox({ chatReplyInfo, setChatReplyInfo, ...props }) {
  if (chatReplyInfo?.id) {
    return (
      <View style={{ backgroundColor: 'lightgray', paddingVertical: 10 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10 }}>
          <Image source={{ uri: chatReplyInfo?.user?.avatar }} style={{ width: 40, height: 40, borderRadius: 20 }} />
          <View style={{ marginLeft: 10 }}>
            <Text>{chatReplyInfo?.user?.displayName}</Text>
            <Text style={{ color: 'gray' }}>
              {(chatReplyInfo?.text).substr(0, 40) + ((chatReplyInfo?.text?.length > 40) ? '...' : '')}
            </Text>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => setChatReplyInfo(prev => ({ ...prev, id: 0 }))}
          style={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CustomIcon name="close-circle" size={22} />
        </TouchableOpacity>
      </View>
    )
  } else {
    return <View />
  }
}

export function CustomSystemMessage({ props }) {
  if (props.currentMessage.type == "MeetingClose") {
    return (
      <View style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
        <Text style={{ color: 'gray', fontSize: 12 }}>
          {moment(props.currentMessage.createdAt).format('HH:mm')}
        </Text>
        <Text style={{ color: 'gray', fontSize: 12 }}>
          ห้องสนทนาถูกปิดแล้ว
        </Text>
      </View>
    )
  } else {
    return (
      <View style={{ alignSelf: 'center', marginVertical: 10 }}>
        <Text style={{ color: 'gray' }}>{props.currentMessage.text}</Text>
      </View>
    )
  }
}

export function MessageVideo({ props }) {
  const videoRef = useRef()
  const videoWidth = 250;
  const [videoHeight, setVideoHeight] = useState(videoWidth);

  const updateVideoRatioOnDisplay = (videoDetails) => {
    const { width, height } = videoDetails.naturalSize;
    setVideoHeight(videoWidth / width * height);
  }
  if (Platform.OS == 'web') {
    return (
      <video width={Dimensions.get('window').width / 6} height={Dimensions.get('window').width / 6 * 3 / 4} controls>
        <source src={props?.currentMessage?.video} type="video/mp4" />
      </video>
    )
  } else {
    return (
      <View style={{}}>
        <CustomVideo
          ref={videoRef}
          style={{ width: videoWidth, height: videoHeight }}
          source={{ uri: props?.currentMessage?.video }}
          useNativeControls
          resizeMode="contain"
          isLooping={false}
          onReadyForDisplay={updateVideoRatioOnDisplay}
        />
      </View>
    )
  }

}

export function MessageImage({ props, onPressViewImage, globalState, setChatReplyInfo }) {
  const { actionSheet } = useChatContext();
  const styles = StyleSheet.create({
    image: { width: 150, height: 100, borderRadius: 13, margin: 3, resizeMode: 'cover' },
  });
  if (props?.currentMessage == null) {
    return null;
  }
  if (Platform.OS == 'web') {
    return <CustomAntDImage source={{ uri: props?.currentMessage.image }} style={{ width: Dimensions.get('window').width / 8 }} />
  } else {
    return (
      <View>
        <TouchableOpacity
          onPress={() => onPressViewImage(props?.currentMessage.image)}
          onLongPress={() => onLongPress(props?.currentMessage, actionSheet, globalState, setChatReplyInfo)}
        >
          <Image
            {...props?.imageProps}
            style={[styles.image, props?.imageStyle]}
            source={{ uri: props?.currentMessage.image }}
          />
        </TouchableOpacity>
      </View>
    );
  }
}

export function onLongPress(currentMessage, actionSheet, globalState, chatRef, setChatReplyInfo) {
  const options = [
    { id: 1, text: 'Unsend' },
    { id: 2, text: 'Reply' },
    { id: 3, text: 'Copy' },
    { id: 4, text: 'Cancel' }
  ];
  let optionToUse = options;
  if (globalState?.user?.id != currentMessage?.user?.id) {
    optionToUse = options.filter(item => [2, 3, 4].includes(item.id));
  }
  if (!(currentMessage?.text) || Platform.OS == 'web') {
    optionToUse = options.filter(item => [1, 2, 4].includes(item.id));
  }
  // const cancelBtnIdx = options.length - 1;
  const cancelBtnIdx = optionToUse.length - 1;

  actionSheet().showActionSheetWithOptions({ options: optionToUse.map(item => item.text), cancelBtnIdx },
    async (btnIdx) => {
      switch (optionToUse[btnIdx]?.id) {
        case 2:
          chatRef.current.focusTextInput();
          setChatReplyInfo(currentMessage);
          break;
        case 3:
          if (Platform.OS == 'web') {
            await navigator.clipboard.writeText(currentMessage.text);
          } else {
            await CustomClipboard.setStringAsync(currentMessage.text);
          }
          break;
        case 1:
          console.log('currentMessage' + JSON.stringify(currentMessage, '', 2));
          globalState.client.service('messages').patch(currentMessage.id, {
            system: true,
            text: `${globalState?.user?.displayName} ยกเลิกข้อความ`,
            type: null,
          });
          break;
      }
    });
}

export function BottomMenu({ setShowMenu, showMenu, sendMessage, setWaiting }) {
  const { globalState } = useContext(store);
  const fileRef1 = useRef();

  const fileUpload2 = async (targetFiles) => {
    let res;
    setWaiting(true);
    try {
      res = await uploadFile('chat', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลด' + JSON.stringify(e));
    }
    setWaiting(false);
    if (!res) return;
    if (res?.originalFileName && res?.newFileName) {
      sendMessage({
        type: 'File', data: {
          fileName: res.originalFileName, uri: `${server_var.server_api}/images/chat/${res.newFileName}`
        }
      });
    } else {
      alert('มีปัญหาในการอัพโหลด ไฟล์นามสกุลนี้อาจไม่ได้รับอนุญาต หรือเกิดข้อผิดพลาดอื่นเกิดขึ้น');
    }
  }

  const fileUpload1 = async (e) => {
    fileUpload2(e.target.files)
  }

  const BOTTOM_MENULIST = [
    { id: 1, title: 'ชุดคำถาม', iconName: 'chat-question', staffOnly: true, onPress: () => setShowMenu(2) },
    {
      id: 2, title: 'ส่งไฟล์', iconName: 'file', staffOnly: false, onPress: async () => {
        if (Platform.OS == 'web') {
          fileRef1.current.click();
        } else {
          fileUpload2('chat');
        }
      }
    },
    { id: 3, title: 'FAQ', iconName: 'frequently-asked-questions', staffOnly: true, onPress: () => setShowMenu(3) },
  ];
  let bottomMenu = BOTTOM_MENULIST.filter(item => !item.staffOnly);
  if (globalState?.user?.staffFlag) {
    bottomMenu = BOTTOM_MENULIST;
  }
  return (
    <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: 'lightgray' }}>
      <TouchableOpacity style={{ position: 'absolute', top: 0, right: 0 }} onPress={() => setShowMenu(0)}>
        <CustomIcon name="close-circle-outline" size={25} color="gray" />
      </TouchableOpacity>
      {bottomMenu.map(item => (
        <TouchableOpacity key={item.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => item.onPress()}>
          <View style={{ width: 60, height: 60, justifyContent: 'center', alignItems: 'center' }}>
            <CustomIcon name={item.iconName} size={20} color={'gray'} />
            <Text style={{ fontSize: 10, marginTop: 5, color: 'gray' }}>{item.title}</Text>
          </View>
        </TouchableOpacity>
      ))}
      {
        Platform.OS == 'web' && (
          <div style={{ display: 'flex' }}>
            <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={fileUpload1} multiple accept=".pdf, .xls, .xlsx, .doc, .docx" />
          </div>
        )
      }
    </View>
  )
}

export function textGenderRepace(orgText, gender) {
  let retText = orgText;
  if (gender == 'M') {
    for (const item of ['ค่ะ', 'คะ']) {
      const searchRegExp = new RegExp(item, 'g')
      retText = retText.replace(searchRegExp, 'ครับ');
    }
  }
  return retText;
}

export function SendIcon(props) {
  return (
    <Send {...props} containerStyle={{ height: 42 }}>
      <View style={{
        marginRight: 10, marginBottom: 8, justifyContent: 'center',
        alignItems: 'center', alignSelf: 'center', height: 30
      }}>
        <CustomIcon name="send" size={18} color={themeColor.color1} />
      </View>
    </Send>
  );
}

export function CustomChatAction({ sendMessage, setShowMenu, showMenu, setWaiting }) {
  const fileRef1 = useRef();

  const uploadPhoto1 = async (e) => {
    uploadPhoto2('photolib', e.target.files)
  }

  const uploadPhoto2 = async (photoType, targetFiles) => {
    let images;
    setWaiting(true)
    try {
      images = await uploadPhoto('chat', true, photoType, targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลด' + JSON.stringify(e));
    }

    if (!images) {
      setWaiting(false);
      return;
    }
    if (!Array.isArray(images)) {
      images = [images];
    }
    images.forEach((item) => {
      if (item.filename) {
        if (item.filename.split('.').pop().toLowerCase() == 'mp4') {
          sendMessage({ video: `${server_var.server_api}/images/chat/${item.filename}` });
        } else {
          sendMessage({ image: `${server_var.server_api}/images/chat/resized/${item.filename}` });
        }
      } else {
        alert('มีปัญหาในการอัพโหลดรูป รูปอาจมีขนาดใหญ่เกินไป หรือมีข้อผิดพลาดเกิดขึ้น');
      }
    });
    setWaiting(false);
  }
  return (
    <View
      style={{
        alignSelf: 'center', height: 30, justifyContent: 'center', alignItems: 'center', marginLeft: 5, flexDirection: 'row'
      }}>
      {[
        { id: 0, iconName: 'plus', visible: true, onPress: () => setShowMenu(prev => prev ? 0 : 1) },
        { id: 1, iconName: 'camera', visible: (Platform.OS != 'web'), onPress: () => uploadPhoto2('camera') },
        {
          id: 2, iconName: 'image', visible: true, onPress: () => {
            if (Platform.OS == 'web') {
              fileRef1.current.click();
            } else {
              uploadPhoto2('photolib')
            }
          }
        },
      ].filter(item2 => item2.visible).map(item => (
        <TouchableOpacity key={item.id.toString()}
          onPress={() => item.onPress()}
          style={{
            height: 30, width: 30, justifyContent: 'center', alignItems: 'center',
            backgroundColor: 'white', borderRadius: 10
          }}>
          <CustomIcon name={item.iconName} size={24} color={themeColor.color1} />
        </TouchableOpacity>
      ))}
      {
        Platform.OS == 'web' && (
          <div style={{ display: 'flex' }}>
            <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".mp4, .jpg, .png" />
          </div>
        )
      }
    </View>
  );
}

