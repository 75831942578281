import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { useDebounce } from '../sharewebapp/common1';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomIcon } from '../component/custom';
import { ZONE_LIST, SHOP_STATUS } from './common1';
import { Dialog } from '../sharewebapp/pharmanotepart';

export function ShopOnlinePart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [dialog, setDialog] = useState({ visible: false });  
  const [searchData, setSearchData] = useState({ statusId: 1, status: 'ทั้งหมด', zoneId: 1, zone: 'ทั้งหมด' });
  const [countOnline, setCountOnline] = useState(0);

  useEffect(() => {
    loadDataList();
  }, [debouncedSearchTerm, searchData])

  const loadDataList = async () => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { shopStatus: 'A', shopType: 'general1', $sort: { shopIdBranch: 1 }, $limit: 1000 };
      if (searchData?.zoneId !== 1) {
        query = {
          ...query,
          shopArea: searchData?.zone
        };
      }
      if (searchArray.length > 0) {
        const tmp1 = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { shopIdBranch: { $like: `%${tmp1}%` } },
            { shopThName: { $like: `%${tmp1}%` } },
          ]
        };
      }

      const res = await globalState.client.service('shops').find({ query });
      let shopData = {};
      let shopList = [];
      let tmpOnline = 0;
      for (const item of res.data) {
        let isOnline = false;
        const shopOnline = item?.shopuser?.filter(i => i.statusOnline == 'online');
        if (shopOnline?.length > 0) {
          isOnline = true;
        } else {
          isOnline = false;
        }
        shopData.id = item?.id;
        shopData.shopIdBranch = item?.shopIdBranch;
        shopData.shopThName = item?.shopThName;
        shopData.shopArea = item?.shopArea;
        shopData.isOnline = isOnline;

        if (isOnline) {
          if (searchData.statusId !== 3) {
            tmpOnline++;
            shopList.unshift(shopData);
          }
        } else {
          if (searchData.statusId !== 2) {
            shopList.push(shopData);
          }          
        }        
        shopData = {};
      }
      setCountOnline(tmpOnline);
      setDataList(shopList);
      setLoading(false);
    }
  }

  const onBackDialog = async (backItem, type) => {
    if (type === 'zonelist') {
      setSearchData(prev => ({ ...prev, zoneId: backItem?.id, zone: backItem?.title }));
    } else {
      setSearchData(prev => ({ ...prev, statusId: backItem?.id, status: backItem?.title }));
    }    
    setDialog(prev => ({ ...prev, visible: false }));
  }
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const [dialogProp, setDialogProp] = useState({ dialog, onBackDialog, setDialog, dialogWidth, dialogList : null, type : null });
  const dialogContent = [
    {
      type: dialogProp?.type,
      title: (dialogProp?.type === 'zonelist') ? 'ภาค' : 'สถานะ',
      com: <DialogList {...dialogProp} />,
    },
  ];  

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        <View style={{ flex: 1, paddingBottom: 5, paddingTop: 5, marginTop: 5 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาจากรหัสสาขา หรือชื่อสาขา'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />        
        </View>
      </View>

        <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
          <View style={{ flex: 0.15, height: 30, justifyContent: 'center' }}>
            <Text style={{ fontSize: 14 }}>ภาค</Text>
          </View>
          <View style={{ flex: 0.3 }}>
            <TouchableOpacity onPress={() => {
              setDialogProp(prev => ({ ...prev, dialogList : ZONE_LIST, type : 'zonelist' }));
              setDialog(prev => ({ ...prev, visible: true, type: 'zonelist' }));
              }}>
              <View style={{
                flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
                height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
              }}>
                <Text style={{ fontSize: 14, color: 'gray' }}>{searchData?.zone ?? 'ทั้งหมด'}</Text>
                <CustomIcon name="chevron-down" size={20} color="gray" />
              </View>
            </TouchableOpacity>
          </View>
          <View style={{ flex: 0.1 }}>
            <Text style={{ fontSize: 14 }}></Text>
          </View>          
          <View style={{ flex: 0.15, height: 30, justifyContent: 'center' }}>
            <Text style={{ fontSize: 14 }}>สถานะ</Text>
          </View>
          <View style={{ flex: 0.3 }}>
          <TouchableOpacity onPress={() => {
              setDialogProp(prev => ({ ...prev, dialogList : SHOP_STATUS, type : 'statuslist' }));
              setDialog(prev => ({ ...prev, visible: true, type: 'statuslist' }));
              }}>
              <View style={{
                flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
                height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
              }}>
                <Text style={{ fontSize: 14, color: 'gray' }}>{searchData?.status ?? 'ทั้งหมด'}</Text>
                <CustomIcon name="chevron-down" size={20} color="gray" />
              </View>
            </TouchableOpacity>
          </View>          
        </View>   

        <View style={{ marginVertical: 10, marginHorizontal: 20 }}>
          <Text style={{ fontSize: 12 }}>จำนวนสาขาที่ออนไลน์ ณ ขณะนี้ {countOnline} สาขา</Text>
        </View>

      

      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(item) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={(item) => loadDataList()}
        refreshing={(loading)}
        style={Platform.OS == 'web' ? { height: '50vh' } : null}
        renderItem={({ item }) => (
          <ShopItem item={item} status={searchData?.status} />
        )}
      />
      <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
    </SafeAreaView>
  )
}

function ShopItem({ item }) {
  return (
    <TouchableOpacity style={{ marginVertical: 5, marginHorizontal: 20, }}>
      <View style={{ flexDirection: 'row', paddingBottom: 10, borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ flex: 0.15, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontSize: 14 }}>{item?.shopIdBranch}</Text>
        </View>  
        <View style={{ flex: 0.55, flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10 }}>
          <Text style={{ fontSize: 14, flex: 1, flexWrap: 'wrap' }}>{item?.shopThName}</Text>
        </View> 
        <View style={{ flex: 0.1, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontSize: 14 }}>{item?.shopArea}</Text>
        </View>
        <View style={{ flex: 0.2, flexDirection: 'row', alignItems: 'center' }}>
          <CustomIcon name="circle-medium" size={35} color={item?.isOnline ? themeColor.color10 : 'gray'} /><Text style={{ fontSize: 14 }}>{item?.isOnline ? 'Online' : 'Offline'}</Text>
        </View>                       
      </View>
    </TouchableOpacity >
  )
}

function DialogList({ dialog, setDialog, onBackDialog, dialogWidth, dialogList, type }) {
  return (
    <View style={{ paddingHorizontal: 5, alignItems: 'center', marginTop: 20 }}>
      {dialogList?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          onBackDialog({ ...item }, type)
          setDialog(prev => ({ ...prev, visible: false }));
        }}>
          <View style={{
            width: dialogWidth - 30, backgroundColor: themeColor.color1, height: 30,
            justifyContent: 'center',
            paddingHorizontal: 10,
            marginVertical: 10, borderRadius: 5
          }}>
            <Text style={{ color: 'white' }}>{item?.title}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}
