import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { getOpenCloseTimeList } from '../sharewebapp/common1';
import { CustomIcon } from '../component/custom';


export function ShopEditPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({
    shopOpenCloseTimeJson: {
      mon: { start: '', end: '', enable: true },
      tue: { start: '', end: '', enable: true },
      wed: { start: '', end: '', enable: true },
      thu: { start: '', end: '', enable: true },
      fri: { start: '', end: '', enable: true },
      sat: { start: '', end: '', enable: true },
      sun: { start: '', end: '', enable: true },
    },
  });
  const { shopId } = route?.params;

  useEffect(() => {
    loadData();
  }, [shopId])

  const loadData = async () => {
    const res = await globalState.client.service('shops').get(shopId, {
      query: {
        $select: ['shopDesc', 'shopOpenCloseTime', 'shopOpenCloseTimeJson']
      }
    });
    setData(res);
  }

  const saveData = async () => {
    const res = await globalState.client.service('shops').patch(shopId, data);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' }, });
    navigation.goBack();
  }

  const SHOP_DESC_LIST = Object.entries({
    mon: { title: 'จันทร์' },
    tue: { title: 'อังคาร' },
    wed: { title: 'พุธ' },
    thu: { title: 'พฤหัส' },
    fri: { title: 'ศุกร์' },
    sat: { title: 'เสาร์' },
    sun: { title: 'อาทิตย์' },
  });

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View style={{ marginHorizontal: 20, marginTop: 20 }}>
          <Text style={{ marginBottom: 20, fontWeight: 'bold', fontSize: 16 }}>วันเวลาทำการ</Text>
          {SHOP_DESC_LIST.map(item => (
            <View key={item[0]} style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 5 }}>
              <TouchableOpacity style={{ flex: 0.1 }}
                onPress={() => {
                  setData(prev => (
                    {
                      ...prev,
                      shopOpenCloseTimeJson: {
                        ...prev.shopOpenCloseTimeJson,
                        [item[0]]: {
                          ...prev?.shopOpenCloseTimeJson?.[item[0]],
                          enable: !prev?.shopOpenCloseTimeJson?.[item[0]]?.enable
                        }
                      }
                    }
                  ));
                }}
              >
                <CustomIcon
                  name={data?.shopOpenCloseTimeJson?.[item[0]]?.enable ? "checkbox-marked-outline" : "checkbox-blank-outline"}
                  size={22} color="gray"
                />
              </TouchableOpacity>
              <View style={{ flex: 0.2 }}>
                <Text style={{}}>{item[1].title}</Text>
              </View>
              <View style={{ flex: 0.3 }}>
                <TextInput
                  value={data?.shopOpenCloseTimeJson?.[item[0]]?.start}
                  style={{ backgroundColor: 'white', height: 30, paddingHorizontal: 5 }}
                  placeholder="HH:mm"
                  onChangeText={text => setData(prev => (
                    {
                      ...prev,
                      shopOpenCloseTimeJson: {
                        ...prev.shopOpenCloseTimeJson,
                        [item[0]]: {
                          ...prev?.shopOpenCloseTimeJson?.[item[0]],
                          start: text
                        }
                      }
                    }
                  ))}
                />
              </View>
              <View style={{ flex: 0.1, alignItems: 'center' }}>
                <Text style={{}}>-</Text>
              </View>
              <View style={{ flex: 0.3 }}>
                <TextInput
                  value={data?.shopOpenCloseTimeJson?.[item[0]]?.end}
                  style={{ backgroundColor: 'white', height: 30, paddingHorizontal: 5 }}
                  placeholder="HH:mm"
                  onChangeText={text => setData(prev => (
                    {
                      ...prev,
                      shopOpenCloseTimeJson: {
                        ...prev.shopOpenCloseTimeJson,
                        [item[0]]: {
                          ...prev?.shopOpenCloseTimeJson?.[item[0]],
                          end: text
                        }
                      }
                    }
                  ))}
                />
              </View>
            </View>
          ))}
          <TouchableOpacity
            onPress={() => saveData()}
            style={{
              height: 40, backgroundColor: themeColor.color1, justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 50
            }}>
            <Text style={{ color: 'white' }}>บันทึก</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}
