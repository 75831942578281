import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  View, Text, StyleSheet, TouchableOpacity, Keyboard, Dimensions, Linking,
  ActivityIndicator, Image, AppState, Platform, Alert
} from 'react-native';
import { GiftedChat, Bubble } from 'react-native-gifted-chat';
import { InputToolbar } from './CustomInputToolbar';
import { QuickReplies } from '../sharewebapp/CustomQuickReplies';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
// import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCustomSafeAreaInsets } from '../component/custom';
import { getDeviceId, getJwtToken } from '../component/custom';
import {
  FrequentQuestion, ReplyBox, CustomSystemMessage, MessageVideo, MessageImage,
  onLongPress, BottomMenu, SendIcon, CustomChatAction
} from './chatpartcom1';
import { KMBox } from './chatpartkmbox';
import { HEADER_BAR_LIST_FOR_SHOP, truncateString } from '../sharewebapp/common1';
import { CustomTabActions, CustomCommonActions } from '../component/custom';
import { CustomHeaderBackButton } from '../component/custom';
import { genActivityLog } from '../component/custom';

const formatMsg = (payload) => ({ ...payload, _id: payload.id, user: { ...payload.user, _id: payload?.user?.id } });
const formatOwnMsg = (payload, pUser) => ({ ...payload, _id: payload.id, user: { ...pUser, _id: pUser.id } });

export function ChatPart({ navigation, route }) {
  const { chatWithId, chatWithType, chatFromId, chatFromType, queId, viewhistory, action, fakeUserId } = route.params;
  const [dataList, setDataList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [chatFocus, setChatFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const { globalState, dispatch } = useContext(store);
  const [customText, setCustomText] = useState('');
  const [keyboadShown, setKeyboardShown] = useState(false);
  const [showMenu, setShowMenu] = useState(0);
  const insets = useCustomSafeAreaInsets();
  const deviceId = useRef();
  const chatRef = useRef();
  const [waiting, setWaiting] = useState(false);
  const [chatReplyInfo, setChatReplyInfo] = useState({
    id: 0, text: '', image: null, video: null, data: null,
    user: { id: 0, displayName: '', avatar: null }
  });
  const [showKM, setShowKM] = useState(false);

  const appState = useRef(AppState.currentState);
  const [chatTitle, setChatTitle] = useState(<CustomHeaderBackButton onPress={() => navigation.goBack()} />);
  const [extHeader, setExtHeader] = useState(0);
  const [redText, setRedText] = useState({ basket: 0 })

  const [toLoad, setToLoad] = useState(false);
  const inLoadRef = useRef(false);

  const setTitle = async () => {
    console.log('setTitle chatWithId:' + chatWithId);
    if (chatWithType == 'user' && chatFromType == 'shop') {
      let res, res1;
      for (let i = 0; i < 3; i++) {
        try {
          res = await globalState.client.service('users').get(chatWithId, { query: { $select: ['displayName'] } });
        } catch (e) {
          console.log('error point1', e);
        }
        if (res) {
          break;
        }
        if (!res && i == 2) {
          console.log('error point11');
          return;
        }
      }

      try {
        res1 = await globalState.client.service('shops').get(chatFromId, { query: { $select: ['shopThName'] } });
      } catch (e) {
        console.log('error point2' + JSON.stringify(e));
      }

      setChatTitle(
        <View style={{ alignItems: 'flex-start', marginLeft: 10, flexDirection: 'row', alignItems: 'center' }}>
          <CustomHeaderBackButton onPress={() => navigation.goBack()} />
          <View>
            <Text style={{ fontSize: 14 }}>{`${truncateString(res?.displayName, 40)}`}</Text>
            <Text style={{ fontSize: 10 }}>[{`${truncateString(res1?.shopThName, 40)}`}]</Text>
          </View>
        </View>
      )

    } else if (chatWithType == 'shop' && chatFromType == 'user') {
      const res1 = await globalState.client.service('shops').get(chatWithId, { query: { $select: ['shopThName'] } });
      navigation.setOptions({ title: truncateString(res1?.shopThName, 40), headerTitleStyle: { fontSize: 12, fontWeight: 'normal' } });
    }
  }

  const onPressHeaderBar = async (name) => {
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/shopAllowToSell`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ shopId: chatFromId, queId })
    });
    const resJson = await res.json();

    if (name == 'basket') {
      if (resJson.allowToSell) {
        navigation.navigate('Basket', { userId: chatWithId, shopId: chatFromId, queId: queId })
      } else {
        alert(resJson.message);
        return
      }
    } else if (name == 'editpreorder') {
      if (resJson.allowToSell) {
        navigation.navigate('EditPreOrder', { userId: chatWithId, shopId: chatFromId, queId: queId })
      } else {
        alert(resJson.message);
        return
      }
    } else if (name == 'viewuser') {
      navigation.navigate('ViewUser', { userId: chatWithId })
    } else if (name == 'close') {
      setExtHeader(prev => prev == 0 ? 2 : 0)
    } else if (name == 'call') {
      setExtHeader(prev => prev == 0 ? 1 : 0)
    }
  }

  const caseCancelByUser = () => {
    return new Promise(async (resolve, reject) => {
      const resOrderMain = await globalState.client.service('ordermains').find({
        query: { queId, orderStatus: { $nin: ['cancel_by_admin', 'cancel_by_system'] }, $strict: 1, $select: ['id'] }
      });
      if (resOrderMain.total > 0) {
        alert('ท่านไม่สามารถปิดห้องสนทนานี้ได้ เนื่องจากมีคำสั่งซื้อค้างอยู่');
        resolve(false)
      } else {
        Alert.alert('ท่านต้องการออกจากห้องสนทนากับเภสัชกรท่านนี้หรือไม่', '', [
          {
            text: 'ออกจากห้องสนทนา', onPress: async () => {
              const jwtToken = await getJwtToken();
              const res = await fetch(`${server_var.server_api}/kickOutFromChat?queId=${queId}&reason=cancel_by_user`,
                {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
                }
              );
              navigation.dispatch(CustomTabActions?.jumpTo('home'));
              dispatch({ type: 'showToast', payload: { text: 'ยกเลิกห้องสนทนากับเภสัชกรท่านนี้แล้ว' }, });
              resolve(true);
            }
          },
          { text: 'สนทนาต่อ', onPress: () => resolve(false) },
        ])
      }
    });
  }

  useEffect(() => {
    delay(5000).then(() => {
      dispatch({ type: 'setChatWith', payload: { chatWithId, chatWithType, chatFromId, chatFromType } });
    })

    if (action && action.name == 'set_cc_tabindex') {
      navigation.dispatch(CustomTabActions?.jumpTo('consult', { tabIndex: action.tabIndex }));
    } else {
      if (!globalState?.user?.staffFlag) {
        const navState1 = navigation.getState().routes;
        console.log('hello route1' + JSON.stringify(navState1));
        const chatNavIndex = navState1?.findIndex(item => item.name == 'Chat');
        if (chatNavIndex > -1 && (chatNavIndex == (navState1.length - 1))) {
          navigation.popToTop();
          navigation.dispatch(CustomTabActions?.jumpTo('consult'));
          const navState2 = navigation.getState().routes;
          console.log('hello route2' + JSON.stringify(navState2));
          const tmpNavState = [navState2[0], navState1[chatNavIndex]]
          const resetAction = CustomCommonActions?.reset({
            index: 1,
            routes: tmpNavState,
          });
          console.log('hello mod route' + JSON.stringify(tmpNavState));
          navigation.dispatch(resetAction);
        }
      }
    }
    let headerBarList = [];
    if (chatWithType == 'user' && chatFromType == 'shop') {
      headerBarList = HEADER_BAR_LIST_FOR_SHOP(onPressHeaderBar);
    } else if (chatWithType == 'shop' && chatFromType == 'user') {
      headerBarList = [
        { id: 1, iconName: 'basket', redText: 'basket', onPress: () => navigation.navigate('Basket', { onlyBasket: true, userId: chatFromId }) },
        { id: 2, iconName: 'note-text-outline', onPress: () => navigation.navigate('ViewPreOrder', { forUser: true, queId: queId }) },
        { id: 3, iconName: 'close-circle', onPress: () => caseCancelByUser() }
      ];
    }
    navigation.setOptions({
      headerLeft: () => (chatTitle),
      headerRight: () => (
        <View style={{ flexDirection: 'row' }}>
          {headerBarList.map(item => (
            <TouchableOpacity key={item.id.toString()} style={{ marginRight: 10 }} onPress={item.onPress}>
              <View style={{ flexDirection: 'row' }}>
                <CustomIcon name={item?.iconName} size={24} color={themeColor.color1} />
                {item?.redText && redText?.[item?.redText] > 0 && (
                  <View style={{
                    width: 20, height: 20, borderRadius: 10, backgroundColor: themeColor.color6,
                    justifyContent: 'center', alignItems: 'center'
                  }}>
                    <Text style={{ color: 'white', fontSize: 10, fontWeight: 'bold' }}>
                      {redText?.[item?.redText]}
                    </Text>
                  </View>
                )}
              </View>
            </TouchableOpacity>
          ))}
        </View>
      ),
    }, route?.custom?.index)
    return () => {
      dispatch({ type: 'setChatWith', payload: null });
    };
  }, [chatWithId, chatWithType, chatFromId, chatFromType, extHeader, redText, chatTitle, queId, viewhistory]);
  const onPressPharmaNote = ({ queId, userId, pharmaId, shopId }) => {
    console.log('onPress PharmaNote', queId, userId, pharmaId, shopId);
    navigation.navigate('PharmaNote', { queId, userId, pharmaId, shopId })
  }

  const onPressForward = ({ queId, fromShopId, fromPharmaId }) => {
    navigation.navigate('Forward', { queId, fromShopId, fromPharmaId });
  }

  const onCaseClose = ({ refresh, tabIndex }) => {
    if (Platform.OS != 'web') {
      navigation.dispatch(state => {
        console.log('hello route1:Platform:' + Platform.OS + ':userId:' + globalState?.user.id + ':' + JSON.stringify(state));
        const routes = state?.routes.filter(r => r.name !== 'Chat');
        return CustomCommonActions?.reset({
          ...state,
          routes,
          index: routes.length - 1,
        });
      });
      return
    }
    if (Platform.OS == 'web') {
      navigation.goBack(1);
    }

  }

  const onPressJoinVideoRoom = async (roomId, callMode) => {
    navigation.navigate('VideoCall1', { roomId, callMode });
  }

  const onPressViewImage = async (uri) => {
    navigation.navigate('ViewImage1', { uri });
  }

  const EXT_HEADER = [
    null,
    [
      {
        id: 2, iconName: 'video', text: 'วิดีโอคอล', onPress: async () => {
          setExtHeader(prev => prev == 0 ? 1 : 0)
          const jwtToken = await getJwtToken();
          const res1 = await fetch(
            `${server_var.server_api}/createRoomForQueId?queId=${queId}`,
            {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
            }
          );
          const resJson1 = await res1.json();
          let roomId = resJson1?.room;
          sendMessage({ type: 'Meeting', data: { roomId, type: 'video' } });
          onPressJoinVideoRoom(roomId, 'video');
        }
      },
      {
        id: 1, iconName: 'phone', text: 'สนทนาแบบเสียง', onPress: async () => {
          setExtHeader(prev => prev == 0 ? 1 : 0)
          const jwtToken = await getJwtToken();
          const res1 = await fetch(
            `${server_var.server_api}/createRoomForQueId?queId=${queId}`,
            {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
            }
          );
          const resJson1 = await res1.json();
          let roomId = resJson1?.room;
          sendMessage({ type: 'Meeting', data: { roomId, type: 'voice' } });
          onPressJoinVideoRoom(roomId, 'voice');
        }
      },
    ],
    [
      {
        id: 1, iconName: 'note', text: 'บันทึกประวัติการรักษา', onPress: () => {
          setExtHeader(prev => prev == 0 ? 2 : 0)
          onPressPharmaNote({ queId, userId: chatWithId, pharmaId: globalState.user.id, shopId: chatFromId })
        }
      },
      {
        id: 2, iconName: 'share', text: 'ส่งต่อเคส', onPress: () => {
          setExtHeader(prev => prev == 0 ? 2 : 0)
          onPressForward({ queId, fromShopId: chatFromId, fromPharmaId: globalState.user.id });
        }
      },
    ]
  ]

  const loadDeviceId = async () => {
    const tmpDeviceId = await getDeviceId();
    deviceId.current = tmpDeviceId;
  }

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setKeyboardShown(true);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardShown(false);
    });
    loadDeviceId();
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    globalState.client.service('messages').on('created', onMsgCreateListener);
    globalState.client.service('messages').on('removed', onMsgRemoveListener);
    globalState.client.service('messages').on('patched', onMsgPatchListener);
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('messages').removeListener('created', onMsgCreateListener);
      globalState.client.service('messages').removeListener('removed', onMsgRemoveListener);
      globalState.client.service('messages').removeListener('patched', onMsgPatchListener);
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, [chatWithId, chatWithType, chatFromId, chatFromType, queId, action, dataList, globalState.inPayScreen]);

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        delay(2000).then(() => {
          setToLoad(true);
        })
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    loadAll();
    if (toLoad) {
      setToLoad(false);
    }
  }, [chatWithId, chatWithType, chatFromId, chatFromType, toLoad, queId])

  const loadAll = async () => {
    console.log(themeColor.FgCyan, 'check load all', inLoadRef.current, themeColor.FgReset);
    if (!inLoadRef.current) {
      inLoadRef.current = true;
      console.log(themeColor.FgGreen, 'load All start', themeColor.FgReset);
      await genActivityLog(globalState, 'in_chat_screen');
      await setTitle();
      await loadMessage({ chatWithId, chatWithType, chatFromId, chatFromType, queId, viewhistory }, 0);
      await patchUnread({ chatWithId, chatWithType, chatFromId, chatFromType });
      await loadBasketCount();
      console.log(themeColor.FgGreen, 'load All complete', themeColor.FgReset);
      inLoadRef.current = false;
    }
  }

  const loadBasketCount = async () => {
    let query;
    if (chatWithType == 'user' && chatFromType == 'shop') {
      query = { userId: chatWithId };

    } else if (chatWithType == 'shop' && chatFromType == 'user') {
      query = { userId: chatFromId, shopId: chatWithId };
    }
    let res;
    try {
      res = await globalState.client.service('baskets').find({ query });
    } catch (e) {
      console.log('error load basket count', e)
    }
    if (res) {
      setRedText(prev => ({ ...prev, basket: res.total }))
      if (chatWithType == 'shop' && chatFromType == 'user') {
        dispatch({ type: 'setBasketAmount', payload: res.total });
      }
    }
  }

  const onNotiListener = (payload) => {
    console.log('payload', payload);
    if (payload.toUserId == globalState.user.id && payload.source == chatWithId && payload.type == 'MsgReadAll') {
      setDataList(prev => [...prev].map((item) => ({ ...item, received: true })));
    } else if (payload.toUserId == globalState.user.id && payload.type == 'CaseClose' && payload?.data?.queId == queId) {
      if (!globalState.inPayScreen) {
        onCaseClose({ refresh: true, tabIndex: 2 })
      } else {
        dispatch({ type: 'setDeferCloseCase', payload: true });
      }

    } else if (payload.toUserId == globalState?.user?.id && payload.type == 'RefreshBasket' && (
      (chatWithType == 'user' && chatFromType == 'shop' && payload?.data?.shopId == chatFromId && payload?.data?.userId == chatWithId) ||
      (chatWithType == 'shop' && chatFromType == 'user' && payload?.data?.userId == chatFromId && payload?.data?.shopId == chatWithId)
    )) {
      setRedText(prev => ({ ...prev, basket: payload?.data?.basketCount }))
      if (chatWithType == 'shop' && chatFromType == 'user') {
        dispatch({ type: 'setBasketAmount', payload: payload?.data?.basketCount })
      }
    }
  };

  const QUERY_LIST =
    [
      {
        chatWithType: 'user', chatFromType: 'user',
        query: {
          userId: chatWithId, userId2: chatFromId, shopId: 0, shopId2: 0
        },
        filter: (item) => {
          return (item.userId == chatWithId && item.chatFromId == chatFromId && item.shopId == 0 && item.shopId2 == 0)
        },
        sendParam: { userId: chatFromId, userId2: chatWithId, queId, deviceId: deviceId.current },
        patch: true
      },
      {
        chatWithType: 'user', chatFromType: 'user',
        query: {
          userId2: chatWithId, userId: chatFromId, shopId: 0, shopId2: 0
        },
        filter: (item) => {
          return (item.userId2 == chatWithId && item.userId == chatFromId && item.shopId == 0 && item.shopId2 == 0)
        },
        sendParam: { userId: chatFromId, userId2: chatWithId, queId, deviceId: deviceId.current },
      },
      {
        chatWithType: 'shop', chatFromType: 'user',
        query: {
          shopId2: chatWithId, userId: chatFromId, shopId: 0
        },
        filter: (item) => {
          return (item.shopId2 == chatWithId && item.userId == chatFromId && item.shopId == 0)
        },
        sendParam: { userId: chatFromId, shopId2: chatWithId, queId, deviceId: deviceId.current },
      },
      {
        chatWithType: 'shop', chatFromType: 'user',
        query: {
          shopId: chatWithId, userId2: chatFromId, shopId2: 0
        },
        filter: (item) => {
          return (item.shopId == chatWithId && item.userId2 == chatFromId && item.shopId2 == 0)
        },
        sendParam: { userId: chatFromId, shopId2: chatWithId, queId, deviceId: deviceId.current },
        patch: true
      },
      {
        chatWithType: 'user', chatFromType: 'shop',
        query: {
          userId2: chatWithId, shopId: chatFromId, shopId2: 0
        },
        filter: (item) => {
          return (item.userId2 == chatWithId && item.shopId == chatFromId && item.shopId2 == 0)
        },
        sendParam: { shopId: chatFromId, userId: globalState?.user?.id, userId2: chatWithId, queId, deviceId: deviceId.current },
      },
      {
        chatWithType: 'user', chatFromType: 'shop',
        query: {
          userId: chatWithId, shopId2: chatFromId, shopId: 0
        },
        filter: (item) => {
          return (item.userId == chatWithId && item.shopId2 == chatFromId && item.shopId == 0)
        },
        sendParam: { shopId: chatFromId, userId: globalState?.user?.id, userId2: chatWithId, queId, deviceId: deviceId.current },
        patch: true
      },
    ];

  const onMsgCreateListener = async (payload) => {
    let filterList = QUERY_LIST.filter(item => (
      item.chatWithType == chatWithType && item.chatFromType == chatFromType
    ))
    for (const item of filterList) {
      if (item.filter(payload)) {
        if (payload?.deviceId != deviceId.current) {
          setDataList(prev => [formatMsg(payload), ...prev]); ///
        }
        if (item.patch) {
          try {
            await globalState.client.service('uumsglogs').patch(null, { unreadNum: 0 },
              { query: item.query },
            );
          } catch (e) {
            console.log('error patch uumsglogs', e)
          }

        }
      }
    }
  };

  const onMsgRemoveListener = (payload) => {
    setDataList(prev => {
      const tmpList = [...prev].filter(item => item.id != payload.id);
      return tmpList;
    });
  }

  const onMsgPatchListener = (payload) => {
    console.log('on message patched id:' + payload?.id)
    setDataList(prev => {
      const tmpList = [...prev];
      const tmpList2 = tmpList.map(item => {
        if (item.id == payload.id) {
          return formatMsg(payload);
        } else {
          return item;
        }
      })
      return tmpList2;
    });
  }

  const sendMessage = async (messages) => {
    const tmpMessage = QUERY_LIST.find(item => (
      item.chatWithType == chatWithType && item.chatFromType == chatFromType
    ))?.sendParam;
    let tmpToSend, tmpOwnMsg;
    const tmpId = Date.now()

    if (chatReplyInfo?.id) {
      tmpToSend = { ...messages, ...tmpMessage, type: 'Reply', data: { ...chatReplyInfo } };
      tmpOwnMsg = { ...messages, ...tmpMessage, id: tmpId, type: 'Reply', data: { ...chatReplyInfo } };
      setChatReplyInfo(prev => ({ id: 0 }));
      console.log('tmpToSend', tmpToSend);
      console.log('tmpOwnMsg', tmpOwnMsg);
    } else {
      tmpToSend = { ...messages, ...tmpMessage };
      tmpOwnMsg = { ...messages, ...tmpMessage, id: tmpId };
    }
    setDataList(prev => [formatOwnMsg(tmpOwnMsg, globalState.user), ...prev]);
    try {
      const resMsg = await globalState.client.service('messages').create(tmpToSend);
      console.log('resMsg', resMsg);
      setDataList(prev => {
        const tmpList = [...prev];
        const tmpList2 = tmpList.map(item2 => {
          if (item2.id == tmpId) {
            return formatOwnMsg(resMsg, globalState.user)
          } else {
            return item2
          }
        })
        return tmpList2;
      });
    } catch (error) {
      console.log('message error:' + JSON.stringify(error));
    }
  };

  const patchUnread = async ({ chatWithId, chatWithType, chatFromId, chatFromType }) => {
    let query1 = { query: null };
    query1['query'] = QUERY_LIST
      .find(item => (item.chatWithType == chatWithType && item.chatFromType == chatFromType && item.patch))?.query;
    try {
      await globalState.client.service('uumsglogs').patch(null, { unreadNum: 0 }, query1);
    } catch (e) {
      console.log('error patch uumsglogs 2 :', e);
    }

  };

  const loadMessage = async ({ chatWithId, chatWithType, chatFromId, chatFromType, queId, viewhistory }, pSkip) => {
    console.log('load message');
    setLoading(true);
    let query1;
    query1 = { query: { $sort: { id: -1 }, $skip: pSkip ?? skip, $limit: 20 } };
    query1.query['$or'] = QUERY_LIST
      .filter(item => (item.chatWithType == chatWithType && item.chatFromType == chatFromType))
      .map(item => item.query);

    let res;
    if (viewhistory) {
      setShowMenu(4);
      let query2;
      query2 = { query: { queId, $sort: { id: -1 }, $skip: pSkip ?? skip, $limit: 500 } };
      try {
        res = await globalState.client.service('oldmessages').find(query2);
        console.log('load history message success');
      } catch (e) {
        console.log('error load history message', e);
      }
    } else {
      try {
        res = await globalState.client.service('messages').find(query1);
        console.log('load message success');
      } catch (e) {
        console.log('error load message', e);
      }
    }

    if (res) {
      const tmpDataList = res.data.map(item => formatMsg(item));
      if (pSkip == 0 || skip == 0) {
        setDataList([...tmpDataList]);
      } else {
        setDataList(prev => [...prev, ...tmpDataList]);
      }
      setSkip(res.skip + res.limit);
    }
    setLoading(false);
  };

  const loadMore = () => loadMessage({ chatWithId, chatWithType, chatFromId, chatFromType, queId, viewhistory });

  const onQuickReply = async (obj) => {
    if (chatFromType != 'shop') {
      const res = await globalState.client.service('pharmanotes').find({ query: { queId, userId: globalState?.user?.id } });
      if (res.total > 0) {
        if (!res.data[0]?.symtom?.includes(obj[0].value) && res?.data?.[0]?.id) {
          await globalState.client.service('pharmanotes').patch(res.data[0].id, { symtom: `${res.data[0].symtom} ${obj[0].value}` })
        }
      } else {
        const tmpCreate = {
          queId, userId: chatFromId, shopId: chatWithId, symtom: obj[0].value, psctype: 'not_prescribe'
        };
        try {
          await globalState.client.service('pharmanotes').create(tmpCreate);
        } catch (e) {
          console.log('error', e);
        }
      }
      setCustomText(prev => (prev + ' ' + obj[0].value));
    }
  }

  const joinVideoRoom = async (roomId, callMode) => {
    onPressJoinVideoRoom(roomId, callMode);
  }

  const renderCustomView = (props) => {
    if (props?.currentMessage?.type == "Reply") {
      return (
        <View style={{ padding: 10, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ flexDirection: 'row', paddingHorizontal: 10 }}>
            <Image source={{ uri: props?.currentMessage?.data?.user?.avatar }} style={{ width: 30, height: 30, borderRadius: 15 }} />
            <View style={{ marginLeft: 10 }}>
              <Text>{props?.currentMessage?.data?.user?.displayName}</Text>
              <Text style={{ color: 'gray', marginTop: 3 }}>{props?.currentMessage?.data?.text}</Text>
            </View>
          </View>
        </View>
      )
    } else if (props?.currentMessage?.type == "File") {
      return (
        <TouchableOpacity onPress={() => props?.currentMessage?.data?.uri ? Linking.openURL(props?.currentMessage?.data?.uri) : null}>
          <View style={{ padding: 10, justifyContent: 'center', alignItems: 'center' }}>
            <CustomIcon name={"file"} size={26}
              color={props?.currentMessage?.userId == globalState?.user?.id ? 'lightgray' : 'black'}
            />
            <Text style={{
              marginTop: 5,
              color: props?.currentMessage?.userId == globalState?.user?.id ? 'lightgray' : 'black'
            }}>{props?.currentMessage?.data?.fileName}</Text>
          </View>
        </TouchableOpacity>
      )
    } else if (props?.currentMessage?.type == "Meeting") {
      const roomId = props?.currentMessage?.data?.roomId
      const bMeetingWidth = Platform.OS == 'web' ? Dimensions.get('window').width / 5 : Dimensions.get('window').width / 1.5
      return (
        <View style={{ padding: 10, justifyContent: 'center', alignItems: 'center', width: bMeetingWidth }}>
          <View style={{
            justifyContent: 'center', alignItems: 'center', width: 36, height: 36, borderRadius: 18, backgroundColor: 'gray'
          }}>
            <CustomIcon name={props?.currentMessage?.data?.type == 'video' ? 'video' : "phone"} size={20}
              color={props?.currentMessage?.userId == globalState?.user?.id ? 'lightgray' : 'black'}
            />
          </View>
          <Text style={{
            marginTop: 10, fontSize: 12,
            color: props?.currentMessage?.userId == globalState?.user?.id ? 'lightgray' : 'black'
          }}>ห้องสนทนา{props?.currentMessage?.data?.type == 'video' ? 'วิดีโอ' : 'เสียง'}เริ่มแล้ว</Text>
          <TouchableOpacity onPress={() => joinVideoRoom(roomId, props?.currentMessage?.data?.type)}>
            <View style={{ marginTop: 15, backgroundColor: 'gray', paddingHorizontal: 40, paddingVertical: 10, borderRadius: 10 }}>
              <Text style={{ fontSize: 10, fontWeight: 'bold', color: props?.currentMessage?.userId == globalState?.user?.id ? 'lightgray' : 'black' }}>
                กดเพื่อเข้าสนทนา
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      )
    }
  }

  return (
    // <View style={{ flex: 1, paddingBottom: keyboadShown ? 0 : insets.bottom }}>
    <View style={{ flex: 1, paddingBottom: 0 }}>
      <GiftedChat
        ref={chatRef}
        text={customText}
        onInputTextChanged={text => setCustomText(text)}
        dateFormat={'DD/MM/YYYY'}
        timeFormat={'HH:mm'}
        messages={dataList}
        alwaysShowSend={true}
        autoFocus={true}
        listViewProps={{ onEndReached: () => loadMore(), onEndReachedThreshold: 0.2 }}
        wrapInSafeArea={true}
        renderInputToolbar={props => ([1, 2, 3, 4].includes(showMenu) ? null : <InputToolbar {...props} />)}
        renderChatFooter={props => <ReplyBox {...props} chatReplyInfo={chatReplyInfo} setChatReplyInfo={setChatReplyInfo} />}

        keyboardShouldPersistTaps={'never'}
        renderBubble={(props) => (
          <Bubble {...props} wrapperStyle={{ right: { backgroundColor: themeColor.color1 }, left: { backgroundColor: 'lightgray' } }} />
        )}
        renderSend={(props) => <SendIcon {...props} />}
        renderActions={(props) => (
          !chatFocus &&
          <CustomChatAction
            currentUserId={globalState.user.id}
            chatWithId={chatWithId}
            sendMessage={sendMessage}
            setShowMenu={setShowMenu}
            showMenu={showMenu}
            setWaiting={setWaiting}
          />
        )}
        textInputProps={{ onFocus: () => setChatFocus(true), onBlur: () => setChatFocus(false) }}
        onSend={(messages) => {
          sendMessage(messages[0]);
          setCustomText('');
        }}
        user={{ _id: fakeUserId ?? globalState?.user?.id }}
        onQuickReply={obj => onQuickReply(obj)}
        renderQuickReplies={(props) => (
          <View>
            <QuickReplies  {...props} color={themeColor.color1}
              quickReplyStyle={{ fontSize: 18, borderWidth: 1, borderRadius: 10, marginRight: 5, marginVertical: 5 }}
            />
          </View>
        )}
        renderCustomView={renderCustomView}
        renderMessageImage={props =>
          <MessageImage
            props={props}
            onPressViewImage={onPressViewImage}
            globalState={globalState}
            setChatReplyInfo={setChatReplyInfo}
          />
        }
        renderMessageVideo={props => <MessageVideo props={props} />}
        renderSystemMessage={props => <CustomSystemMessage props={props} />}
        onLongPress={(context, currentMessage) => onLongPress(currentMessage, context.actionSheet, globalState, chatRef, setChatReplyInfo)}
        parsePatterns={(linkStyle) => [
          {
            pattern: /ไอคอนตะกร้าสินค้ามุมบนด้านขวา หรือคลิกที่นี่/i, style: { textDecorationLine: 'underline' },
            onPress: () => {
              if (chatWithType == 'user' && chatFromType == 'shop') {
                navigation.navigate('Basket', { userId: chatWithId, shopId: chatFromId, queId: queId })
              } else {
                navigation.navigate('Basket', { onlyBasket: true, userId: chatFromId })
              }
            }
          }
        ]}
      />
      {showMenu == 1 && !keyboadShown &&
        <BottomMenu setShowMenu={setShowMenu} showMenu={showMenu} sendMessage={sendMessage} setWaiting={setWaiting} />
      }
      {showMenu == 2 && !keyboadShown &&
        <FrequentQuestion setShowMenu={setShowMenu} showMenu={showMenu} sendMessage={sendMessage} setCustomText={setCustomText} />
      }
      {showMenu == 3 &&
        <KMBox
          setShowMenu={setShowMenu} showMenu={showMenu} sendMessage={sendMessage}
          setCustomText={setCustomText} navigation={navigation}
        />
      }
      {
        extHeader > 0 && (
          <View style={{
            position: 'absolute', left: 0, right: 0, top: 0, height: 50, backgroundColor: 'lightgray',
            justifyContent: 'center', alignItems: 'center', flexDirection: 'row'
          }}>
            {EXT_HEADER[extHeader].map(item => (
              <TouchableOpacity key={item.id.toString()} onPress={item.onPress} style={{ marginHorizontal: 30 }}>
                <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                  <CustomIcon name={item.iconName} size={20} color={"black"} />
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>{item.text}</Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        )
      }
      {waiting && (
        <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', opacity: 0.5 }}>
          <ActivityIndicator size={"large"} color={"black"} />
        </View>
      )}
    </View>
  );
}

const delay = ms => new Promise(res => setTimeout(res, ms));