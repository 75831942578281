import React from 'react';
import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  Text, View, TouchableOpacity, Image, TextInput, Button, Platform,
  Dimensions, FlatList, ActivityIndicator, Modal, ScrollView, SafeAreaView, Alert, AppState
} from 'react-native';
import EventEmitter from "./EventEmitter";
import { server_var, themeColor, fontStyles } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import moment from 'moment';
import { getJwtToken } from '../component/custom';
import { CouponView } from '../component/couponview';
import { useCustomFocusEffect } from '../component/custom';

export function BasketAction({
  basketList, userId, onlyBasket, shopId, setAllowToBuyOnly,
  loadBasketList, allowToBuyOnly, dirty, setDirty, setMainWaitFlag, queId, proceedToOrder, navigation,
  priceInfo
}) {
  const { globalState } = useContext(store);
  const [modeId, setModeId] = useState();
  const [basketOrderData, setBasketOrderData] = useState({ deliverType: null })
  const [error, setError] = useState({ at: '', message: '' });
  const [delOption, setDelOption] = useState({
    sevenTradeArea: false, lalamoveArea: false, grabArea: false, postArea: false, errorDelOption: false
  });
  const [waitFlag, setWaitFlag] = useState(false);
  const [couponUsedList, setCouponUsedList] = useState([]);
  const [loadOrderFlag, setLoadOrderFlag] = useState(false);

  useCustomFocusEffect(
    useCallback(() => {
      unlockOrder()
    }, [basketOrderData])
  );

  const unlockOrder = async () => {
    const res = await fetch(`${server_var.server_api}/lockorder`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ ordermainId: basketOrderData?.id, method: 'set', setValue: null })
    });
    const resJson = await res.json();
    console.log('resJson', resJson);
  }
  const lockOrder = async () => {
    const res = await fetch(`${server_var.server_api}/lockorder`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ ordermainId: basketOrderData?.id, method: 'set', setValue: 1 })
    });
    const resJson = await res.json();
    console.log('resJson', resJson);
  }

  useEffect(() => {
    EventEmitter.addListener('Basket:CouponRefresh', loadCouponUsedList);
    return () => {
      EventEmitter.removeListener('Basket:CouponRefresh', loadCouponUsedList);
    };
  }, [])

  const loadCouponUsedList = (payload) => {
    setCouponUsedList(payload)
  }

  const confirmToOrder = async () => {
    setError({ at: '', message: '' });
    console.log('basketOrderData', basketOrderData);
    if (!basketOrderData?.deliverType) {
      setMainWaitFlag(false)
      setError({ at: 'deliverType', message: 'กรุณาเลือก' });
      return;
    }
    if (delOption?.errorDelOption) {
      setMainWaitFlag(false);
      alert('มีข้อผิดพลาดเกิดขึ้นกับตัวเลือกการจัดส่ง กรุณาเข้าหน้านี้ใหม่อีกครั้ง');
      return;
    }
    let resOrder;
    try {
      resOrder = await globalState.client.service('ordermains').patch(basketOrderData.id, {
        deliverType: basketOrderData.deliverType,
        couponUsedList: couponUsedList,
      });
    } catch (e) {
      alert('มีข้อผิดพลาดเกิดขึ้น กรุณากดยืนยันรายการอีกครั้ง');
      return;
    }
    // เพิ่งเพิ่มเข้ามาเพื่อกันอีกชั้น 11Oct2023
    // เพิ่งเพิ่มเข้ามาเพื่อกันอีกชั้น 6Mar2024
    if (!(resOrder?.deliverType) || !resOrder) {
      // if (!(resOrder?.deliverType)) {
      alert('มีข้อผิดพลาดเกิดขึ้น กรุณากดยืนยันรายการอีกครั้ง');
      return;
    }
    await lockOrder();
    proceedToOrder()
  }

  const allowCustomerToBuy = async () => {
    console.log('\n\npass0');
    if (!dirty && priceInfo.returnCode == '0000') {
      console.log('\n\npass1');
      setMainWaitFlag(true)
      let resOrder;
      const jwtToken = await getJwtToken();
      if (!basketList[0].ordermainId) {
        let resOldQueLine;
        try {
          resOldQueLine = await globalState.client.service('oldquelines').find({
            query: { oldId: basketList[0].queId, $select: ['deliverJsonAddr'] }
          });
        } catch (e) {
          alert('มีข้อผิดพลาดเกิดขึ้น กรุณากดยืนยันรายการอีกครั้ง 101')
          setMainWaitFlag(false)
          return;
        }

        let tmpDeliverJsonAddr = resOldQueLine?.data?.[0]?.deliverJsonAddr;
        if (tmpDeliverJsonAddr.id == 0) {
          tmpDeliverJsonAddr['detailAddress'] = '';
          tmpDeliverJsonAddr['otherAddress'] = '';
        }
        try {
          const createQuery = {
            orderStatus: 'in_basket', userId, shopId: basketList[0].shopId,
            queId: basketList[0].queId, pharmaId: basketList[0].pharmaId,
            deliverType: basketOrderData.deliverType,
            deliverJsonAddr: resOldQueLine?.data?.[0]?.deliverJsonAddr,
            '$calcFromServer': true,
          }
          console.log('createquery' + JSON.stringify(createQuery, '', 2));
          const tmpResOrder = await fetch(`${server_var.server_api}/ordermains`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwtToken}`
            },
            body: JSON.stringify(createQuery),
          });
          resOrder = await tmpResOrder.json();
        } catch (e) {
          console.log(themeColor.FgRed, "มีข้อผิดพลาด", e, themeColor.FgReset);
          alert('มีข้อผิดพลาดเกิดขึ้น กรุณากดยืนยันรายการอีกครั้ง 102')
          setMainWaitFlag(false)
          return;
        }
      } else {
        try {
          const tmpResOrder = await fetch(`${server_var.server_api}/ordermains/${basketList[0].ordermainId}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwtToken}`
            },
            body: JSON.stringify({
              orderStatus: 'in_basket',
              shopId,
              '$calcFromServer': true,
            }),
          });
          resOrder = await tmpResOrder.json();
        } catch (e) {
          console.log('error103__111:', e);
          alert('มีข้อผิดพลาดเกิดขึ้น กรุณากดยืนยันรายการอีกครั้ง 103')
          setMainWaitFlag(false)
          return;
        }
        console.log('resOrder111:', resOrder);
      }
      try {
        await globalState.client.service('baskets').patch(null,
          { allowToPay: 1, ordermainId: resOrder.id }, { query: { userId, $limit: 200 } }
        );
      } catch (e) {
        console.log('error!!', e);
        alert('มีข้อผิดพลาดเกิดขึ้น กรุณากดยืนยันรายการอีกครั้ง 104')
        setMainWaitFlag(false)
        return;
      }

      //ตรวจสอบว่ามีการสร้าง pharmanotes แล้วหรือยัง
      const resPhamarNote = await globalState.client.service('pharmanotes').find(
        { query: { userId, shopId, queId, pharmaId: globalState?.user?.id } }
      )
      if (resPhamarNote?.total == 0) {
        globalState.client.service('pharmanotes').create({
          queId, shopId, userId, pharmaId: globalState?.user?.id, psctype: 'prescribe',
        })
      } else {
        if (resPhamarNote?.data?.[0]?.id) {
          globalState.client.service('pharmanotes').patch(resPhamarNote?.data?.[0]?.id, { psctype: 'prescribe' });
        }
      }
      setMainWaitFlag(false)
    }
  }

  const MODE_LIST = [
    {
      id: 1, condition: onlyBasket && basketList?.length > 0 && allowToBuyOnly,
      actionList: [
        {
          id: 1, title: 'ยืนยันรายการ', backgroundColor: themeColor.color1, actionItemProp: { width: '80%' },
          onPress: () => confirmToOrder()
        }
      ],
      additionalCom:
        <View>
          <View style={[error?.at == 'deliverType' ? { borderColor: 'red', borderWidth: 1 } : null]}>
            <BasketDeliverChoice
              setBasketOrderData={setBasketOrderData} basketOrderData={basketOrderData} delOption={delOption}
            />
            {error?.message ? <Text style={{ color: 'red', textAlign: 'center' }}>กรุณาเลือก</Text> : <View />}
          </View>
          {server_var.couponMode && (
            <CouponChoice navigation={navigation} couponUsedList={couponUsedList} ordermainId={basketOrderData?.id} />
          )}
        </View>
      ,
    },
    {
      id: 2, condition: onlyBasket && basketList?.length > 0 && !allowToBuyOnly,
      actionList: [
        { id: 1, title: 'สั่งซื้อสินค้า', backgroundColor: 'gray', actionItemProp: { width: '80%' }, onPress: () => console.log('cannot buy yet') }
      ],
    },
    {
      id: 3, condition: basketList?.length > 0 && !allowToBuyOnly,
      actionList: [
        {
          id: 1, title: '1. คำนวณราคาสินค้าในตะกร้า', backgroundColor: themeColor.color1,
          actionItemProp: { width: '45%' }, actionItemTextProp: { fontSize: 12 },
          onPress: async () => {
            setMainWaitFlag(true);
            await loadBasketList({ refreshBasket: false, calcTotalPrice: false, checkLp: true, label: 'calcprice' })
            setDirty(false)
            setMainWaitFlag(false);
          }
        },
        {
          id: 2, title: '2. ยืนยันรายการให้ลูกค้าสั่งซื้อ', backgroundColor: (dirty || priceInfo.returnCode != '0000') ? 'gray' : 'green',
          actionItemProp: { width: '45%' }, actionItemTextProp: { fontSize: 12 },
          onPress: () => allowCustomerToBuy()
        }
      ],
    },
    {
      id: 4, condition: basketList?.length > 0 && allowToBuyOnly,
      actionList: [
        {
          id: 1, title: '1. คำนวณราคาสินค้าในตะกร้า', backgroundColor: themeColor.color1,
          actionItemProp: { width: '45%' }, actionItemTextProp: { fontSize: 12 },
          onPress: async () => {
            setMainWaitFlag(true);
            await loadBasketList({ refreshBasket: false, calcTotalPrice: false, checkLp: true, label: 'calcprice2' })
            setDirty(false);
            setMainWaitFlag(false);
          }
        },
        {
          id: 2, title: '2. แก้ไขรายการในตะกร้าลูกค้า', backgroundColor: themeColor.color6,
          actionItemProp: { width: '45%' }, actionItemTextProp: { fontSize: 12 },
          onPress: async () => {
            setMainWaitFlag(true);
            try {
              //checklock
              const res = await fetch(`${server_var.server_api}/lockorder`, {
                method: 'POST',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify({ ordermainId: basketOrderData?.id, method: 'get' })
              });
              const resJson = await res.json();
              console.log('resJson', resJson);
              if (resJson?.returnValue) {
                setMainWaitFlag(false);
                alert('ลูกค้าอยู่ในระหว่างการชำระเงิน ไม่สามารถแก้ไขได้')
                return
              }
            } catch { }
            await globalState.client.service('baskets').patch(null, { allowToPay: 0 }, { query: { userId, $limit: 200 } });
            setDirty(true);
            setMainWaitFlag(false);
          }
        }
      ]
    }
  ];

  useEffect(() => {
    if (basketList?.[0]?.ordermainId) {
      loadOrderMain(basketList?.[0]?.ordermainId)
    }
    if (basketList?.[0]?.allowToPay) {
      setAllowToBuyOnly(true)
      if (basketList?.length > 0) {
        if (!waitFlag) {
          if (!delOption.sevenTradeArea && !delOption.lalamoveArea && !delOption.grabArea && !delOption.postArea) {
            checkTradeArea();
          }
        }
      }
    }
  }, [basketList])

  useEffect(() => {
    setModeId(MODE_LIST.find(item => item.condition)?.id)
  }, [basketList, allowToBuyOnly, onlyBasket])

  useEffect(() => {
    const delOptionIndex = Object.entries(delOption).findIndex(item => item[1]);
    if (!(basketOrderData?.deliverType) && delOptionIndex > -1) {
      console.log('find result', Object.entries(delOption)[delOptionIndex]);
      const findResult = Object.entries(delOption)[delOptionIndex][0];
      const findValue = [
        { retValue: 'postArea', value: 'post' }, { retValue: 'grabArea', value: 'grab' },
        { retValue: 'lalamoveArea', value: 'lalamove' }, { retValue: 'sevenTradeArea', value: '7deli' },
      ].find(item => item.retValue == findResult)?.value;
      console.log('findValue', findValue);
      if (['post'].includes(findValue)) {
        setBasketOrderData(prev => ({ ...prev, deliverType: findValue }));
      }
    }
  }, [basketOrderData?.deliverType, delOption])

  const checkTradeArea = async () => {
    setWaitFlag(true);
    console.log(themeColor.FgRed, 'check trade area1', themeColor.FgReset);
    const jwtToken = await getJwtToken();
    let resDelOption, resDelOptionJson, resOldQueLine;
    try {
      resOldQueLine = await globalState.client.service('oldquelines').find({
        query: { oldId: basketList[0].queId, $select: ['deliverJsonAddr'] }
      });
      resDelOption = await fetch(`${server_var.server_api}/checkDeliveryOption`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
        body: JSON.stringify({
          shopId: basketList[0].shopId,
          lat: resOldQueLine?.data?.[0]?.deliverJsonAddr.lat,
          long: resOldQueLine?.data?.[0]?.deliverJsonAddr.long,
          detailAddress: resOldQueLine?.data?.[0]?.deliverJsonAddr?.detailAddress,
          mainAddressFull: resOldQueLine?.data?.[0]?.deliverJsonAddr?.mainAddressFull,
          otherAddress: resOldQueLine?.data?.[0]?.deliverJsonAddr?.otherAddress,
        })
      });
      resDelOptionJson = await resDelOption.json();
      setDelOption(resDelOptionJson)
    } catch (e) {
      console.log('error checkTradeArea', e);
      setDelOption(prev => ({ ...prev, errorDelOption: true }))
    }
    setWaitFlag(false);
    console.log(themeColor.FgCyan, 'check trade area2', themeColor.FgReset);
  }

  const loadOrderMain = async (ordermainId) => {
    if (loadOrderFlag) {
      return
    }
    setLoadOrderFlag(true);
    let resOrder = await globalState.client.service('ordermains').get(ordermainId, {
      query: {
        $select: ['orderStatus', 'id', 'deliverType', 'orderPrice', 'deliveryPrice',
          'servicePrice', 'servicePharmacistPrice', 'discountPrice', 'discountCouponPrice', 'couponUsedList']
      }
    })
    setBasketOrderData(prev => ({ ...prev, ...resOrder, ...prev.deliverType ? { deliverType: prev.deliverType } : null }));
    console.log('resOrder', resOrder);
    setCouponUsedList(resOrder.couponUsedList)
    setLoadOrderFlag(false);
  }

  if (basketList?.length > 0) {
    return (
      <View style={{
        width: "100%", paddingTop: 0, marginBottom: 20, paddingHorizontal: 0, borderTopColor: 'lightgray', borderTopWidth: 1
      }}>
        {MODE_LIST.find(item => item.id == modeId)?.additionalCom}
        <View >
          {[
            { id: 1, type: 'subject', title: 'รายการทั้งหมด', value: 0, textProp: { fontWeight: 'bold', fontSize: 14, marginTop: 5 } },
            {
              id: 2, type: 'price', title: 'ค่าสินค้า', textProp: { fontSize: 13 },
              value: numberWithCommas(parseFloat(priceInfo.totalPrice).toFixed(2))
            },
            {
              id: 3, type: 'price', title: 'ค่าบริการ', textProp: { fontSize: 13 },
              value: parseFloat((basketOrderData?.servicePrice) ?? 0).toFixed(2)
            },
            {
              id: 4, type: 'price', title: 'ค่าบริการทางเภสัชกรรม', textProp: { fontSize: 13 },
              value: parseFloat((basketOrderData?.servicePharmacistPrice) ?? 0).toFixed(2)
            },
            {
              id: 5, type: 'price', title: 'ค่าจัดส่ง', textProp: { fontSize: 13 },
              value: parseFloat((basketOrderData?.deliveryPrice) ?? 0).toFixed(2)
            },
            {
              id: 6, type: 'price', title: 'ส่วนลด', textProp: { color: themeColor.color6, fontSize: 13 },
              value: numberWithCommas(parseFloat((priceInfo.discountPrice ?? 0) - (priceInfo.discountCouponPrice ?? 0)).toFixed(2))
            },
            {
              id: 8, type: 'total', title: 'ยอดรวม',
              value: numberWithCommas((parseFloat(priceInfo.totalPrice ?? 0) - parseFloat(priceInfo.discountPrice ?? 0) + parseFloat(priceInfo.discountCouponPrice ?? 0)).toFixed(2)),
              textProp: { fontWeight: 'bold', fontSize: 14, color: themeColor.color1, paddingHorizontal: 20 },
              viewProp: { borderTopWidth: 1, borderColor: 'lightgray', marginTop: 5, paddingTop: 0, marginHorizontal: 0 }
            },
            { id: 9, type: 'subject', title: 'ยังไม่รวมส่วนลดประเภทคูปอง', value: 0, textProp: { fontSize: 10, color: themeColor.color6 } }
          ].map(item3 => (
            <View key={item3.id.toString()}
              style={[{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20 }, item3.viewProp]}
            >
              <Text style={item3.textProp}>{item3.title}</Text>
              {item3.type != 'subject' && (<Text style={item3.textProp}>฿{item3.value}</Text>)}
            </View>
          ))}
          <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
            {(MODE_LIST.find(item3 => item3.id == modeId))?.actionList?.map(item3 => (
              <BasketSubAction
                key={item3.id} onPress={item3.onPress} backgroundColor={item3.backgroundColor}
                title={item3.title} waitFlag={waitFlag} actionItemProp={item3.actionItemProp}
                actionItemTextProp={item3.actionItemTextProp}
              />
            ))}
          </View>
        </View>
      </View>
    )
  } else {
    return <View />
  }
}

function BasketDeliverChoice({ setBasketOrderData, basketOrderData, delOption }) {
  const DELIVERY_OPTION_LIST = [
    {
      id: 1, title: ['รับสินค้าที่ร้าน'], value: 'self_pickup', enable: true, image: require('../assets/images/shop.png'),
      enable: (!(delOption?.postArea) && !(delOption?.errorDelOption)),
      onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: 'self_pickup' }))
    },
    {
      id: 3, title: ['บริการจัดส่ง'], value: '7deli', image: require('../assets/images/fastdelivery.png'),
      enable: (delOption.sevenTradeArea && !(delOption?.errorDelOption)),
      onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: '7deli' }))
    },
    {
      id: 4, title: ['บริการจัดส่ง'], value: 'lalamove', image: require('../assets/images/fastdelivery.png'),
      enable: (delOption.lalamoveArea && !(delOption?.errorDelOption)),
      onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: 'lalamove' }))
    },
    {
      id: 5, title: ['บริการจัดส่ง G'], value: 'grab', image: require('../assets/images/fastdelivery.png'),
      enable: (delOption.grabArea && !(delOption?.errorDelOption)),
      onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: 'grab' }))
    },
    {
      id: 6, title: ['บริการจัดส่งทางไปรษณีย์'], value: 'post', image: require('../assets/images/delivery-man.png'),
      enable: (delOption?.postArea && !(delOption?.errorDelOption)),
      onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: 'post' }))
    }
  ].filter(item4 => item4.enable);
  return (
    <View style={{ paddingHorizontal: 10, marginBottom: 0, borderRadius: 10, borderBottomWidth: 1, borderColor: 'lightgray', paddingBottom: 10 }}>
      <Text>ตัวเลือกการจัดส่ง</Text>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {DELIVERY_OPTION_LIST.map(item3 => (
          <TouchableOpacity
            key={item3.id.toString()}
            style={{
              marginHorizontal: 5, flexDirection: 'row', padding: 10, backgroundColor: 'white',
              marginTop: 5, borderRadius: 10, height: 35, alignItems: 'center', alignSelf: 'center',
              width: Platform.OS == 'web' ? '45%' : (Dimensions.get('window').width / 2) - 25,
            }}
            onPress={() => {
              if (item3.enable) {
                setBasketOrderData(prev => ({ ...prev, deliverType: item3.value }))
              }
            }}
          >
            <CustomIcon
              name={basketOrderData.deliverType == item3.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={16}
            />
            <Image source={item3.image} style={{ width: 25, height: 25, marginLeft: 5, marginRight: 5 }} />
            <View style={{ marginLeft: 5, flexDirection: 'row' }}>
              {item3.title.map((item4, index4) =>
                <Text key={item4} style={[index4 > 0 ? { fontSize: 12, color: 'gray' } : { fontSize: 12, color: item3.enable ? 'black' : 'gray' }]}>
                  {item4}
                </Text>
              )}
            </View>
          </TouchableOpacity>
        ))}
        {DELIVERY_OPTION_LIST.length == 1 && DELIVERY_OPTION_LIST.includes(item => (item.id == 1)) ?
          <Text style={{ color: 'red', fontSize: 12, marginTop: 5, marginBottom: 10 }}>* ขออภัยในความไม่สะดวก ท่านอยู่นอกพื้นที่การจัดส่ง</Text> : null
        }
      </View>
    </View>
  )
}

function BasketSubAction({ onPress, backgroundColor, title, waitFlag, actionItemProp, actionItemTextProp }) {
  return (
    <TouchableOpacity style={[{ marginTop: 5, alignSelf: 'center' }, actionItemProp]}
      onPress={() => waitFlag ? console.log('wait') : onPress()}
    >
      <View style={[
        { height: 35, borderRadius: 10, justifyContent: 'center', alignItems: 'center' },
        { backgroundColor: waitFlag ? 'lightgray' : backgroundColor },
      ]}>
        <Text style={[{ color: 'white' }, actionItemTextProp]}>{title}</Text>
      </View>
    </TouchableOpacity>
  )
}

function CouponChoice({ navigation, couponUsedList, ordermainId }) {
  return (
    <View style={{ paddingHorizontal: 10, borderColor: 'lightgray', borderBottomWidth: 1, paddingBottom: 10, paddingTop: 5 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, alignItems: 'center' }}>
        <Text style={{}}>คูปองและส่วนลด</Text>
        <TouchableOpacity onPress={() => navigation.navigate('CouponList', { couponUsedList, ordermainId })}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ color: 'gray', fontSize: 12, marginRight: 5 }}>ดูเพิ่มเติม</Text>
            <CustomIcon name="chevron-right-circle" size={18} color={"gray"} />
          </View>
        </TouchableOpacity>
      </View>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {couponUsedList?.map(item => (
          <CouponView key={`${item.id}_$${item.subId}`}
            couponWidth={200} couponHeight={60} couponBorderRadius={5} couponCircleRadius={10} couponLeftWidth={140}
            couponBorderWidth={1} couponBorderColor={themeColor.color1} couponBackgroundColor={"white"}
            couponTransparentColor={themeColor.color8} couponSeparationColor={'lightgray'} style={{ marginRight: 10 }}
          >
            <View style={{
              position: 'absolute', width: 140, paddingHorizontal: 5, paddingTop: 0,
              height: 60, justifyContent: 'flex-start', alignItems: 'center',
              flexDirection: 'row'
            }}>
              <CustomIcon name={couponUsedList.map(item5 => item5.id)?.includes(item.id) ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={16} color={themeColor.color1} />
              <Text style={{ fontSize: 12, color: themeColor.color1, marginLeft: 5 }}>{(item.title)?.substring(0, 50) + ((item.title)?.length > 50 ? '...' : '')}</Text>
            </View>
            <View style={{ position: 'absolute', right: 0, width: 50, height: 60, justifyContent: 'center', alignItems: 'center' }}>
              <Image source={require('../assets/images/logo_and_text.png')} style={{ width: 45, height: 45 }} resizeMode='contain' />
            </View>
          </CouponView>
        ))}
      </ScrollView>
    </View>
  )
}
