import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, ScrollView, SafeAreaView, Dimensions, Linking, Platform } from 'react-native';
import { themeColor, fontStyles, server_var, default_shop_image } from '../config/servervar';
import { store } from './store'
import EventEmitter from './EventEmitter';
import { Dialog } from './pharmanotepart';
import { getJwtToken, useCustomSafeAreaInsets } from '../component/custom';
import { CustomIcon, ImageWithAuth } from '../component/custom';

export function ViewProductPart({ navigation, route }) {
  const { productId, userId, shopId, queId, ordermainId, hideButton, printLabelFlag } = route?.params;
  const goBack = (index) => navigation.goBack(index);
  const { globalState, dispatch } = useContext(store);
  const insets = useCustomSafeAreaInsets()
  const [dialog, setDialog] = useState({ visible: false, catId: null });

  const [productData, setProductData] = useState(null);
  const [adjustItemData, setAdjustItemData] = useState(null);
  const [productDataToSave, setProductDataToSave] = useState(null);
  const [lang, setLang] = useState('th');
  const [selectedShopId, setSelectedShopId] = useState();

  const DATA_TO_SHOW = [
    { id: 2, title: 'Image', value: productData?.productPhoto1, type: 'image' },
    { id: 4, title: 'รหัสสินค้า', value: productData?.altId1, type: 'text' },
    { id: 1, title: 'ชื่อสินค้าจาก MM', value: productData?.thProductName, type: 'text' },
    { id: 17, title: 'ชื่อสามัญทางยา', value: productData?.productCommonName, type: 'text' },
    { id: 3, title: 'ราคา', value: productData?.productPrice, type: 'text' },
    { id: 11, title: 'Unit Size', value: productData?.unitSizeDesc, type: 'text' },
    {
      id: 18, title: 'วิธีใช้',
      value: (adjustItemData?.labelJson?.[lang + 'Dosage']) ?? (productData?.[lang + 'Dosage']),
      type: 'textinput', field: lang + 'Dosage', textInputProp: { height: 100 }
    },
    {
      id: 20, title: 'ข้อบ่งใช้',
      value: (adjustItemData?.labelJson?.[lang + 'TimeToUse']) ?? (productData?.[lang + 'TimeToUse']),
      type: 'textinput', field: lang + 'TimeToUse', textInputProp: { height: 100 }
    },
    {
      id: 22, title: 'คำแนะนำเพิ่มเติม',
      value: (adjustItemData?.labelJson?.[lang + 'Comment']) ?? (productData?.[lang + 'Comment']),
      type: 'textinput', field: lang + 'Comment', textInputProp: { height: 100 }
    },
  ]

  const onBackDialog = (id) => {
    setSelectedShopId(id);
    console.log('hello');
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth };
  const dialogContent = [
    {
      type: 'shoplist',
      title: 'เลือกร้าน',
      com: <ShopList {...dialogProp} />,
      actionList: [
        {
          id: 1, title: 'ยืนยันเลือกร้านที่จะพิมพ์', touchStyle: { flex: 0.8 }, onPress: () => {
            printLabel(selectedShopId)
          }
        },
      ]
    },
  ];

  const printLabel = async (shopId) => {
    setDialog(prev => ({ ...prev, visible: false }));
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/genPdfDrugLabel`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ productId, shopId, lang })
    });
    const resJson = await res.json();
    Linking.openURL(`${server_var.server_api}/label/${resJson.result}`)
  }


  const onDataChange = ({ field, text }) => {
    console.log('hello', field, text);
    setProductDataToSave(prev => ({ ...prev, [field]: text }))
  }

  useEffect(() => {
    loadData();
  }, [productId, userId, shopId, queId, ordermainId, hideButton, printLabelFlag]);

  useEffect(() => {
    console.log('adjustItemData', adjustItemData)
  }, [adjustItemData])

  const loadData = async () => {
    const res = await globalState.client.service('products').get(productId)
    setProductData(res);

    if (userId) {
      const query = { userId, productId };
      console.log('pass1_1');
      const res1 = await globalState.client.service('baskets').find({ query });
      if (res1.total > 0) {
        console.log('pass1');
        setAdjustItemData(res1.data[0])
        if (res1.data[0].langToPrint) {
          setLang(res1.data[0].langToPrint);
        }
        return;
      }
    }
    if (ordermainId) {
      console.log('pass2_1');
      const res2 = await globalState.client.service('orderitems').find({ query: { ordermainId, itemType: 'normal', productId } });
      if (res2.total > 0) {
        console.log('pass2');
        setAdjustItemData(res2.data[0])
        if (res2.data[0].langToPrint) {
          setLang(res2.data[0].langToPrint);
        }
        return;
      }
    }
    setAdjustItemData(null);
  }

  const addToBasket = async (prodId, prodPrice) => {
    console.log('\n\nordermainId', ordermainId);
    ////
    const resBasket = await globalState.client.service('baskets').find({ query: { userId } });
    if (resBasket.total > 99) {
      alert('ไม่สามารถใส่สินค้าลงในตะกร้าของลูกค้าได้ มีสินค้าสูงสุดที่มีได้คือ 100 ชิ้น')
      return;
    }
    ///
    const resOrder = await globalState.client.service('ordermains').find({ query: { queId: queId, $select: ['id'] } });
    try {
      let data = {
        userId: userId,
        shopId: shopId,
        queId: queId,
        pharmaId: globalState?.user?.id,
        productId: prodId,
        amount: 1,
        unitPrice: prodPrice,
        totalPrice: prodPrice,
        labelJson: productDataToSave,
        langToPrint: lang,
        allowToPay: 0,
      }
      if (resOrder.total > 0) {
        data = { ...data, ordermainId: resOrder?.data[0]?.id }
      }
      await globalState.client.service('baskets').create(data);
    } catch (err) {
      dispatch({ type: 'showToast', payload: { text: 'มีอยู่ในตระกร้าแล้ว' }, });
    }
    goBack(3);
  }

  const patchToAdjustData = async () => {
    console.log('adjustItemData', adjustItemData);
    if (ordermainId) {
      await globalState.client.service('orderitems').patch(null, {
        labelJson: productDataToSave, langToPrint: lang,
      }, { query: { ordermainId, productId } });
    } else {
      await globalState.client.service('baskets').patch(adjustItemData.id, {
        labelJson: productDataToSave, langToPrint: lang,
      });
    }
    EventEmitter.notify('OrderMain:refresh', null);
    goBack(3);
  }

  const savePrintLabel = async () => {
    console.log('savePrintLabel')
    const res = await globalState.client.service('shopusers').find({
      query: {
        userId: globalState.user.id,
        '$shop.shopType$': 'general1',
      }
    });
    if (res.total == 0) {
      alert('คุณต้องถูกกำหนดอยู่ในร้านอย่างน้อย 1 ร้าน จึงจะพิมพ์ฉลากยาได้')
    } else if (res.total == 1) {
      printLabel(res.data[0].shopId)
    } else {
      setDialog(prev => ({ ...prev, visible: !prev.visible, type: 'shoplist', shoplist: res.data }));
    }
  }
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={[{ marginTop: 0 }, Platform.OS == 'web' ? { height: '80vh' } : null]}>
        <View style={{
          paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginVertical: 10
        }}>
          {[
            { id: 1, title: 'ไทย', value: 'th' },
            { id: 2, title: 'English', value: 'en' },
          ].map(item => (
            <TouchableOpacity key={item.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => setLang(item.value)}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CustomIcon name={item.value == lang ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
                <Text style={{ marginLeft: 5 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
        <View>
          {DATA_TO_SHOW.map(item => <ProductComponent key={item.id.toString() + (item?.value ?? '')} item={item} onDataChange={onDataChange} />)}
        </View>
        <View style={{ height: 350 }} />
      </ScrollView>
      {hideButton != true && (
        <TouchableOpacity
          style={{ position: 'absolute', bottom: insets?.bottom, alignSelf: 'center' }}
          onPress={() => {
            if (adjustItemData) {
              patchToAdjustData()
            } else {
              addToBasket(productData.id, productData.productPrice);
            }
          }}
        >
          <View style={{
            height: 40, width: 200, backgroundColor: themeColor.color1,
            borderRadius: 10, justifyContent: 'center', alignItems: 'center'
          }}>
            <Text style={{ color: 'white' }}>{adjustItemData ? 'Save' : 'ใส่ตะกร้า'}</Text>
          </View>
        </TouchableOpacity>
      )}
      {printLabelFlag && (
        <TouchableOpacity
          style={{ position: 'absolute', bottom: insets?.bottom, alignSelf: 'center' }}
          onPress={savePrintLabel}
        >
          <View style={{
            height: 40, width: 200, backgroundColor: themeColor.color1,
            borderRadius: 10, justifyContent: 'center', alignItems: 'center'
          }}>
            <Text style={{ color: 'white' }}>พิมพ์ฉลากยา</Text>
          </View>
        </TouchableOpacity>
      )
      }
      <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
    </SafeAreaView>
  )
}

function ProductComponent({ item, onDataChange }) {
  const [textValue, setTextValue] = useState();
  useEffect(() => {
    if (item.type == 'textinput' && item?.value) {
      setTextValue(item.value);
    }
  }, [item.value])

  const onChangeText = text => {
    setTextValue(text);
    onDataChange({ field: item.field, text })
  }

  if (item.type == 'textinput') {
    return (
      <View style={{ marginVertical: 10, paddingHorizontal: 20 }}>
        <Text style={{ color: 'gray', fontSize: 12 }}>{item.title}</Text>
        <TextInput
          value={textValue}
          onChangeText={onChangeText}
          multiline={true}
          style={[{
            backgroundColor: 'white', paddingVertical: 5, paddingHorizontal: 5, borderRadius: 5,
            marginTop: 5, textAlignVertical: 'top'
          }, item.textInputProp]}
        />
      </View>
    )
  } else if (item.type == 'text') {
    return (
      <View style={{ marginVertical: 10, paddingHorizontal: 20 }}>
        <Text style={{ color: 'gray', fontSize: 12 }}>{item.title}</Text>
        <Text style={{ marginTop: 5 }}>{item.value}</Text>
      </View>
    )
  } else if (item.type == 'image') {
    let imageUrl = `${server_var.server_api}/images/no_image_available.jpeg`;
    if (item?.value) {
      if (item.value.includes('http')) {
        imageUrl = item.value
      } else {
        imageUrl = `${server_var.server_api}/${item.value}`;
      }
    }
    return (
      <View style={{ alignItems: 'center' }}>
        <ImageWithAuth url={imageUrl} style={{ width: 150, height: 150 }} />
      </View>
    )
  } else if (item.type == 'break') {
    return (
      <View style={{ height: 2, backgroundColor: 'lightgray', marginHorizontal: 30 }} />
    )
  }
}

export function ShopList({ dialog, setDialog, onBackDialog, dialogWidth, nextAction }) {
  const { globalState, dispatch } = useContext(store);
  const [shopId, setShopId] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(dialog.shoplist)
  }, [])
  return (
    <View style={{ paddingHorizontal: 10 }}>
      {data?.map(item => (
        <TouchableOpacity key={item.id.toString()} style={{ marginVertical: 5 }} onPress={() => {
          setShopId(item.shopId);
          onBackDialog(item.shopId)
        }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <CustomIcon name={shopId == item?.shopId ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={22} color="gray" />
            <Text style={{ marginLeft: 5 }}>{item?.shop?.shopThName}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}

