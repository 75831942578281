import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from '../sharewebapp/store'
import { CustomIcon } from '../component/custom';
import moment from 'moment';
import { useCustomFocusEffect } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';

export function ViewFeedNotiPart({ navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [refreshNo,setRefreshNo] = useState(0);

  useCustomFocusEffect(
    useCallback(() => {
      setRefreshNo(Math.floor(Date.now() / 1000));
    }, [])
  );

  useEffect(()=>{
    loadData();
  },[refreshNo])

  useEffect(() => {
    EventEmitter.addListener('ViewFeedNoti:refresh', loadData);
    return () => {
      EventEmitter.removeListener('ViewFeedNoti:refresh', loadData);
    }
  }, [])

  const loadData = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res;
      try {
        res = await globalState.client.service('feeds').find({
          query: {
            feedType: { $in: ['Message', 'PromoMessage', 'AlertImageFull'] },
            feedPublic: 0,
            $sort: { publicDateTime: 1 },
            $skip: pSkip ?? skip,
          }          
        });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
      //console.log('\nfeeds : ', res.data);
    }
  }

  const onView = (id) => {
    navigation.navigate('ViewFeedNotiDetail', { id });
  }  

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        style={{ marginVertical: 10 }}
        onEndReached={(xxx) => loadData()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadData(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          <NotiItem item={item} onView={onView} />
        )}
      />
    </View>
  )
}

function NotiItem({ item, onView }) {
  return (
    <TouchableOpacity style={{ marginHorizontal: 20, }} onPress={() => onView(item.id)} >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottomColor: 'lightgray', borderBottomWidth: 1 }}>
        <View style={{ paddingVertical: 15, flex: 1 }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>วันและเวลาที่ส่ง : </Text> 
            <Text style={{ fontSize: 12 }}>{moment(item.publicDateTime).format('DD-MM-YYYY HH:mm')}</Text> 
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>ประเภทการส่ง : </Text> 
            <Text style={{ fontSize: 12, flex: 1, flexWrap: 'wrap' }}>{
            [
              { id: 1, title: 'ส่งการแจ้งเตือนเข้ากระดิ่ง (สำหรับผู้ใช้งานที่ยอมรับข่าวสารและโปรโมชัน)', value: 'PromoMessage' },
              { id: 2, title: 'ส่งการแจ้งเตือนเข้ากระดิ่ง (สำหรับผู้ใช้งานทุกคน)', value: 'Message' },
              { id: 3, title: 'ส่งการแจ้งเตือนแบบ Pop Up Banner (สำหรับผู้ใช้งานที่ยอมรับข่าวสารและโปรโมชัน)', value: 'AlertImageFull' }
            ].find(item2 => item2.value == item.feedType)?.title}
            </Text>
          </View>          
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
            <Text style={{ fontSize: 14, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>{item?.title}</Text>
          </View>
          <Text style={{ flex: 1, flexWrap: 'wrap', fontSize: 14 }}>{item?.content ? textSubString(item?.feedType, item?.content) : ''}</Text>
        </View>
        <CustomIcon name="chevron-right-circle" size={20} color="gray" style={{ paddingHorizontal: 10 }} />
      </View>
    </TouchableOpacity >
  )
}

function textSubString(type, text) {
  let txtLng = 100;
  if (type == 'PromoMessage') {
    txtLng = 100;
  }
  return text?.split('\n')?.[0]?.substring(0, txtLng) + ((text?.split('\n')?.[0]?.length > txtLng) ? '...' : '')
}
