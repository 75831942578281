
import React, { useState, useCallback, useEffect, useContext, useRef, useLayoutEffect } from 'react'
import { TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar, AppState } from 'react-native'
import { themeColor, fontStyles } from '../config/servervar';
import { store } from "../sharewebapp/store";
import moment from 'moment';
import { Col, Row } from '@pankod/refine-antd';
import { HEADER_BAR_LIST_FOR_SHOP } from '../sharewebapp/common1';
import { CustomIcon } from '../component/custom';
import { server_var } from './../config/servervar';
import axios from "axios";
import { ComCenterPart } from '../sharewebapp/comcenterpart';
import { ViewUserPart } from '../sharewebapp/viewuserpart';
import { ChatPart } from '../sharewebapp/chatpart';
import { PharmaNotePart } from '../sharewebapp/pharmanotepart';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ForwardPart } from '../sharewebapp/forwardpart';
import { PanelHeader, getPanelIndex, getFlexIndex } from './webcommon';
import { BasketPart } from '../sharewebapp/basketpart';
import { BasketPart7 } from '../sharewebapp/basketpart7';
import { ViewProductPart } from '../sharewebapp/viewproductpart';
import { ViewPharmaNoteOrderAppointmentPart } from '../sharewebapp/viewpharmanoteorderappointment';
import { OtherNotePart } from '../sharewebapp/othernotepart';

export function TestScreen1() {
  const [listPanel, setListPanel] = useState([
    { name: 'ComCenter', params: null }
  ]);

  const listPanelRef = useRef(listPanel);

  useEffect(() => {
    listPanelRef.current = listPanel;
  }, [listPanel])

  const navigation = {
    navigate: (navName, navParams) => {
      const panelIndex = listPanelRef.current.findIndex(item => item.name == navName);
      // alert('Hello1:' + navName)
      if (panelIndex >= 0) {
        setListPanel(prev => {
          prev.length = panelIndex;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'Chat') {
        setListPanel(prev => {
          prev.length = 1;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ViewUser') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'PharmaNote') {
        setListPanel(prev => {
          // prev.length = 2;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'Forward') {
        setListPanel(prev => {
          prev.length = 2;
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'Basket') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'ViewProduct') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'VideoCall1') {
        alert('การทำงานนี้ใช้ได้เฉพาะจาก Mobile App');
        return;
      }
      if (navName == 'ViewImage1') {
        window.open(`${navParams.uri}`, '_blank')
        return;
      }
      if (navName == 'ViewPharmaNoteOrderAppointment') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      if (navName == 'OtherNote') {
        setListPanel(prev => {
          return [...prev, { name: navName, params: navParams, index: prev.length }]
        })
        return;
      }
      alert('Hello' + navName)
    },
    setOptions: (optionParams, index) => {
      setListPanel(prev => {
        const tmpListPanel = [...prev];
        if (index >= 0) {
          tmpListPanel[index] = { ...tmpListPanel[index], options: optionParams }
        } else {
          tmpListPanel[tmpListPanel.length - 1] = { ...tmpListPanel[tmpListPanel.length - 1], options: optionParams }
        }
        return tmpListPanel
      })
    },
    goBack: (index) => {
      setListPanel(prev => {
        if (index >= 0) {
          prev.length = index;
        }
        return [...prev];
      })
    },
    dispatch: () => {
      console.log('dispatch')
    },
    setListPanel: (setListPanelValue) => {
      setListPanel(setListPanelValue)
    }
  }

  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={8} style={{ display: getFlexIndex(0, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
        <Panel panel={listPanel?.[0]} navigation={navigation} />
      </Col>
      <Col span={8} style={{ display: getFlexIndex(1, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
        <Panel panel={listPanel?.[1]} navigation={navigation} />
      </Col>
      <Col span={8} style={{ display: getFlexIndex(2, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
        <Panel panel={listPanel?.[2]} navigation={navigation} />
      </Col>
      <Col span={8} style={{ display: getFlexIndex(3, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
        <Panel panel={listPanel?.[3]} navigation={navigation} />
      </Col>
      <Col span={8} style={{ display: getFlexIndex(4, listPanel.length), borderRightWidth: 1, borderRightStyle: 'solid', borderColor: 'lightgray' }}>
        <Panel panel={listPanel?.[4]} navigation={navigation} />
      </Col>
    </Row>
  )
}

function Panel({ panel, navigation }) {
  const panelProp = { navigation, route: { params: panel?.params, custom: { index: panel?.index } } };
  if (panel?.name == 'ComCenter') {
    return (
      <SafeAreaProvider>
        <ComCenterPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'Chat') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ChatPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewUser') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewUserPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'PharmaNote') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <PharmaNotePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'Forward') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ForwardPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'Basket') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        {server_var.apiBackEnd == 'self' ?
          <BasketPart {...panelProp} /> : <BasketPart7 {...panelProp} />
        }
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewProduct') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewProductPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'ViewPharmaNoteOrderAppointment') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <ViewPharmaNoteOrderAppointmentPart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  if (panel?.name == 'OtherNote') {
    return (
      <SafeAreaProvider>
        <PanelHeader panel={panel} {...panelProp} />
        <OtherNotePart {...panelProp} />
      </SafeAreaProvider>
    )
  }
  return <View />
}

