import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView } from 'react-native';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from "./store";
import EventEmitter from './EventEmitter';
import moment from 'moment';

export function OtherNotePart({ route, navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const { othernoteId, userId } = route?.params;

  const FIELD_LIST = [
    { id: 1, title: 'วันที่บันทึก', type: 'text', value: moment(data?.createdAt).format('MM/DD/YYYY HH:mm'), style: {} },
    { id: 2, title: 'วันที่โดย', type: 'text', value: data?.pharma?.displayName, style: { marginTop: 5 } },
    { id: 3, title: 'บันทึกภายใน', type: 'textinput', field: 'notes', prop: { multiline: true, editable: (data?.pharmaId == globalState?.user?.id) }, style: { height: 150, marginTop: 20 } },
    { id: 4, title: 'บันทึก', type: 'button', visible: (data?.pharmaId == globalState?.user?.id), onPress: () => saveData(), style: { marginTop: 30 } }
  ]

  // console.log('userId' + route?.params?.userId);

  useEffect(() => {
    if (othernoteId) {
      loadData();
    } else {
      setData({
        userId,
        pharmaId: globalState?.user?.id,
        createdAt: moment().utc().format('YYYY-MM-DDTHH:mm[Z]'),
        pharma: globalState?.user
      })
    }
  }, [othernoteId, userId])

  const loadData = async () => {
    const res = await globalState.client.service('othernotes').get(othernoteId);
    let resUser;
    try {
      resUser = await globalState.client.service('users').get(res.pharmaId, { query: { $select: ['displayName'] } })
    } catch (e) { }
    setData({ ...res, pharma: resUser });
  }

  const saveData = async () => {
    if (othernoteId) {
      await globalState.client.service('othernotes').patch(othernoteId, data);
    } else {
      const res = await globalState.client.service('othernotes').create(data);
      console.log('res', res);
    }
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลแล้ว' }, });
    EventEmitter.notify('refreshOtherNoteList', data);
    if (Platform.OS == 'web') {
      navigation.goBack(2);
    } else {
      navigation.goBack();
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View style={{ paddingHorizontal: 20 }}>
          <Text style={{
            marginLeft: 40, marginTop: 20, textAlign: 'center', fontSize: 20,
            color: themeColor.color1, fontWeight: 'bold', marginTop: 20, marginBottom: 10,
          }}>
            บันทึกภายใน
          </Text>
          {
            FIELD_LIST.map(item => (
              <ViewItem key={item.id.toString()} item={item} setData={setData} data={data} />
            ))
          }
        </View>
      </ScrollView>
    </SafeAreaView >
  )
}

function ViewItem({ item, setData, data }) {
  const { globalState, dispatch } = useContext(store);

  if (item.type == 'text') {
    return (
      <View style={[{ flexDirection: 'row', alignItems: 'center' }, item.style]}>
        <Text style={{ flex: 0.3 }}>{item.title}:</Text>
        <Text style={{ flex: 0.7 }}>{item.value}</Text>
      </View>
    )
  } else if (item.type == 'textinput') {
    return (
      <TextInput
        style={[
          { height: 40, borderRadius: 5, borderWidth: 1, paddingLeft: 10, backgroundColor: 'white', borderColor: 'lightgray' },
          item.style
        ]}
        placeholder={item.title}
        placeholderTextColor={'gray'}
        value={data?.[item.field]}
        {...item.prop}
        onChangeText={text => setData(prev => ({ ...prev, [item.field]: text }))}
      />
    )
  } else if (item.type == 'button') {
    if (item.visible) {
      return (
        <TouchableOpacity style={{ marginTop: 5 }}
          onPress={() => item.onPress()}>
          <View style={[
            {
              height: 40, backgroundColor: themeColor.color1, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center'
            },
            item.style
          ]}>
            <Text style={[{ color: 'white' }]}>{item.title}</Text>
          </View>
        </TouchableOpacity>
      )
    } else {
      return <View />
    }
  }
}
