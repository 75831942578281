import React,{ useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import EventEmitter from "../sharewebapp/EventEmitter"
import { useDebounce } from '../sharewebapp/common1';
import { getJwtToken } from '../component/custom';

const PERSONAL_LIST = [
  { id: 1, title: 'เลขประจำตัวผู้เสียภาษี *', type: 'textinput', field: 'personalTaxId', part: 'top' },
  { id: 2, title: 'ชื่อ-นามสกุล *', type: 'textinput', field: 'taxPersonalName', part: 'top' },
  { id: 3, title: 'ที่อยู่ในการออกใบกำกับภาษี (บรรทัดที่ 1) *', type: 'textinput', field: 'taxPersonalAddressLine1', part: 'top' },
  { id: 4, title: 'ที่อยู่ในการออกใบกำกับภาษี (บรรทัดที่ 2)', type: 'textinput', field: 'taxPersonalAddressLine2', part: 'top' },
  { id: 5, title: 'จังหวัด *', type: 'textinput', field: 'taxPersonalProvince', part: 'top' },
  { id: 6, title: 'รหัสไปรษณีย์', type: 'textinput', field: 'taxPersonalPostCode', part: 'top' },
  { id: 7, title: 'เบอร์โทรศัพท์', type: 'textinput', field: 'taxPersonalPhoneNumber', part: 'top' },
  //{ id: 8, title: 'อีเมล์ (สำหรับการจัดส่งเอกสาร)', type: 'textinput', field: 'taxPersonalEmail', part: 'top' },
]

const COMPANY_LIST = [
  { id: 1, title: 'เลขประจำตัวผู้เสียภาษี *', type: 'textinput', field: 'companyTaxId', part: 'top' },
  {
    id: 2, title: 'สถานประกอบการ *', type: 'singlechoice', field: 'companyType', part: 'top',
    choice: [
      { id: 1, value: 'H', title: 'สำนักงานใหญ่', textInputField: null },
      { id: 2, value: 'B', title: 'สาขา', textInputField: 'branchNo' },
    ]
  },
  { id: 3, title: 'บริษัท *', type: 'textinput', field: 'taxCompanyName', part: 'top' },
  { id: 4, title: 'ที่อยู่ในการออกใบกำกับภาษี (บรรทัดที่ 1) *', type: 'textinput', field: 'taxCompanyAddressLine1', part: 'top' },
  { id: 5, title: 'ที่อยู่ในการออกใบกำกับภาษี (บรรทัดที่ 2)', type: 'textinput', field: 'taxCompanyAddressLine2', part: 'top' },
  { id: 6, title: 'จังหวัด *', type: 'textinput', field: 'taxCompanyProvince', part: 'top' },
  { id: 7, title: 'รหัสไปรษณีย์', type: 'textinput', field: 'taxCompanyPostCode', part: 'top' },
  { id: 8, title: 'เบอร์โทรศัพท์', type: 'textinput', field: 'taxCompanyPhoneNumber', part: 'top' },
  //{ id: 9, title: 'อีเมล์ (สำหรับการจัดส่งเอกสาร)', type: 'textinput', field: 'taxCompanyEmail', part: 'top' },
]

const FOREIGNER_LIST = [  
  {
    id: 1, title: 'Expats & Tourists ID *', type: 'singlechoice', field: 'taxIdType', part: 'top',
    choice: [
      { id: 1, value: 'P', title: 'Passport No.', textInputField: null },
      { id: 2, value: 'J', title: 'Juristic ID', textInputField: null },
    ]
  },  
  { id: 2, title: 'Country Code *', type: 'country', field: 'countryName', part: 'top' },
  { id: 3, title: 'Passport No. / Juristic ID *', type: 'textinput', field: 'foreignerTaxId', part: 'top' },
  { id: 4, title: 'Name / Company Name *', type: 'textinput', field: 'taxForeignerName', part: 'top' },
  { id: 5, title: 'Address 1 *', type: 'textinput', field: 'taxForeignerAddressLine1', part: 'top' },
  { id: 6, title: 'Address 2', type: 'textinput', field: 'taxForeignerAddressLine2', part: 'top' },
  { id: 7, title: 'Telephone No.', type: 'textinput', field: 'taxForeignerPhoneNumber', part: 'top' },
  //{ id: 8, title: 'Email', type: 'textinput', field: 'taxForeignerEmail', part: 'top' },
]

const FIELD_LIST = [
  //{ id: 0, title: 'ไม่ต้องการ', list: [] },
  { id: 1, title: 'บุคคลธรรมดา', list: PERSONAL_LIST },
  { id: 2, title: 'นิติบุคคล', list: COMPANY_LIST },
  { id: 3, title: 'Foreigner', list: FOREIGNER_LIST },
];

export function VatRequestPart({route,navigation}) {
  const { globalState, dispatch } = useContext(store);
  const ordermainId = route?.params?.ordermainId;
  const readOnly = route?.params?.readOnly;
  const [data, setData] = useState();
  const [error, setError] = useState({ at: '', message: '' });
  const [tabIndex, setTabIndex] = useState(0);
  const [vatProfile, setVatProfile] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });

  useEffect(() => {
    loadData();
  }, [ordermainId])

  useEffect(() => {
    if (!readOnly) {
      const tmpVatInfo = vatProfile?.find(item => item.tabIndex == tabIndex)?.vatInfo ?? null;
      console.log('vatInfo : ', tmpVatInfo);
      if (tmpVatInfo) {
        if (tmpVatInfo?.rememberFlag) {
          setData(vatProfile?.find(item => item.tabIndex == tabIndex)?.vatInfo);
        }
      } else {
        setData(prev => ({ ...prev, rememberFlag: true }));
      }
    }
  }, [tabIndex])

  const loadData = async () => {
    if (!readOnly) {
      await loadVatProfile();
    }
    const res = await globalState.client.service('ordermains').get(ordermainId);
    //console.log('res', res);
    if (res?.vatRequestJson?.vat == 'personal') {
      setData(res?.vatRequestJson?.data);
      setTabIndex(1);
    } else if (res?.vatRequestJson?.vat == 'company') {
      setData(res?.vatRequestJson?.data)
      setTabIndex(2);
    } else if (res?.vatRequestJson?.vat == 'foreigner') {
      setData(res?.vatRequestJson?.data)
      setTabIndex(3);
    }
  }

  const loadVatProfile = async () => {
    setData(null);
    const resUser = await globalState.client.service('vatusers').find({ query: { userId: globalState?.user?.id } });
    const resCom = await globalState.client.service('vatcoms').find({ query: { userId: globalState?.user?.id } });
    const resForeign = await globalState.client.service('vatforeigners').find({ query: { userId: globalState?.user?.id } });
    
    setVatProfile([
      { tabIndex: 0, vatInfo: null },
      { tabIndex: 1, vatInfo: resUser?.data[0] ?? null },
      { tabIndex: 2, vatInfo: resCom?.data[0] ?? null },
      { tabIndex: 3, vatInfo: resForeign?.data[0] ?? null }
    ])
  }

  const validateVatCompany = () => {
    const blankField = ['companyTaxId', 'companyType', 'taxCompanyName', 'taxCompanyAddressLine1', 'taxCompanyPostCode'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }
  const validateVatPersonal = () => {
    const blankField = ['personalTaxId', 'taxPersonalName', 'taxPersonalAddressLine1', 'taxPersonalPostCode'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }
  const validateVatForeigner = () => {
    const blankField = ['taxIdType', 'countryName', 'foreignerTaxId', 'taxForeignerName', 'taxForeignerAddressLine1'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'required field' })
      return false;
    }
    return true;
  }  

  const saveData = async () => {
    setError({ at: '', message: '' });
    if (tabIndex == 0) {
      globalState.client.service('ordermains').patch(ordermainId, { vatRequestJson: null });
    } else if (tabIndex == 1) {
      if (!validateVatPersonal()) {
        return;
      }
      globalState.client.service('ordermains').patch(ordermainId, { vatRequestJson: { vat: 'personal', data } });
      const tmpVatInfo = vatProfile.find(item => item.tabIndex == 1)?.vatInfo;
      if (tmpVatInfo) {
        globalState.client.service('vatusers').patch(tmpVatInfo.id, data);
      } else {
        globalState.client.service('vatusers').create({ userId: globalState.user.id, ...data });
      }
      console.log('save ordermains tab 1');
    } else if (tabIndex == 2) {
      if (!validateVatCompany()) {
        return;
      }
      globalState.client.service('ordermains').patch(ordermainId, { vatRequestJson: { vat: 'company', data } });
      const tmpVatInfo = vatProfile.find(item => item.tabIndex == 2)?.vatInfo;
      if (tmpVatInfo) {
        globalState.client.service('vatcoms').patch(tmpVatInfo.id, data);
      } else {
        globalState.client.service('vatcoms').create({ userId: globalState.user.id, ...data });
      }
      console.log('save ordermains tab 2');
    } else if (tabIndex == 3) {
      if (!validateVatForeigner()) {
        return;
      }
      globalState.client.service('ordermains').patch(ordermainId, { vatRequestJson: { vat: 'foreigner', data } });
      const tmpVatInfo = vatProfile.find(item => item.tabIndex == 3)?.vatInfo;
      if (tmpVatInfo) {
        globalState.client.service('vatforeigners').patch(tmpVatInfo.id, data);
      } else {
        globalState.client.service('vatforeigners').create({ userId: globalState.user.id, ...data });
      }
      console.log('save ordermains tab 3');
    }
    dispatch({ type: 'showToast', payload: { text: 'บันทึกเรียบร้อยแล้ว' }, });
    navigation.goBack();
  }

  const flagVatPrintComplete = async () => {
    const res = await globalState.client.service('ordermains').get(ordermainId, { query: { $select: ['stepJson'] } });
    await globalState.client.service('ordermains').patch(ordermainId, { stepJson: { ...res.stepJson, step3: true } });
    EventEmitter.notify("orderAction:refresh");
    if (Platform.OS != 'web') {
      navigation.goBack();
    }
  }

  const onBack = async (item) => {
    setData(prev => ({ ...prev, countryName: item?.country_code_2 }));
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20, marginTop: 20 }}>
        <Text style={{ color: themeColor.color1, fontWeight: 'bold' }}>
          ขอใบกำกับภาษี
        </Text>
      </View>
      {!readOnly && (
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 20, justifyContent: 'space-between', marginTop: 5 }}>
          {FIELD_LIST.map((item, index) => (
            <TouchableOpacity key={item.id.toString()} onPress={() => setTabIndex(item.id)}>
              <View style={{ flexDirection: 'row' }}>
                <CustomIcon
                  name={tabIndex == item.id ? 'checkbox-marked-circle-outline' : 'checkbox-blank-circle-outline'}
                  size={20} color={'gray'}
                />
                <Text style={{ marginLeft: 5 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      )}
      {readOnly && (
        <Text style={{ paddingHorizontal: 20 }}>{FIELD_LIST.find((item) => (tabIndex == item.id))?.title}</Text>
      )}

      <ScrollView style={{ marginTop: 20 }}>
        <View>
          {FIELD_LIST.find(item => item.id == tabIndex)?.list
            .map(item => (
              <ViewItem key={item.id.toString()} item={item} setData={setData} data={data} error={error} setDialog={setDialog} />
            ))}
        </View>
        {!readOnly && (
          <View>
            {(tabIndex == 1) && (
            <View style={{ flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 20, marginTop: 20 }}>
              <View style={{ flex: 0.1 }}>
                <TouchableOpacity onPress={() => setData(prev => ({ ...prev, rememberFlag: !data?.rememberFlag })) }>
                  <CustomIcon name={data?.rememberFlag ? "checkbox-marked-outline" : "checkbox-blank-outline"} size={20} />
                </TouchableOpacity>
              </View>
              <View style={{ flex: 0.9 }}>
                <Text>ต้องการบันทึกข้อมูลเพื่อใช้สำหรับการออกใบกำกับภาษีในครั้งถัดไป</Text>
              </View>
            </View>  
            )}          
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 50 }}>
              {[
                {
                  id: 1, title: 'ยกเลิก', buttonStyle: { backgroundColor: 'white' }, textStyle: { color: themeColor.color1 },
                  onPress: () => setTabIndex(0)
                },
                {
                  id: 2, title: 'ยืนยัน', buttonStyle: { backgroundColor: themeColor.color1 }, textStyle: { color: 'white' },
                  onPress: () => saveData()
                }
              ].map(item => (
                <TouchableOpacity key={item.id.toString()} onPress={item.onPress} style={{ flex: 0.4, marginHorizontal: 20 }}>
                  <View style={[{
                    backgroundColor: themeColor.color1, justifyContent: 'center', alignItems: 'center', height: 35, borderRadius: 10,
                    borderColor: themeColor.color1, borderWidth: 1,
                  }, item.buttonStyle]}>
                    <Text style={item.textStyle}>{item.title}</Text>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
            <View style={{ alignItems: 'center', marginTop: 30 }}>
              <Text style={{ color: 'gray', fontSize: 12 }}>* สามารถขอใบกำกับภาษีได้ภายในวันที่สั่งซื้อเท่านั้น</Text>
            </View>
          </View>
        )}
        {readOnly && (
          <TouchableOpacity
            onPress={() => flagVatPrintComplete()}
            style={{ marginVertical: 10, width: "70%", alignSelf: 'center' }}
          >
            <View style={{
              height: 40, borderRadius: 10, justifyContent: 'center',
              alignItems: 'center', backgroundColor: themeColor.color1
            }}>
              <Text style={{ color: 'white' }} >เสร็จแล้ว</Text>
            </View>
          </TouchableOpacity>
        )}
        <CountryDialog dialog={dialog} setDialog={setDialog} onBack={onBack} />
        <View style={{ height: 300, width: 100 }} />
      </ScrollView>
    </SafeAreaView >
  )
}

function ViewItem({ item, setData, data, error, setDialog }) {
  const { globalState, dispatch } = useContext(store);

  if (item.type == 'textinput') {
    return (
      <View>
        <View style={{ marginTop: 5, flexDirection: 'row' }}>
          <TextInput
            style={[
              { height: 40, flex: 1, marginHorizontal: 20, borderRadius: 5, borderWidth: 1, paddingLeft: 10, textAlign: "left", backgroundColor: 'white' },
              error['at'] == item.field ? { borderColor: themeColor.color6 } : { borderColor: 'lightgray' }
            ]}
            placeholder={item.title}
            placeholderTextColor={'gray'}
            value={data?.[item.field]}
            {...item.prop}
            onChangeText={text => setData(prev => ({ ...prev, [item.field]: text }))}
          />
        </View>
        <Text style={{ color: themeColor.color6, marginHorizontal: 20 }}>{error['at'] == item.field ? error['message'] : ''}</Text>
      </View>
    )
  } else if (item.type == 'singlechoice') {
    return (
      <View>
        <View style={{ marginTop: 5, flexDirection: 'column', paddingHorizontal: 20 }}>
          <Text style={{ color: 'gray' }}>{item.title}</Text>
          <View style={{ flexDirection: 'column', marginLeft: 20, justifyContent: 'space-between', flex: 1, marginTop: 5 }}>
            {item.choice.map(item2 => (
              <TouchableOpacity
                key={item2.id.toString()}
                onPress={() => setData(prev => ({ ...prev, [item.field]: item2.value }))}
                style={{ marginVertical: 5 }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <CustomIcon
                    name={data?.[item.field] == item2.value ? 'checkbox-marked-circle-outline' : 'checkbox-blank-circle-outline'}
                    size={20} color={'gray'}
                  />
                  <Text style={{ color: 'gray', marginLeft: 5 }}>{item2.title}</Text>
                  {
                    item2?.textInputField != null && (
                      <TextInput
                        style={{
                          flex: 0.5, backgroundColor: 'white', borderRadius: 5, borderColor: 'lightgray',
                          borderWidth: 1, marginLeft: 10, height: 30, paddingHorizontal: 5
                        }}
                        value={data?.[item2.textInputField]}
                        editable={data?.companyType == 'B'}
                        onChangeText={text => setData(prev => ({ ...prev, [item2.textInputField]: text }))}
                      />
                    )}
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
        <Text style={{ color: themeColor.color6, marginHorizontal: 20 }}>{error['at'] == item.field ? 'กรุณาเลือกข้อนี้' : ''}</Text>
      </View>
    )
  } else if (item.type == 'country') {
    return (
      <View>
        <View style={{ marginTop: 5, flexDirection: 'row', paddingHorizontal: 20 }}>
          <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: true }))}>
            <View style={{
              flexDirection: 'row', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 40, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white',
              borderColor: (error['at'] == item.field) ? themeColor.color6 : 'lightgray', width: Dimensions.get('window').width / 2
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{data?.[item.field] ?? item.title}</Text>
              <CustomIcon name="chevron-down" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
        <Text style={{ color: themeColor.color6, marginHorizontal: 20 }}>{error['at'] == item.field ? error['message'] : ''}</Text>
      </View>
    )
  }
}

function CountryDialog({ dialog, setDialog, onBack }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    loadCountryList();
  }, [dialog.visible])

  useEffect(() => {
    loadDataList(searchText);
  }, [debouncedSearchTerm])
  
  const loadDataList = async (pSearch) => {
    if (!loading) {
      setLoading(true);
      await setDataList(countryList?.filter(item => (item?.country_name?.includes(pSearch))));
      setLoading(false);
    }
  }

  const loadCountryList = async () => {
    if (!loading) {
      setLoading(true);
      let res;
      let jwtToken;
      try {
        jwtToken = await getJwtToken();
      } catch (e) { }
      try {
        res = await fetch(`${server_var.server_api}/inquiryCountry`, {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
          body: JSON.stringify({ userId: globalState?.user?.id })
        });
      } catch (e) {
        console.log('inquiryCountry post error : ', e);
      }
      if (!res) return;
      const resJson = await res.json();
      console.log(themeColor.FgYellow, 'inquiryCountry resJson : ' + JSON.stringify(resJson), themeColor.FgReset);
  
      if (resJson?.result) {
        setCountryList(resJson?.resultData);
        setDataList(resJson?.resultData);
      }
      setLoading(false);
    }
  }

  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30,
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <View style={{ width: dialogWidth, height: (dialogWidth * 1.5) - 30 }}>
            <TextInput
              value={searchText}
              onChangeText={text => setSearchText(text)}
              placeholder={'search by country name'}
              style={{
                alignSelf: 'center', borderColor: 'lightgray', borderWidth: 1, marginTop: 10, marginBottom: 5, height: 40,
                paddingHorizontal: 10, borderRadius: 5, backgroundColor: 'white', marginHorizontal: 20, width: (dialogWidth - 30)
              }}
            />
            <FlatList
              keyExtractor={(item) => item?.country_code_2}
              data={dataList}
              //onEndReached={(xxx) => loadDataList()}
              //onEndReachedThreshold={0.5}
              //onRefresh={() => loadDataList(0)}
              refreshing={loading}
              style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: 100 } : null]}
              renderItem={({ item }) => (<CountryItem item={item} setDialog={setDialog} onBack={onBack} />)}
            />
          </View>

          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => {
              setSearchText('');
              setDialog(prev => ({ ...prev, visible: false }));
            }}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

function CountryItem({ item, setDialog, onBack }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, paddingHorizontal: 20, }}
      onPress={() => {
        onBack(item);
        setDialog(prev => ({ ...prev, visible: false }));
      }}>
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, flex: 1 }}>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.country_code_2}</Text>
        </View>
        <View style={{ flex: 0.7, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item?.country_name}</Text>
        </View>
      </View>
    </TouchableOpacity >
  )
}