import React, { useState, useCallback, useEffect, useContext } from 'react'
import { store } from "../sharewebapp/store";
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Typography } from 'antd';
import { message, Popconfirm } from 'antd';
import { Button, Modal } from 'antd';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import { Layout } from 'antd';
import { useLocation } from "react-router-dom";
import { deviceDetect } from "react-device-detect";
import { isMobile } from 'react-device-detect';
import { server_var, themeColor } from '../config/servervar';
import { Image, TouchableOpacity, Text, View, Linking } from 'react-native-web';

const { Header, Footer, Sider, Content } = Layout;

export const AppRemovePage = () => {
  useEffect(() => { }, [])

  if (isMobile) {
    return (
      <View style={{ alignItems: 'center',backgroundColor: 'white' }}>
        <img src="howtodelete1.jpg" style={{ width: '100%' }} />
      </View>
    );
  } else {
    return (
      <View style={{ alignItems: 'center', backgroundColor: 'white' }}>
        <img src="howtodelete1.jpg"  />
      </View>
    )
  }


};

