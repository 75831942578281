import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';

export function UserManualPart() {
  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      <View style={{ marginTop: 20, paddingHorizontal: 20 }}>
        <Text style={{ color: themeColor.color1, fontWeight: 'bold' }}>คู่มือการใช้งาน</Text>
        <View style={{ backgroundColor: 'white', marginTop: 10, borderRadius: 5 }}>
          {[
            {
              id: 1, icon: 'book-open-variant', title: 'คู่มือการใช้งานแอปพลิเคชัน', option: { borderTopWidth: 0 }, onPress: async () => Linking.openURL(`${server_var.server_api}/content/app_manual.pdf`)
            },
            {
              id: 2, icon: 'book-open-variant', title: 'คู่มือการปฏิบัติงาน', option: { borderTopWidth: 1, paddingBottom: 10 }, onPress: async () => Linking.openURL(`${server_var.server_api}/content/operating_manual.pdf`)
            },
          ].map(item => (
            <TouchableOpacity
              key={item.id.toString()}
              onPress={() => item.onPress()}
              style={[{ marginVertical: 5, marginHorizontal: 10, justifyContent: 'center', borderColor: themeColor.color8, paddingTop: 5 }, item.option]}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {(item?.imagePath) ?
                  <Image source={item.imagePath} style={{ width: 25, height: 25 }} /> :
                  <CustomIcon name={item.icon} size={25} color={themeColor.color1} />
                }

                <Text style={{ fontSize: 14, color: '#303030', paddingHorizontal: 10 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </SafeAreaView>
  )
}
