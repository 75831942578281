import React, { useState } from "react";
import { useTitle, CanAccess, useRouterContext } from "@pankod/refine-core";
import { AntdLayout, Menu, useMenu, Grid, Badge } from "@pankod/refine-antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { useLogin, useLogout } from "@pankod/refine-core";
import { Image, View, Text } from "react-native";
import { server_var } from "../config/servervar";


export const CustomSider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const Title = useTitle();
  const { Link } = useRouterContext();
  const { SubMenu } = Menu;
  const { mutate: logout } = useLogout();

  const { menuItems, selectedKey } = useMenu();
  console.log('\n\nhello', menuItems);
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;

  const renderTreeView = (tree, selectedKey) => {
    return tree.map((item) => {
      const { icon, label, route, name, children, parentName } = item;

      if (children.length > 0) {
        return (
          <SubMenu
            key={name}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      console.log('name', name);
      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
        >
          <Menu.Item
            key={selectedKey}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
            onClick={() => {
              if (name == 'logout') {
                logout();
              }
            }}
          >
            {!['logout'].includes(name) && (
              <Link href={route} to={route}>
                {label == 'Dashboard' ? 'หน้าแรก' : label}
              </Link>)
            }
            {name === 'logout' && (label)}
            {/* {name === 'Dashboard' && ('หน้าแรก')} */}

            <Badge size="small" count={item?.options?.badge ?? 0} offset={[10, 0]} />
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <View style={{ alignItems: 'center', paddingVertical: 10 }}>
        <Image source={require('../assets/images/eicon_splash.png')} style={{ width: 40, height: 40 }} />
        {
          collapsed ? (<View />) : (<View>
            <Text style={{ color: 'white', fontSize: 10, marginTop: 5, fontWeight: 'bold', textAlign: 'center' }}>ALL PharmaSee</Text>
            <Text style={{ color: 'white', fontSize: 10, marginTop: 5, textAlign: 'center' }}>version: {server_var.version}</Text>
          </View>)
        }

      </View>
      {Title && <Title collapsed={collapsed} />}
      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        {renderTreeView(menuItems, selectedKey)}
      </Menu>
    </AntdLayout.Sider>
  );
};