import React, { useEffect, useState, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { numberWithCommas } from './common1'
import { CustomIcon } from '../component/custom';
import EventEmitter from "./EventEmitter";
import { PRE_ORDER_STATUS } from '../sharewebapp/common1';

export function ViewPreOrderPart({ route, navigation }) {
  const forUser = route?.params?.forUser;
  const { globalState, dispatch } = useContext(store);
  const [orderData, setOrderData] = useState(null);
  const [dialog, setDialog] = useState({ visible: false });

  useEffect(() => {
    if (route?.params?.id > 0) {
      loadData();
    } else {
      if (route?.params?.forUser) {
        loadDataForUser();
      }
    }
  }, [route?.params?.id])

  const loadData = async () => {
    let query = { id: route?.params?.id };
    const res = await globalState.client.service('preorders').find({ query });
    console.log('preorders : ', route?.params?.id, res);
    if (res.total == 0) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูลใบสั่งจองสินค้านี้' }, });
      navigation.goBack(2);
    }
    setOrderData(res.data[0]);
  }

  const loadDataForUser = async () => {
    let query = { queId: route?.params?.queId };
    const res = await globalState.client.service('preorders').find({ query });
    console.log('preorders : ', route?.params?.queId, res);
    if (res.total > 0) {
      setOrderData(res.data[0]);
    }
  }

  const onBack = async (item) => {
    let res;
    if (item?.action == 'confirm') {
      res = await globalState.client.service('preorders').patch(route?.params?.id, {
        orderStatus: 'customer_got_deliver'
      });      

    } else if (item?.action == 'save') {
      res = await globalState.client.service('preorders').patch(route?.params?.id, {
        orderStatus: 'cancel',
        reasonToCancel: item?.reasonToCancel,
        reasonToCancelOther: item?.reasonToCancelOther
      });
    } else {
      return
    }
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' } });
    EventEmitter.notify('ManagePreOrder:refresh');
    navigation.goBack(2);
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {(!orderData) && (
        <View style={{
          position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', alignItems: 'center'
        }}>
          <Text style={{ color: 'gray', fontSize: 16, fontWeight: 'bold' }}>
            {
              server_var.appMode == 'commerce' ? 'ไม่มีรายการใบสั่งจองสินค้า' : 'ยังไม่เปิดให้บริการ'
            }
          </Text>
        </View>
      )}      
      {orderData &&
      <ScrollView style={{ marginVertical: 5, marginHorizontal: 20 }}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
          <Text style={{ fontSize: 16, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>{`หมายเลขใบสั่งจองสินค้า P${String(orderData?.id).padStart(5, '0')}`}</Text>
        </View>      
        <View style={{ paddingHorizontal: 10, paddingVertical: 10, backgroundColor: 'white', borderRadius: 10, marginTop: 10 }}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ fontWeight: 'bold' }}>สถานะ</Text>     
            <View style={{ flexDirection: 'row' }}>
              <Text>
              {[
                { id: 1, title: 'สร้างรายการสั่งจองสินค้าสำเร็จ', value: 'pre_ordered' },
                { id: 2, title: 'รับสินค้าสำเร็จ', value: 'customer_got_deliver' },
                { id: 3, title: 'ยกเลิก', value: 'cancel' }
              ].find(item2 => item2.value == orderData?.orderStatus)?.title }
              </Text> 
              { (orderData?.orderStatus == 'cancel') && <Text>{` (${orderData?.reasonToCancel == 'อื่นๆ' ? (orderData?.reasonToCancelOther ?? '') : orderData?.reasonToCancel})`}</Text> }
            </View>
          </View>          
        </View>
        <View style={{ paddingHorizontal: 10, paddingVertical: 10, backgroundColor: 'white', borderRadius: 10, marginTop: 10 }}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ fontWeight: 'bold' }}>รายละเอียดผู้สั่งจอง</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 0.3 }}><Text>ชื่อ-นามสกุล</Text></View>
              <View style={{ flex: 0.1 }}><Text>:</Text></View>
              <View style={{ flex: 0.6 }}><Text>{orderData?.user?.displayName}</Text></View>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 0.3 }}><Text>เบอร์โทร</Text></View>
              <View style={{ flex: 0.1 }}><Text>:</Text></View>
              <View style={{ flex: 0.6 }}><Text>{orderData?.user?.mobileNumber}</Text></View>
            </View>            
          </View>          
        </View>       

        { !forUser && 
        <View style={{ paddingHorizontal: 10, paddingVertical: 10, backgroundColor: 'white', borderRadius: 10, marginTop: 10 }}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ fontWeight: 'bold' }}>กลุ่มสินค้าสั่งจอง</Text>
            {orderData?.productType?.map(item => (
              <Text>
                {[
                  { id: 1, title: 'สินค้า PMA18 ALL Products', value: 'all' },
                  { id: 2, title: 'สินค้า PMA18 Pack Link (สินค้าสุขภาพราคายกแพ็ก)', value: 'pack' },
                  { id: 3, title: 'สินค้า PMA18 Pre-Order (Medicine Catalogue)', value: 'pre' }
                ].find(item2 => item2.value == item)?.title }
              </Text> 
            ))}
          </View>          
        </View> 
        }   
        
        <View style={{ paddingHorizontal: 10, paddingVertical: 10, backgroundColor: 'white', borderRadius: 10, marginTop: 10 }}>
          <View style={{ flexDirection: 'column' }}>
            <Text style={{ fontWeight: 'bold' }}>รายการสินค้าสั่งจอง</Text>
            <Text>{orderData?.orderDetail}</Text>
            <View style={{ flex: 1, borderBottomWidth: 1, borderColor: 'lightgray', marginVertical: 10 }} />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 0.5 }}><Text style={{ fontSize: 16, fontWeight: 'bold', color: themeColor.color1 }}>ยอดรวม</Text></View>
              <View style={{ flex: 0.5, alignItems: 'flex-end' }}><Text style={{ fontSize: 16, fontWeight: 'bold', color: themeColor.color1 }}>฿{numberWithCommas(orderData?.orderPrice ?? 0)}</Text></View>
            </View>                        
          </View>          
        </View>

        { (orderData?.orderStatus == 'pre_ordered' && !forUser) &&
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 20, flex: 1 }}>
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => setDialog(prev => ({ ...prev, visible: true, dialogType: 'confirm' }))} >
            <View style={{
              height: 60, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
            }}>
              <Text style={{ color: 'white' }}>ลูกค้ารับสินค้าสั่งจอง</Text>
              <Text style={{ color: 'white' }}>เรียบร้อยแล้ว</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => setDialog(prev => ({ ...prev, visible: true, dialogType: 'cancel' }))} >
            <View style={{
              height: 60, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
            }}>
              <Text style={{ color: 'white' }}>ยกเลิก</Text>
              <Text style={{ color: 'white' }}>ใบสั่งจองสินค้า</Text>
            </View>
          </TouchableOpacity>        
        </View>
        }
        <Dialog dialog={dialog} setDialog={setDialog} onBack={onBack} />
      </ScrollView>
      }
  </SafeAreaView>
  )
}

function Dialog({ dialog, setDialog, onBack }) {
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
        }}>
        <View style={{
          width: dialogWidth, height: dialogWidth, paddingTop: 10, paddingBottom: 30,
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
          }}>
          { (dialog?.dialogType == 'confirm') && <ConfirmDialog dialog={dialog} setDialog={setDialog} dialogWidth={dialogWidth} onBack={onBack} /> }
          { (dialog?.dialogType == 'cancel') && <CancelDialog dialog={dialog} setDialog={setDialog} dialogWidth={dialogWidth} onBack={onBack} /> }

          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

function ConfirmDialog({ dialog, setDialog, dialogWidth, onBack }) {
  const [data, setData] = useState({});
  return (
    <View style={{ marginTop: 20 }}>
      <Image
        source={require('../assets/images/bag.png')}
        style={{ width: dialogWidth * 0.8, height: dialogWidth / 3 }}
        resizeMode="contain"
      />
      <Text style={{ fontSize: 16, textAlign: 'center', marginTop: 20 }}>ยืนยันการส่งสินค้าสั่งจอง</Text>
      <Text style={{ fontSize: 16, textAlign: 'center', marginTop: 5 }}>ให้ลูกค้า</Text>
      <View style={{ flex: 1, borderBottomWidth: 1, borderColor: 'lightgray', marginVertical: 20 }} />
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10, flex: 1 }}>
        <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} 
          onPress={() => {
            setDialog(prev => ({ ...prev, visible: false }));
            onBack({...data, action: 'cancel'});
          }}>
          <View style={{
            height: 40, borderRadius: 10,
            justifyContent: 'center', alignItems: 'center', marginTop: 10, borderWidth: 1, borderColor: themeColor.color1
          }}>
            <Text style={{ color: themeColor.color1 }}>ยกเลิก</Text>
          </View>
        </TouchableOpacity>          
        <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} 
          onPress={() => {
            setDialog(prev => ({ ...prev, visible: false }));
            onBack({...data, action: 'confirm'});
          }}>
          <View style={{
            height: 40, borderRadius: 10,
            justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
          }}>
            <Text style={{ color: 'white' }}>ยืนยัน</Text>
          </View>
        </TouchableOpacity>
        </View>      
    </View>

  )
}

function CancelDialog({ dialog, setDialog, dialogWidth, onBack }) {
  const [data, setData] = useState({ reasonToCancel: 'ลูกค้าเปลี่ยนใจ', reasonToCancelOther: null });
  return (
    <View style={{ marginTop: 10 }}>
      <View style={{ alignItems: 'flex-start', width: dialogWidth * 0.8 }}>
        <Text style={{ fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>กรุณาระบุเหตุผลในการยกเลิก</Text>        
        <View style={{ flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start', marginVertical: 10 }}>
          { [
                { id: 1, title: 'ลูกค้าเปลี่ยนใจ', value: 'ลูกค้าเปลี่ยนใจ' },
                { id: 2, title: 'ไม่สามารถสั่งผ่านเครื่อง SC ได้', value: 'ไม่สามารถสั่งผ่านเครื่อง SC ได้' },
                { id: 3, title: 'สินค้าส่งมาที่หน้าร้านล่าช้า', value: 'สินค้าส่งมาที่หน้าร้านล่าช้า' },
                { id: 4, title: 'อื่นๆ ระบุ', value: 'อื่นๆ' }
              ].map(item2 => (
            <TouchableOpacity key={item2.id.toString()} style={{ marginHorizontal: 10, marginTop: 5 }}
              onPress={() => setData(prev => ({ ...prev, reasonToCancel: item2.value }))}
            >
              <View style={{ flexDirection: 'row' }}>
                <CustomIcon name={item2.value == data?.reasonToCancel ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
                <Text style={{ marginLeft: 10 }}>{item2.title}</Text>                      
              </View>
              {(data?.reasonToCancel == 'อื่นๆ') && (item2.value == data?.reasonToCancel) && (
                <TextInput
                  value={data?.reasonToCancelOther}
                  onChangeText={text => setData(prev => ({ ...prev, reasonToCancelOther: text }))}
                  style={{ marginTop: 5, width: 200, height: 30, borderWidth: 1, borderColor: 'lightgray', paddingHorizontal: 3, borderRadius: 5 }}
                />
              )} 
            </TouchableOpacity>
          ))}
        </View>        
      </View>

      <View style={{ flex: 1, borderBottomWidth: 1, borderColor: 'lightgray', marginVertical: 20 }} />
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10, flex: 1 }}>
        <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} 
          onPress={() => {
            setDialog(prev => ({ ...prev, visible: false }));
            onBack({...data, action: 'cancel'});
          }}>
          <View style={{
            height: 40, borderRadius: 10,
            justifyContent: 'center', alignItems: 'center', marginTop: 10, borderWidth: 1, borderColor: themeColor.color1
          }}>
            <Text style={{ color: themeColor.color1 }}>ยกเลิก</Text>
          </View>
        </TouchableOpacity>          
        <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} 
          onPress={() => {
            setDialog(prev => ({ ...prev, visible: false }));
            onBack({...data, action: 'save'});   
          }}>
          <View style={{
            height: 40, borderRadius: 10,
            justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
          }}>
            <Text style={{ color: 'white' }}>บันทึก</Text>
          </View>
        </TouchableOpacity>
      </View>      
    </View>

  )
}