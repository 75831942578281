import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, SafeAreaView, ScrollView, Dimensions, Modal, Alert, Linking } from 'react-native';
import React, { useState, useEffect, useCallback, useContext, forwardRef, useRef, useImperativeHandle } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import { numberWithCommas, getTotalPriceText } from './common1';
import { OrderAction } from './ordermainpart'
import { DELIVER_LIST, PAYMENT_LIST } from './common1';
import Lightbox from 'react-native-lightbox-v2';
import EventEmitter from './EventEmitter';
import { CustomIcon, ImageWithAuth, CustomAntDImage } from '../component/custom';
import { useCustomNavigation } from '../component/custom';
import { getJwtToken } from '../component/custom';
import { ORDER_STATUS } from './common1';

export function ViewOrderPart({ route, navigation }) {
  const [orderMain, setOrderMain] = useState();
  const { ordermainId, pharmaNoteId, showMailOrder } = route?.params;
  const { globalState } = useContext(store);

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, []);

  const onNotiListener = async (payload) => {
    if (payload.toUserId == globalState.user.id && payload.type == 'RefreshOrder') {
      EventEmitter.notify('orderAction:refresh');
    }
  }

  const onPressPrintOrder = (pressParam) => {
    const { ordermainId } = pressParam;
    navigation.navigate('PrintOrder', pressParam);
  }
  const onPressViewProduct = (pressParam) => {
    navigation.navigate('ViewProduct', pressParam);
  }
  const onPressVatRequest = (pressParam) => {
    navigation.navigate('VatRequest', pressParam);
  }
  const onPressLalamove = (pressParam) => {
    navigation.navigate('LalamoveStatus', pressParam)
  }
  const onPressGrab = (pressParam) => {
    navigation.navigate('GrabStatus', pressParam);
  }
  const goBack = (index) => {
    navigation.goBack(index);
  }
  const onPressConfirmDelivery = (pressParam) => {
    navigation.navigate('RatingPurchase', { ordermainId: pressParam?.ordermainId })
  }

  const onPressPaymentOption = async (pressParam) => {
    const { ordermainId, totalOrderPrice } = pressParam;
    const res = await globalState.client.service('ordermains').get(ordermainId);

    navigation.navigate('PaymentOption', {
      totalOrderPrice, ordermainId, deliverJsonAddr: res?.deliverJsonAddr
    });
  }

  const onPressReOtp = async (pressParam) => {
    const { ordermainId, totalOrderPrice } = pressParam;
    console.log('press reotp', pressParam);
    const resOrder = await globalState.client.service('ordermains').get(ordermainId);
    console.log('resOrder', resOrder)
    const jwtToken = await getJwtToken();
    navigation.navigate('PaymentWeb', {
      uri: `${server_var.server_api}/paydeli?ordermainId=${ordermainId}&paymentMethod=TMW&tmwMobileNumber=${resOrder?.paymentInfo?.tmwMobileNumber}&reOtpFlag=true`,
      ordermainId, jwtToken
    })
  }

  const onPressEslip = async (pressParam) => {
    const validateEslip = async () => {
      let res;
      let jwtToken;
      try {
        jwtToken = await getJwtToken();
      } catch (e) { }
      try {
        res = await fetch(`${server_var.server_api}/validateEslip`, {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
          body: JSON.stringify({ userId: globalState?.user?.id })
        });
      } catch (e) {
        console.log('validateEslip post error : ', e);
      }
      if (!res) return 0;
      const resJson = await res.json();
      console.log(themeColor.FgBlue, 'validateEslip resJson : ' + JSON.stringify(resJson), themeColor.FgReset);

      if (resJson?.result) {
        console.log(themeColor.FgYellow, 'validateEslip statusId : ' + resJson?.statusId, themeColor.FgReset);
        return resJson?.statusId;
      }
      return 0;
    }

    if (Platform.OS != 'web') {
      if (globalState?.user?.allmemId) {
        const statusId = await validateEslip();
        console.log('onPress ViewEslip statusId : ', statusId);
        if (statusId == 1) {
          navigation.navigate('ViewEslip', { ordermainId: pressParam?.ordermainId });
        } else {
          navigation.navigate('AllMemberApplyNow', { userId: globalState.user.id, mobileNumber: globalState.user.mobileNumber, settingEslip: true });
        }
      } else {
        navigation.navigate('AllMemberApplyNow', { userId: globalState.user.id, mobileNumber: globalState.user.mobileNumber, applyEslip: true });
      }
    }
  }

  const onPressPostVoid = (pressParam) => {
    if (pressParam?.receiptNo) {
      navigation.navigate('PostVoid', { ordermainId: pressParam?.ordermainId, orderId: pressParam?.orderId, receiptNo: pressParam?.receiptNo });
    } else {
      alert('เกิดข้อผิดพลาดในการขอยกเลิกรายการสั่งซื้อ [receiptNo not found]');
    }
  }

  const onPressOrderVoid = async (pressParam) => {
    const { ordermainId, orderId, receiptNo, orderVoidStatus } = pressParam;
    let resOrder;
    if (ordermainId) {
      resOrder = await globalState.client.service('ordermains').get(ordermainId);
    }
    console.log('ordermains : ', resOrder);
    
    if (pressParam?.orderVoidStatus) {
      let res;
      let jwtToken;
      try {
        jwtToken = await getJwtToken();
      } catch (e) { }
      try {
        res = await fetch(`${server_var.server_api}/orderVoidRequest`, {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
          body: JSON.stringify({ userId: resOrder?.userId, orderId, orderVoidReasonId: resOrder?.orderVoidReasonId, orderVoidOtherReasonText: resOrder?.orderVoidOtherReasonText, receiptNo  })
        });
      } catch (e) {
        console.log('onPressOrderVoid post error : ', e);
      }
      if (!res) return;
      const resJson = await res.json();
      console.log(themeColor.FgYellow, 'onPressOrderVoid resJson : ' + JSON.stringify(resJson), themeColor.FgReset);
  
      if (resJson?.result) {
        console.log('Admin approved orderVoid!');
        const res = await globalState.client.service('ordermains').patch(ordermainId, {
          orderVoidStatus: 'void_process',
        }); 
        EventEmitter.notify("ManageOrderVoid:refresh");
        navigation.navigate('ManageOrderVoid');
      } else {
        alert(`เกิดข้อผิดพลาดในการขอยกเลิกรายการสั่งซื้อ (${resJson?.resultData?.errorMsgTh})`);
      }
    } else {
      console.log('Admin rejected orderVoid!');
      const res = await globalState.client.service('ordermains').patch(ordermainId, {
        orderVoidStatus: null,
        paymentPartialStatus: 'void_reject'
      });
      EventEmitter.notify("ManageOrder:refresh");
      navigation.navigate('ManageOrder', { forAdmin: true });
    }
  }  

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={[Platform.OS == 'web' ? { height: '70vh' } : null]}>
        <ViewOrderView
          setOrderMain={setOrderMain} ordermainId={ordermainId} onPressViewProduct={onPressViewProduct}
          onPressLalamove={onPressLalamove} onPressGrab={onPressGrab}
          pharmaNoteId={pharmaNoteId} showMailOrder={showMailOrder} onPressEslip={onPressEslip}
        />
      </ScrollView>
      <OrderAction totalOrderPrice={0} ordermainId={ordermainId} onPressPrintOrder={onPressPrintOrder}
        onPressPaymentOption={onPressPaymentOption} onPressConfirmDelivery={onPressConfirmDelivery} onPressVatRequest={onPressVatRequest}
        goBack={goBack} onPressReOtp={onPressReOtp} onPressPostVoid={onPressPostVoid} onPressOrderVoid={onPressOrderVoid} />
    </View>
  )
}

export function ViewOrderView({
  setOrderMain, ordermainId, onPressViewProduct, onPressLalamove, pharmaNoteId, showMailOrder, onPressGrab, onPressEslip
}) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [orderList, setOrderList] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const [eTaxMode, setEtaxMode] = useState(false);

  const loadData = async () => {
    if (ordermainId) {
      console.log('loadData ordermainId', ordermainId)
      const res = await globalState.client.service('ordermains').get(ordermainId);
      console.log('ordermains : ', res);
      setData(res);
      if (setOrderMain) {
        setOrderMain(res);
      }

      console.log(themeColor.FgYellow, 'eTaxMode : ' + server_var?.eTaxMode, themeColor.FgReset);
      if (server_var?.eTaxMode) {
        if (globalState?.user?.staffFlag == 0) {
          if (res?.paymentDate) {
            if (moment(res?.paymentDate).add(30, 'days') > moment()) {
              setEtaxMode(true);
            }
          }
        }
      } else {
        setEtaxMode(false);
      }

      const res1 = await globalState.client.service('orderitems').find({ query: { ordermainId, itemType: 'normal', $limit: 200 } });
      if (res1.total > 0) {
        setOrderList(res1.data);
        return;
      }
      const res2 = await globalState.client.service('baskets').find({ query: { ordermainId, $limit: 200 } });
      if (res2.total > 0) {
        setOrderList(res2.data);
        return;
      }
      setOrderList([]);
    }
  }

  useEffect(() => {
    EventEmitter.addListener('ViewOrder:refresh', loadData);
    EventEmitter.addListener('orderAction:refresh', loadData);
    loadData();
    return () => {
      EventEmitter.removeListener('ViewOrder:refresh', loadData);
      EventEmitter.removeListener('orderAction:refresh', loadData);
    };
  }, [ordermainId]);

  const COMPONENT_LIST = [
    {
      id: 10, type: 'component', title: 'Order Flow',
      component:
        ['cancel', 'cancel_by_system', 'cancel_by_admin', 'selfpickup_not_pickup'].includes(data?.orderStatus) ?
          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{ORDER_STATUS.find(item2 => item2.value == data.orderStatus).title}</Text> :
          <OrderStatusIcon data={findOrderStatus(data)} />
    },
    { id: 15, type: 'break' },
    {
      id: 16, type: 'eSlip', title: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอิเล็กทรอนิกส์', field: data?.id, hide: !server_var.eTaxMode
    },
    { id: 20, type: 'break', hide: !server_var.eTaxMode },
    {
      id: 25, type: 'address', field: {
        contactDisplayName: data?.deliverJsonAddr?.contactDisplayName,
        contactMobileNumber: data?.deliverJsonAddr?.contactMobileNumber,
        deliverJsonAddr: data?.deliverJsonAddr,
      }
    },
    { id: 70, type: 'break' },
    { id: 80, type: 'title', title: 'สรุปคำสั่งซื้อ' },
    {
      id: 85, type: 'productlist',
      field: orderList.map(item2 => ({
        id: item2.id,
        amount: item2.amount,
        name: item2?.product?.thProductName,
        price: item2.unitPrice,
        photo1: item2?.product?.productPhoto1,
        ordermainId: item2?.ordermainId,
        productId: item2?.productId,
        discountPrice: item2.discountPrice,
        userId: data?.userId
      }))
    },
    { id: 90, type: 'break' },
    { id: 95, type: 'title', title: 'รายการทั้งหมด' },
    { id: 97, type: 'inline', title: 'จำนวนสินค้า', field: orderList.reduce((acc, item5) => (acc + item5.amount), 0) + ' ชิ้น', textProp: { textAlign: 'right' } },
    { id: 101, type: 'inline', title: 'ค่าสินค้า', field: data?.orderPrice, textProp: { textAlign: 'right' } },
    { id: 102, type: 'inline', title: 'ค่าบริการ', field: data?.servicePrice, textProp: { textAlign: 'right' } },
    { id: 103, type: 'inline', title: 'ค่าบริการทางเภสัชกรรม', field: data?.servicePharmacistPrice, textProp: { textAlign: 'right' } },
    { id: 104, type: 'inline', title: 'ค่าจัดส่ง', field: data?.deliveryPrice, textProp: { textAlign: 'right' } },
    { id: 105, type: 'inline', title: 'ส่วนลด', field: (data?.discountPrice - data?.discountCouponPrice).toFixed(2), textProp0: { color: themeColor.color6 }, textProp: { textAlign: 'right', color: themeColor.color6 } },
    { id: 106, type: 'inline', title: 'คูปอง/โปรโมชั่นโค้ด', field: data?.discountCouponPrice, textProp0: { color: themeColor.color6 }, textProp: { textAlign: 'right', color: themeColor.color6 } },
    {
      id: 107, type: 'inline', title: 'ยอดรวมคำสั่งซื้อ', textProp0: { color: themeColor.color1, fontWeight: 'bold', fontSize: 14 }, textProp: { textAlign: 'right', color: themeColor.color1, fontWeight: 'bold', fontSize: 14 },
      field: getTotalPriceText(data)
    },
    { id: 110, type: 'break' },
    { id: 120, type: 'title', title: 'การจัดส่ง' },
    {
      id: 125, type: 'noline',
      field: DELIVER_LIST.find(item2 => item2.value == data?.deliverType)?.title
    },
    { id: 126, type: 'break', hide: data?.deliverType != 'post' },
    {
      id: 127, type: 'post', title: 'เลขการจัดส่งพัสดุ', field: data?.postRefNo,
      hide: data?.deliverType != 'post'
    },
    { id: 130, type: 'break' },
    { id: 140, type: 'title', title: 'การชำระเงิน' },
    { id: 145, type: 'noline', field: PAYMENT_LIST.find(item2 => item2.value == data?.paidChannel)?.title },
    { id: 150, type: 'break' },
    { id: 155, type: 'title', title: 'ใบกำกับภาษี' },
    { id: 160, type: 'nolinevat', field: data?.vatRequestJson },
    { id: 165, type: 'break', hide: (!globalState?.user?.staffFlag) },
    { id: 170, type: 'title', title: 'รูปภาพสินค้าที่จัดส่ง', hide: (!globalState?.user?.staffFlag) },
    { id: 175, type: 'nolineimage', field: data?.orderPhotoList, hide: (!globalState?.user?.staffFlag) },
    { id: 180, type: 'break', hide: (!globalState?.user?.staffFlag) },
    {
      id: 190, type: 'lalamove', title: 'หมายเลข Lalamove รับงานขนส่ง:',
      hide: (!globalState?.user?.staffFlag || data?.deliverType != 'lalamove'),
      field: `${[data?.lalamoveOrderId, ...(data?.lalamoveOrderHist ?? [])].filter(item => item).join(',')}`,
      field2: data?.id
    },
    {
      id: 192, type: 'grab', title: 'หมายเลข Grab รับงานขนส่ง:',
      hide: (!globalState?.user?.staffFlag || data?.deliverType != 'grab'),
      field: `${[data?.grabOrderId, ...(data?.grabOrderHist ?? [])].filter(item => item).join(',')}`,
      field2: data?.id
    },
    {
      id: 200, type: 'title', title: 'หมายเลขอ้างอิง Speed D:',
      hide: (!globalState?.user?.staffFlag || data?.deliverType != 'speed_d'),
    },
    {
      id: 210, type: 'noline',
      hide: (!globalState?.user?.staffFlag || data?.deliverType != 'speed_d'),
      field: data?.speedDRefNo,
    },
  ];

  return (

    <View>
      <View style={{ flexDirection: 'row', paddingHorizontal: 20, marginTop: 10, justifyContent: 'space-between' }}>
        <Text style={{ fontWeight: 'bold' }}>
          คำสั่งซื้อ
          <Text style={{ fontWeight: 'normal', color: 'gray', fontSize: 12 }}> เลขที่ {data?.id}</Text>
        </Text>
        {showMailOrder && (
          <TouchableOpacity onPress={() => setDialog({ visible: true, ordermainId: ordermainId, pharmaNoteId: pharmaNoteId })}>
            <View>
              <CustomIcon name="file-move" size={26} color={themeColor.color1} />
            </View>
          </TouchableOpacity>
        )}
      </View>
      <View style={{
        paddingHorizontal: 20, paddingVertical: 10, backgroundColor: 'white',
        marginHorizontal: 20, borderRadius: 10, marginTop: 10, marginBottom: 20
      }}>
        {COMPONENT_LIST.filter(item => (!item?.hide)).map(item =>
          <CustomCom
            key={item.id.toString()} item={item}
            onPressViewProduct={onPressViewProduct ? onPressViewProduct : () => console.log('no action')}
            onPressLalamove={onPressLalamove} onPressGrab={onPressGrab} onPressEslip={onPressEslip} eTaxMode={eTaxMode}
          />
        )}
      </View>
      <Dialog dialog={dialog} setDialog={setDialog} />
    </View>
  )
}

function CustomCom({ item, onPressViewProduct, onPressLalamove, onPressGrab, onPressEslip, eTaxMode }) {
  const customNavigation = useCustomNavigation();
  const { globalState } = useContext(store);
  const [seeMore, setSeeMore] = useState(false);
  const addressClick = () => {
    if (Platform.OS != 'web') {
      customNavigation.navigate('LocationMap', { locId: -1, locationData: item.field.deliverJsonAddr })
    } else {
      const lat = item.field.deliverJsonAddr.lat;
      const long = item.field.deliverJsonAddr.long;
      window.open(`https://maps.google.com/?q=${lat},${long}`, '_blank')
    }
  }

  if (item.type == 'break') {
    return <View style={{ height: 2, backgroundColor: themeColor.color8, marginVertical: 10, marginHorizontal: -20 }} />
  } else if (item.type == 'address') {
    return (
      <TouchableOpacity onPress={addressClick}>
        <View>
          <View style={{ position: 'absolute', right: 10, top: 10 }}>
            <Image source={require('../assets/images/pin1.png')} style={{ width: 25, height: 25 }} resizeMode="contain" />
          </View>
          <View style={{ flexDirection: 'row', marginBottom: 5 }}>
            <Text style={{ fontWeight: 'bold', fontSize: 16 }}>ส่งไปยัง</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={[{ flex: 0.4, fontSize: 13 }, item?.textProp0]}>ชื่อผู้รับสินค้า</Text>
            <Text style={[{ flex: 0.6, fontSize: 13 }, item?.textProp]}>{item.field?.contactDisplayName}</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={[{ flex: 0.4, fontSize: 13 }, item?.textProp0]}>เบอร์โทรผู้รับสินค้า</Text>
            <Text style={[{ flex: 0.6, fontSize: 13 }, item?.textProp]}>{item.field?.contactMobileNumber}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 13 }}>{item.field?.deliverJsonAddr?.detailAddress}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 13 }}>{item.field?.deliverJsonAddr?.mainAddressFull}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: 13 }}>{item.field?.deliverJsonAddr?.otherAddress}</Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  } else if (item.type == 'title') {
    return (
      <View style={{ flexDirection: 'row', marginBottom: 5 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{item.title}</Text>
      </View>
    )
  } else if (item.type == 'inline') {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={[{ flex: 0.4, fontSize: 13 }, item?.textProp0]}>{item.title}</Text>
        <Text style={[{ flex: 0.6, fontSize: 13 }, item?.textProp]}>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'noline') {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ fontSize: 13 }}>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'post') {
    return (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={{ flex: 1 }} onPress={() => Linking.openURL(`https://track.thailandpost.co.th/?trackNumber=${item.field}`)}>
          <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
            <View>
              <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{item.title}</Text>
              <Text style={{ fontSize: 13 }}>{item.field}</Text>
            </View>
            {item?.field != null && (
              <View style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: 'gray' }}>
                <CustomIcon name="chevron-right" size={20} color={'white'} />
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View >
    )
  } else if (item.type == 'lalamove') {
    return (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={{ flex: 1 }} onPress={() => onPressLalamove({ lalaId: item.field, ordermainId: item.field2 })}>
          <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
            <View>
              <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{item.title}</Text>
              <Text style={{ fontSize: 13 }}>{item.field}</Text>
            </View>
            {item?.field != null && (
              <View style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: 'gray' }}>
                <CustomIcon name="chevron-right" size={20} color={'white'} />
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View >
    )
  } else if (item.type == 'grab') {
    return (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={{ flex: 1 }} onPress={() => onPressGrab({ grabId: item.field, ordermainId: item.field2 })}>
          <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
            <View>
              <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{item.title}</Text>
              <Text style={{ fontSize: 13 }}>{item.field}</Text>
            </View>
            {item?.field != null && (
              <View style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: 'gray' }}>
                <CustomIcon name="chevron-right" size={20} color={'white'} />
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View >
    )
  } else if (item.type == 'eSlip') {
    return (
      <TouchableOpacity onPress={() => eTaxMode ? onPressEslip({ ordermainId: item?.field }) : null}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: "center" }}>
          <Image source={eTaxMode ? require('../assets/images/eTaxBlue.png') : require('../assets/images/eTaxGray.png')} style={{ width: 50, height: 50, paddingHorizontal: 5 }} resizeMode="contain" />
          <View style={{ flexDirection: 'row', flex: 0.9 }}>
            <View style={{ flexDirection: 'column', marginBottom: 5 }}>
              <Text style={{ color: eTaxMode ? 'black' : 'gray', fontWeight: 'bold', fontSize: 13 }}>{item.title}</Text>
              <Text style={{ color: eTaxMode ? 'black' : 'gray', fontSize: 10, marginTop: 5 }}>* ขอใบกำกับภาษีได้ภายในวันที่ซื้อเท่านั้น</Text>
              <Text style={{ color: eTaxMode ? 'black' : 'gray', fontSize: 10 }}>* รายการซื้อสินค้าแสดงย้อนหลังสูงสุด 30 วัน</Text>
            </View>

          </View>

          <CustomIcon name="chevron-right-circle" size={20} color="gray" style={{ paddingHorizontal: 10 }} />
        </View>
      </TouchableOpacity>
    )
  } else if (item.type == 'nolinevat') {
    if (seeMore) {
      return (
        <View style={{ flexDirection: 'column' }}>
          <Text style={{ fontSize: 13 }}>
            {
              [
                { value: null, title: 'ไม่ได้ขอใบกำกับภาษี' },
                { value: 'personal', title: 'ขอใบกำกับภาษีส่วนบุคคล' },
                { value: 'company', title: 'ขอใบกำกับภาษีนิติบุคคล' }
              ].find(item3 => (item3.value == item.field?.vat)).title
            }
          </Text>
          <View>
            {VatInfo(item?.field?.data)?.map((item5, index5) => {
              if (item5 && item5.title) {
                return (
                  <View key={item5?.id?.toString()} style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 13 }}>{item5.title}: </Text>
                    <Text style={{ fontSize: 13 }}>{item5.value}</Text>
                  </View>
                )
              } else {
                return <View key={index5.toString()} />
              }
            }
            )}
          </View>
          {item?.field?.data != null && (
            <View>
              <TouchableOpacity style={{ marginTop: 5 }} onPress={() => setSeeMore(prev => !prev)}>
                <Text style={{ color: themeColor.color1, fontSize: 13 }}>ดูเพิ่มเติม {seeMore ? '<<' : '>>'}</Text>
              </TouchableOpacity>
            </View>
          )}

        </View>
      )
    } else {
      return (
        <View style={{ flexDirection: 'column' }}>
          <Text style={{ fontSize: 13 }}>
            {
              [
                { value: null, title: 'ไม่ได้ขอใบกำกับภาษี' },
                { value: 'personal', title: 'ขอใบกำกับภาษีส่วนบุคคล' },
                { value: 'company', title: 'ขอใบกำกับภาษีนิติบุคคล' }
              ].find(item3 => (item3.value == item.field?.vat)).title
            }
          </Text>
          {item?.field?.data != null && (
            <View>
              <TouchableOpacity style={{ marginTop: 5 }} onPress={() => setSeeMore(prev => !prev)}>
                <Text style={{ color: themeColor.color1, fontSize: 13 }}>ดูเพิ่มเติม {seeMore ? '<<' : '>>'}</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )
    }

  } else if (item.type == 'nolineimage') {
    if (item?.field && item?.field?.length > 0) {
      if (Platform.OS == 'web') {
        return (
          <View style={{ flexDirection: 'row' }}>
            {item.field.map((item5, index5) => (
              <CustomAntDImage
                source={{ uri: item5.filename }}
                style={{ height: 80, flex: 1, width: 80, marginHorizontal: 5 }}
              // style={{ width: Dimensions.get('window').width / 8 }}
              />
            ))}
          </View>
        )
      } else {
        return (
          <View style={{ flexDirection: 'row' }}>
            {item.field.map((item5, index5) => (
              <Lightbox key={index5.toString()} activeProps={{ style: { flex: 1, resizeMode: 'contain' } }}>
                <Image source={{ uri: item5.filename }} style={{ height: 80, flex: 1, width: 80, marginHorizontal: 5 }} resizeMode="contain" />
              </Lightbox>
            ))}
          </View>
        )
      }

    } else {
      return (
        <View>
          <Text style={{ fontSize: 13 }}>ยังไม่มีรูป</Text>
        </View>)
    }

  } else if (item.type == 'productlist') {
    return (
      <View>
        {item.field.map(item3 => {
          let imageUrl = `${server_var.server_api}/images/no_image_available.jpeg`;
          if (item3?.photo1) {
            if (item3.photo1.includes('http')) {
              imageUrl = item3.photo1;
            } else {
              imageUrl = `${server_var.server_api}/${item3.photo1}`;
            }
          }
          return (
            <TouchableOpacity key={item3.id.toString()} style={{ marginVertical: 10 }} onPress={() => {
              if (globalState?.user?.staffFlag) {
                onPressViewProduct({
                  productId: item3?.productId, userId: item3?.userId, ordermainId: item3?.ordermainId
                });
              }
            }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', flex: 0.9 }}>
                  <ImageWithAuth url={imageUrl} style={{ width: 60, height: 60 }} />
                  <View style={{ marginLeft: 10, flex: 1 }}>
                    <Text style={{ fontSize: 13 }}>{item3.name}</Text>
                    <Text style={{ color: themeColor.color1 }}>{item3.price}</Text>
                    {parseFloat(item3.discountPrice ?? 0) > 0 && <Text style={{ color: themeColor.color6, fontSize: 12 }}>ส่วนลด {numberWithCommas(item3.discountPrice)}</Text>}
                  </View>
                </View>
                <Text style={{ fontSize: 13 }}>x{item3.amount}</Text>
              </View>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  } else if (item.type == 'nextline') {
    return (
      <View>
        <Text>{item.title}</Text>
        <Text>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'component') {
    return item.component
  } else {
    return <View />
  }
}

function findOrderStatus(data) {
  if (data) {
    const arrayData = Object.entries(data);
    const list = [
      { thName: 'ชำระเงิน', enName: 'paymentDate' },
      { thName: 'ระหว่างนำส่ง', enName: 'extaStartDeliverDate' },
      { thName: 'จัดส่งสำเร็จ', enName: 'partnerCompleteDeliverDate' },
      { thName: 'รับสินค้าสำเร็จ', enName: 'custGotDeliverDate' }
    ]
    const tmp = arrayData.filter(item => list.map(item2 => item2.enName).includes(item[0]));
    const tmp2 = tmp.map(item => {
      return [item[0], list.find(item2 => item2.enName == item[0]).thName ?? item[0], item[1]]
    })
    console.log('tmp2', tmp2);
    return tmp2
  } else {
    return null;
  }
}

function OrderStatusIcon({ data }) {
  console.log(data)
  return (
    <View style={{ flexDirection: 'row' }}>
      {data?.map(item => (
        <View key={item[0]} style={{ marginHorizontal: 10, alignItems: 'center' }}>
          <CustomIcon name="check-circle" size={30} color={item[2] ? themeColor.color1 : 'gray'} />
          <Text style={{ fontSize: 10, textAlign: 'center', color: item[2] ? themeColor.color1 : 'gray' }}>{item[1]}</Text>
        </View>
      ))}
    </View>
  )
}

function VatInfo(data) {
  const VAT_LIST = [
    { id: 1, title: 'เลขประจำตัวผู้เสียภาษี', field: 'personalTaxId' },
    { id: 2, title: 'ชื่อ-นามสกุล', field: 'taxPersonalName' },
    { id: 3, title: 'ที่อยู่ใบกำกับภาษี 1', field: 'taxPersonalAddressLine1' },
    { id: 4, title: 'ที่อยู่ใบกำกับภาษี 2', field: 'taxPersonalAddressLine2' },
    { id: 5, title: 'จังหวัด', field: 'taxPersonalProvince' },
    { id: 6, title: 'เบอร์โทรศัพท์', field: 'taxPersonalPhoneNumber' },
    { id: 7, title: 'อีเมล์', field: 'taxPersonalEmail' },

    { id: 8, title: 'เลขประจำตัวผู้เสียภาษี', field: 'companyTaxId' },
    {
      id: 9, title: 'สถานประกอบการ', field: 'companyType',
      value: [
        { id: 1, value: 'H', title: 'สำนักงานใหญ่' },
        { id: 2, value: 'B', title: 'สาขา' }
      ]
    },
    { id: 10, title: 'สาขา', field: 'branchNo' },
    { id: 11, title: 'บริษัท', field: 'taxCompanyName' },
    { id: 12, title: 'ที่อยู่ใบกำกับภาษี 1', field: 'taxCompanyAddressLine1' },
    { id: 13, title: 'ที่อยู่ใบกำกับภาษี 2', field: 'taxCompanyAddressLine2' },
    { id: 14, title: 'จังหวัด', field: 'taxCompanyProvince' },
    { id: 15, title: 'เบอร์โทรศัพท์', field: 'taxCompanyPhoneNumber' },
    { id: 16, title: 'อีเมล์ ', field: 'taxCompanyEmail' },
  ]
  if (data) {
    const tmp1 = Object.entries(data);
    const tmp2 = [];
    for (const item of tmp1) {
      tmp2.push({
        id: (VAT_LIST.find(item2 => item2.field == item[0])?.id),
        title: (VAT_LIST.find(item2 => item2.field == item[0])?.title),
        field: item[0],
        value: item[1],
      })
    }
    console.log('tmp2', tmp2);
    return (tmp2.sort((a, b) => a.id - b.id));
  } else {
    return [];
  }
}

function Dialog({ dialog, setDialog, onBack }) {
  const { globalState, dispatch } = useContext(store);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const [error, setError] = useState({ at: '', message: '' });

  const validateForm = () => {
    if (!dialog.email) {
      setError({ at: 'email', message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(dialog.email) === false) {
      setError({ at: 'email', message: 'อีเมลไม่ถูกต้อง' })
      return false;
    }
    return true;
  }

  const mailOrder = async (ordermainId, pharmaNoteId, email) => {
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/mailOrder`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ ordermainId, pharmaNoteId, email })
    });
    const resJson = await res.json();
    console.log('send mail order : ', resJson.result);

    if (resJson.result == 'OK' && resJson.fileJwt) {
      dispatch({ type: 'showToast', payload: { text: 'ส่งประวัติคำสั่งซื้อเรียบร้อยแล้ว' }, });
      Linking.openURL(`${server_var.server_api}/getpdf/${resJson.fileJwt}/order_${ordermainId}.pdf`);
    } else {
      dispatch({ type: 'showToast', payload: { text: 'ไม่สามารถส่งอีเมลได้ กรุณาลองใหม่อีกครั้ง' }, });
    }
    setError({ at: '', message: '' })
    setDialog({ visible: false });
  }

  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30, justifyContent: "flex-start",
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <ScrollView>
            <View style={{ marginVertical: 20, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 20 }}>
              <Text style={{ color: themeColor.color1, marginTop: 10, textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>ส่งประวัติคำสั่งซื้อ</Text>
              <Text style={{ color: themeColor.color1, marginBottom: 20, textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>ไปยังอีเมลของคุณ</Text>
              <Text style={{ textAlign: 'center', color: 'gray' }}>
                โปรดตรวจสอบให้แน่ใจว่าอีเมลที่คุณใส่นั้นถูกต้อง เพื่อให้เราสามารถส่งประวัติการปรึกษาให้คุณได้
              </Text>
              <TextInput
                value={dialog.email}
                onChangeText={text => setDialog(prev => ({ ...prev, email: text }))}
                placeholder={'กรุณาใส่อีเมลของคุณ'}
                style={[{ height: 40, width: "100%", borderRadius: 5, borderWidth: 1, paddingLeft: 10, alignSelf: 'center', marginTop: 20, textAlign: "left", backgroundColor: 'white' },
                error['at'] == 'email' ? { borderColor: themeColor.color6 } : { borderColor: 'lightgray' }]}
              />
              <Text style={{ color: themeColor.color6 }}>{error['at'] == 'email' ? error['message'] : ''}</Text>
            </View>
          </ScrollView>
          <View style={{ flexDirection: 'row' }}>
            {
              [
                {
                  id: 1, title: 'ยกเลิก', onPress: () => {
                    setError({ at: '', message: '' });
                    setDialog({ visible: false });
                  },
                  viewStyle: { backgroundColor: 'white' }, textStyle: { color: themeColor.color1 }
                },
                {
                  id: 2, title: 'ส่ง', onPress: async () => {
                    if (!validateForm()) {
                      return;
                    }
                    mailOrder(dialog.ordermainId, dialog.pharmaNoteId, dialog.email);
                  },
                  viewStyle: { backgroundColor: themeColor.color1 }, textStyle: { color: 'white' }
                }
              ].map(item => (
                <TouchableOpacity key={item.id.toString()} onPress={() => item.onPress()} style={{ marginHorizontal: 10 }}>
                  <View style={[{
                    width: 120, height: 35, borderRadius: 10, borderWidth: 1, borderColor: themeColor.color1,
                    justifyContent: 'center', alignItems: 'center'
                  }, item.viewStyle]}>
                    <Text style={[item.textStyle]}>{item.title}</Text>
                  </View>
                </TouchableOpacity>
              ))
            }
          </View>
          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => {
              setError({ at: '', message: '' });
              setDialog({ visible: false });
            }}>
              <CustomIcon name="close-circle-outline" size={25} color={'gray'} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}
