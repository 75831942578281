import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from '../sharewebapp/store'
import moment from 'moment';
import { useCustomFocusEffect } from '../component/custom';

export function NotiPart({ navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [seedNum, setSeedNum] = useState(0);

  useCustomFocusEffect(
    useCallback(() => {
      setSeedNum(Math.floor(Date.now() / 1000))
    }, [])
  );

  useEffect(() => {
    loadData();
  }, [seedNum])

  const loadData = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res;
      try {
        res = await globalState.client.service('notilogs').find({
          query: {
            type: { $in: ['Message', 'PromoMessage'] },
            toUserId: globalState?.user?.id,
            $skip: pSkip ?? skip,
            $sort: { id: -1 }
          }
        });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
    await globalState.client.service('users').patch(globalState?.user?.id, { unreadNoti: 0 });
  }

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        style={[{ marginTop: 20 }, Platform.OS == 'web' ? { height: '70vh' } : null]}
        onEndReached={(xxx) => loadData()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadData(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          <NotiItem item={item} navigation={navigation} />
        )}
      />
    </View>
  )
}

function NotiItem({ item, navigation }) {
  return (
    <TouchableOpacity style={{ paddingBottom: 10, paddingTop: 0 }} onPress={() => {
      // if (item?.data?.link && Platform.OS != 'web') {
      if (item?.data?.link) {
        if (Platform.OS != 'web') {
          Linking.openURL(item?.data?.link);
          return;
        }
        if (Platform.OS == 'web') {

          if (item?.data?.link?.includes('allpharmasee://OrderMain')) {
            const tmp1 = item?.data?.link?.split('ordermainId=');
            const tmpOrderId = tmp1[1].split('&')
            window.open(`manage_order?ordermainId=${tmpOrderId}`, '_blank');
            return;
          }
          if (item?.data?.link?.includes('allpharmasee://')) {
            return;
          }
          Linking.openURL(item?.data?.link)
        }
      } else {
        navigation.navigate('FeedView', { id: item?.id });
      }
    }}>
      <View style={{
        marginHorizontal: 20, borderBottomWidth: 1, borderColor: 'lightgray',
        paddingBottom: 10, flexDirection: 'row', justifyContent: 'space-between'
      }}>
        <Image
          source={item?.type == 'PromoMessage' ?
            require('../assets/images/PromoMessage.png') : require('../assets/images/PersonalMessage.png')
          }
          style={{ width: 30, height: 30, marginRight: 10 }}
        />
        <View style={{ flex: 1 }}>
          {item.data?.title &&
            <Text style={{ fontSize: 14, fontWeight: item.source ? 'bold' : 'normal' }}>
              {item.data?.title}
            </Text>
          }
          <Text style={{ fontSize: 14 }}>
            {textSubString(item?.type, item?.data?.text)}
          </Text>
          <Text style={{ color: 'gray', fontSize: 12, marginTop: 3 }}>
            {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

function textSubString(type, text) {
  let txtLng = 160;
  if (type == 'PromoMessage') {
    txtLng = 60;
  }
  return text?.split('\n')?.[0]?.substring(0, txtLng) + ((text?.split('\n')?.[0]?.length > txtLng) ? ' ...' : '')
}
