import React from 'react';
import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  Text, View, TouchableOpacity, Image, TextInput, Button, Platform,
  Dimensions, FlatList, ActivityIndicator, Modal, ScrollView, SafeAreaView, Alert, AppState
} from 'react-native';
import EventEmitter from "./EventEmitter";
import { server_var, themeColor, fontStyles } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import moment from 'moment';
import { getJwtToken } from '../component/custom';
import { CustomWebView } from '../component/custom';

export function ProductList7({ userId, shopId, queId, onPressViewProduct, hideListButton, visible, navigation }) {
  const { globalState, dispatch } = useContext(store);
  const [productList, setProductList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [searchById, setSearchById] = useState(1);

  useEffect(() => {
    EventEmitter.addListener('Barcode:input', barcodeInput);
    return () => {
      EventEmitter.removeListener('Barcode:input', barcodeInput);
    };
  }, [])

  const barcodeInput = (data) => {
    setSearchById(3);
    setSearchText(data);
    loadProductList(0, 3, data);
  }

  const SEARCH_BY_LIST = [
    { id: 2, title: 'ชื่อสินค้า', mode: 'byProductName' }, { id: 3, title: 'บาร์โค้ด', mode: 'byBarCode' },
  ]

  const loadProductList = async (paramSkip, pSearchById, pSearchText) => {
    if (!loading) {
      let jwtToken;
      try {
        jwtToken = await getJwtToken();
      } catch (e) {
        alert('มีปัญหากับ jwtToken' + JSON.stringify(e));
        return
      }
      const defHeader = {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken }
      };
      setLoading(true);
      let retData = [];
      let fetchParam = {
        ...defHeader, body: JSON.stringify({ shopId, searchText, searchById })
      }
      if (pSearchById && pSearchText) {
        fetchParam = {
          ...defHeader, body: JSON.stringify({ shopId, searchText: pSearchText, searchById: pSearchById })
        }
      }
      console.log('fetchParam', fetchParam);
      const res = await fetch(`${server_var.server_api}/getProduct7Deli`, fetchParam);
      retData = await res.json();
      console.log('resData', retData);
      setProductList(retData);
      setLoading(false);
    }
  }
  if (!visible) {
    return <View />
  } else {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
          {SEARCH_BY_LIST.map(item => (
            <TouchableOpacity key={item.id.toString()} onPress={() => setSearchById(item.id)}>
              <View style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 20 }}>
                <CustomIcon
                  name={item.id == searchById ? "check-circle-outline" : "checkbox-blank-circle-outline"}
                  size={18}
                  color="gray"
                />
                <Text style={{ marginLeft: 3 }}>
                  {item.title}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
          <TouchableOpacity onPress={() => navigation.navigate('BarcodeScan')}>
            <View style={{ backgroundColor: themeColor.color1, borderRadius: 5, padding: 3 }}>
              <CustomIcon name="camera" size={20} color="white" />
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาสินค้า'}
            style={{
              alignSelf: 'center', marginTop: 10, marginHorizontal: 10, width: "85%",
              borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40,
              paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white'
            }}
          />
          <TouchableOpacity
            onPress={() => loadProductList()}
            style={{ width: 28, height: 28, borderRadius: 14, backgroundColor: themeColor.color1, justifyContent: 'center', alignItems: 'center' }}
          >
            <CustomIcon name="magnify" size={22} color="white" />
          </TouchableOpacity>
        </View>
        <FlatList
          keyExtractor={(item) => item.id.toString()}
          data={productList}
          onRefresh={() => loadProductList()}
          refreshing={loading}
          renderItem={({ item }) => (
            <ProductItem7 item={item} onPressViewProduct={onPressViewProduct} hideListButton={hideListButton} />
          )}
          style={{ height: 20 }}
        />
      </View>
    )
  }
}

function ProductItem7({ item, onPressViewProduct, hideListButton }) {
  let imageUrl = `${server_var.server_api}/images/no_image_available.jpeg`;
  if (item?.productPhoto1) {
    if (item.productPhoto1.includes('http')) {
      imageUrl = item.productPhoto1;
    } else {
      imageUrl = `${server_var.server_api}/${item.productPhoto1}`;
    }
  }
  let soldOutFlag = false;
  if (item?.inventoryInfo?.is_sold_out) {
    soldOutFlag = true;
  }

  return (
    <View key={item.id.toString()} style={{
      flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between',
      marginVertical: 5, width: '90%', opacity: (soldOutFlag ? 0.5 : 1)
    }}
    >
      <TouchableOpacity
        style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}
        onPress={() => {
          if (!soldOutFlag) {
            onPressViewProduct(item.id)
          }
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Image resizeMode="contain"
            source={{ uri: imageUrl, headers: { headertemp: server_var.deliProdMode == 'uat' ? server_var.headertempUat : server_var.headertempProd } }}
            style={{ width: 50, height: 50, marginRight: 5 }}
          />
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
              <Text style={{ fontSize: 12, flex: 1, flexWrap: 'wrap' }}>{item.thProductName}</Text>
            </View>
            <Text style={{ marginLeft: 0, color: themeColor.color1 }}>฿{numberWithCommas(parseFloat(item.productPrice))}</Text>
            {item.promotion?.communication != null && (
              <Text style={{ marginLeft: 0, color: themeColor.color6, fontSize: 12 }}>โปรโมชั่น : {item.promotion?.communication}</Text>
            )}
            {soldOutFlag && (
              <Text style={{ marginLeft: 0, fontSize: 12 }}>สินค้าหมด</Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
      {hideListButton != true && (
        <TouchableOpacity style={{ marginRight: 5 }}
          onPress={() => {
            if (!soldOutFlag) {
              onPressViewProduct(item.id)
            }
          }}
        >
          <View style={{
            width: 50, backgroundColor: themeColor.color1, height: 30, borderRadius: 10, justifyContent: 'center', alignItems: 'center'
          }}>
            <Text style={{ color: 'white', fontSize: 10, }}>เลือก</Text>
          </View>
        </TouchableOpacity>
      )}
      {/* <Text>{JSON.stringify(item, '', 2)}</Text> */}
    </View>
  )
}

export function BasketDialog({ dialog, setDialog, onBack, uri, actionList }) {
  const dialogWidth = Dimensions.get('window').width;
  const buttonOnPress = async (onPressValue) => {
    console.log('onPressValue', onPressValue);
    setDialog(prev => ({ ...prev, visible: false }));
    onBack(onPressValue)
  }

  return (
    <Modal transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
          <View style={{
            justifyContent: "flex-start", flex: 1, borderWidth: 0,
            borderColor: 'lightgray', backgroundColor: 'white',
          }}>
            <Text style={{ paddingHorizontal: 15, textAlign: 'center' }}>
              ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคลแก่บริษัทในกลุ่ม สำหรับบริการชำระเงิน และจัดส่งสินค้า
            </Text>
            <CustomWebView
              style={{ marginTop: 0, paddingHorizontal: 10, width: dialogWidth }}
              source={{ uri }}
            />
            <View style={{ flexDirection: 'row', backgroundColor: 'white', justifyContent: 'space-around' }}>
              {actionList?.map(item => (
                <TouchableOpacity
                  key={item.id.toString()} style={{ alignSelf: 'center' }}
                  onPress={() => buttonOnPress(item.onPressValue)}
                >
                  <View style={[
                    {
                      backgroundColor: themeColor.color1, paddingHorizontal: 10, height: 40,
                      width: dialogWidth / ((actionList?.length ?? 1) + 0.3),
                      marginTop: 10, borderRadius: 5, justifyContent: 'center', alignItems: 'center'
                    },
                    item.onPressValue.value == 'reject' ?
                      { backgroundColor: 'white', borderWidth: 1, borderColor: themeColor.color1 } :
                      null
                  ]}
                  >
                    <Text style={[
                      fontStyles.buttonText,
                      item.onPressValue.value == 'accept' ? { color: 'white', textAlign: 'center' } : null,
                      item?.onPressValue.value == 'reject' ? { color: themeColor.color1 } : null
                    ]}>
                      {item.title}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </SafeAreaView>
      </View>
    </Modal>
  )
}

