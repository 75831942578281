import React from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Dimensions, FlatList,AppState } from 'react-native';
import { useState, useEffect, useContext,useCallback,useRef } from 'react';
import SegmentedControlTab from '../sharewebapp/SegmentedControlTab';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon, useCustomFocusEffect } from '../component/custom';
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import moment from 'moment';
import { getJwtToken } from '../component/custom';
import { CustomHeaderBackButton } from '../component/custom';

export function BasketPart({route,navigation}) {
  const {globalState} = useContext(store);
  const [allowToBuyOnly, setAllowToBuyOnly] = useState(false);
  const [refreshNo, setRefreshNo] = useState(0);
  const appState = useRef(AppState.currentState);
  const onlyBasket = route?.params?.onlyBasket;
  const userId = route?.params?.userId ?? globalState?.user?.id;
  const shopId = route?.params?.shopId;
  const queId = route?.params?.queId;
  
  const pressToOrder = (params) => {
    navigation.navigate('OrderMain', params)
  }

  const onPressViewProduct = (productId) => {
    navigation.navigate('ViewProduct', { productId, userId, shopId, queId })
  }

  const MODE_LIST = [
    {
      id: 1, condition: !onlyBasket, title: 'ค้นหาสินค้า', com: (
        <ProductList userId={userId} shopId={shopId} queId={queId}
          onPressViewProduct={onPressViewProduct} />
      )
    },
    {
      id: 2, condition: true, title: 'ตะกร้า', com: (
        <BasketList userId={userId} onlyBasket={onlyBasket} pressToOrder={pressToOrder}
          onPressViewProduct={onPressViewProduct} shopId={shopId} setAllowToBuyOnly={setAllowToBuyOnly}
          refreshNo={refreshNo} queId={queId}
        />
      )
    },
  ]
  const tabList = MODE_LIST.filter(item => item.condition);
  const [tabIndex, setTabIndex] = useState(onlyBasket ? 0 : 1);

  useCustomFocusEffect(
    useCallback(() => {
      setRefreshNo(Math.floor(Date.now() / 1000));
    }, [])
  );

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (
        appState.current.match(/inactive|background/) && nextAppState === "active"
      ) {
        setRefreshNo(Math.floor(Date.now() / 1000));
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, [])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <CustomHeaderBackButton {...props} />
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Text style={{ fontSize: 16, color: themeColor.color1 }}>กลับไปที่แชท</Text>
          </TouchableOpacity>
        </View>
      )
    })
  }, [])

  return (
    <View style={{ flex: 1 }}>
      {
        tabList.length > 1 && (
          <SegmentedControlTab
            values={MODE_LIST.filter(item => item.condition).map(item => item.title)}
            textNumberOfLines={1}
            selectedIndex={tabIndex}
            tabTextStyle={{ textAlign: 'center', color: themeColor.color1 }}
            onTabPress={(index) => {
              if (allowToBuyOnly && index == 0) {
                alert("ไม่สามารถค้นหาสินค้าได้ ขณะที่ให้ลูกค้ารอจ่ายเงิน")
              } else {
                setTabIndex(index)
              }
            }}
            tabsContainerStyle={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
            activeTabStyle={{ backgroundColor: themeColor.color1 }}
            tabStyle={{ borderColor: themeColor.color1 }}
          />
        )
      }
      {MODE_LIST.filter(item => item.condition).map(item => item.com)[tabIndex]}
    </View >
  )
}

export function ProductList({ userId, shopId, queId, onPressViewProduct, hideListButton }) {
  const { globalState, dispatch } = useContext(store);
  const [productList, setProductList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  useEffect(() => {
    loadProductList(0)
  }, [debouncedSearchTerm])

  const loadProductList = async (paramSkip) => {
    if (!loading) {
      setLoading(true);
      let res;
      try {
        const jwtToken = await getJwtToken();
        const defHeader = {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken }
        };
        const results = await fetch(`${server_var.server_api}/loadProductWithPromotion`, {
          ...defHeader,
          body: JSON.stringify({ mode: 'byCombine', searchText, skip: paramSkip ?? skip })
        });
        res = await results.json();
        console.log('\n\n\nproduct res', res);
      } catch { }
      if (res) {
        if ((paramSkip ?? skip) === 0) {
          setProductList(res.data);
        } else {
          setProductList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }
  return (
    <View style={{ flex: 1 }}>
      <TextInput
        value={searchText}
        onChangeText={text => setSearchText(text)}
        placeholder={'ค้นหาสินค้า'}
        style={{
          alignSelf: 'center', marginTop: 20, marginHorizontal: 20, width: "90%",
          borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40,
          paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white'
        }}
      />
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={productList}
        onEndReached={(xxx) => loadProductList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadProductList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          <ProductItem item={item} onPressViewProduct={onPressViewProduct} hideListButton={hideListButton} />
        )}
        style={{ height: 20 }}
      />
    </View>
  )
}

function ProductItem({ item, onPressViewProduct, hideListButton }) {
  let imageUrl = `${server_var.server_api}/images/no_image_available.jpeg`;
  if (item?.productPhoto1) {
    if (item.productPhoto1.includes('http')) {
      imageUrl = item.productPhoto1;
    } else {
      imageUrl = `${server_var.server_api}/${item.productPhoto1}`;
    }
  }

  return (
    <View key={item.id.toString()} style={{
      flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between', marginVertical: 5, width: '90%'
    }}
    >
      <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }} onPress={() => onPressViewProduct(item.id)}>
        <View style={{ flexDirection: 'row' }}>
          <Image resizeMode="contain"
            source={{ uri: imageUrl, headers: { headertemp: server_var.deliProdMode == 'uat' ? server_var.headertempUat : server_var.headertempProd } }}
            style={{ width: 50, height: 50, marginRight: 5 }}
          />
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
              <Text style={{ fontSize: 12, flex: 1, flexWrap: 'wrap' }}>{item.thProductName}</Text>
            </View>
            <Text style={{ marginLeft: 0, textAlight: 'right', color: themeColor.color1 }}>฿{numberWithCommas(parseFloat(item.productPrice))}</Text>
            {item.promotion?.communication != null && <Text style={{ marginLeft: 0, textAlight: 'right', color: themeColor.color6, fontSize: 12 }}>โปรโมชั่น : {item.promotion?.communication}</Text>}
          </View>
        </View>
      </TouchableOpacity>
      {hideListButton != true && (
        <TouchableOpacity
          onPress={() => onPressViewProduct(item.id)}
          style={{ marginRight: 5 }}
        >
          <View style={{
            width: 50, backgroundColor: themeColor.color1, height: 30, borderRadius: 10, justifyContent: 'center', alignItems: 'center'
          }}>
            <Text style={{ color: 'white', fontSize: 10, }}>เลือก</Text>
          </View>
        </TouchableOpacity>
      )}

    </View>
  )
}

function BasketList({
  userId, onlyBasket, pressToOrder, onPressViewProduct, shopId, setAllowToBuyOnly, refreshNo, queId
}) {
  const { globalState } = useContext(store);
  const [basketList, setBasketList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, []);

  useEffect(() => {
    loadBasketList()
  }, [refreshNo])

  const onNotiListener = async (payload) => {
    if (payload.toUserId == globalState.user.id && payload.type == 'RefreshBasket') {
      console.log('onnoti');
      loadBasketList(0)
    }
  }

  const loadBasketList = async (paramSkip) => {
    if (!loading) {
      setLoading(true);
      let jsonResult;
      try {
        const jwtToken = await getJwtToken();
        const pskip = paramSkip ?? skip;
        const fetchResult = await fetch(
          `${server_var.server_api}/loadBasketWithPromotion?userId=${userId}`,
          {
            method: 'GET',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
          }
        );
        jsonResult = await fetchResult.json();

      } catch { }
      if (jsonResult) {
        const baskets = jsonResult.baskets;
        const pDiscountPrice = jsonResult.discountPrice;
        setBasketList(baskets.data);
        setDiscountPrice(pDiscountPrice);
        setSkip((baskets.skip + baskets.limit));
      }
      setLoading(false);
    }
  }

  const changeAmount = async (pAmount, id) => {
    const res = await globalState.client.service('baskets').get(id)
    const newAmount = res.amount + pAmount
    if (newAmount < 1) {
      await globalState.client.service('baskets').remove(id);
    } else {
      await globalState.client.service('baskets').patch(id, { amount: newAmount, totalPrice: newAmount * res.unitPrice });
    }
    loadBasketList(0);
  }
  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      {(!basketList || basketList.length == 0) && (
        <View style={{
          position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', alignItems: 'center'
        }}>
          <Text style={{ color: 'gray', fontSize: 16, fontWeight: 'bold' }}>
            {
              server_var.appMode == 'commerce' ? 'ยังไม่มีสินค้าในตะกร้า' : 'ยังไม่เปิดให้บริการ'
            }
          </Text>
        </View>
      )}
      {onlyBasket && (basketList && basketList.length > 0) && (
        <View style={{ paddingHorizontal: 20, paddingTop: 10, paddingBottom: 10 }}>
          <Text style={{ fontWeight: 'bold' }}>ตะกร้าของฉัน</Text>
          <Text style={{ color: themeColor.color1, fontSize: 12 }}>
            (ตะกร้าของท่านจะถูกยกเลิกโดยอัตโนมัติ หากไม่ชำระเงินภายใน {moment(basketList?.[0]?.ordermain?.orderDate).utc().add(7, 'hours').add(30, 'minutes').format('HH:mm')}
            )
          </Text>
        </View>
      )}
      <FlatList
        keyExtractor={(item) => (item.id.toString() + item.amount.toString())}
        data={basketList}
        onRefresh={() => loadBasketList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          <BasketItem item={item} changeAmount={changeAmount} onlyBasket={onlyBasket} onPressViewProduct={onPressViewProduct} />
        )}
      />
      {(
        <BasketAction basketList={basketList} onlyBasket={onlyBasket} userId={userId}
          pressToOrder={pressToOrder} shopId={shopId} discountPrice={discountPrice}
          setAllowToBuyOnly={setAllowToBuyOnly} loadBasketList={loadBasketList} queId={queId}
        />
      )}
    </View>
  )
}

function BasketDeliverChoice({ setBasketOrderData, basketOrderData, allow7Deli }) {

  return (
    <View style={{ paddingHorizontal: 10, marginBottom: 5, borderRadius: 10 }}>
      <Text>
        ตัวเลือกในการจัดส่ง
      </Text>
      <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
        {[
          {
            id: 1, title: ['รับสินค้าที่ร้าน', 'ไม่มีค่าจัดส่ง'], value: 'self_pickup', enable: true,
            onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: 'self_pickup' }))
          },
          {
            id: 2, title: ['จัดส่งวันถัดไป', 'โดย SpeedD', 'ค่าจัดส่ง ฿0'], value: 'speed_d', enable: true,
            onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: 'speed_d' }))
          },
          {
            id: 3, title: ['ส่งทันที', 'โดย 7-Delivery', 'ค่าจัดส่ง ฿0'], value: '7deli', enable: allow7Deli,
            onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: '7deli' }))
          },
          {
            id: 4, title: ['ส่งทันที', 'โดย Lalamove', 'ค่าจัดส่ง ฿0'], value: 'lalamove', enable: true,
            onPress: () => setBasketOrderData(prev => ({ ...prev, deliverType: 'lalamove' }))
          }
        ].map(item3 => (
          <TouchableOpacity
            key={item3.id.toString()}
            style={{
              marginHorizontal: 10, flexDirection: 'row', padding: 10, backgroundColor: 'white',
              marginTop: 5, borderRadius: 10, width: 150, height: 70
            }}
            onPress={() => {
              if (item3.enable) {
                setBasketOrderData(prev => ({ ...prev, deliverType: item3.value }))
              }
            }}
          >
            <CustomIcon
              name={basketOrderData.deliverType == item3.value ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18}
            />
            <View style={{ marginLeft: 10 }}>
              {item3.title.map((item4, index4) =>
                <Text key={item4} style={[index4 > 0 ? { fontSize: 12, color: 'gray' } : { fontSize: 12, color: item3.enable ? 'black' : 'gray' }]}>{item4}</Text>
              )}
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

function BasketAction({
  basketList, userId, onlyBasket, pressToOrder, shopId, discountPrice, setAllowToBuyOnly,
  loadBasketList, queId
}) {
  const { globalState } = useContext(store);
  const [modeId, setModeId] = useState();
  const [basketOrderData, setBasketOrderData] = useState({ deliverType: null })
  const [error, setError] = useState({ at: '', message: '' });
  const [allow7Deli, setAllow7Deli] = useState(false);
  const [waitFlag, setWaitFlag] = useState(false);

  const MODE_LIST = [
    {
      id: 1, condition: onlyBasket && basketList?.length > 0 && basketList[0].allowToPay,
      title: 'สั่งซื้อสินค้า', backgroundColor: themeColor.color1, onPress: () => pressToOrder()
    },
    {
      id: 2, condition: onlyBasket && basketList?.length > 0 && !basketList[0].allowToPay,
      title: 'สั่งซื้อสินค้า', backgroundColor: 'gray', onPress: () => console.log('cannot buy yet')
    },
    {
      id: 3, condition: basketList?.length > 0 && !basketList[0].allowToPay, title: 'ยืนยันรายการให้ลูกค้าสั่งซื้อ',
      backgroundColor: 'green',
      additionalCom:
        <View style={[error?.at == 'deliverType' ? { borderColor: 'red', borderWidth: 1 } : null]}>
          <BasketDeliverChoice
            setBasketOrderData={setBasketOrderData}
            basketOrderData={basketOrderData}
            allow7Deli={allow7Deli}
          />
          {error?.message ? <Text style={{ color: 'red', textAlign: 'center' }}>กรุณาเลือก</Text> : <View />}
        </View>
      ,
      onPress: async () => {
        setWaitFlag(true)
        setError({ at: '', message: '' });
        if (!basketOrderData?.deliverType) {
          setError({ at: 'deliverType', message: 'กรุณาเลือก' });
          return;
        }
        let resOrder;
        let tmpTotalOrderPrice = (basketList?.reduce((acc, item) => (acc + parseFloat(item.totalPrice)), 0) || 0)
        if (!basketList[0].ordermainId) {
          const resOldQueLine = await globalState.client.service('oldquelines').find({
            query: { oldId: basketList[0].queId, $select: ['deliverJsonAddr'] }
          });
          let tmpDeliverJsonAddr = resOldQueLine?.data?.[0]?.deliverJsonAddr;
          if (tmpDeliverJsonAddr.id == 0) {
            tmpDeliverJsonAddr['detailAddress'] = '';
            tmpDeliverJsonAddr['otherAddress'] = '';
          }
          resOrder = await globalState.client.service('ordermains').create({
            orderStatus: 'in_basket', userId, shopId: basketList[0].shopId,
            queId: basketList[0].queId, pharmaId: basketList[0].pharmaId,
            deliverType: basketOrderData.deliverType,
            deliverJsonAddr: resOldQueLine?.data?.[0]?.deliverJsonAddr,
            orderPrice: tmpTotalOrderPrice,
            discountPrice: discountPrice
          });
        } else {
          resOrder = await globalState.client.service('ordermains').patch(basketList[0].ordermainId, {
            orderStatus: 'in_basket', deliverType: basketOrderData.deliverType, shopId,
            orderPrice: tmpTotalOrderPrice, discountPrice: discountPrice
          });
        }
        await globalState.client.service('baskets').patch(null,
          { allowToPay: 1, ordermainId: resOrder.id }, { query: { userId, $limit: 100 } }
        );
        //ตรวจสอบว่ามีการสร้าง pharmanotes แล้วหรือยัง
        const resPhamarNote = await globalState.client.service('pharmanotes').find(
          { query: { userId, shopId, queId, pharmaId: globalState?.user?.id } }
        )
        if (resPhamarNote.total == 0) {
          globalState.client.service('pharmanotes').create({
            queId, shopId, userId, pharmaId: globalState?.user?.id, psctype: 'prescribe',
          })
        } else {
          await globalState.client.service('pharmanotes').patch(resPhamarNote.data[0]?.id, { psctype: 'prescribe' })
        }
        await loadBasketList(0)
        setAllowToBuyOnly(true)
        setWaitFlag(false)
      }
    },
    {
      id: 4, condition: basketList?.length > 0 && basketList[0].allowToPay, title: 'แก้ไขรายการในตะกร้าลูกค้า', backgroundColor: themeColor.color6,
      onPress: async () => {
        setWaitFlag(true);
        const res = await globalState.client.service('baskets').patch(null, { allowToPay: 0 }, { query: { userId, $limit: 100 } });
        if (basketList[0].ordermainId) {
          await globalState.client.service('ordermains').patch(basketList[0].ordermainId, { orderStatus: 'pharmacist_choose' });
          setAllowToBuyOnly(false)
        }
        await loadBasketList(0)
        setWaitFlag(false);
      }
    }
  ];

  useEffect(() => {
    setModeId(MODE_LIST.find(item => item.condition)?.id)
    if (basketList?.[0]?.ordermainId) {
      console.log('load');
      loadOrderMain(basketList?.[0]?.ordermainId)
    }
    if (basketList?.[0]?.allowToPay) {
      setAllowToBuyOnly(true)
    }
    if (basketList?.length > 0) {
      console.log('check trade area');
      checkTradeArea();
    }
  }, [basketList])

  const checkTradeArea = async () => {
    const jwtToken = await getJwtToken();
    const resOldQueLine = await globalState.client.service('oldquelines').find({
      query: { oldId: basketList[0].queId, $select: ['deliverJsonAddr'] }
    });
    const res7Delivery = await fetch(`${server_var.server_api}/check7Delivery`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({
        shopId: basketList[0].shopId,
        lat: resOldQueLine?.data?.[0]?.deliverJsonAddr.lat,
        long: resOldQueLine?.data?.[0]?.deliverJsonAddr.long
      })
    });
    const res7DeliveryJson = await res7Delivery.json();
    console.log('res7Delivery', res7DeliveryJson);
    setAllow7Deli(res7DeliveryJson.inTradeArea);
  }

  const loadOrderMain = async (ordermainId) => {
    const resOrder = await globalState.client.service('ordermains').get(ordermainId, {
      query: {
        $select: [
          'orderStatus', 'id', 'deliverType', 'orderPrice', 'deliveryPrice',
          'servicePrice', 'servicePharmacistPrice', 'discountPrice'
        ]
      }
    })
    setBasketOrderData(resOrder);
  }

  if (basketList?.length > 0) {
    return (
      <View style={{
        width: "100%", paddingTop: 10, marginBottom: 20, paddingHorizontal: 0, borderTopColor: 'lightgray', borderTopWidth: 1
      }}>
        {MODE_LIST.find(item => item.id == modeId)?.additionalCom}
        <View>
          {[
            { id: 1, type: 'subject', title: 'รายการทั้งหมด', value: 0, textProp: { fontWeight: 'bold', fontSize: 14 } },
            {
              id: 2, type: 'price', title: 'ค่าสินค้า',
              value: numberWithCommas(
                (basketList?.reduce((acc, item) => (acc + parseFloat(item.totalPrice)), 0) || 0).toFixed(2)
              )
            },
            {
              id: 3, type: 'price', title: 'ค่าบริการ',
              value: parseFloat((basketOrderData?.servicePrice) ?? 0).toFixed(2)
            },
            {
              id: 4, type: 'price', title: 'ค่าบริการทางเภสัชกรรม',
              value: parseFloat((basketOrderData?.servicePharmacistPrice) ?? 0).toFixed(2)
            },
            {
              id: 5, type: 'price', title: 'ค่าจัดส่ง',
              value: parseFloat((basketOrderData?.deliveryPrice) ?? 0).toFixed(2)
            },
            {
              id: 6, type: 'price', title: 'ส่วนลด', textProp: { color: themeColor.color6 },
              value: numberWithCommas(parseFloat(discountPrice).toFixed(2))
            },
            {
              id: 7, type: 'total', title: 'ยอดรวม',
              value: numberWithCommas(
                ((basketList?.reduce((acc, item) => (acc + parseFloat(item.totalPrice)), 0) || 0) - parseFloat(discountPrice)).toFixed(2)
              ),
              textProp: { fontWeight: 'bold', fontSize: 14, color: themeColor.color1, paddingHorizontal: 20 },
              viewProp: { borderTopWidth: 1, borderColor: 'lightgray', marginTop: 10, paddingTop: 10, marginHorizontal: 0 }
            },
            { id: 8, type: 'subject', title: 'ยังไม่รวมส่วนลดประเภทคูปอง', value: 0, textProp: { fontSize: 10, color: themeColor.color6 } }
          ].map(item3 => (
            <View
              key={item3.id.toString()}
              style={[{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20 }, item3.viewProp]}
            >
              <Text style={[{ color: 'black' }, item3.textProp]}>
                {item3.title}
              </Text>
              {item3.type != 'subject' && (
                <Text style={[{ color: 'black' }, item3.textProp]}>
                  ฿{item3.value}
                </Text>
              )}
            </View>
          ))}
          <TouchableOpacity
            style={{ marginTop: 10, width: '80%', alignSelf: 'center' }}
            onPress={() => waitFlag ? console.log('wait') : MODE_LIST.find(item => item.id == modeId)?.onPress()}
          >
            <View style={[
              { height: 40, borderRadius: 10, justifyContent: 'center', alignItems: 'center' },
              { backgroundColor: waitFlag ? 'lightgray' : MODE_LIST.find(item => item.id == modeId)?.backgroundColor },
            ]}>
              <Text style={{ color: 'white' }}>{MODE_LIST.find(item => item.id == modeId)?.title}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  } else {
    return <View />
  }
}

function BasketItem({ item, changeAmount, onlyBasket, onPressViewProduct }) {
  let imageUrl = `${server_var.server_api}/images/no_image_available.jpeg`;
  if (item?.product?.productPhoto1) {
    if (item.product.productPhoto1.includes('http')) {
      imageUrl = item.product.productPhoto1;
    } else {
      imageUrl = `${server_var.server_api}/${item.product.productPhoto1}`;
    }
  }
  return (
    <View key={item.id.toString()} style={{
      flexDirection: 'row', alignSelf: 'center', alignItems: 'center', width: "90%", justifyContent: 'space-between', marginVertical: 5,
    }}>
      <TouchableOpacity style={{ flex: 0.7 }} onPress={() => {
        onPressViewProduct(item.productId)
      }}>
        <View style={{ flexDirection: 'row' }}>
          <Image
            source={{ uri: imageUrl, headers: { headertemp: server_var.deliProdMode == 'uat' ? server_var.headertempUat : server_var.headertempProd } }}
            style={{ width: 60, height: 60, marginRight: 10 }} resizeMode="contain"
          />
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <Text style={{ flexWrap: 'wrap', fontSize: 12 }}>{item?.product?.thProductName}</Text>
            </View>
            <Text style={{ marginTop: 5, color: themeColor.color1 }}>฿{numberWithCommas(item.unitPrice)}</Text>
            {
              parseFloat(item?.discountPrice ?? 0) > 0 &&
              <Text style={{ color: themeColor.color6, fontSize: 12 }}>
                ส่วนลด ฿{numberWithCommas(item?.discountPrice)}
              </Text>
            }
          </View>
        </View>
      </TouchableOpacity>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 0.3 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {!onlyBasket && (
            <TouchableOpacity onPress={() => {
              if (!item.allowToPay) {
                changeAmount(-1, item.id)
              }
            }}>
              <CustomIcon name="minus-circle" color={item.allowToPay ? 'gray' : themeColor.color1} size={20} />
            </TouchableOpacity>
          )
          }
          <Text style={[
            { marginHorizontal: 10, width: 50, textAlign: 'right', borderRadius: 5 },
            !onlyBasket ? { backgroundColor: 'white' } : null
          ]}>
            {onlyBasket ? 'x' : ''}{item.amount.toString()}
          </Text>
          {!onlyBasket && (
            <TouchableOpacity onPress={() => {
              if (!item.allowToPay) {
                changeAmount(1, item.id)
              }
            }}>
              <CustomIcon name="plus-circle" color={item.allowToPay ? 'gray' : themeColor.color1} size={20} />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

