import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Button, TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar, Platform, TextInput } from 'react-native'
import { themeColor, fontStyles, server_var } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import SegmentedControlTab from './SegmentedControlTab';
import { numberWithCommas, getTotalPriceText } from './common1'
import { DELIVER_LIST, ORDER_STATUS } from './common1';
import { CustomIcon, CustomVideo, CustomDatePicker5 } from '../component/custom';
import EventEmitter from "./EventEmitter";
import { useDebounce } from "./common1";
import { Dialog } from '../sharewebapp/pharmanotepart';

export function ManagePreOrderPart({ navigation, route }) {
  const forAdmin = route?.params?.forAdmin;
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [queryText, setQueryText] = useState('');
  const [dialog, setDialog] = useState({ visible: false });
  const [searchData, setSearchData] = useState({ statusId: null, status: null, dateFrom: null, dateTo: null });

  const QUERY_LIST = [
    { id: 1, title: 'สร้างรายการสั่งจองสินค้าสำเร็จ', value: 'pre_ordered', query: { orderStatus: 'pre_ordered' } },
    { id: 2, title: 'รับสินค้าสำเร็จ', value: 'customer_got_deliver', query: { orderStatus: 'customer_got_deliver' } },
    { id: 3, title: 'ยกเลิก', value: 'cancel', query: { orderStatus: 'cancel' } },
  ]

  useEffect(() => {
    EventEmitter.addListener('ManagePreOrder:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManagePreOrder:refresh', handleRefresh);
    };
  }, [])

  const handleRefresh = () => {
    loadData(0);
  }

  useEffect(() => {    
    loadData(0);
  }, [queryText, searchData?.statusId, searchData?.dateFrom, searchData?.dateTo])

  const loadData = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res1, res2;
      let query = { $skip: pSkip ?? skip, $sort: { id: -1 } }

      if (!forAdmin) {
        res1 = await globalState.client.service('shopusers').find({
          query: { userId: globalState.user.id, $limit: 400 }
        });
        query = {
          ...query,
          shopId: { $in: res1?.data?.map(item => item.shopId) }
        }        
      }

      let criteriaDateFrom;
      if (searchData?.dateFrom) {
        criteriaDateFrom = moment(searchData?.dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        criteriaDateFrom = moment().format('YYYY-MM-DD');
      }
      let criteriaDateTo;
      if (searchData?.dateTo) {
        criteriaDateTo = moment(searchData?.dateTo, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
      } else {
        criteriaDateTo = moment().add(1, 'days').format('YYYY-MM-DD');
      }
      query = {
        ...query,
        $and: [
          { createdAt: { $gte: criteriaDateFrom } },
          { createdAt: { $lte: criteriaDateTo } }
        ],
      };

      if (queryText) {
        if (forAdmin) {
          query = {
            ...query,
            $or: [
              { id: { $like: `%${queryText}%` } },
              { '$user.displayName$': { $like: `%${queryText}%` } },
              { '$shop.shopIdBranch$': { $like: `%${queryText}%` } },
              { '$shop.shopThName$': { $like: `%${queryText}%` } }
            ],
          }
        } else {
          query = {
            ...query,
            $or: [
              { id: { $like: `%${queryText}%` } },
              { '$user.displayName$': { $like: `%${queryText}%` } },
            ],
          }
        }

      }
      if (searchData?.statusId) {
        query = {
          ...query,
          ...QUERY_LIST[searchData?.statusId - 1].query
        }
      }

      try {
        //alert(JSON.stringify(query));
        res2 = await globalState.client.service('preorders').find({ query });
      } catch { }

      if (res2) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res2.data);
        } else {
          setDataList(prev => [...prev, ...res2.data]);
        }
        setSkip((res2.skip + res2.limit));
      }

      setLoading(false);
    }
  }

  const onBackDialog = async (backItem) => {
    setSearchData(prev => ({ ...prev, statusId: backItem?.id, status: backItem?.title }));
    setDialog(prev => ({ ...prev, visible: false }));
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth, QUERY_LIST };
  const dialogContent = [
    {
      type: 'statuslist',
      title: 'สถานะ',
      com: <StatusList {...dialogProp} />,
    },
  ];

  const onManageOrderPress = (pressParam) => {
    navigation.navigate('ViewPreOrder', pressParam);
  }

  return (
    <View style={{ flex: 1, backgroundColor: 'white', margin: 10, borderRadius: 10 }}>
      <SafeAreaView style={{ flex: 1 }}>
        <SearchBox queryText={queryText} setQueryText={setQueryText} dialog={dialog} setDialog={setDialog} searchData={searchData} setSearchData={setSearchData} forAdmin={forAdmin} />
        <FlatList
          keyExtractor={(item) => item.id.toString()}
          data={dataList}
          style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '85vh' } : null]}
          onEndReached={(xxx) => loadData()}
          onEndReachedThreshold={0.5}
          onRefresh={() => loadData(0)}
          refreshing={(loading)}
          renderItem={({ item }) => (
            <Item item={item} onManageOrderPress={onManageOrderPress} QUERY_LIST={QUERY_LIST} />
          )}
        />
        <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
      </SafeAreaView>
    </View>
  )
}

function SearchBox({ queryText, setQueryText, dialog, setDialog, searchData, setSearchData, forAdmin }) {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const placeholder = `ค้นหาจาก หมายเลขใบสั่งจองสินค้า / ชื่อ-นามสกุล${forAdmin ? ' / สาขาที่ให้บริการ' : ''}`;

  useEffect(() => {
    setQueryText(searchText)
  }, [debouncedSearchTerm])

  return (
    <View style={{ marginHorizontal: 20, marginVertical: 10 }}>
      <TextInput
        value={searchText}
        onChangeText={text => setSearchText(text)}
        style={{ borderWidth: 1, borderColor: 'lightgray', borderRadius: 5, height: 35, paddingHorizontal: 5 }} 
        placeholder={placeholder} />

      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ตั้งแต่วันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateFrom}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateFrom: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ถึงวันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateTo}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateTo: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>

      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>สถานะ</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: true, type: 'statuslist' }))}>
            <View style={{
              flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{searchData?.status ?? 'ทั้งหมด'}</Text>
              <CustomIcon name="chevron-down" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

function Item({ item, onManageOrderPress, QUERY_LIST }) {
  return (
    <TouchableOpacity onPress={() => onManageOrderPress({ id: item.id })}>
      <View style={{
        paddingVertical: 10, borderBottomWidth: 1,
        borderColor: 'lightgray', paddingBottom: 15, marginHorizontal: 20,
      }}>
        <View style={{ flex: 1 }}>
          <Text style={{ color: themeColor.color1, fontWeight: 'bold' }}>{`หมายเลขใบสั่งจองสินค้า P${String(item?.id).padStart(5, '0')}`}</Text>
        </View>        
        {[
          //{ id: 1, title: 'หมายเลขใบสั่งซื้อยาล่วงหน้า', value: item?.id, textStyle: { color: themeColor.color1 } },
          { id: 2, title: 'ผู้รับบริการ', value: item?.user?.displayName ?? '', textStyle: {} },
          { id: 3, title: 'เบอร์โทร', value: item?.user?.mobileNumber ?? '', textStyle: {} },
          { id: 4, title: 'สาขาที่ให้บริการ', value: item?.shop?.shopThName, textStyle: {} },          
          { id: 5, title: 'สถานะ', value: QUERY_LIST.find(item2 => item2.value == item?.orderStatus)?.title, textStyle: {} },
          { id: 6, title: 'ยอดรวม', value: numberWithCommas(item?.orderPrice), textStyle: {} },
          { id: 7, title: 'วันและเวลาที่สั่งซื้อ', value: moment(item.createdAt).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
        ].map(item3 => (
          <View key={item3.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <Text style={item3?.textStyle}>{item3?.title}</Text>
            </View>
            <View style={{ flex: 0.1 }}>
              <Text style={item3?.textStyle}>:</Text>
            </View>
            <View style={{ flex: 0.5 }}>
              <Text style={item3?.textStyle}>{item3?.value}</Text>
            </View>
          </View>
        ))}

      </View>
    </TouchableOpacity>
  )
}

function StatusList({ dialog, setDialog, onBackDialog, dialogWidth, QUERY_LIST }) {
  let statusList = [{ id: 0, title: 'ทั้งหมด' }, ...QUERY_LIST];
  return (
    <View style={{ paddingHorizontal: 5, alignItems: 'center', marginTop: 20 }}>
      {statusList?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          onBackDialog({ ...item, type: 'statuslist' })
          setDialog(prev => ({ ...prev, visible: false }));
        }}>
          <View style={{
            width: dialogWidth - 30, backgroundColor: themeColor.color1, height: 30,
            justifyContent: 'center',
            paddingHorizontal: 10,
            marginVertical: 10, borderRadius: 5
          }}>
            <Text style={{ color: 'white' }}>{item?.title}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}
