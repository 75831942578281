import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Platform, StyleSheet, TextInput, TextInputProps, NativeSyntheticEvent, TextInputContentSizeChangeEventData } from 'react-native'
import { useCallbackOne } from 'use-memo-one'

const MIN_COMPOSER_HEIGHT = Platform.select({
  ios: 33,
  android: 41,
  web: 34,
})

const DEFAULT_PLACEHOLDER = 'Type a message...'

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
    lineHeight: 16,
    ...Platform.select({
      web: {
        paddingTop: 6,
        paddingLeft: 4,
      },
    }),
    marginTop: Platform.select({
      ios: 6,
      android: 0,
      web: 6,
    }),
    marginBottom: Platform.select({
      ios: 5,
      android: 3,
      web: 4,
    }),
  },
})


export function Composer({
  composerHeight = MIN_COMPOSER_HEIGHT,
  disableComposer = false,
  keyboardAppearance = 'default',
  multiline = true,
  onInputSizeChanged = () => { },
  onTextChanged = () => { },
  placeholder = DEFAULT_PLACEHOLDER,
  placeholderTextColor = '#b2b2b2',
  text = '',
  textInputAutoFocus = false,
  textInputProps = {},
  textInputStyle,
}) {
  const dimensionsRef = useRef()

  const determineInputSizeChange = useCallbackOne(
    (dimensions) => {
      if (!dimensions) {
        return
      }
      if (
        !dimensionsRef ||
        !dimensionsRef.current ||
        (dimensionsRef.current &&
          (dimensionsRef.current.width !== dimensions.width ||
            dimensionsRef.current.height !== dimensions.height))
      ) {
        dimensionsRef.current = dimensions
        onInputSizeChanged(dimensions)
      }
    },
    [onInputSizeChanged],
  )

  const handleContentSizeChange = ({
    nativeEvent: { contentSize },
  }) =>
    determineInputSizeChange(contentSize)

  return (
    <TextInput
      nativeID="chat_text_input1"
      testID={placeholder}
      accessible
      accessibilityLabel={placeholder}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor}
      multiline={multiline}
      editable={!disableComposer}
      onContentSizeChange={handleContentSizeChange}
      onChangeText={onTextChanged}
      style={[
        styles.textInput,
        textInputStyle,
        {
          height: composerHeight,
          ...Platform.select({
            web: {
              outlineWidth: 0,
              outlineColor: 'transparent',
              outlineOffset: 0,
            },
          }),
        },
      ]}
      autoFocus={textInputAutoFocus}
      value={text}
      enablesReturnKeyAutomatically
      underlineColorAndroid='transparent'
      keyboardAppearance={keyboardAppearance}
      {...textInputProps}
    />
  )
}

