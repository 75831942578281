import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, SafeAreaView, ScrollView, Dimensions, Modal, Alert, Linking, ActivityIndicator } from 'react-native';
import React, { useState, useEffect, useCallback, useContext, forwardRef, useRef, useImperativeHandle } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import { useCustomSafeAreaInsets } from '../component/custom';
import EventEmitter from "./EventEmitter";
import { useCustomNavigation } from '../component/custom';

export function PrintQrCodePart({ navigation, route }) {
  const { ordermainId } = route?.params;
  const goBack = (index) => navigation.goBack(index);
  return (
    <View style={{ flex: 1 }}>
      <PrintOrderView ordermainId={ordermainId} />
      <PrintAction ordermainId={ordermainId} goBack={goBack} />
    </View>
  )
}
export function PrintOrderView({ ordermainId }) {
  const { globalState, dispatch } = useContext(store);
  const [qrImage, setQrImage] = useState(null);
  const [qrText, setQrText] = useState('');
  const widthQrCode = '100%';//Dimensions.get('window').width > 300 ? '100%' : Dimensions.get('window').width;
  useEffect(() => {
    loadData()
  }, [ordermainId])

  const loadData = async () => {
    if (ordermainId) {
      const res1 = await globalState.client.service('orderitems').find({ query: { ordermainId, $limit: 200 } });
      const qrText = res1?.data?.map(item => {
        const tmpBarCode = item?.product?.barCodeUnit.split(',');
        return (`${item.amount}*${tmpBarCode[0]}`)
        // return (`${item.amount}*${item?.product?.barCodeUnit}`)
      }).join('\n');
      setQrText(qrText);
      const res2 = await fetch(`${server_var.server_api}/showQrCode`, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ qrText })
      });
      const resJson = await res2.json();
      setQrImage(resJson.result);
      console.log(resJson.result);

    }
  }
  return (
    qrImage ? (
      <View>
        <Image source={{ uri: qrImage }} style={{ width: widthQrCode, aspectRatio: 1 }} />
        <Text style={{ marginTop: 30, paddingHorizontal: 20 }}>{qrText}</Text>
      </View>
    ) : (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator color={'gray'} size="large" />
      </View>
    )
  )
}


function PrintAction({ ordermainId, goBack }) {
  const { globalState, dispatch } = useContext(store);
  const insets = useCustomSafeAreaInsets();
  const customNavigation = useCustomNavigation();
  // const [orderMain,setOrderMain] = useState()

  // useEffect(()=>{
  //   loadData()
  // },[ordermainId])

  // const loadData = async() => {
  //   const resOrderMain = await globalState.client.service('ordermains').get(ordermainId);
  //   setOrderMain(resOrderMain);
  // }

  const flagQrCodeComplete = async () => {
    let res;
    try {
      res = await globalState.client.service('ordermains').get(ordermainId, { query: { $select: ['stepJson'] } });
    } catch { }
    try {
      await globalState.client.service('ordermains').patch(ordermainId, { stepJson: { ...res.stepJson, step2: true } });
    } catch { }

    EventEmitter.notify("orderAction:refresh", ordermainId);
    if (Platform.OS != 'web') {
      customNavigation.goBack();
    } else {
      if (goBack) goBack();
    }
  }
  return (
    <View
      style={{
        position: 'absolute', left: 0, right: 0,
        bottom: insets.bottom, paddingHorizontal: 0, paddingTop: 10,
        backgroundColor: 'white', borderTopWidth: 1, borderColor: 'lightgray'
      }}>
      <TouchableOpacity
        onPress={() => flagQrCodeComplete()}
        style={{ marginVertical: 10, width: "70%", alignSelf: 'center' }}
      >
        <View style={{
          height: 40, borderRadius: 10, justifyContent: 'center',
          alignItems: 'center', backgroundColor: themeColor.color1
        }}>
          <Text style={{ color: 'white' }} >เสร็จแล้ว</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}


