import React from 'react';
import { Text, View, TouchableOpacity, Image, SafeAreaView, Dimensions, FlatList, ScrollView, Alert, StyleSheet, Platform, Linking, Modal } from 'react-native';
import { useState, useEffect, useContext, useCallback } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { GENDER_LIST } from '../sharewebapp/common1';
import { store } from '../sharewebapp/store'
import moment from "moment";
import { formatPhoneNumber, numberWithCommas } from './common1';
import SegmentedControlTab from './SegmentedControlTab';
import { CustomIcon } from '../component/custom';
import EventEmitter from './EventEmitter';
import { ORDER_STATUS, PRE_ORDER_STATUS, ORDER_VOID_STATUS } from '../sharewebapp/common1';
import { useCustomIsFocused, useCustomFocusEffect, CustomCommonActions } from '../component/custom';

export function ViewUserPart({ route, navigation }) {
  const [data, setData] = useState(null);
  const { globalState } = useContext(store);
  const [editMode, setEditMode] = useState(false);
  const isFocused = useCustomIsFocused();
  const [seedNum, setSeedNum] = useState();
  const [dialog, setDialog] = useState({ appId: 0, appDateTime: null, visible: false });
  let userId = route?.params?.userId;
  const viewInfo = route?.params?.viewInfo;
  const fromTab = route?.params?.fromTab;
  const toHistoryTab = route?.params?.toHistoryTab;
  // let { userId, viewInfo, fromTab, toHistoryTab } = route?.params
  if (!userId) {
    userId = globalState?.user?.id
  }

  useCustomFocusEffect(
    useCallback(() => {
      setSeedNum(Math.floor(Date.now() / 1000));
    }, [])
  );

  const onPharmaNoteAndOrderPress = (pharmaNoteId, ordermainId) => {
    navigation.navigate('ViewPharmaNoteOrderAppointment', { pharmaNoteId, ordermainId, showEditPharmaNote: true })
  }

  const onPharmaNotePress = (id) => {
    navigation.navigate('ViewPharmaNote', { pharmaNoteId: id })
  }

  const onOtherNotePress = (id) => {
    navigation.navigate('OtherNote', { othernoteId: id });
  }

  const onNewOtherNote = () => {
    navigation.navigate('OtherNote', { othernoteId: 0, userId });
  }

  const onOrderPress = (ordermainId, pharmaNoteId) => {
    if (ordermainId == 'preorder') {
      navigation.navigate('ViewPreOrder', { id: pharmaNoteId, forUser: true });
    } else {
      navigation.navigate('ViewOrder', { ordermainId, pharmaNoteId, showMailOrder: true });
    }
  }

  const onActionPress = () => {
    const params = {
      chatWithId: viewInfo?.viewItem?.userId, chatWithType: 'user',
      chatFromId: viewInfo?.viewItem?.shopId2, chatFromType: 'shop',
      queId: viewInfo?.viewItem?.id,
      action: { name: 'set_cc_tabindex', tabIndex: 0 }
    };
    if (Platform.OS == 'web') {
      navigation.setListPanel([
        { name: 'ComCenter', params: { ccTabIndex: 1, ccTabSeed: Math.floor(Date.now() / 1000) }, index: 0 },
        { name: 'Chat', params: params, index: 1 },
      ]);
    } else {
      const resetAction = CustomCommonActions?.reset({
        index: 1,
        routes: [
          { name: 'Main' },
          { name: 'Chat', params }
        ],
      });
      navigation.dispatch(resetAction);
    }

  }

  const onAppointmentPress = ({ shopId, pharmaNoteId, appId }) => {
    Linking.openURL(`allpharmasee://WaitQue?shopId=${shopId}&queType=from_appointment&fromPharmaNoteId=${pharmaNoteId}&queText=มาจากนัดหมาย&fromAppId=${appId}`);
  }

  const onEditProfilePress = () => {
    navigation.navigate('Profile')
  }


  const preOnActionPress = async () => {
    console.log('viewInfo', viewInfo?.viewItem);
    await globalState.client.service('quelines').patch(viewInfo?.viewItem?.id,
      { acceptStatus: 'accept', acceptByUserId: globalState?.user?.id }
    );
    onActionPress();
  }

  const VIEW_DEFINE = [
    {
      viewName: 'waiting_to_consult',
      component1: <ActionType actionText={viewInfo?.viewText} onActionPress={() => preOnActionPress()} />
    },
    {
      viewName: 'tab_user_history', retResult: [
        { id: 0, title: 'การปรึกษา' }, { id: 6, title: 'การนัดหมาย' }
      ]
    },
    {
      viewName: 'default', retResult: [
        { id: 8, title: 'ข้อมูลทั่วไป' }, { id: 1, title: 'ประวัติการปรึกษา' }, { id: 2, title: 'บันทึกภายใน' }
      ]
    }
  ]

  const tabList = VIEW_DEFINE.find(item => item.viewName == viewInfo?.viewName)
    ?.retResult
    ?? VIEW_DEFINE.find(item => item.viewName == 'default').retResult;

  const [tabIndex, setTabIndex] = useState(tabList[0]?.id);

  useEffect(() => {
    if (globalState?.user?.id == userId) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
    loadData();
  }, [userId, viewInfo, isFocused])

  useEffect(() => {
    if (toHistoryTab) {
      setTabIndex(toHistoryTab);
    }
  }, [toHistoryTab, seedNum])

  const loadData = async () => {
    const res = await globalState.client.service('users').get(userId);
    setData(res)
  }

  const onBackDialog = async (item) => {
    console.log('onBackDialog : ', JSON.stringify(dialog));
    if (item?.action == 'confirm') {
      const res = await globalState.client.service('appointments').patch(dialog.appId, { appStatus: 'cancel' });
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {!fromTab && (
        <View style={{ alignItems: 'center' }}>
          <Image source={{ uri: data?.avatar }} style={{ width: 120, height: 120, borderRadius: 60, marginTop: 20 }} />
          <Text style={{ marginTop: 20, marginBottom: 10 }}>{data?.firstName} {data?.lastName}</Text>
          <View style={{ paddingHorizontal: 15, paddingVertical: 2, borderRadius: 10, backgroundColor: themeColor.color1 }}>
            <Text style={{ color: 'white', fontSize: 12 }}>{(data?.allmemId) ? 'ALL member' : 'ผู้ใช้งานทั่วไป'}</Text>
          </View>
        </View>
      )}
      {(!editMode || viewInfo?.viewName == 'tab_user_history') && (
        <SegmentedControlTab
          values={tabList.map(item => item.title)}
          textNumberOfLines={1}
          selectedIndex={tabList.findIndex(item => item.id == tabIndex)}
          onTabPress={(index) => { setTabIndex(tabList[index].id) }}
          tabTextStyle={{ textAlign: 'center', color: themeColor.color1 }}
          tabsContainerStyle={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
          activeTabStyle={{ backgroundColor: themeColor.color1 }}
          tabStyle={{ borderColor: themeColor.color1 }}
        />
      )}
      {[0].includes(tabIndex) && <PharmaNoteList userId={userId} onPharmaNotePress={onPharmaNotePress} onOrderPress={onOrderPress} mode="user" seedNum={seedNum} />}
      {[1].includes(tabIndex) && <PharmaNoteList userId={userId} onPharmaNotePress={onPharmaNotePress} onOrderPress={onOrderPress} onPharmaNoteAndOrderPress={onPharmaNoteAndOrderPress} mode="pharmacist" seedNum={seedNum} />}
      {[6].includes(tabIndex) && <AppointmentList onAppointmentPress={onAppointmentPress} userId={userId} setDialog={setDialog} />}
      {[2].includes(tabIndex) && <OtherNoteList userId={userId} onOtherNotePress={onOtherNotePress} onNewOtherNote={onNewOtherNote} />}
      {[8].includes(tabIndex) && <GeneralInfo userId={userId} onEditProfilePress={onEditProfilePress} isFocused={isFocused} />}
      {VIEW_DEFINE.find(item => item.viewName == viewInfo?.viewName)?.component1}
      <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} />
    </SafeAreaView>
  )
}

function GeneralInfo({ userId, onEditProfilePress, isFocused }) {
  const { globalState } = useContext(store);
  const [data, setData] = useState();

  const SUB_FIELD_LIST = [
    {
      id: 10, type: 'com', name: '1', com: (
        <View key={"10"} style={{ flexDirection: 'row' }}>
          {[
            { id: 11, title: 'เพศ', field: 'gender', type: 'whiteview', style: { flex: 0.5 } },
            { id: 12, title: 'อายุ', field: 'birthDate', type: 'whiteview', unit: 'ปี', style: { flex: 0.5 } }
          ].map(item => (<GeneralInfoItem key={item.id.toString()} item={item} data={data} />))}
        </View>
      )
    },
    {
      id: 20, type: 'com', name: '2', com: (
        <View key={"20"} style={{ flexDirection: 'row' }}>
          {[
            { id: 21, title: 'ส่วนสูง', field: 'height', unit: 'ซม.', type: 'whiteview', style: { flex: 0.33 } },
            { id: 22, title: 'น้ำหนัก', field: 'weight', unit: 'กก.', type: 'whiteview', style: { flex: 0.33 } },
            { id: 23, title: 'ค่า BMI', field: 'bmi', type: 'whiteview', style: { flex: 0.33 } },
          ].map(item => (<GeneralInfoItem key={item.id.toString()} item={item} data={data} />))}
        </View>
      )
    },
    {
      id: 30, type: 'com', name: '2', com: (
        <GeneralInfoItem key={'31'} item={{ id: 1, title: 'ประวัติแพ้ยา/อาหาร', type: 'whiteview', field: 'drugAllergy', style: { borderTopWidth: 1, borderColor: 'lightgray', paddingTop: 5, marginTop: 10 }, textStyle: { color: 'black' } }} data={data} />
      )
    },
    {
      id: 40, type: 'com', name: '2', com: (
        <GeneralInfoItem key={'41'} item={{ id: 1, title: 'โรคประจำตัว', type: 'whiteview', field: 'congenitalDisease', style: { borderTopWidth: 1, borderColor: 'lightgray', paddingTop: 5, marginTop: 10, }, textStyle: { color: 'black' } }} data={data} />
      )
    },
    {
      id: 50, type: 'com', name: '2', com: (
        <GeneralInfoItem key={'51'} item={{ id: 1, title: 'ยาและอาหารเสริมที่รับประทานเป็นประจำ', type: 'whiteview', field: 'frequentDrug', style: { borderTopWidth: 1, borderColor: 'lightgray', paddingTop: 5, marginTop: 10, }, textStyle: { color: 'black' } }} data={data} />
      )
    },
    {
      id: 60, type: 'com', name: '3', com: (
        <GeneralInfoItem key={'61'} item={{ id: 1, title: 'เบอร์โทรศัพท์มือถึอ', type: 'whiteview', field: 'mobileNumber', style: {}, textStyle: { color: 'black' } }} data={data} />
      )
    },
    {
      id: 70, type: 'com', name: '3', com: (
        <View key={'71'} style={{ borderTopWidth: 1, borderColor: 'lightgray', paddingTop: 5, marginTop: 10 }}>
          <Text style={{ color: 'gray' }}>ที่อยู่จัดส่ง</Text>
          {data?.locationList.map(item2 => (
            <View key={item2.id.toString()} style={{ flexDirection: 'row', marginVertical: 5 }}>
              <View style={{ flex: 0.1 }}>
                <CustomIcon name="circle-medium" size={20} color={'gray'} />
              </View>
              <View style={{ flex: 0.9 }}>
                <Text>{item2.mainAddressFull}</Text>
                {item2.detailAddress != null ? <Text>{item2.detailAddress}</Text> : <View />}
                {item2.otherAddress != null ? <Text>{item2.otherAddress}</Text> : <View />}
              </View>
            </View>
          ))}
        </View >
      )
    }
  ]

  const FIELD_LIST = [
    { id: 100, title: 'ข้อมูลทั่วไป', type: 'header' },
    { id: 110, type: 'container', name: '1', com: SUB_FIELD_LIST.filter(item => (item.name == '1'))?.map(item => item.com) },
    { id: 200, title: 'ข้อมูลสุขภาพ', type: 'header' },
    { id: 210, type: 'container', name: '2', com: SUB_FIELD_LIST.filter(item => (item.name == '2'))?.map(item => item.com) },
    { id: 300, title: 'ข้อมูลติดต่อ', type: 'header' },
    { id: 310, type: 'container', name: '3', com: SUB_FIELD_LIST.filter(item => (item.name == '3'))?.map(item => item.com) },
    { id: 400, title: 'แก้ไข', type: 'button', visible: globalState?.user?.id == userId ? true : false, onPress: () => onEditProfilePress() },
  ]

  const loadData = async () => {
    const res = await globalState.client.service('users').get(userId);
    const resLocation = await globalState.client.service('locations').find({ query: { userId } });
    setData({ ...res, locationList: resLocation.data });
  }

  useEffect(() => {
    loadData()
  }, [isFocused])

  return (
    <ScrollView style={[
      { paddingHorizontal: 10, marginTop: 10 },
      Platform.OS == 'web' ? { height: Dimensions.get('window').height / 2 } : null
    ]}
    >
      <View>
        {FIELD_LIST.map(item => <GeneralInfoItem key={item.id.toString()} item={item} data={data} />)}
      </View>
    </ScrollView>
  )
}

function GeneralInfoItem({ item, data }) {
  if (item.type == 'header') {
    return (
      <View>
        <Text style={{ color: themeColor.color1 }}>{item.title}</Text>
      </View>
    )
  } else if (item.type == 'container') {
    return (
      <View style={{ backgroundColor: 'white', marginVertical: 10, padding: 10, borderRadius: 5 }}>
        {item.com}
      </View>
    )
  } else if (item.type == 'whiteview') {
    return (
      <View style={item.style}>
        <View>
          <Text style={{ color: 'gray' }}>{item.title}</Text>
          <Text style={[{ color: themeColor.color1, marginTop: 5 }, item?.textStyle]}>
            {getData(data, item?.field)} {item?.unit}
          </Text>
        </View>
      </View>
    )
  } else if (item.type == 'button') {
    if (item.visible) {
      return (
        <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center' }} onPress={() => item.onPress()}>
          <View style={{ height: 40, width: Dimensions.get('window').width - 60, backgroundColor: themeColor.color1, borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 60 }}>
            <Text style={{ color: 'white' }}>{item.title}</Text>
          </View>
        </TouchableOpacity>
      )
    }
  }
}

function getData(data, field) {
  if (['weight', 'height'].includes(field)) {
    const value = parseFloat(data?.[field]).toFixed(0);
    return isNaN(value) ? '' : value;
  } else if ('bmi' == field) {
    const value = (data?.weight / Math.pow(data?.height / 100, 2)).toFixed(2);
    return isNaN(value) ? '' : value;
  } else if ('gender' == field) {
    return GENDER_LIST.find(item => item.value == data?.[field])?.title
  } else if ('birthDate' == field) {
    return moment().diff(data?.[field], 'years');
  } else if ('mobileNumber' == field) {
    return data?.[field] ? formatPhoneNumber(data?.[field]) : '';
  } else if ('locationList' == field) {
    return JSON.stringify(data?.[field], '', 2);
  } else {
    return data?.[field]
  }
}

function OtherNoteList({ userId, onOtherNotePress, onNewOtherNote }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    loadDataList(0);
    EventEmitter.addListener('refreshOtherNoteList', refreshData);
    return () => {
      EventEmitter.removeListener('refreshOtherNoteList', refreshData);
    };
  }, [userId])

  const refreshData = async () => {
    loadDataList(0);
  }

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res;
      try {
        res = await globalState.client.service('othernotes').find({
          query: {
            userId: userId,
            $skip: pSkip ?? skip, $sort: { id: -1 }
          }
        });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }

  }
  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      {(!dataList || dataList.length == 0) && (
        <View style={{
          position: 'absolute', top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', left: 0, right: 0,
        }}>
          <Text style={{ color: 'gray' }}>ยังไม่มีรายการ</Text>
        </View>
      )}
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (<OtherNoteItem item={item} onOtherNotePress={onOtherNotePress} />)}
      />
      <TouchableOpacity style={{ position: 'absolute', bottom: 200, right: 20 }} onPress={onNewOtherNote}>
        <View
          style={{ width: 50, height: 50, borderRadius: 25, backgroundColor: themeColor.color1, justifyContent: 'center', alignItems: 'center' }}
        >
          <CustomIcon name="plus" color="white" size={25} />

        </View>

      </TouchableOpacity>
    </View>
  )
}
function OtherNoteItem({ item, onOtherNotePress }) {
  return (
    <TouchableOpacity style={{ marginVertical: 10 }} onPress={() => onOtherNotePress(item.id)}>
      <View style={{
        marginHorizontal: 20,
        flexDirection: 'row', borderBottomWidth: 1,
        borderColor: 'lightgray', paddingBottom: 10
      }}>
        <View style={{ flex: 0.3 }}>
          <Text>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <Text>{item.notes}</Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

function PharmaNoteList({ userId, onPharmaNotePress, onOrderPress, mode, onPharmaNoteAndOrderPress, seedNum }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [seedNum2, setSeedNum2] = useState();

  useEffect(() => {
    loadDataList(0);
  }, [userId, seedNum])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      setSeedNum2(Math.floor(Date.now() / 1000));
      let res;
      try {
        res = await globalState.client.service('pharmanotes').find({
          query: {
            userId: userId,
            // $or: [{ status: null }, { status: 'done' }],
            // status: { $in: [null, 'done', 'cancel_by_system', 'cancel_by_user'] },
            $skip: pSkip ?? skip, $sort: { id: -1 }
          }
        });
      } catch { }

      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }
  return (
    <View style={{ flex: 1, flexDirection: 'column', paddingTop: 10 }}>
      {(!dataList || dataList.length == 0) && (
        <View style={{
          position: 'absolute', top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', left: 0, right: 0,
        }}>
          <Text style={{ color: 'gray' }}>ยังไม่มีรายการ</Text>
        </View>
      )}
      <FlatList
        style={Platform.OS == 'web' ? { height: '50vh' } : null}
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.8}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          [
            {
              mode: 'user',
              com: <PharmaNoteUserItem item={item} onPharmaNotePress={onPharmaNotePress} onOrderPress={onOrderPress} seedNum2={seedNum2} />
            },
            {
              mode: 'pharmacist',
              com: <PharmaNotePharmacistItem item={item} onPharmaNotePress={onPharmaNotePress} onOrderPress={onOrderPress} onPharmaNoteAndOrderPress={onPharmaNoteAndOrderPress} />
            },
          ].find(item2 => item2.mode == mode)?.com
        )}
      />
    </View>
  )
}

function PharmaNotePharmacistItem({ item, onPharmaNotePress, onOrderPress, onPharmaNoteAndOrderPress }) {
  return (
    <TouchableOpacity style={{ marginVertical: 5, marginHorizontal: 20, }} onPress={() => onPharmaNoteAndOrderPress(item.id, item?.oldqueline?.ordermain?.id)}>
      <View style={{
        flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottomColor: 'lightgray', borderBottomWidth: 1, paddingBottom: 10
      }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text>{moment(item.createdAt).local().format('DD/MM/YYYY')}</Text>
          <Text style={{ marginLeft: 10 }}>{item.symtom}</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {[
            { id: 1, iconName: 'note-outline', visible: true },
            { id: 2, iconName: 'basket', visible: item?.oldqueline?.ordermain?.id },
          ].map(item2 => {
            if (item2.visible) {
              return (
                <View key={item2.id.toString()}
                  style={{
                    width: 24, height: 24, borderRadius: 12, backgroundColor: themeColor.color1,
                    justifyContent: 'center', alignItems: 'center', marginHorizontal: 5
                  }}>
                  <CustomIcon name={item2.iconName} size={16} color="white" />
                </View>
              )
            } else {
              return <View key={item2.id.toString()} style={{ width: 24, height: 24, marginHorizontal: 5 }} />
            }
          })}
        </View>
      </View>
    </TouchableOpacity>

  )
}

function PharmaNoteUserItem({ item, onPharmaNotePress, onOrderPress, seedNum2 }) {
  const { globalState } = useContext(store);
  const [preOrderId, setPreOrderId] = useState(0);
  const [preOrderStatus, setPreOrderStatus] = useState(null);

  useEffect(() => {
    loadPreOrders();
  }, [item?.queId, seedNum2])

  const loadPreOrders = async () => {
    let query = { queId: item?.queId };
    const resPreOrders = await globalState.client.service('preorders').find({ query });
    console.log('resPreOrders : ', resPreOrders);
    if (resPreOrders.total > 0) {
      console.log('resPreOrders id : ', resPreOrders.data[0].id);
      setPreOrderId(resPreOrders.data[0].id);
      setPreOrderStatus(resPreOrders.data[0].orderStatus);
    } else {
      setPreOrderId(0);
      setPreOrderStatus(null);
    }
  }

  return (
    < View style={{ marginHorizontal: 20, backgroundColor: 'white', marginVertical: 10, borderRadius: 10 }}>
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, marginBottom: 10, flex: 1 }}>
        <View style={{ flex: 0.2, paddingHorizontal: 10, paddingVertical: 10 }}>
          <Image
            style={{ width: 40, height: 40 }}
            source={item?.avatar ? { uri: item?.shop?.shopPhoto1 } : { uri: `${server_var.server_api}/images/exta_logo_sq1.png` }}
          />
        </View>
        <View style={{ flex: 0.8, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, fontSize: 12, color: themeColor.color1, fontWeight: 'bold' }}>การปรึกษา {item.id}</Text>
          <Text style={{ flex: 1, fontSize: 12 }}>{item?.shop?.shopThName}</Text>
        </View>
      </View>

      {[
        { id: 1, title: 'วันเวลาที่รับบริการ', field: (item?.startTime) ? moment(item.startTime).format('DD/MM/YYYY HH:mm') : '' },
        { id: 2, title: item?.shop?.shopType == 'special1' ? 'ผู้ให้บริการ' : 'เภสัชกร', field: item?.pharmacist?.displayName },
        //{ id: 3, title: 'ร้านยา', field: `${item?.shop?.shopThName}` },
        { id: 4, title: 'สถานะการสั่งซื้อ', field: (item?.oldqueline?.ordermain?.orderVoidStatus ? ORDER_VOID_STATUS.find(item6 => item6.value == item?.oldqueline?.ordermain?.orderVoidStatus)?.title : (item?.oldqueline?.ordermain?.paymentPartialStatus == 'reotp' ? 'รอยืนยัน OTP อีกครั้ง' : ORDER_STATUS.find(item6 => item6.value == item?.oldqueline?.ordermain?.orderStatus)?.title)) ?? 'ไม่มี' },
        { id: 5, title: 'สถานะสินค้าสั่งจอง', field: (PRE_ORDER_STATUS.find(item7 => item7.value == preOrderStatus)?.title) ?? 'ไม่มี' },
      ].map(item5 => (
        <View key={item5.id.toString()} style={{ flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10 }}>
          <Text style={{ flex: 0.4, fontSize: 12 }}>{item5.title}</Text>
          <Text style={{ flex: 0.05 }}>:</Text>
          <Text style={{ flex: 0.6, fontSize: 12 }}>{item5.field}</Text>
        </View>
      ))}

      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 10, flex: 1 }}>
        {[
          {
            id: 1, title: `คำแนะนำจาก${item?.shop?.shopType == 'special1' ? 'ผู้ให้บริการ' : 'เภสัชกร'}`,
            buttonProp: { borderColor: item.status == 'done' ? themeColor.color1 : 'lightgray' },
            textProp: { color: item.status == 'done' ? themeColor.color1 : 'gray' },
            onPress: () => {
              if (item.status == 'done') {
                onPharmaNotePress(item.id)
              }
            }
          },
          server_var.appMode == 'commerce' ?
            {
              id: 2, title: 'คำสั่งซื้อ',
              buttonProp: { borderColor: item?.oldqueline?.ordermain?.id ? themeColor.color1 : 'lightgray' },
              textProp: { color: item?.oldqueline?.ordermain?.id ? themeColor.color1 : 'gray' },
              onPress: () => item?.oldqueline?.ordermain?.id ? onOrderPress(item?.oldqueline?.ordermain?.id, item.id) : null
            } :
            {
              id: 2, title: '',
              buttonProp: { borderColor: item?.oldqueline?.ordermain?.id ? themeColor.color1 : 'lightgray' },
              textProp: { color: item?.oldqueline?.ordermain?.id ? themeColor.color1 : 'gray' },
              onPress: () => console.log('n/a')
            },
          server_var.appMode == 'commerce' ?
            {
              id: 3, title: 'สินค้าสั่งจอง',
              buttonProp: { borderColor: preOrderId > 0 ? themeColor.color1 : 'lightgray' },
              textProp: { color: preOrderId > 0 ? themeColor.color1 : 'gray' },
              onPress: () => preOrderId > 0 ? onOrderPress('preorder', preOrderId) : null
            } :
            {
              id: 3, title: '',
              buttonProp: { borderColor: preOrderId > 0 ? themeColor.color1 : 'lightgray' },
              textProp: { color: preOrderId > 0 ? themeColor.color1 : 'gray' },
              onPress: () => console.log('n/a')
            },
        ].map(item5 => (
          <TouchableOpacity key={item5.id.toString()} style={{ marginBottom: 5, alignSelf: 'center', width: '30%' }} onPress={() => item5.onPress()} >
            <View style={[{
              height: 40, borderRadius: 6, borderWidth: 1,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: 'white'
            }, item5.buttonProp]}>
              <Text style={[{ color: themeColor.color1, fontSize: 10, textAlign: 'center' }, item5.textProp]}>{item5.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View >
  )
}

function ActionType({ actionText, onActionPress }) {
  return (
    <View style={{ bottom: 0, alignSelf: 'center', width: "50%" }}>
      <Text style={{ marginTop: 5, textAlign: 'center' }}>{actionText}</Text>
      <TouchableOpacity style={{ marginTop: 10 }} onPress={() => onActionPress()}>
        <View style={{
          height: 40, backgroundColor: themeColor.color1,
          justifyContent: 'center', alignItems: 'center', borderRadius: 10
        }}
        >
          <Text style={{ color: 'white' }}>รับปรึกษา</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

function AppointmentList({ onAppointmentPress, userId, setDialog }) {
  const { globalState } = useContext(store);
  const [appList, setAppList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onSocketListener);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onSocketListener);
    };
  }, []);

  useEffect(() => {
    loadAppList()
  }, [])

  const onSocketListener = (payload) => {
    if (payload.toUserId == globalState?.user?.id && payload.type == 'RefreshAppointment') {
      loadAppList();
    }
  }

  const loadAppList = async (paramSkip) => {
    if (!loading) {
      setLoading(true);
      let res;
      try {
        res = await globalState.client.service('appointments').find({
          query: {
            userId,
            appDateTime: { $ne: null },
            $skip: paramSkip ?? skip, $sort: { id: -1 }
          }
        });
      } catch { }
      if (res) {
        if ((paramSkip ?? skip) === 0) {
          setAppList(res.data);
        } else {
          setAppList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      {(!appList || appList.length == 0) && (
        <View style={{
          position: 'absolute', top: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', left: 0, right: 0
        }}>
          <Text style={{ color: 'gray' }}>ยังไม่มีรายการนัดหมาย</Text>
        </View>
      )}
      <FlatList
        keyExtractor={(item) => (item.id.toString())}
        data={appList}
        onEndReached={(xxx) => loadAppList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadAppList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          <AppointmentItem item={item} onAppointmentPress={onAppointmentPress} setDialog={setDialog} />
        )}
      />
    </View>
  )
}

function AppointmentItem({ item, onAppointmentPress, setDialog }) {
  const { globalState } = useContext(store);
  const appDateTime = moment(item.appDateTime).local().format('DD/MM/YYYY HH:mm');

  const onCancelAppointment = () => {
    setDialog({ appId: item.id, appDateTime: appDateTime, visible: true });

    // const cancelAction = () => {
    //   globalState.client.service('appointments').patch(item.id, { appStatus: 'cancel' });
    // }
    // if (Platform.OS == 'web' && window.confirm('ต้องการยกเลิกนัดหมาย')) {
    //   cancelAction();
    // } else {
    //   Alert.alert('ต้องการยกเลิกนัดหมาย', '', [
    //     { text: 'ไม่ยกเลิก', onPress: () => console.log('hello') },
    //     { text: 'ยืนยันยกเลิก', onPress: async () => cancelAction() },
    //   ])
    // }
  }

  return (
    < View style={{
      marginHorizontal: 20, backgroundColor: 'white',
      marginVertical: 10, borderRadius: 10
    }}>
      <Text style={{ color: themeColor.color1, paddingHorizontal: 10, marginTop: 10, marginBottom: 10 }}>
        วันและเวลานัดหมาย {moment(item.appDateTime).local().format('DD/MM/YYYY HH:mm')}
      </Text>
      {[
        { id: 1, title: 'เภสัชกร', field: `เภสัชกร ${item?.pharmacist?.displayName}` },
        { id: 2, title: 'ร้านยา', field: `${item?.shop?.shopThName}` },
        {
          id: 3, title: 'สถานะการนัดหมาย',
          field: [
            { id: 0, title: 'ยืนยันนัดหมาย', value: null },
            { id: 1, title: 'สำเร็จ', value: 'done' },
            { id: 2, title: 'ยกเลิกนัดหมาย', value: 'cancel' },
          ].find(item2 => item2.value == item?.appStatus)?.title
        }
      ].map(item5 => (
        <View key={item5.id.toString()} style={{ flexDirection: 'row', alignItems: 'flex-start', paddingHorizontal: 10 }}>
          <Text style={{ flex: 0.4, fontSize: 12 }}>{item5.title}</Text>
          <Text style={{ flex: 0.05 }}>:</Text>
          <Text style={{ flex: 0.6, fontSize: 12 }}>{item5.field}</Text>
        </View>
      ))
      }
      <View>
        <Text style={{ fontSize: 11, color: themeColor.color6, marginTop: 10, paddingHorizontal: 10 }}>
          *หากไม่ได้ "ปรึกษาเภสัชกร" ภายในเวลา 15 นาที การนัดหมายจะถูกยกเลิก
        </Text>
      </View>

      <View style={{
        height: 30, marginTop: 10, backgroundColor: themeColor.color1,
        flexDirection: 'row', borderBottomRightRadius: 10, borderBottomLeftRadius: 10
      }}>
        {[
          {
            id: 0, title: 'ยกเลิกนัดหมาย',
            buttonProp: { backgroundColor: canCancel(item) ? themeColor.color6 : 'lightgray', borderBottomLeftRadius: 10 },
            textProp: { color: canCancel(item) ? 'white' : 'gray' },
            onPress: () => canCancel(item) ? onCancelAppointment() : null
          },
          {
            id: 1, title: 'ปรึกษาเภสัชกร',
            buttonProp: { backgroundColor: canConsult(item) ? themeColor.color1 : 'lightgray', borderBottomRightRadius: 10 },
            textProp: { color: canConsult(item) ? 'white' : 'gray' },
            onPress: () => canConsult(item) ? onAppointmentPress({ shopId: item.shopId, pharmaNoteId: item?.pharmanote?.id, appId: item?.id }) : null
          },
        ].map(item5 => (
          <TouchableOpacity
            key={item5.id.toString()}
            style={[{ flex: 0.5, justifyContent: 'center', alignItems: 'center' }, item5.buttonProp]}
            onPress={() => item5.onPress()}
          >
            <Text style={[{ fontSize: 12 }, item5.textProp]}>{item5.title}</Text>
          </TouchableOpacity>
        ))
        }
      </View>
    </View>
  )
}

function canCancel(item) {
  const { validExpired, validStart, appDateTime } = item;
  if (item.appStatus == 'done') {
    return false;
  } if (validExpired && validStart && appDateTime) {
    return !moment().isAfter(validExpired) && item.appStatus != 'cancel';
  }
  return false;
}

function canConsult(item) {
  const { validExpired, validStart, appDateTime } = item;
  if (item.appStatus == 'done') {
    return false
  } else if (validExpired && validStart && appDateTime) {
    return moment().isAfter(validStart) && moment().isBefore(validExpired) && item.appStatus != 'cancel';
  }
  return false
}

function Dialog({ dialog, setDialog, onBackDialog }) {
  const { globalState, dispatch } = useContext(store);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30, justifyContent: "flex-start",
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <ScrollView>
            <View style={{ marginVertical: 20, alignItems: 'center', paddingHorizontal: 20 }}>
              <Image
                // source={require('../assets/images/exclamation1.png')}
                source={{ uri: `${server_var.server_api}/images/exclamation1.png` }}
                style={{ width: 160, height: 160, alignSelf: 'center' }}
                resizeMode="contain"
              />
              <Text style={{ textAlign: 'center', color: 'gray', marginTop: 20 }}>
                ท่านต้องการยกเลิกนัดหมายกับเภสัชกร
              </Text>
              <Text style={{ textAlign: 'center', color: 'gray' }}>
                วันและเวลานัดหมาย {dialog?.appDateTime}
              </Text>
            </View>
          </ScrollView>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '80%' }}
              onPress={() => {
                setDialog(prev => ({ ...prev, visible: false }));
                onBackDialog({ action: 'confirm' });
              }}>
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
              }}>
                <Text style={{ color: 'white' }}>ยืนยันการยกเลิกนัดหมาย</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

