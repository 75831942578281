import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { ProductList } from './basketpart';

export function ManageProductPart({ navigation }) {
  const onPressViewProduct = (productId) => {
    navigation.navigate('ViewProduct', { productId, hideButton: true })
  }

  return (
    <View style={{ flex: 1 }}>
      <ProductList hideListButton={true} onPressViewProduct={onPressViewProduct} />
    </View>
  )
}
