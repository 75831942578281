import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { uploadPhoto, uploadFile, CustomIcon } from '../component/custom';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomVideo } from '../component/custom';

export function EditPromotionPart({ navigation, route }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });

  const FIELD_LIST = [
    {
      id: 1, type: 'text', title: 'หัวข้อ', titleStyle: { marginTop: 10 }, fieldName: 'title', value: data?.title, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 2, type: 'text', title: 'ลิงค์', titleStyle: { marginTop: 10 }, fieldName: 'feedLink', value: data?.feedLink, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 3, type: 'text', title: 'การแสดงลำดับที่', titleStyle: { marginTop: 10 }, fieldName: 'feedScore', value: data?.feedScore?.toString(), textInputStyle: { height: 40, marginTop: 5 }, textInputProp: { keyboardType: 'number-pad' }
    },
    {
      id: 4, type: 'image', title: 'เพิ่มรูปภาพแบนเนอร์ที่หน้าจอหลัก\nขนาด : กว้าง 1367 Pixels x สูง 575 Pixels', titleStyle: { marginTop: 10 }, fieldName: 'feedPhoto1', value: data?.feedPhoto1, mode: 'banner'
    },
    {
      id: 5, type: 'image', title: 'เพิ่มรูปภาพรายละเอียด\nขนาด : กว้าง 800 Pixels x สูง 1200 Pixels', titleStyle: { marginTop: 10 }, fieldName: 'feedPhoto2', value: data?.feedPhoto2, mode: 'poster'
    },    
  ]

  useEffect(() => {
    if (route?.params?.id > 0) {
      loadData();
    }
  }, [route?.params?.id])

  const loadData = async () => {
    let query = {
      id: route?.params?.id,
      $select: ['id', 'title', 'feedLink', 'feedScore', 'feedPhoto1', 'feedPhoto2']
    };
    const res = await globalState.client.service('feeds').find({ query });
    //console.log('\nPromoBanner : ', route?.params?.id, res);
    if (res.total == 0) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูลโปรโมชั่นนี้' }, });
      navigation.goBack(2);
    }
    setData(res.data[0]);
  }

  const onDelete = async (id) => {
    const delAction = async () => {
      await globalState.client.service('feeds').remove(id);
      dispatch({ type: 'showToast', payload: { text: 'ลบข้อมูลเรียบร้อย' } });
      EventEmitter.notify('Promotion:refresh');
      navigation.goBack(2);
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบโปรโมชั่นนี้ ?')) {
      delAction()
    } else {
      return Alert.alert('ลบโปรโมชั่น', 'คุณต้องการลบโปรโมชั่นนี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }
  }

  const validateForm = () => {
    const blankField = ['title', 'feedScore', 'feedPhoto1', 'feedPhoto2'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    let res;
    if (id == 0) {
      res = await globalState.client.service('feeds').create({
        userId: globalState.user.id,
        title: data?.title,
        feedType: 'PromoBanner', 
        feedPublic: 1, 
        feedLink: data?.feedLink,
        feedScore: data?.feedScore,
        feedPhoto1: data?.feedPhoto1,
        feedPhoto2: data?.feedPhoto2
      });
    } else {
      res = await globalState.client.service('feeds').patch(id, {
        userId: globalState.user.id,
        title: data?.title,
        feedLink: data?.feedLink,
        feedScore: data?.feedScore,
        feedPhoto1: data?.feedPhoto1,
        feedPhoto2: data?.feedPhoto2
      });
    }
    setLoading(false);
    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' } });
    EventEmitter.notify('Promotion:refresh');
    navigation.goBack(2);
  }



  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <ScrollView contentContainerStyle={[
        { paddingBottom: 260, paddingHorizontal: 20 }, Platform.OS == 'web' ? { height: '80vh' } : null
      ]}>
        {
          FIELD_LIST.map(item =>
            <CustomItem key={item.id.toString()} item={item} setData={setData} error={error} />
          )}

        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 30, flex: 1 }}>
          {(route?.params?.id != 0) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onDelete(route?.params?.id)} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
              }}>
                <Text style={{ color: 'white' }}>ลบ</Text>
              </View>
            </TouchableOpacity>
          }
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onSave(route?.params?.id)} >
            <View style={{
              height: 40, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
            }}>
              <Text style={{ color: 'white' }}>บันทึก</Text>
            </View>
          </TouchableOpacity>
        </View>

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
      {/* </TouchableWithoutFeedback> */}
    </SafeAreaView>
  )
}

function CustomItem({ item, setData, error }) {
  if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'image') {
    return <CustomImage item={item} setData={setData} error={error} viewMode={false} />
  }
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }, error?.at == item.fieldName && { borderColor: 'red' }]}
      />
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}

function CustomImage({ item, setData, error, viewMode }) {
  const fileRef1 = useRef();
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('banner', false, 'photolib', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลดรูปภาพ');
    }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item2 of images) {
      setData(prev => ({ ...prev, [item.fieldName]: `${server_var.server_api}/images/feed/${item2.filename}` }))
    }
  }

  if (item?.value) {
    return (
      <View>
        <View style={{ marginTop: 10 }}>
          { item?.mode == 'banner' &&
            <Image
            resizeMode="contain"
            source={{ uri: item?.value }}
            style={{
              borderRadius: 15,
              width: (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : (Dimensions.get('window').width - 40),
              height: (Platform.OS == 'web') ? Dimensions.get('window').width / 10 : ((Dimensions.get('window').width - 20) / 2.3) - 5,
              marginVertical: 10 }} />    
          }     
          { item?.mode == 'poster' &&
            <Image
            resizeMode="contain"
            source={{ uri: item?.value }}
            style={{ borderRadius: 15, maxWidth: '100%', aspectRatio: 3 / 2 }} />           
          }
          {!viewMode &&
            <TouchableOpacity
              style={{ position: "absolute", top: 0, left: 0 }}
              onPress={() => {
                setData(prev => ({ ...prev, [item.fieldName]: '' }))
              }}
            >
              <View style={{ marginTop: 0, marginLeft: 0 }}>
                <CustomIcon name="close-circle" size={20} color={"red"} />
              </View>
            </TouchableOpacity>
          }
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <TouchableOpacity
          style={{
            borderColor: error?.at == item.fieldName ? "red" : "gray", borderWidth: 1, borderRadius: 6, width: '100%', height: 100,
            marginTop: 10, justifyContent: "center", alignItems: "flex-start", padding: 5
          }}
          onPress={() => {
            if (!viewMode) {
              if (Platform.OS == 'web') {
                fileRef1.current.click();
              } else {
                uploadPhoto2('')
              }
            }
          }}
        >
          <View style={{ flexDirection: 'row', paddingHorizontal: 10, justifyContent: "center" }} >
            <CustomIcon name="plus-circle-outline" size={40} color="gray" />
            <Text style={{ color: "gray", paddingHorizontal: 10 }}>{item.title}</Text>
          </View>
        </TouchableOpacity>
        {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".jpg, .png" />
            </div>
          )
        }
      </View>
    )
  }
}
