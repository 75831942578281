import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import moment from 'moment';

export function ConsultVideoListPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    if (globalState.user?.id) {
      loadDataList(0);
    }
  }, [])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let query = { userId: globalState.user?.id, expiredDate: { $gte: moment().utc().format('YYYY-MM-DD HH:mm:ss') }, $skip: pSkip ?? skip, $sort: { updatedAt: -1 }, $limit: 20 };
      let res;
      try {
        res = await globalState.client.service('authenlogs').find({ query });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {(!dataList || dataList.length == 0) && (
        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: 'gray' }}>ไม่มีข้อมูลการเข้าถึงแชท/วิดีโอการปรึกษา</Text>
        </View>
      )}
      <FlatList
        contentContainerStyle={{ justifyContent: 'center' }}
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item, index }) => (
          <DataItem item={item} navigation={navigation} />
        )}
      />
      {loading &&
        <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size='large' />
        </View>
      }
    </SafeAreaView>
  )
}

function DataItem({ item, navigation }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5 }}
      onPress={() => {
        if (item?.mediaType == 'video') {
          navigation.navigate('ViewConsultVideo', { id: item?.authenValue?.id });
        } else {
          const paramChat = {
            queId: item?.authenValue?.id,
            viewhistory: true,
            fakeUserId: item?.authenValue?.pharmaId,
          }
          navigation.navigate('Chat', paramChat);
        }
      }}
    >
      <View style={{ borderBottomWidth: 1, borderBottomColor: 'lightgray', marginHorizontal: 20, paddingBottom: 10 }}>
        {[
          { id: 1, title: `หมายเลข${(item?.mediaType == 'video') ? 'วิดีโอ' : 'แชท'}`, value: item?.authenValue?.id, textStyle: { color: themeColor.color1 } },
          { id: 2, title: 'สาขาที่ให้บริการ', value: item?.authenValue?.shopThName, textStyle: {} },
          { id: 3, title: 'ผู้ให้บริการ', value: item?.authenValue?.pharmaName ?? '', textStyle: {} },
          { id: 4, title: 'ผู้รับบริการ', value: item?.authenValue?.userName ?? '', textStyle: {} },
          { id: 5, title: 'วันและเวลาที่รับบริการ', value: moment(item?.authenValue?.consultDate).format('DD/MM/YYYY HH:mm'), textStyle: {} },
          { id: 6, title: 'สิทธิ์หมดอายุ', value: moment(item?.expiredDate).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
        ].map(item3 => (
          <View key={item3.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <Text style={item3?.textStyle}>{item3?.title}</Text>
            </View>
            <View style={{ flex: 0.1 }}>
              <Text style={item3?.textStyle}>:</Text>
            </View>
            <View style={{ flex: 0.5 }}>
              <Text style={item3?.textStyle}>{item3?.value}</Text>
            </View>
          </View>
        ))}
      </View>
    </TouchableOpacity>
  )
}

