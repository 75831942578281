import React, { useState, useCallback, useEffect, useContext } from 'react'
import { TouchableOpacity, Text, View, FlatList, Image, ScrollView } from 'react-native'
import { themeColor, fontStyles, server_var } from '../config/servervar';
import { store } from "../sharewebapp/store";
import moment from 'moment';
import { getJwtToken } from '../component/custom';

export function MessageListPart({ tabIndex, refresh, onPress, refreshNo }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    if (refresh) {
      loadData(0, tabIndex)
    }
  }, [refresh])

  useEffect(() => {
    loadData(0);
  }, [refreshNo])

  useEffect(() => {
    globalState.client.service('uumsglogs').on('created', onMsgListener);
    globalState.client.service('uumsglogs').on('patched', onMsgListener);
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('uumsglogs').removeListener('created', onMsgListener);
      globalState.client.service('uumsglogs').removeListener('patched', onMsgListener);
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, [
    dataList, globalState?.inChatScreen?.chatWithId,
    globalState?.inChatScreen?.chatWithType, tabIndex
  ]);

  useEffect(() => {
    loadData(0);
  }, [tabIndex]);

  const onNotiListener = (payload) => {
    if (payload.toUserId == globalState.user.id && payload.type == 'CaseClose') {
      loadData(0);
    }
  };

  const getData = (item) => {
    let commonData;
    if (item.type == 'user_to_user') {
      if (item.userId2 == globalState.user.id) {
        commonData = {
          fromTitle: item.user?.displayName, fromId: item.user?.id,
          fromAvatar: item.user?.avatar, fromType: 'user', other: true
        };
      } else {
        commonData = {
          fromTitle: item.user2?.displayName, fromId: item.user2?.id,
          fromAvatar: item.user2?.avatar, fromType: 'user', other: false
        };
      }
    } else if (item.type == 'shop_to_user') {
      commonData = {
        fromTitle: item.shop?.shopThName, fromId: item.shop?.id,
        fromAvatar: item.shop?.shopPhoto1, fromType: 'shop', other: true
      };
    } else if (item.type == 'user_to_shop') {
      commonData = {
        fromTitle: item.user?.displayName, fromId: item.user?.id,
        fromAvatar: item.user?.avatar, fromType: 'user', other: true
      };
    }
    return commonData;
  }

  const QUERY_LIST = [
    {
      tabIndex: 0,
      query: { userId2: globalState?.user?.id, type: { $in: ['user_to_user', 'shop_to_user'] } },
      filter: (item) => {
        return (item.userId2 == globalState?.user?.id) && ['user_to_user', 'shop_to_user'].includes(item.type)
      }
    },
    {
      tabIndex: 1,
      // query: { shopId2: 1, type: { $in: ['user_to_shop'] } },
      query: 'findUumsglogsByPharmaId',
      filter: (item) => {
        // return ((item.shopId2 == 1) && (item.type == 'user_to_shop'))
        // return ((item.shopId2 == 1) && (item.type == 'user_to_shop'))
        return true
      }
    }
  ]

  const onMsgListener = (payload) => {
    if (
      (tabIndex == 0 && ['user_to_user', 'shop_to_user'].includes(payload.type))
      ||
      (tabIndex == 1 && payload.type == 'user_to_shop')
    ) {
      if (QUERY_LIST.find(item => item.tabIndex == tabIndex)?.filter(payload)) {
        let tmpDataList = [...dataList];
        if (tmpDataList.find(item => item.mid == payload.id)) {
          tmpDataList = tmpDataList.map(item => {
            if (item.mid == payload.id) {
              const commonData = getData(item);
              return {
                ...item,
                ...commonData,
                ...commonData?.other ? { unreadNum: payload.unreadNum } : null,
                lastMsgId: payload.lastMsgId,
                lastMsgData: payload.lastMsgData,
                lastMsgDate: moment(payload.lastMsgDate),
              }
            } else {
              return item;
            }
          })
          tmpDataList.sort((a, b) => (a.lastMsgDate < b.lastMsgDate) ? 1 : -1);
        } else if (!tmpDataList.map(item => item.lastMsgId).includes(payload.lastMsgId)) {
          const commonData = getData(payload);
          tmpDataList.unshift({
            ...payload,
            ...commonData,
            ...commonData?.other ? { unreadNum: payload.unreadNum } : null,
            mid: payload.id,
            lastMsgId: payload.lastMsgId,
            lastMsgData: payload.lastMsgData,
            lastMsgDate: moment(payload.lastMsgDate),
          });
        }
        setDataList(tmpDataList);
      }
    }
  };

  const loadData = async (paramSkip, pTabIndex = tabIndex) => {
    const limit = 20;
    if (!loading) {
      setLoading(true);
      // let query = { query: { $skip: paramSkip ?? skip, $limit: 100, $sort: { lastMsgDate: -1 } } };
      let query = { query: { $skip: paramSkip, $limit: limit, $sort: { lastMsgDate: -1 } } };
      let res;
      if (QUERY_LIST.find(item => item.tabIndex == pTabIndex).query == 'findUumsglogsByPharmaId') {
        try {
          const jwtToken = await getJwtToken();
          const resRaw = await fetch(`${server_var.server_api}/findUumsglogsByPharmaId`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
            body: JSON.stringify({ pharmaId: globalState?.user?.id, skip: paramSkip, limit })
          });
          res = await resRaw.json();
        } catch { }
      } else {
        query.query = { ...QUERY_LIST.find(item => item.tabIndex == pTabIndex).query, ...query.query };
        try {
          // res = (await globalState.client.service('uumsglogs').find(query))['data'];
          res = (await globalState.client.service('uumsglogs').find(query));
        } catch { }

      }
      if (res) {
        console.log("\n\nresdata", res);
        let res2 = res.data.map(item => {
          const commonData = getData(item);
          return ({
            ...item,
            mid: item.id,
            lastMsgDate: moment(item.lastMsgDate),
            ...commonData
          });
        });
        if ((paramSkip) == 0) {
          setDataList(res2);
        } else {
          setDataList(prev => [...prev, ...res2]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  const clearUnread = (id) => {
    setDataList(prev => [...prev].map(item => {
      if (item.userId == id) {
        return ({ ...item, unreadNum: 0 });
      } else {
        return item;
      }
    }))
  }

  return (
    <View style={{ flex: 1 }}>
      {[0, 1].includes(tabIndex) && (!dataList || dataList.length == 0) && (
        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: 'gray' }}>ยังไม่มีข้อความ</Text>
        </View>
      )}
      <FlatList
        keyExtractor={(item) => item.mid.toString()}
        data={dataList}
        onEndReached={(xxx) => loadData(skip)}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadData(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => (
          <MessageItem item={item} onEnterChat={(id) => clearUnread(id)} onPress={onPress} />
        )}
      />
    </View>
  )
}

function MessageItem({ item, onEnterChat, onPress }) {
  const getItemText = () => {
    if (item?.lastMsgData?.text) {
      return (item.lastMsgData.text.substring(0, 80) + (item.lastMsgData.text.length > 80 ? '...' : ''));
    } else if (item?.lastMsgData?.video) {
      return "[Video]";
    } else if (item?.lastMsgData?.image) {
      return "[Photo]";
    } else if (item?.lastMsgData?.type) {
      return `[${item?.lastMsgData?.type}]`;
    } else {
      return JSON.stringify(item, '', 2);
    }
  }

  return (
    <TouchableOpacity
      onPress={async () => {
        await onEnterChat(item.fromId);
        onPress(item)
      }}>
      <View
        style={{
          marginTop: 10, borderBottomWidth: 1, borderColor: 'lightgray', paddingBottom: 10,
          flexDirection: 'row', marginHorizontal: 10,
        }}>
        <Image
          style={[{ width: 40, height: 40 }, item.fromType == 'user' ? { borderRadius: 20 } : null]}
          source={{ uri: item.fromAvatar }}
        />
        <View style={{ marginLeft: 10, flex: 1 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              <Text style={[fontStyles.body1, { fontWeight: 'bold' }]}>
                {item.fromTitle}
              </Text>
              {item?.shop2?.shopThName ? <Text style={{ fontSize: 10 }}>[{item?.shop2?.shopThName}]</Text> : <View />}
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={[fontStyles.body1, { color: 'gray' }]}>
                {moment(item.lastMsgDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')}
              </Text>
              {item.unreadNum ? (
                <View
                  style={{
                    marginLeft: 5, width: 26, height: 26, borderRadius: 13, backgroundColor: themeColor.color6,
                    justifyContent: 'center', alignItems: 'center'
                  }}>
                  <Text style={[fontStyles.body1, { fontWeight: 'bold', color: 'white' }]}>
                    {item.unreadNum ? item.unreadNum.toString() : ''}
                  </Text>
                </View>
              ) : (
                <View style={{ width: 26, height: 26 }} />
              )}
            </View>
          </View>
          <Text style={[fontStyles.body1]}>{getItemText()}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}
