import React from "react";
import { useLogin } from "@pankod/refine-core";
import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button, Checkbox } from "@pankod/refine-antd";
import "./styles.css";
import { useContext } from "react";
import { store } from "../../sharewebapp/store";
import { themeColor } from "../../config/servervar";

const { Text, Title } = Typography;

export const Login = () => {
  const { globalState, dispatch } = useContext(store);
  const [form] = Form.useForm();
  const { mutate: login } = useLogin();
  const CardTitle = (
    <Title level={3} className="title">
      Sign in your account
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row justify="center" align="middle" style={{ height: "100vh", backgroundColor: themeColor.color8 }}>
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              {/* <img src="https://refine.dev/img/refine_logo.png" alt="Refine Logo" /> */}
              <img src="allpharmasee_logo3.png" alt="All PharmaSee Logo" style={{ width: 400 }} />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                form={form}
                requiredMark={false}
                initialValues={{ remember: false }}
                // onFinish={async (values) => login({ ...values, redirectPath: '/manage_order' })}
                onFinish={async (values) => login({ ...values, redirectPath: '/' })}
              >
                <Form.Item name="username" label="Username" rules={[{ required: true }]}>
                  <Input size="large" placeholder="Username" />
                </Form.Item>
                <Form.Item name="password" label="Password" rules={[{ required: true }]} style={{ marginBottom: "12px" }}>
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <div style={{ marginBottom: "12px" }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox style={{ fontSize: "12px" }}>
                      Remember me
                    </Checkbox>
                  </Form.Item>
                  {/* <a style={{ float: "right", fontSize: "12px" }} href="#">
                    Forgot password?
                  </a> */}
                </div>
                <Button type="primary" size="large" htmlType="submit" block>
                  Sign in
                </Button>
              </Form>
              {/* <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  Don’t have an account?{" "}
                  <a href="#" style={{ fontWeight: "bold" }}>
                    Sign up
                  </a>
                </Text>
              </div> */}
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};