import React, { useEffect, useCallback, useState, useContext, useRef, lazy, Suspense } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator, Alert, TouchableWithoutFeedback, Keyboard, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from '../sharewebapp/store';
import EventEmitter from '../sharewebapp/EventEmitter';
import { CustomIcon, uploadPhoto } from '../component/custom';
// import Editor from '../lexcom/Editor';
const Editor = lazy(() => import('../lexcom/Editor'));

export function EditContentPage({ navigation, route }) {
  const feedId = route?.params?.feedId;
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({ sourceId: '01' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ at: '', message: '' });

  const FIELD_LIST = [
    {
      id: 1, type: 'text', title: 'ชื่อบทความ', titleStyle: { marginTop: 10 }, fieldName: 'title', value: data?.title, textInputStyle: { height: 40, marginTop: 5 }
    },
    {
      id: 2, type: 'category', title: 'หมวดหมู่บทความ', titleStyle: { marginTop: 10 }, fieldName: 'sourceId', value: data?.sourceId
    },
    {
      id: 3, type: 'image', title: 'หน้าปกบทความ', titleStyle: { marginTop: 10 }, fieldName: 'feedPhoto1', value: data?.feedPhoto1
    },
    {
      id: 4, type: 'editor', title: 'เนื้อหาบทความ', titleStyle: { marginTop: 10 }, fieldName: 'content', value: data?.content
    },

  ]

  useEffect(() => {
    loadContent();
  }, [feedId])

  const loadContent = async () => {
    let res;
    if (feedId==0) {
      res = null;
    } else {
      res = await globalState.client.service('feeds').get(feedId);
    }
    setData(res);
  }

  const setHtmlString = (text) => {
    setData(prev => ({ ...prev, content: text }))
  }

  const onDelete = async (id) => {
    const delAction = async () => {
      await globalState.client.service('feeds').remove(id);
      dispatch({ type: 'showToast', payload: { text: 'ลบข้อมูลเรียบร้อย' } });
      EventEmitter.notify('ManageContent:refresh');
      navigation.goBack(2);
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบบทความสุขภาพหัวข้อนี้ ?')) {
      delAction()
    } else {
      return Alert.alert('ลบบทความสุขภาพ', 'คุณต้องการลบบทความสุขภาพหัวข้อนี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }
  }

  const validateForm = () => {
    const blankField = ['title', 'content'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }

  const onSave = async (id) => {
    setError({ at: '', message: '' });
    if (!validateForm()) {
      return;
    }

    if (feedId == 0) {
      await globalState.client.service('feeds').create({
        userId: globalState?.user?.id,
        feedType: 'article',
        sourceId: data?.sourceId,
        title: data?.title,
        content: validateHtml(data?.content),
        feedPhoto1: data?.feedPhoto1,
        feedPublic: 1,
      })
    } else {
      await globalState.client.service('feeds').patch(id, {
        sourceId: data?.sourceId,
        title: data?.title,
        content: validateHtml(data?.content),
        feedPhoto1: data?.feedPhoto1,
      })
    }

    dispatch({ type: 'showToast', payload: { text: 'บันทึกข้อมูลเรียบร้อย' } });
    EventEmitter.notify('ManageContent:refresh');
    navigation.goBack(2);
  }

  const validateHtml = (content) => {
    const HEAD = '<html><head><meta name="viewport" content="width=device-width, initial-scale=1" /></head><body>';
    const TAIL = '</body></html>';
    let htmlText;
    if (content.substring(0, 6) != '<html>') {
      htmlText = HEAD.concat(content, TAIL);
      htmlText = htmlText.replaceAll('<img', '<img style="max-width: 100%; object-fit: contain;" ');
      // htmlText = htmlText.replaceAll('width="100"', ' ');
      htmlText = htmlText.replaceAll(/width\="\w*"/gi, '');
      htmlText = htmlText.replaceAll(/height\="\w*"/gi, '');
    } else {
      return content;
    }
    return htmlText;
  }

  return (
    <SafeAreaView style={{ flex: 1, marginVertical: 5, }}>
      <ScrollView contentContainerStyle={{ paddingBottom: 260, paddingHorizontal: 20, height: '80vh' }}>
        {
          FIELD_LIST.map(item =>
            <CustomItem key={item.id.toString()} item={item} setData={setData} setHtmlString={setHtmlString} error={error} />
          )}

        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 20, flex: 1 }}>
          {(feedId != 0) &&
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onDelete(feedId)} >
              <View style={{
                height: 40, borderRadius: 10,
                justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color6
              }}>
                <Text style={{ color: 'white' }}>ลบ</Text>
              </View>
            </TouchableOpacity>
          }
          <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center', width: '45%' }} onPress={() => onSave(feedId)} >
            <View style={{
              height: 40, borderRadius: 10,
              justifyContent: 'center', alignItems: 'center', marginTop: 10, backgroundColor: themeColor.color1
            }}>
              <Text style={{ color: 'white' }}>บันทึก</Text>
            </View>
          </TouchableOpacity>
        </View>

        {loading &&
          <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size='large' />
          </View>
        }
      </ScrollView>
    </SafeAreaView>
  );

}

function CustomItem({ item, setData, setHtmlString, error }) {
  if (item.type == 'text') {
    return <CustomText item={item} setData={setData} error={error} />
  } else if (item.type == 'editor') {
    return <CustomEditor item={item} setHtmlString={setHtmlString} error={error} />
  } else if (item.type == 'image') {
    return <CustomImage item={item} setData={setData} error={error} viewMode={false} />
  } else if (item.type == 'category') {
    return <CustomCategory item={item} setData={setData} error={error} />
  }
}

function CustomText({ item, setData, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <TextInput
        {...item.textInputProp}
        value={item.value}
        onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
        style={[item.textInputStyle, { borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white' }, error?.at == item.fieldName && { borderColor: 'red' }]}
      />
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>
  )
}

function CustomEditor({ item, setHtmlString, error }) {
  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <Suspense fallback={<div>Loading...</div>}>
        <Editor initHtml={item.value} setHtmlString={setHtmlString} />
      </Suspense>
      {error?.at == item.fieldName && <Text style={{ textAlign: 'center', color: 'red' }}>{error.message}</Text>}
    </View>

  )
}

function CustomImage({ item, setData, error, viewMode }) {
  const fileRef1 = useRef();
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('feed', false, 'photolib', targetFiles);
    } catch (e) {
      alert('มีปัญหาในการอัพโหลดรูปภาพ');
    }
    console.log('images!!', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item of images) {
      setData(prev => ({ ...prev, feedPhoto1: `${server_var.server_api}/images/feed/${item.filename}` }))
    }
  }

  if (item?.value) {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <View style={{ marginVertical: 10 }}>
          <Image style={{ width: 120, height: 120 }}
            source={{ uri: item?.value }}
            resizeMode="cover"
          />
          {!viewMode &&
            <TouchableOpacity
              style={{ position: "absolute", top: -5, left: -5 }}
              onPress={() => {
                setData(prev => ({ ...prev, feedPhoto1: null }))
              }}
            >
              <View style={{ marginTop: -5, marginLeft: -5 }}>
                <CustomIcon name="close-circle" size={20} color={"red"} />
              </View>
            </TouchableOpacity>
          }
        </View>
      </View>
    )
  } else {
    return (
      <View>
        <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
        <TouchableOpacity
          style={{
            borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 100, height: 100,
            margin: 10, justifyContent: "center", alignItems: "center", padding: 5
          }}
          onPress={() => {
            if (!viewMode) {
              if (Platform.OS == 'web') {
                fileRef1.current.click();
              } else {
                uploadPhoto2('')
              }
            }
          }}
        >
          <CustomIcon name="plus-circle" size={20} color="gray" />
          <Text style={{ color: "gray", marginTop: 5 }}>ใส่รูปภาพ</Text>
        </TouchableOpacity>
        {
          Platform.OS == 'web' && (
            <div style={{ display: 'flex' }}>
              <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".jpg, .jpeg, .png" />
            </div>
          )
        }
      </View>
    )
  }
}

function CustomCategory({ item, setData, error }) {
  const { globalState, dispatch } = useContext(store);
  const [catList, setCatList] = useState();

  useEffect(() => {
    const loadCatList = async () => {
      const res = await globalState.client.service('cats').find({ query: { catType: 'article' } });
      let res2 = res.data.filter(cat => cat.catAltId !== '00');
      setCatList(res2);
    }
    loadCatList();
  }, [])

  return (
    <View>
      <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
      <ScrollView horizontal style={{ marginVertical: 10 }}>
        {catList?.map(item2 => (
          <View key={item2.id.toString()} style={{ marginTop: 5, marginBottom: 5 }}>
            <TouchableOpacity
              style={{ padding: 10, borderRadius: 10, backgroundColor: item.value == item2.catAltId ? themeColor.color1 : 'lightgray', marginRight: 10 }}
              onPress={() => {
                setData(prev => ({ ...prev, [item.fieldName]: item2.catAltId }))
              }}>
              <Text style={{ color: item.value == item2.catAltId ? 'white' : 'black' }}>{item2.catName.replace(' ', '')}</Text>
            </TouchableOpacity>
          </View>
        ))}
      </ScrollView>
    </View>
  )
}

