import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, SafeAreaView, ScrollView, Dimensions, Modal, Alert, Linking } from 'react-native';
import React, { useState, useEffect, useCallback, useContext, forwardRef, useRef, useImperativeHandle } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import { numberWithCommas } from './common1';
// import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useCustomSafeAreaInsets } from '../component/custom';
import EventEmitter from './EventEmitter';
import { CustomIcon } from '../component/custom';
import { useCustomNavigation } from '../component/custom';
import { getJwtToken, ImageWithAuth } from '../component/custom';

export function PrintOrderPart({ navigation, route }) {
  const { ordermainId } = route?.params;
  const [orderMain, setOrderMain] = useState();
  const [selectedList, setSelectedList] = useState([])
  const [lang, setLang] = useState('th');

  const onPressViewProduct = (pressParam) => {
    navigation.navigate('ViewProduct', pressParam);
  }

  const goBack = (index) => navigation.goBack(index);

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={[Platform.OS == 'web' ? { height: '70vh' } : null]}>
        <PrintOrderView
          setOrderMain={setOrderMain} selectedList={selectedList}
          setSelectedList={setSelectedList} lang={lang} setLang={setLang}
          ordermainId={ordermainId} onPressViewProduct={onPressViewProduct}
        />
      </ScrollView>
      <PrintAction
        ordermainId={ordermainId} selectedList={selectedList} lang={lang} goBack={goBack}
      />
    </View>
  )
}
export function PrintOrderView({ setOrderMain, selectedList, setSelectedList, lang, setLang, ordermainId, onPressViewProduct }) {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    EventEmitter.addListener('OrderMain:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('OrderMain:refresh', handleRefresh);
    }
  }, [])

  const handleRefresh = () => {
    loadData()
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    if (ordermainId) {
      const res = await globalState.client.service('ordermains').get(ordermainId);
      setData(res);
      if (setOrderMain) {
        setOrderMain(res);
      }
      const res1 = await globalState.client.service('orderitems').find({ query: { ordermainId, itemType: 'normal', $limit: 200 } });
      setOrderList(res1.data);
      console.log(res);
    }
  }

  const COMPONENT_LIST = [
    { id: 30, type: 'title', title: 'ส่งไปยัง' },
    { id: 40, type: 'inline', title: 'ชื่อผู้รับสินค้า', field: data?.user?.displayName },
    { id: 50, type: 'inline', title: 'เบอร์โทรผู้รับสินค้า' },
    { id: 60, type: 'noline', field: data?.deliveryAddr },
    { id: 70, type: 'break' },
    { id: 80, type: 'title', title: 'สรุปคำสั่งซื้อ' },
    {
      id: 85, type: 'productlist',
      field: orderList.map(item2 => ({
        id: item2.id,
        amount: item2.amount,
        name: item2?.product?.thProductName,
        price: item2.unitPrice,
        photo1: item2?.product?.productPhoto1,
        ordermainId: item2?.ordermainId,
        productId: item2?.productId,
        userId: data?.userId,
        langToPrint: item2?.langToPrint,
        discountPrice: item2.discountPrice
      }))
    },
  ];

  return (
    <View>
      <View style={{
        paddingRight: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 10
      }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
          <Text style={{ paddingHorizontal: 20, fontWeight: 'bold', marginTop: 10 }}>
            คำสั่งซื้อ
            <Text style={{ fontWeight: 'normal', color: 'gray', fontSize: 12 }}> เลขที่ {data?.id}</Text>
          </Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
          {[
            { id: 1, title: 'ไทย', value: 'th' },
            { id: 2, title: 'English', value: 'en' },
          ].map(item => (
            <TouchableOpacity key={item.id.toString()} style={{ marginHorizontal: 10 }} onPress={() => setLang(item.value)}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {/* <MaterialCommunityIcons name={item.value == lang ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} /> */}
                <CustomIcon name={item.value == lang ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color={"gray"} />
                <Text style={{ marginLeft: 5 }}>{item.title}</Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </View>
      <View style={{
        paddingHorizontal: 20, paddingVertical: 10, backgroundColor: 'white',
        marginHorizontal: 20, borderRadius: 10, marginTop: 10, marginBottom: 20
      }}>
        {COMPONENT_LIST.map(item =>
          <CustomCom key={item.id.toString()} item={item} selectedList={selectedList} setSelectedList={setSelectedList} onPressViewProduct={onPressViewProduct} />
        )}
      </View>
    </View>
  )
}

function CustomCom({ item, setSelectedList, selectedList, onPressViewProduct }) {
  if (item.type == 'break') {
    return <View style={{ height: 2, backgroundColor: themeColor.color8, marginVertical: 10, marginHorizontal: -20 }} />
  } else if (item.type == 'title') {
    return (
      <View style={{ flexDirection: 'row', marginBottom: 5 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 16 }}>{item.title}</Text>
      </View>
    )
  } else if (item.type == 'inline') {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={[{ flex: 0.4, fontSize: 12 }, item?.textProp0]}>{item.title}</Text>
        <Text style={[{ flex: 0.6, fontSize: 12 }, item?.textProp]}>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'noline') {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Text>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'productlist') {
    return <PrintItemList item={item} setSelectedList={setSelectedList} selectedList={selectedList} onPressViewProduct={onPressViewProduct} />
  } else if (item.type == 'nextline') {
    return (
      <View >
        <Text>{item.title}</Text>
        <Text>{item.field}</Text>
      </View>
    )
  } else if (item.type == 'component') {
    return item.component
  } else {
    return <View />
  }
}

function PrintItemList({ item, setSelectedList, selectedList, onPressViewProduct }) {
  useEffect(() => {
    if (item?.field?.length > 0) {
      setSelectedList(item.field.map(item3 => item3.id))
    }
  }, [item?.field?.length])

  return (
    <View>
      {item.field.map(item3 => {
        let imageUrl = `${server_var.server_api}/images/no_image_available.jpeg`;
        if (item3?.photo1) {
          if (item3.photo1.includes('http')) {
            imageUrl = item3.photo1;
          } else {
            imageUrl = `${server_var.server_api}/${item3.photo1}`;
          }
        }
        return (
          <View key={item3.id.toString()} style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10 }}>
            <TouchableOpacity
              style={{ flex: 0.1, height: 40, justifyContent: 'center' }}
              onPress={() => {
                if (selectedList.includes(item3.id)) {
                  setSelectedList(prev => [...prev.filter(item4 => item4 != item3.id)])
                } else {
                  setSelectedList(prev => [...prev, item3.id]);
                }
              }}
            >
              <CustomIcon
                name={selectedList.includes(item3.id) ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={18} color="gray"
              />
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.85 }} onPress={() => {
              onPressViewProduct({
                productId: item3?.productId, userId: item3?.userId, ordermainId: item3?.ordermainId
              });
            }}>
              <View style={{ flexDirection: 'row' }}>
                <ImageWithAuth url={imageUrl} style={{ width: 60, height: 60 }} />
                <View style={{ marginLeft: 10, flex: 0.9 }}>
                  <Text style={{ fontSize: 12 }}>{item3.name}</Text>
                  <View style={{ flexDirection: 'column', flex: 1 }}>
                    <Text style={{ color: themeColor.color1 }}>{item3.price}</Text>
                    {parseFloat(item3.discountPrice ?? 0) > 0 && <Text style={{ color: themeColor.color6, fontSize: 12 }}>ส่วนลด {numberWithCommas(item3.discountPrice)}</Text>}
                  </View>
                </View>
              </View>
            </TouchableOpacity>
            <View>
              <Text style={{ fontSize: 12 }}>x{item3.amount}</Text>
              <Text style={{ color: 'gray', fontSize: 12 }}>{item3.langToPrint}</Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}

function PrintAction({ ordermainId, selectedList, lang, goBack }) {
  const { globalState, dispatch } = useContext(store);
  const customNavigation = useCustomNavigation();
  const insets = useCustomSafeAreaInsets();
  const printLabelPdf = async () => {
    console.log('\n\n\naa' + JSON.stringify({ ordermainId, orderitemList: selectedList, lang }));
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/genPdfAll`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ ordermainId, orderitemList: selectedList, lang })
    });
    const resJson = await res.json();
    console.log(resJson);
    Linking.openURL(`${server_var.server_api}/label/${resJson.result}`)
  }

  const flagPrintComplete = async () => {
    let res;
    try {
      res = await globalState.client.service('ordermains').get(ordermainId, { query: { $select: ['stepJson'] } });
    } catch (e) { }
    try {
      await globalState.client.service('ordermains').patch(ordermainId, { stepJson: { ...res.stepJson, step1: true } });
    } catch (e) {
      console.log('error', e);
    }
    console.log('orderMain.id3:');
    EventEmitter.notify("orderAction:refresh", ordermainId);
    if (Platform.OS != 'web') {
      customNavigation.goBack(1);
    } else {
      if (goBack) {
        goBack(2)
      };
    }

  }

  return (
    <View
      style={{
        paddingBottom: insets.bottom, paddingHorizontal: 0, paddingTop: 10, marginHorizontal: 0,
        backgroundColor: 'white', borderTopWidth: 1, borderColor: 'lightgray'
      }}>
      {[
        {
          id: 1, title: Platform.OS == 'web' ? 'พิมพ์ฉลากยา' : 'พิมพ์ฉลากยา\nผ่าน Browser บนเครื่อง PC เท่านั้น', onPress: async () => {
            if (Platform.OS == 'web') {
              await flagPrintComplete();
              printLabelPdf()
            }
          }, style: { backgroundColor: Platform.OS == 'web' ? themeColor.color1 : 'lightgray' }
        },
        // { id: 2, title: 'เสร็จแล้ว', onPress: () => flagPrintComplete() },
      ].map(item => (
        <TouchableOpacity
          key={item.id.toString()}
          onPress={() => item.onPress()}
          style={{ marginVertical: 5, width: "70%", alignSelf: 'center' }}
        >
          <View style={[{
            height: 40, borderRadius: 10, justifyContent: 'center',
            alignItems: 'center', backgroundColor: themeColor.color1
          }, item?.style]}>
            <Text style={{ color: 'white', textAlign: 'center', fontSize: 12 }} >{item.title}</Text>
          </View>
        </TouchableOpacity>
      ))}


    </View>
  )
}


