import React from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, StyleSheet } from 'react-native';
import { useState, useEffect, useCallback, useContext } from 'react';

export function CouponView({
  couponWidth, couponHeight, couponBorderRadius, couponCircleRadius, couponLeftWidth, couponBorderWidth,
  couponBorderColor, couponBackgroundColor, couponTransparentColor, children, couponSeparationColor, style
}) {
  return (
    <View style={[{
      width: couponWidth, height: couponHeight,
      backgroundColor: couponBackgroundColor, borderColor: couponBorderColor, borderWidth: couponBorderWidth, borderRadius: couponBorderRadius,
      flexDirection: 'row'
    }, style]}>
      <View style={{ position: 'absolute', left: couponLeftWidth + couponCircleRadius / 2, width: 1, backgroundColor: couponSeparationColor, height: couponHeight }} />
      <View style={{
        position: 'absolute', top: -1, left: couponLeftWidth, width: couponCircleRadius, height: couponCircleRadius, overflow: 'hidden'
      }}
      >
        <View style={{
          width: couponCircleRadius, height: couponCircleRadius, backgroundColor: couponTransparentColor,
          borderRadius: couponCircleRadius / 2, position: 'absolute', top: -1 * couponCircleRadius / 2, borderColor: couponBorderColor, borderWidth: couponBorderWidth
        }}
        />
      </View>
      <View style={{
        position: 'absolute', bottom: -1, left: couponLeftWidth,
        width: couponCircleRadius, height: couponCircleRadius / 2, overflow: 'hidden'
      }}
      >
        <View style={{
          width: couponCircleRadius, height: couponCircleRadius, backgroundColor: couponTransparentColor,
          borderRadius: couponCircleRadius / 2, borderColor: couponBorderColor, borderWidth: couponBorderWidth
        }}
        />
      </View>
      {children}
    </View>
  )
}

