import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, Alert, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { uploadPhoto } from '../component/custom';
import { CustomIcon } from '../component/custom';

export function ManageBannerPart() {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const refFlatList = useRef();
  const fileRef1 = useRef();

  useEffect(() => {
    loadDataList(0);
  }, [])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let query = { feedType: 'banner', $skip: pSkip ?? skip, $sort: { feedScore: 1 }, $limit: 20 };
      const res = await globalState.client.service('feeds').find({ query });
      if ((pSkip ?? skip) === 0) {
        setDataList(res.data);
      } else {
        setDataList(prev => [...prev, ...res.data]);
      }
      setSkip((res.skip + res.limit));
      setLoading(false);
    }
  }

  const onDelete = async (id) => {
    const delAction = async () => {
      await globalState.client.service('feeds').remove(id);
      loadDataList(0);
    }
    if (Platform.OS == 'web' && window.confirm('คุณต้องการลบแบนเนอร์นี้ ?')) {
      delAction();
    } else {
      Alert.alert(
        'ลบแบนเนอร์', 'คุณต้องการลบแบนเนอร์นี้ ?',
        [
          { text: 'ยกเลิก' },
          { text: 'ตกลง', onPress: async () => delAction() },
        ]
      );
    }

  }

  const onSave = async (index) => {
    setLoading(true);
    await globalState.client.service('feeds').patch(dataList[index].id, { feedLink: dataList[index]?.feedLink, feedScore: dataList[index]?.feedScore });
    dataList[index].editing = false;
    setLoading(false);
    loadDataList(0);
  }
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('banner', false, 'photolib', targetFiles);
    } catch { }
    console.log('Banner images : ', images);
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }

    for (const item of images) {
      let feedScore = Math.max(...dataList?.map(item => item?.feedScore), dataList[0]?.feedScore) + 1;
      await globalState.client.service('feeds').create({
        userId: globalState.user.id, feedType: 'banner', feedPublic: 1, feedScore: feedScore,
        feedPhoto1: `${server_var.server_api}/images/feed/${item.filename}`
      })
    }
    loadDataList(0);
    onScrollToEnd();
  }

  const onScrollToEnd = () => {
    setTimeout(() => {
      refFlatList.current?.scrollToEnd({ animated: true })
    }, 300);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginVertical: 10, justifyContent: "center", alignItems: "center", }}>
        <Text style={{ fontSize: 16 }}>Banner Size : W1367 x H575</Text>
      </View>
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
      <FlatList
        style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '70vh' } : null]}
        ref={refFlatList}
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item, index }) => (
          <BannerItem item={item} index={index} onDelete={onDelete} />
        )}
        ListFooterComponent={
          <View style={{ flexDirection: 'row', marginVertical: 10, justifyContent: "center", alignItems: "center", marginBottom: 260 }} >
            <TouchableOpacity
              style={{
                borderColor: "gray", borderWidth: 1, borderRadius: 6, width: 274, height: 115,
                margin: 10, justifyContent: "center", alignItems: "center", padding: 5
              }}
              onPress={() => {
                if (Platform.OS == 'web') {
                  fileRef1.current.click();
                } else {
                  uploadPhoto2('')
                }
              }}
            >
              <CustomIcon name="plus-circle" size={30} color="gray" />
              <Text style={{ color: "gray" }}>เพิ่มแบนเนอร์ใหม่</Text>
            </TouchableOpacity>
          </View>
        }
      />
      {/* </TouchableWithoutFeedback> */}
      {
        Platform.OS == 'web' && (
          <div style={{ display: 'flex' }}>
            <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".mp4, .jpg, .png" />
          </div>
        )
      }
    </SafeAreaView>
  )

  function BannerItem({ item, index, onDelete }) {
    return (
      <View style={{ flex: 1, marginVertical: 5, marginHorizontal: 20, justifyContent: "center", alignItems: "center" }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <View style={{ flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
            <Image style={{ width: '100%', aspectRatio: 3 / 2, resizeMode: 'contain' }}
              source={{ uri: item?.feedPhoto1 }}
            />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ flex: 0.2, marginTop: 10 }}>Link: </Text>
              <TextInput
                style={{ flex: 0.8, height: 40, marginTop: 5, marginLeft: 5, borderColor: "lightgray", borderWidth: 1, paddingLeft: 5, paddingRight: 5, borderRadius: 6 }}
                onChangeText={(text) => {
                  var tmpArray = dataList;
                  tmpArray[index].feedLink = text;
                  tmpArray[index].editing = true;
                  setDataList(tmpArray);
                  setLoading(true);
                }}
                onBlur={() => setLoading(false)}
                defaultValue={item?.feedLink}
                autoCapitalize={"none"}
                underlineColorAndroid={"transparent"}
              />
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Text style={{ flex: 0.2, marginTop: 10 }}>Order: </Text>
              <TextInput
                style={{ flex: 0.8, height: 40, marginTop: 10, marginLeft: 5, paddingLeft: 5, paddingRight: 5, borderColor: "lightgray", borderWidth: 1, borderRadius: 6 }}
                onChangeText={(text) => {
                  var tmpArray = dataList;
                  tmpArray[index].feedScore = text;
                  tmpArray[index].editing = true;
                  setDataList(tmpArray);
                  setLoading(true);
                }}
                onBlur={() => setLoading(false)}
                defaultValue={item?.feedScore?.toString()}
                autoCapitalize={"none"}
                underlineColorAndroid={"transparent"}
                keyboardType={'number-pad'}
              />
            </View>
            <View style={{ flexDirection: 'column', justifyContent: "center", alignItems: 'center', marginTop: 20 }}>
              <TouchableOpacity onPress={() => onDelete(item?.id)}>
                <CustomIcon name={"trash-can-outline"} size={40} color={themeColor.color1} />
              </TouchableOpacity>
            </View>
            <TouchableOpacity style={{ marginTop: 5, alignSelf: 'center' }} onPress={() => onSave(index)}>
              {(dataList[index].editing == true) &&
                <View style={{
                  height: 40, width: 274, backgroundColor: themeColor.color1,
                  borderRadius: 10, justifyContent: 'center', alignItems: 'center', marginTop: 10
                }}>
                  <Text style={{ color: 'white' }}>บันทึก</Text>
                </View>
              }
            </TouchableOpacity>
          </View>

        </View>
        <View style={{ borderWidth: 1, borderColor: "lightgray", marginTop: 20, marginBottom: 10 }} />
      </View>
    )
  }
}


