import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, ScrollView, SafeAreaView, Dimensions } from 'react-native';
import { themeColor, fontStyles, server_var, default_shop_image } from '../config/servervar';
import { store } from '../sharewebapp/store'

export function ViewPharmaProfilePart({ route }) {
  const { globalState } = useContext(store);
  const userId = route?.params?.userId;
  const [data, setData] = useState();

  const FIELD_LIST = [
    { id: 1, type: 'avatar', title: 'รูปโปรไฟล์', fieldName: 'avatar', value: data?.avatar },
    { id: 2, type: 'header', title: 'ข้อมูลทั่วไป' },
    { id: 3, type: 'text', title: 'รหัสพนักงาน', fieldName: 'staffId', value: data?.staffId, style: { borderTopLeftRadius: 10, borderTopRightRadius: 10 } },
    { id: 4, type: 'break' },
    { id: 5, type: 'text', title: 'เลขที่ใบอนุญาต', fieldName: 'staffCerNo', value: data?.staffCerNo },
    { id: 6, type: 'break' },
    { id: 7, type: 'text', title: 'ชื่อ-นามสกุล (TH)', fieldName: 'staffNameTh', value: data?.staffNameTh },
    { id: 8, type: 'break' },
    { id: 9, type: 'text', title: 'ชื่อ-นามสกุล (EN)', fieldName: 'staffNameEn', value: data?.staffNameEn },
    { id: 10, type: 'break' },
    { id: 11, type: 'text', title: 'ชื่อเล่น', fieldName: 'staffNickName', value: data?.staffNickName },
    { id: 12, type: 'break' },
    { id: 13, type: 'text', title: 'เบอร์โทรศัพท์', fieldName: 'staffPhoneNumber', value: data?.staffPhoneNumber, style: { borderBottomLeftRadius: 10, borderBottomRightRadius: 10 } },
  ]

  useEffect(() => {
    const loadData = async () => {
      const res = await globalState.client.service('users').get(route?.params?.userId)
      setData(res);
    }
    loadData();
  }, [route?.params?.userId])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={{ marginTop: 20 }}>
        {FIELD_LIST.map(item => <CustomItem key={item.id.toString()} item={item} />)}
      </ScrollView>
    </SafeAreaView>
  )
}

function CustomItem({ item }) {
  if (item.type == 'avatar') {
    return <CustomAvatar item={item} />
  } else if (item.type == 'header') {
    return <CustomHeader item={item} />
  } else if (item.type == 'text') {
    return <CustomText item={item} />
  } else if (item.type == 'break') {
    return <View key={item.id.toString()} style={{ flex: 1, borderBottomWidth: 1, borderColor: themeColor.color8 }} />
  }
}

function CustomAvatar({ item }) {
  return (
    <View style={{ alignItems: 'center' }}>
      <Image source={{ uri: item?.value }} style={{ width: 120, height: 120, borderRadius: 60, marginTop: 20 }} />
    </View>
  )
}

function CustomHeader({ item }) {
  return (
    <View style={{ marginHorizontal: 20, marginTop: 20, marginBottom: 10 }}>
      <Text style={{ color: themeColor.color1 }}>{item.title}</Text>
    </View>
  )
}

function CustomText({ item }) {
  return (
    <View style={[{ marginHorizontal: 20, backgroundColor: 'white', flexDirection: 'row' }, item.style]}>
      <View style={{ flex: 1, flexDirection: 'row', paddingHorizontal: 15, paddingVertical: 10 }}>
        <View style={{ flex: 0.5, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Text style={{ fontSize: 14, color: 'grey', flexWrap: 'wrap', marginRight: 10 }}>{item?.title}</Text>
          <Text style={{ fontSize: 14, color: 'grey' }}>:</Text>
        </View>
        <View style={{ flex: 0.5, paddingHorizontal: 20 }}>
          <Text style={{ fontSize: 14, color: themeColor.color1 }}>{item?.value}</Text>
        </View>
      </View>
    </View>
  )
}

