import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, ActivityIndicator } from 'react-native';

import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { useDebounce } from '../sharewebapp/common1';
import { CustomIcon, CustomVideo, CustomDatePicker5 } from '../component/custom';
import moment from 'moment';
import { Dialog } from '../sharewebapp/pharmanotepart';

export function ManageConsultVideoPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [dialog, setDialog] = useState({ visible: false, visibleShop: false });  
  const [searchData, setSearchData] = useState({ mediaType: 'video', shopId: null, shopName: null, dateFrom: null, dateTo: null });  
  const [skip, setSkip] = useState(0);
  //const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;

  useEffect(() => {
    loadDataList(0);
  }, [debouncedSearchTerm, searchData?.shopId, searchData?.dateFrom, searchData?.dateTo, searchData?.mediaType])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query;
      if (searchData?.mediaType == 'video') {
        query = { $skip: pSkip ?? skip, $limit: 5, recordingFile: { $ne: null }, $sort: { id: -1 } };
        if (searchArray.length > 0) {
          const tmpSearch = searchArray.join('%');
          query = {
            ...query,
            $or: [
              { '$oldqueline.user.displayName$': { $like: `%${tmpSearch}%` } },
              { '$oldqueline.pharmaUser.displayName$': { $like: `%${tmpSearch}%` } },
            ],          
          };
        }
        if (searchData?.shopId) {
          query = {
            ...query,
            '$oldqueline.shopId2$': searchData?.shopId
          };
        }
      } else {
        query = { acceptStatus: 'accept', $skip: pSkip ?? skip, $limit: 5, $sort: { id: -1 } };
        if (searchArray.length > 0) {
          const tmpSearch = searchArray.join('%');
          query = {
            ...query,
            $or: [
              { '$user.displayName$': { $like: `%${tmpSearch}%` } },
              { '$pharmaUser.displayName$': { $like: `%${tmpSearch}%` } },
            ],          
          };
        }
        if (searchData?.shopId) {
          query = {
            ...query,
            shopId2: searchData?.shopId
          };
        }
      }

      let criteriaDateFrom;
      if (searchData?.dateFrom) {
        criteriaDateFrom = moment(searchData?.dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        criteriaDateFrom = moment().format('YYYY-MM-DD');
      }
      let criteriaDateTo;
      if (searchData?.dateTo) {
        criteriaDateTo = moment(searchData?.dateTo, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
      } else {
        criteriaDateTo = moment().add(1, 'days').format('YYYY-MM-DD');
      }
      query = {
        ...query,
        $and: [
          { createdAt: { $gte: criteriaDateFrom } },
          { createdAt: { $lte: criteriaDateTo } }
        ],
      };

      let res;
      if (searchData?.mediaType == 'video') {
        try {
          res = await globalState.client.service('videorooms').find({ query });
        } catch { }
      } else {
        try {
          res = await globalState.client.service('oldquelines').find({ query });
        } catch { }        
      }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          //alert(JSON.stringify(res.data));
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  const onBack = async (item) => {
    setSearchData(prev => ({ ...prev, shopId: item?.id, shopName: item?.shopThName }));
  }

  const onBackDialog = async (backItem) => {
    setSearchData(prev => ({ ...prev, mediaType: backItem.value }));
    setDialog(prev => ({ ...prev, visible: false }));
  }
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const [dialogProp, setDialogProp] = useState({ dialog, onBackDialog, setDialog, dialogWidth, dialogList : null, type : null });
  const dialogContent = [
    {
      type: dialogProp?.type,
      title: 'ชนิดสื่อบันทึก',
      com: <DialogList {...dialogProp} />,
    },
  ];

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', marginHorizontal: 20 }}>
        <View style={{ flex: 1, paddingBottom: 5, paddingTop: 5, marginTop: 5 }}>
          <TextInput
            value={searchText}
            onChangeText={text => setSearchText(text)}
            placeholder={'ค้นหาจากชื่อผู้ให้/รับบริการ'}
            style={{ alignSelf: 'center', width: "100%", borderColor: 'lightgray', borderWidth: 1, marginVertical: 5, height: 40, paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white' }}
          />        
        </View>
      </View> 
      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>สาขา</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visibleShop: true, type: 'shoplist' }))}>
            <View style={{
              flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{searchData?.shopName ?? ''}</Text>
              <CustomIcon name="store" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ตั้งแต่วันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateFrom}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateFrom: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>
      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ถึงวันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateTo}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateTo: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>
      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ชนิดสื่อบันทึก</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <TouchableOpacity onPress={() => {
              setDialogProp(prev => ({ ...prev, dialogList : [
                { id: 1, title: 'วิดีโอ', value: 'video' },
                { id: 2, title: 'แชท', value: 'chat' },
              ], type : 'medialist' }));
              setDialog(prev => ({ ...prev, visible: true, type: 'medialist' }));
              }}>
            <View style={{
              flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{ (searchData?.mediaType == 'video') ? 'วิดีโอ' : 'แชท'}</Text>
              <CustomIcon name="chevron-down" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
      </View>
{/* 
      <View style={{ marginVertical: 10, marginHorizontal: 20, flexDirection: 'row', alignItems: 'flex-start' }}>
      {[
          { id: 1, title: 'วิดีโอ', value: 'video' },
          { id: 2, title: 'แชท', value: 'chat' },
        ].map(item => (
            <View key={item.id.toString()} style={{ marginTop: 5, marginBottom: 5 }}>
              <TouchableOpacity
                style={{ padding: 10, borderRadius: 10, backgroundColor: searchData?.mediaType == item.value ? themeColor.color1 : 'lightgray', marginRight: 10 }}
                onPress={() => {
                  setSearchData(prev => ({ ...prev, mediaType: item.value }));
                  loadDataList(0);
                  setRefresh(true);
                }}
              >
                <Text style={{ color: searchData?.mediaType == item.value ? 'white' : 'black' }}>{item.title}</Text>
              </TouchableOpacity>
            </View>
          ))}
      </View>             */}

      <FlatList
        contentContainerStyle={{ justifyContent: 'center' }}
        keyExtractor={(item) => item.id.toString()}
        data={dataList}     
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        style={[{ marginTop: 0 }, Platform.OS == 'web' ? { height: '60vh' } : null]}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item, index }) => (
          searchData?.mediaType == 'video' ? <VideoItem item={item} navigation={navigation} /> : <ChatItem item={item} navigation={navigation} />
        )}
      />

      {loading &&
        <View style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size='large' />
        </View>
      }
      <ShopDialog dialog={dialog} setDialog={setDialog} onBack={onBack} />      
      <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
    </View>      
  )
}

function VideoItem({ item, navigation }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5 }}
      onPress={() => navigation.navigate('EditConsultVideo', { id: item.id, type: 'video' })}
    >
      <View style={{ borderBottomWidth: 1, borderBottomColor: 'lightgray', marginHorizontal: 20, paddingBottom: 10 }}>
        {[
          { id: 1, title: 'หมายเลขวิดีโอ', value: item?.id, textStyle: { color: themeColor.color1 } },
          { id: 2, title: 'สาขาที่ให้บริการ', value: item?.oldqueline?.shop?.shopThName, textStyle: {} },
          { id: 3, title: 'ผู้ให้บริการ', value: item?.oldqueline?.pharmaUser?.displayName ?? '', textStyle: {} },
          { id: 4, title: 'ผู้รับบริการ', value: item?.oldqueline?.user?.displayName ?? '', textStyle: {} },
          { id: 5, title: 'วันและเวลาที่รับบริการ', value: moment(item?.startTime).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
        ].map(item3 => (
          <View key={item3.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <Text style={item3?.textStyle}>{item3?.title}</Text>
            </View>
            <View style={{ flex: 0.1 }}>
              <Text style={item3?.textStyle}>:</Text>
            </View>
            <View style={{ flex: 0.5 }}>
              <Text style={item3?.textStyle}>{item3?.value}</Text>
            </View>
          </View>
        ))}
      </View>
    </TouchableOpacity>
  )
}

function ChatItem({ item, navigation }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5 }}
      onPress={() => navigation.navigate('EditConsultVideo', { id: item.id, type: 'chat' })}
    >
      <View style={{ borderBottomWidth: 1, borderBottomColor: 'lightgray', marginHorizontal: 20, paddingBottom: 10 }}>
        {[
          { id: 1, title: 'หมายเลขแชท', value: item?.oldId, textStyle: { color: themeColor.color1 } },
          { id: 2, title: 'สาขาที่ให้บริการ', value: item?.shop?.shopThName, textStyle: {} },
          { id: 3, title: 'ผู้ให้บริการ', value: item?.pharmaUser?.displayName ?? '', textStyle: {} },
          { id: 4, title: 'ผู้รับบริการ', value: item?.user?.displayName ?? '', textStyle: {} },
          { id: 5, title: 'วันและเวลาที่รับบริการ', value: moment(item?.createdAt).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
        ].map(item3 => (
          <View key={item3.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <Text style={item3?.textStyle}>{item3?.title}</Text>
            </View>
            <View style={{ flex: 0.1 }}>
              <Text style={item3?.textStyle}>:</Text>
            </View>
            <View style={{ flex: 0.5 }}>
              <Text style={item3?.textStyle}>{item3?.value}</Text>
            </View>
          </View>
        ))}
      </View>
    </TouchableOpacity>
  )
}

function ShopDialog({ dialog, setDialog, onBack }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;

  useEffect(() => {
    loadDataList(0);
  }, [debouncedSearchTerm])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      var searchArray = searchText.split(/(\s+)/).filter(e => e.trim().length > 0)
      let query = { $select: ['id', 'shopIdBranch', 'shopThName'], shopStatus: 'A', $skip: pSkip ?? skip, $sort: { shopIdBranch: 1 }, $limit: 10, };
      if (searchArray.length > 0) {
        const tmpSearch = searchArray.join('%');
        query = {
          ...query,
          $or: [
            { shopIdBranch: { $like: `%${tmpSearch}%` } },
            { shopThName: { $like: `%${tmpSearch}%` } }
          ],
        };
      }
      let res;
      try {
        res = await globalState.client.service('shops').find({ query });
      } catch { }
      if (res) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res.data);
        } else {
          setDataList(prev => [...prev, ...res.data]);
        }
        setSkip((res.skip + res.limit));
      }
      setLoading(false);
    }
  }

  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visibleShop}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: dialogWidth * 1.5, paddingTop: 10, paddingBottom: 30,
          alignItems: 'center', borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <View style={{ width: dialogWidth, height: (dialogWidth * 1.5) - 30 }}>
            <TextInput
              value={searchText}
              onChangeText={text => setSearchText(text)}
              placeholder={'ชื่อหรือรหัสสาขา'}
              style={{
                alignSelf: 'center', borderColor: 'lightgray', borderWidth: 1, marginTop: 10, marginBottom: 5, height: 40,
                paddingHorizontal: 5, borderRadius: 5, backgroundColor: 'white', marginHorizontal: 20, width: (dialogWidth - 30)
              }}
            />
            <FlatList
              keyExtractor={(item) => item.id.toString()}
              data={dataList}
              onEndReached={(xxx) => loadDataList()}
              onEndReachedThreshold={0.5}
              onRefresh={() => loadDataList(0)}
              refreshing={(loading && skip == 0)}
              style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: 100 } : null]}
              renderItem={({ item }) => (<ShopItem item={item} setDialog={setDialog} onBack={onBack} />)}
            />
          </View>

          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visibleShop: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

function ShopItem({ item, setDialog, onBack }) {
  return (
    <TouchableOpacity
      style={{ marginVertical: 5, paddingHorizontal: 20, }}
      onPress={() => {
        onBack(item);
        setDialog(prev => ({ ...prev, visibleShop: false }));
      }}>
      <View style={{ flexDirection: 'row', borderBottomColor: 'lightgray', borderBottomWidth: 1, flex: 1 }}>
        <View style={{ flex: 0.3, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item.shopIdBranch}</Text>
        </View>
        <View style={{ flex: 0.7, paddingBottom: 10, paddingTop: 5 }}>
          <Text style={{ flex: 1, flexWrap: 'wrap' }}>{item.shopThName}</Text>
        </View>
      </View>
    </TouchableOpacity >
  )
}

function DialogList({ dialog, setDialog, onBackDialog, dialogWidth, dialogList, type }) {
  return (
    <View style={{ paddingHorizontal: 5, alignItems: 'center', marginTop: 20 }}>
      {dialogList?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          onBackDialog({ ...item }, type)
          setDialog(prev => ({ ...prev, visible: false }));
        }}>
          <View style={{
            width: dialogWidth - 30, backgroundColor: themeColor.color1, height: 30,
            justifyContent: 'center',
            paddingHorizontal: 10,
            marginVertical: 10, borderRadius: 5
          }}>
            <Text style={{ color: 'white' }}>{item?.title}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}
