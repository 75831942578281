import React, { useState, useCallback, useEffect, useContext } from 'react'
import { store } from "../sharewebapp/store";
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Typography } from 'antd';
import { message, Popconfirm } from 'antd';
import { Button, Modal } from 'antd';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import { Layout } from 'antd';
import { useLocation } from "react-router-dom";
import { deviceDetect } from "react-device-detect";
import { isMobile } from 'react-device-detect';
import { server_var, themeColor } from '../config/servervar';
import { Image, TouchableOpacity, Text, View, Linking } from 'react-native-web';

const { Header, Footer, Sider, Content } = Layout;

export const AppPage = () => {
  const { globalState } = useContext(store);
  const query = new URLSearchParams(useLocation().search);
  const screenName = query.get('screenName')
  const screenParam = query.get('screenParam')

  useEffect(() => { }, [])


  const openAllPharmaSeeApp = () => {
    try {
      window.location = 'allpharmasee://';
      // Linking.openURL('allpharmasee://')
    } catch (e) {
      alert('Error to open')
    }
  }

  const openAppStore = () => {
    if (deviceDetect()?.os?.toLowerCase() == 'ios') {
      window.location = 'https://apps.apple.com/th/app/all-pharmasee/id1468859352';
      // Linking.openURL('https://apps.apple.com/th/app/all-pharmasee/id1468859352')
    } else if (deviceDetect()?.os?.toLowerCase() == 'android') {
      window.location = 'https://play.google.com/store/apps/details?id=com.appexta1';
      // Linking.openURL('https://play.google.com/store/apps/details?id=com.appexta1')
    } else if (deviceDetect()?.isTablet && deviceDetect()?.vendor?.toLowerCase() == 'apple') {
      window.location = 'https://apps.apple.com/th/app/all-pharmasee/id1468859352';
      // Linking.openURL('https://apps.apple.com/th/app/all-pharmasee/id1468859352')
    } else {
      alert('ไม่สามารถเปิด Appstore หรือ Playstore ได้' + JSON.stringify(deviceDetect()))
    }
  }

  if (isMobile) {
    return (
      <View style={{ alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'white' }}>
        <img src="deep_header.png" style={{ width: '60%' }} />
        <img src="deep_phone.png" style={{ width: '60%', marginTop: '30px' }} />
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100vw', marginTop: '20%', }}>
          <TouchableOpacity
            style={{ borderWidth: 1, borderColor: 'gray', height: 40, width: '40%', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
            onPress={() => openAllPharmaSeeApp()}
          >
            <Text style={{ fontFamily: 'Kanit' }}>เปิดแอป</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ borderWidth: 1, borderColor: 'gray', height: 40, width: '40%', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}
            onPress={() => openAppStore()}
          >
            <Text style={{ fontFamily: 'Kanit' }}>ดาวน์โหลด</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  } else {
    return (
      <View style={{ alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: 'white' }}>
        <img src="deep_header.png" style={{ width: '60%' }} />
      </View>
    )
  }


};

