import React, { useEffect, useState, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList, Linking } from 'react-native';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomVideo } from '../component/custom';

export function ViewFaqPart({route,navigation}) {
  const { globalState, dispatch } = useContext(store);
  const [faqData, setFaqData] = useState(null);

  useEffect(() => {
    if (route?.params?.id > 0) {
      loadData();
    }
  }, [route?.params?.id])

  const loadData = async () => {
    let query = {
      id: route?.params?.id,
      $select: ['id', 'title', 'text', 'category', 'keyword', 'img', 'video', 'pdf']
    };
    const res = await globalState.client.service('faqs').find({ query });
    console.log('\nFAQ : ', route?.params?.id, res);
    if (res.total == 0) {
      dispatch({ type: 'showToast', payload: { text: 'ไม่มีข้อมูล FAQ นี้' }, });
      navigation.goBack();
    }
    setFaqData(res.data[0]);
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ marginVertical: 5, marginHorizontal: 20 }}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
          <Text style={{ color: themeColor.color1, fontSize: 16, fontWeight: 'bold', flex: 1, flexWrap: 'wrap' }}>{faqData?.title}</Text>
        </View>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
          <Text style={{ fontSize: 14, flex: 1, flexWrap: 'wrap' }}>{faqData?.text}</Text>
        </View>
        {faqData?.img && <ImgItem item={faqData?.img} navigation={navigation}/>}
        {faqData?.video && <VideoItem item={faqData?.video} />}
        {faqData?.pdf &&
          <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', marginTop: 15 }} onPress={() => Linking.openURL(faqData?.pdf)} >
            <Image style={{ width: 100, height: 100 }} source={{ uri: `${server_var.server_api}/images/pdf_file.png` }} resizeMode="contain" />
          </TouchableOpacity>
        }
      </ScrollView>
  </SafeAreaView>
  )
}

function ImgItem({ item,navigation }) {
  return (
    item.map((item2, index) => (
      <TouchableOpacity key={index.toString()} onPress={() => navigation.navigate('ViewImage1', { uri: item2.filename })} >
        <Image key={index.toString()} source={{ uri: item2.filename }} style={{ marginTop: 15, width: '100%', aspectRatio: 3 / 2, resizeMode: 'contain' }} />
      </TouchableOpacity>
    ))
  )
}

function VideoItem({ item }) {
  const videoRef = useRef()
  const videoWidth = 250;
  const [videoHeight, setVideoHeight] = useState(videoWidth);
  const updateVideoRatioOnDisplay = (videoDetails) => {
    const { width, height } = videoDetails.naturalSize;
    setVideoHeight(videoWidth / width * height);
  }

  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 15 }} >
      <CustomVideo
        ref={videoRef}
        style={{ width: videoWidth, height: videoHeight }}
        source={{ uri: item }}
        useNativeControls
        resizeMode="contain"
        isLooping={false}
        onReadyForDisplay={updateVideoRatioOnDisplay}
      />
    </View>
  )
}
