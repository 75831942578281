import React from 'react';
import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import {
  Text, View, TouchableOpacity, Image, TextInput, Button, Platform,
  Dimensions, FlatList, ActivityIndicator, Modal, ScrollView, SafeAreaView, Alert, AppState
} from 'react-native';

import SegmentedControlTab from '../sharewebapp/SegmentedControlTab';
import { server_var, themeColor, fontStyles } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon, useCustomFocusEffect, useCustomFocusEffectCallBack } from '../component/custom';
import { numberWithCommas, useDebounce } from '../sharewebapp/common1';
import moment from 'moment';
import { getJwtToken } from '../component/custom';
import { updateTc } from '../component/custom';
import { CustomHeaderBackButton } from '../component/custom';
import { ProductList7, BasketDialog } from './basketpart7ext1';
import { BasketAction } from './basketpart7ext2';

export function BasketPart7({ route, navigation }) {
  const [allowToBuyOnly, setAllowToBuyOnly] = useState(false);
  const [dirty, setDirty] = useState(true);
  const [mainWaitFlag, setMainWaitFlag] = useState(false);
  const [dialog, setDialog] = useState({ visible: false, data: null });
  const [proceedToOrderFlag, setProceedToOrderFlag] = useState(false);
  const [allMemCusInfo, setAllMemCusInfo] = useState();
  const { globalState, dispatch } = useContext(store);
  const [refreshNo, setRefreshNo] = useState(0);
  const appState = useRef(AppState.currentState);
  const onlyBasket = route?.params?.onlyBasket;
  const userId = route?.params?.userId ?? globalState?.user?.id;
  const shopId = route?.params?.shopId;
  const queId = route?.params?.queId;

  const pressToOrder = (params) => {
    navigation.navigate('OrderMain', params)
  }

  const onPressViewProduct = (productId) => {
    navigation.navigate('ViewProduct', { productId, userId, shopId, queId })
  }

  const onBack = async (value) => {
    console.log("onback" + JSON.stringify(value));
    console.log('allMemCusInfo', allMemCusInfo);
    if (value?.value == 'accept') {
      await updateTc(globalState?.user?.id, { [allMemCusInfo?.memcusType]: 'accept' });
      setProceedToOrderFlag(true);
    }
  }

  useCustomFocusEffectCallBack(() => {
    console.log('setrefresh no')
    setRefreshNo(Math.floor(Date.now() / 1000));
  }, [])

  useEffect(() => {
    const subscription = AppState.addEventListener("change", nextAppState => {
      if (
        appState.current.match(/inactive|background/) && nextAppState === "active"
      ) {
        setRefreshNo(Math.floor(Date.now() / 1000));
      }
      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, [])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <CustomHeaderBackButton {...props} />
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Text style={{ fontSize: 16, color: themeColor.color1 }}>กลับไปที่แชท</Text>
          </TouchableOpacity>
        </View>
      )
    })
  }, [])

  useEffect(() => {
    if (proceedToOrderFlag) {
      setProceedToOrderFlag(false);
      proceedToOrder();
    }
  }, [proceedToOrderFlag])

  const actionList = [
    { id: 2, title: 'ไม่ยอมรับ', onPressValue: { value: 'reject' } },
    { id: 1, title: 'ยอมรับ', onPressValue: { value: 'accept' } },
  ];

  const checkAllMemCusConsent = async () => {
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/checkFlow?flowType=payment`,
      {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      }
    );
    const resJson = await res.json();
    console.log(themeColor.FgMagenta, 'flowList', resJson?.flowList, themeColor.FgReset);
    const flowList = resJson?.flowList;
    if (flowList?.includes('allmem_consent1')) {
      setAllMemCusInfo({ memcusType: 'allmem_consent1', uri: globalState?.initInfo?.allmember?.consent1?.link })
      setDialog(prev => ({ ...prev, visible: true }))
      return
    }
    if (flowList?.includes('allcus_consent1')) {
      setAllMemCusInfo({ memcusType: 'allcus_consent1', uri: globalState?.initInfo?.allcustomer?.consent1?.link })
      setDialog(prev => ({ ...prev, visible: true }))
      return
    }
    setProceedToOrderFlag(true);
  }

  const proceedToOrder = async () => {
    let jwtToken;
    try {
      jwtToken = await getJwtToken();
    } catch (e) {
      alert('มีปัญหากับ jwtToken' + JSON.stringify(e));
      return
    }
    let result;
    let retData;
    setMainWaitFlag(true)
    try {
      const defHeader = {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken }
      };
      result = await fetch(`${server_var.server_api}/verifyBasketAndCheckLp7Deli`, {
        ...defHeader, body: JSON.stringify({ userId })
      });
      retData = await result.json();
      console.log('hello ', retData);
      setMainWaitFlag(false);
    } catch (error) {
      console.log(error);
      setMainWaitFlag(false)
    }

    if (retData?.result == 'ok_to_proceed') {
      pressToOrder({ passVerifyBasket: false });
    } else if (retData?.result == 'soldout') {
      Alert.alert('สินค้าในตะกร้ามีการเปลี่ยนแปลง', '\nสินค้าบางรายการมีการเปลี่ยนแปลง\nกรุณาเข้ารับการปรึกษากับเภสัชกรใหม่อีกครั้ง',
        [{ text: 'ยืนยัน', onPress: async () => console.log('ok') }]
      );
    } else {
      Alert.alert('สินค้าในตะกร้าไม่สามารถสั่งซื้อได้', (retData?.resultData?.errorMsgTh) ?? 'มีข้อผิดพลาดเกิดขึ้น',
        [{ text: 'ตกลง', onPress: async () => console.log('ok') }]
      );
    }
  }

  const TAB_LIST = [
    {
      id: 1, condition: !onlyBasket, title: 'ค้นหาสินค้า',
      prop: (visible) => ({ userId, shopId, queId, onPressViewProduct, visible, type: 'productlist' })
    },
    {
      id: 2, condition: true, title: 'ตะกร้า',
      prop: (visible) => ({
        userId, onlyBasket, pressToOrder, onPressViewProduct, shopId, setAllowToBuyOnly, refreshNo,
        allowToBuyOnly, dirty, setDirty, setMainWaitFlag, queId, dialog, setDialog,
        proceedToOrder: checkAllMemCusConsent,
        visible, type: 'basketlist'
      })
    },
  ]
  const tabList = TAB_LIST.filter(item => item.condition);
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <View style={{ flex: 1 }}>
      {tabList.length > 1 && (
        <SegmentedControlTab
          values={TAB_LIST.filter(item => item.condition).map(item => item.title)}
          textNumberOfLines={1}
          selectedIndex={tabIndex}
          tabTextStyle={{ textAlign: 'center', color: themeColor.color1 }}
          onTabPress={(index) => {
            if (allowToBuyOnly && index == 0) {
              alert("ไม่สามารถค้นหาสินค้าได้ ขณะที่ให้ลูกค้ารอจ่ายเงิน")
            } else {
              setTabIndex(index)
            }
          }}
          tabsContainerStyle={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
          activeTabStyle={{ backgroundColor: themeColor.color1 }}
          tabStyle={{ borderColor: themeColor.color1 }}
        />
      )}
      {TAB_LIST.map(item => <GenTag key={item.id.toString()} {...item.prop(tabIndex == (item.id - 1))} navigation={navigation} />)}

      {mainWaitFlag && (
        <View style={{
          position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', opacity: 0.5
        }}>
          <ActivityIndicator size={'large'} color="black" />
        </View>
      )}
      <BasketDialog dialog={dialog} setDialog={setDialog} onBack={onBack} uri={allMemCusInfo?.uri} actionList={actionList} />
    </View >
  )
}

function BasketList({
  userId, onlyBasket, pressToOrder, onPressViewProduct, shopId, setAllowToBuyOnly,
  refreshNo, allowToBuyOnly, dirty, setDirty, setMainWaitFlag, queId, dialog,
  setDialog, proceedToOrder, visible, navigation
}) {
  const { globalState } = useContext(store);
  const [basketList, setBasketList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [priceInfo, setPriceInfo] = useState({ totalPrice: 0, discountPrice: 0, discountCouponPrice: 0, returnCode: '0000', returnMessage: '' });

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, []);

  useEffect(() => {
    if (refreshNo != 0) {
      console.log('hello1', refreshNo);
      loadBasketList({ refreshBasket: true, calcTotalPrice: false, checkLp: false, label: 'useeffect' })
    }
  }, [refreshNo])


  const onNotiListener = async (payload) => {
    if (payload.toUserId == globalState.user.id && payload.type == 'RefreshBasket') {
      loadBasketList({ refreshBasket: true, calcTotalPrice: false, checkLp: false, label: 'notilistner' })
      setAllowToBuyOnly(payload?.data?.allowToBuyOnly);
      return;
    }
    if (payload.toUserId == globalState.user.id && payload.type == 'OrderPaidRepeatNoti') {
      alert("รายการสั่งซื้อสินค้านี้ได้รับการชำระเงินแล้ว");
      navigation.goBack(2);
      return;
    }
  }

  const loadBasketList = async ({ refreshBasket, calcTotalPrice, checkLp, label }) => {
    if (!loading) {
      let jwtToken;
      try {
        jwtToken = await getJwtToken();
      } catch (e) {
        alert('มีปัญหากับ jwtToken' + JSON.stringify(e));
        return
      }
      const defHeader = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken }
      };
      setLoading(true);
      let res;
      try {
        const fetchResult = await fetch(
          `${server_var.server_api}/loadBasket7Deli?userId=${userId}` +
          `${refreshBasket ? '&refreshBasket=1' : ''}` +
          `${calcTotalPrice ? '&calcTotalPrice=1' : ''}` +
          `${checkLp ? '&checkLp=1' : ''}`,
          defHeader
        );
        res = await fetchResult.json();
        console.log(themeColor.FgRed, 'ABC!!!!res!!!' + JSON.stringify(res, '', 2), themeColor.FgReset);
      } catch {
        alert('มีปัญหาในการดึงข้อมูลตะกร้าสินค้า กรุณาตรวจสอบการเชื่อมต่อ internet ของท่าน เข้าหน้านี้ใหม่อีกครั้ง')
        setLoading(false);
        return;
      }
      if (res.returnCode != '0000') {
        alert('มีปัญหาการสั่งซื้อ\n\nรหัสผิดพลาด:' + res?.returnCode + '\n\nข้อความ:' + res?.returnMessage);
      }
      if (refreshBasket) {
        setBasketList(res?.baskets?.data);
        console.log('check flag', refreshBasket, calcTotalPrice, checkLp, label);
      }
      console.log(
        themeColor.FgGreen, 'loadBasket', label, refreshBasket,
        calcTotalPrice, checkLp, res?.totalPrice, res?.discountPrice, themeColor.FgReset
      );
      const { totalPrice, discountPrice, discountCouponPrice, returnCode, returnMessage, ...rest } = res;
      setPriceInfo({ totalPrice, discountPrice, discountCouponPrice, returnCode, returnMessage });

      setLoading(false);
    }
  }

  const changeAmount = async (pAmount, id) => {
    const res = await globalState.client.service('baskets').get(id)
    const newAmount = res.amount + pAmount
    if (newAmount < 1) {
      await globalState.client.service('baskets').remove(id);
    } else {
      await globalState.client.service('baskets').patch(id, { amount: newAmount, totalPrice: newAmount * res.unitPrice });
    }
    loadBasketList({ refreshBasket: true, calcTotalPrice: true, checkLp: false, label: 'changeamount' });
    setDirty(true);
  }
  if (!visible) {
    return <View />
  } else {
    return (
      <View style={{ flex: 1, flexDirection: 'column' }}>
        {(!basketList || basketList.length == 0) && (
          <View style={{
            position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, justifyContent: 'center', alignItems: 'center'
          }}>
            <Text style={{ color: 'gray', fontSize: 16, fontWeight: 'bold' }}>
              ยังไม่มีสินค้าในตระกร้า
            </Text>
          </View>
        )}
        {onlyBasket && (basketList && basketList.length > 0) && (
          <View style={{ paddingHorizontal: 20, paddingTop: 10, paddingBottom: 10 }}>
            <Text style={{ fontWeight: 'bold' }}>ตะกร้าของฉัน</Text>
            <Text style={{ color: themeColor.color1, fontSize: 12 }}>
              (ตะกร้าของท่านจะถูกยกเลิกโดยอัตโนมัติ หากไม่ชำระเงินภายใน {moment(basketList?.[0]?.ordermain?.orderDate).utc().add(7, 'hours').add(30, 'minutes').format('HH:mm')}
              )
            </Text>
          </View>
        )}
        <FlatList
          keyExtractor={(item) => (item.id.toString() + item.amount.toString())}
          data={basketList}
          onEndReachedThreshold={0.5}
          refreshing={loading}
          style={[Platform.OS == 'web' ? { height: '60vh' } : null]}
          renderItem={({ item }) => (
            <BasketItem item={item} changeAmount={changeAmount} onlyBasket={onlyBasket} onPressViewProduct={onPressViewProduct} allowToBuyOnly={allowToBuyOnly} />
          )}
          {...loading ? null : { onRefresh: () => loadBasketList({ refreshBasket: true, calcTotalPrice: true, checkLp: false, label: 'refresh' }) }}
        // onRefresh={() => loadBasketList({ refreshBasket: true, calcTotalPrice: true, checkLp: false, label: 'refresh' })}
        />
        {(
          <BasketAction basketList={basketList} onlyBasket={onlyBasket} userId={userId}
            shopId={shopId} setAllowToBuyOnly={setAllowToBuyOnly} loadBasketList={loadBasketList}
            allowToBuyOnly={allowToBuyOnly} dirty={dirty} setDirty={setDirty}
            setMainWaitFlag={setMainWaitFlag} queId={queId}
            proceedToOrder={proceedToOrder} navigation={navigation}
            priceInfo={priceInfo}
          />
        )}
      </View>
    )
  }
}

function BasketItem({ item, changeAmount, onlyBasket, onPressViewProduct, allowToBuyOnly }) {
  let imageUrl = `${server_var.server_api}/images/no_image_available.jpeg`;
  if (item?.product?.productPhoto1) {
    if (item.product.productPhoto1.includes('http')) {
      imageUrl = item.product.productPhoto1;
    } else {
      imageUrl = `${server_var.server_api}/${item.product.productPhoto1}`;
    }
  }

  return (
    <View key={item.id.toString()} style={{
      flexDirection: 'row', alignSelf: 'center', alignItems: 'center', width: "90%", justifyContent: 'space-between', marginVertical: 5,
    }}>
      <TouchableOpacity style={{ flex: 0.7 }} onPress={() => {
        onPressViewProduct(item.productId)
      }}>
        <View style={{ flexDirection: 'row' }}>
          <Image
            source={{ uri: imageUrl, headers: { headertemp: server_var.deliProdMode == 'uat' ? server_var.headertempUat : server_var.headertempProd } }}
            style={{ width: 60, height: 60, marginRight: 10 }} resizeMode="contain"
          />
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <Text style={{ flexWrap: 'wrap', fontSize: 12 }}>{item?.product?.thProductName}</Text>
            </View>
            <Text style={{ marginTop: 5, color: themeColor.color1 }}>฿{numberWithCommas(item.unitPrice)}</Text>
          </View>
        </View>
      </TouchableOpacity>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 0.3 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {!onlyBasket && (
            <TouchableOpacity onPress={() => {
              if (!allowToBuyOnly) {
                changeAmount(-1, item.id)
              }
            }}>
              <CustomIcon name="minus-circle" color={allowToBuyOnly ? 'gray' : themeColor.color1} size={20} />
            </TouchableOpacity>
          )
          }
          <Text style={[
            { marginHorizontal: 10, width: 50, textAlign: 'right', borderRadius: 5 },
            !onlyBasket ? { backgroundColor: 'white' } : null
          ]}>
            {onlyBasket ? 'x' : ''}{item.amount.toString()}
          </Text>
          {!onlyBasket && (
            <TouchableOpacity onPress={() => {
              if (!allowToBuyOnly) {
                changeAmount(1, item.id)
              }
            }}>
              <CustomIcon name="plus-circle" color={allowToBuyOnly ? 'gray' : themeColor.color1} size={20} />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

function GenTag(props) {
  if (props.type == "productlist") {
    return (<ProductList7 {...props} />);
  } else if (props.type == 'basketlist') {
    return <BasketList {...props} />
  }
}
